import { Spinner} from '@chakra-ui/react';
import { useGetAdsetFormQuery, useGetAdsetByIDQuery } from 'store/campaigns/adsetFormApi';
import { useGetcampaignByIdQuery } from 'store/campaigns/campaignsApi';
import LeadUpdateForm from './LeadUpdateForm';
import SalesUpdateForm from './SalesUpdateForm';

const AdSetUpdateForm = ({ selectedAdset, adsAccountData, onClose, onSaveSuccess }) => {

    const { campaign_id : campaignId} = selectedAdset || {};
    
    const { data: campaignData } = useGetcampaignByIdQuery(
        {campaignId, adsAccountId:adsAccountData?.account_id},
        { skip: !campaignId }
    );

    const campaignType = campaignData?.[campaignId]?.objective;

    const { data: adsetData, isLoading:isAdsetLoading } = useGetAdsetByIDQuery({
        adsAccountId: adsAccountData?.account_id,
        adsetId:selectedAdset?.adset_id
    }, { skip: !adsAccountData?.account_id || !selectedAdset?.adset_id });

    const { data: dataSales, error: errorSales, isLoading: isLoadingSales } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_SALES' }, { skip: campaignType !== 'OUTCOME_SALES' });
    const { data: dataLeads, error: errorLeads, isLoading: isLoadingLeads } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_LEADS' }, { skip: campaignType !== 'OUTCOME_LEADS' });    

    const renderForm = () => {
  
        if (isLoadingSales || isLoadingLeads || isAdsetLoading) {
            return (
                <Spinner size={'md'} />
            );
        }

        if (campaignType === 'OUTCOME_SALES' && dataSales?.data && campaignData) {
            return (
                <SalesUpdateForm
                    adsAccountData={adsAccountData}
                    data={dataSales?.data}
                    campaignData={campaignData}
                    selectedAdset={adsetData}
                    onEditModalClose={onClose}
                    onClose={onClose}
                    context="adsetEdit"
                    onSaveSuccess={onSaveSuccess}
                />
            );
        } else if (campaignType === 'OUTCOME_LEADS' && dataLeads?.data && campaignData) {
            return (
                <LeadUpdateForm
                    adsAccountData={adsAccountData}
                    data={dataLeads?.data}
                    campaignData={campaignData}
                    selectedAdset={adsetData}
                    onEditModalClose={onClose}
                    onClose={onClose}
                    context="adsetEdit"
                    onSaveSuccess={onSaveSuccess}
                />
            );
        }

        return null;
    };

    return (
        <>
         {renderForm()}
        </>    
    );
};

export default AdSetUpdateForm;
