import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Flex, Text, Box, Spinner, Alert, Tab, TabList, TabPanels, TabPanel, Tabs, Popover, PopoverTrigger, PopoverContent, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { SlGraph } from 'react-icons/sl';
import { useGetAudienceEstimateListMutation } from 'store/campaigns/adsetFormApi';

function Audience({ adsAccount, targeting_spec, optimizationGoal }) {

    const [selectedTab, setSelectedTab] = useState('overview');
    const backgroundColor = useColorModeValue('gray.50', 'gray.800');

    // const { adsAccountId, businessId } = adsAccount;
    const serializedTargetingSpec = encodeURIComponent(JSON.stringify(targeting_spec));
    const geoLocationList = targeting_spec?.geo_locations || null;

    const [getAudienceEstimate, { data: audienceList, isLoading, error }] = useGetAudienceEstimateListMutation();

    // const { data: audienceList, isLoading, error } = useGetAudienceEstimateListQuery({
    //     adsAccountId: adsAccount,
    //     targeting_spec: serializedTargetingSpec
    // },
    //     { skip: !geoLocationList }
    // );

    useEffect(() => {
        if (geoLocationList) {
            getAudienceEstimate({
                adsAccountId: adsAccount,
                payload: {
                    optimization_goal : optimizationGoal,
                    targeting_spec: targeting_spec
                }
            });
        }
    }, [targeting_spec]);


    return (
        <Box>
            <Flex borderRadius="md" bg={backgroundColor} py={'10px'} px={'20px'} direction={'column'}>
                <Flex align="center">
                    <Text fontWeight="bold">Audience definition</Text>
                    <Popover placement="bottom" closeOnBlur={true}>
                        <PopoverTrigger>
                            <InfoOutlineIcon pointerEvents="all" ml={2} />
                        </PopoverTrigger>
                        <PopoverContent>
                            <Box fontSize="sm" p={'10px'} bg={'gray.700'}>
                                <Text color={'#fff'}>
                                    <strong>Audience definition</strong> <br />
                                    The audience definition scale indicates whether an audience is specific or broad based on the audience selections that you added. When an audience is too specific, ad delivery may be affected.
                                </Text>
                            </Box>
                        </PopoverContent>
                    </Popover>
                </Flex>

                {isLoading ? (
                    <Box mt={4}>
                        <Spinner size="sm" />
                    </Box>
                ) : error ? (
                    <Box mt={4}>
                        <Text fontSize={'12px'}> There was no audience estimate yet.</Text>
                    </Box>
                ) : (
                    <Box mt={4}>
                        {audienceList?.data?.[0]?.estimate_ready ? (
                            <Flex direction={'column'} gap={2}>
                                <Flex align="center">
                                    <Text fontSize={'11px'} letterSpacing={-0.5}>
                                        Estimated audience size: {audienceList.data[0].estimate_mau_lower_bound.toLocaleString()} - {audienceList.data[0].estimate_mau_upper_bound.toLocaleString()}
                                    </Text>
                                    <Popover placement="bottom-end" closeOnBlur={true}>
                                        <PopoverTrigger>
                                            <InfoOutlineIcon pointerEvents="all" ml={2} />
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <Box fontSize="sm" p={'5px'} bg={'gray.700'}>
                                                <Tabs size="sm" isLazy onChange={(index) => setSelectedTab(index === 0 ? 'overview' : 'details')} color={'#fff'} colorScheme={'orange'}>
                                                    <Text margin={2} fontWeight={'bold'}>How this number is estimated</Text>
                                                    <TabList>
                                                        <Tab>Overview</Tab>
                                                        <Tab>Details</Tab>
                                                    </TabList>
                                                    <TabPanels>
                                                        <TabPanel>
                                                            {selectedTab === 'overview' && (
                                                                <Text color={'#fff'}>
                                                                    Estimated audience size is an estimate of how many Accounts Centre accounts may meet your targeting criteria. It is based on factors such as targeting selections, ad placements and how many Accounts Centre accounts were shown ads on Meta apps and services in the past 30 days.<br></br>
                                                                    This is not an estimate of how many people will see your ad and is not designed to match population or census data. This metric is in in development.
                                                                </Text>
                                                            )}
                                                        </TabPanel>
                                                        <TabPanel>
                                                            {selectedTab === 'details' && (
                                                                <Text color={'#fff'} lineHeight="1.4">
                                                                    This estimate is affected by the ad placements you've selected:<br />
                                                                    <strong>Facebook:</strong> Feed, Profile feed, In-stream videos, Right column, Video feeds, Marketplace, Stories, Overlay ads in Reels, Search results and Reels <br />
                                                                    <strong>Instagram:</strong> Feed, Stories, Explore, Explore home, Reels, Profile feed and Search results <br />
                                                                    <strong>Messenger:</strong>Messenger inbox and Stories <br />
                                                                    <strong>Audience Network:</strong> Native, banner and interstitial and Rewarded videos <br />
                                                                </Text>
                                                            )}
                                                        </TabPanel>
                                                    </TabPanels>
                                                </Tabs>
                                            </Box>
                                        </PopoverContent>
                                    </Popover>
                                </Flex>
                                <Flex gap={2}>
                                    <SlGraph fontSize="30px" />
                                    <Text fontSize={'11px'}>
                                        Estimates may vary significantly over time based on your targeting selections and available data and do not reflect Advantage audience options.
                                    </Text>
                                </Flex>
                            </Flex>
                        ) : (
                            <Text fontSize={'12px'}>No audience estimatation available yet.</Text>
                        )}
                    </Box>
                )}
            </Flex>
        </Box>
    );
}

export default Audience;
