import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table, TableContainer,
  Tabs,
  Tbody, Td,
  Text,
  Textarea,
  Tooltip,
  Tr,
  UnorderedList,
  useDisclosure,
  useToast,
  Checkbox,
  useColorModeValue,
  InputRightAddon,
  InputGroup,
  Link,
  Spacer
} from '@chakra-ui/react';

import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';

import { FiFile, FiImage } from "react-icons/fi";

import { InfoOutlineIcon, AddIcon } from '@chakra-ui/icons';
import { ErrorMessage, FieldArray, Form, Formik, useFormik } from 'formik';

import { SingleDatepicker } from "chakra-dayzed-datepicker";
import AppTable from 'components/AppTable';
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import FileUploadBox from 'components/FileUploadBox';
import SidebarWithHeader from 'components/SidebarWithHeader';
import moment from "moment";
import { FiDelete, FiEdit2, FiEye } from "react-icons/fi";
import { useGetAllAccountsQuery, useGetAccountsByUserIDQuery } from 'store/adAccounts/adAccountsApi';
import {
  useAddNewTopUpRequestMutation,
  useApproveTopUpRequestMutation,
  useGetTopUpRequestsQuery,
  useGetTransferListQuery,
  useRemoveTopUpRequestMutation,
  useReplyToTopUpRequestMutation,
  useTransferAccountBalanceMutation,
  useUpdateTopUpRequestMutation,
  useUpdateTopUpStatusMutation,
  useUploadTopUpFileMutation,
  usePrefetch,
  useGetTransactionsQuery
} from 'store/topUp/topUpApi';
import { approveTopUpSchema, replyToRequestSchema, topUpRequestSchema, transferFormSchema } from 'utils/schema';

import CircleIcon from 'components/CircleIcon';
import LightBox from 'components/LightBox';
import MultiFileUploadButton from 'components/MultiFileUploadButton';
import SelectColumnFilter from 'components/SelectColumnFilter';
import { useSelector } from 'react-redux';
import { useGetCompaniesByUserIDQuery, useGetBankListQuery } from 'store/companies/companiesApi';
import AppTableWithPagination from 'components/AppTableWithPagination';
import { parse, parseISO } from 'date-fns'

function TopUpsForClient() {
  const btnRef = React.useRef()
  const approveFormRef = React.useRef();
  const editFormRef = useRef();
  const iconColor = useColorModeValue('gray.400', 'gray.500');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isRequestModalOpen,
    onOpen: onRequestModalOpen,
    onClose: onRequestModalClose
  } = useDisclosure();

  const toast = useToast()
  const [activeRequest, setactiveRequest] = useState(null);
  const [topUpToApprove, settopUpToApprove] = useState(null);

  const [fileSource, setfileSource] = useState(null);
  const [files, setfiles] = useState([]);
  const [replyFiles, setreplyfiles] = useState([]);
  const [requestToDelete, setrequestToDelete] = useState(null);
  const [selectedFromAccount, setselectedFromAccount] = useState(null);

  const checkTextColor = useColorModeValue("gray.600", "gray.50");

  const userRole = useSelector((state) => state.auth?.userInfo?.role);

  const userInfo = useSelector((state) => state.auth?.userInfo);
  const { _id: clientId } = userInfo;

  const scrollBoxRef = useRef();
  const requestFormRef = useRef()
  const scrollToBottom = () => {
    scrollBoxRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  useEffect(() => {
    if (activeRequest) {
      setTimeout(() => {
        scrollToBottom();
      }, 10);
    }
  }, [activeRequest]);

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose
  } = useDisclosure();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const {
    isOpen: isDetailsModalOpen,
    onOpen: onDetailsModalOpen,
    onClose: onDetailsModalClose
  } = useDisclosure();

  const {
    isOpen: isApproveModalOpen,
    onOpen: onApproveModalOpen,
    onClose: onApproveModalClose,
  } = useDisclosure();

  const {
    isOpen: isTransferModalOpen,
    onOpen: onTransferModalOpen,
    onClose: onTransferModalClose,
  } = useDisclosure();


  let statusBadgeColor = 'gray';

  if (activeRequest?.status === 'pending') {
    statusBadgeColor = 'red';
  } else if (activeRequest?.status === 'approved') {
    statusBadgeColor = 'orange';
  } else if (activeRequest?.status === 'toppedup') {
    statusBadgeColor = 'green';
  } else if (activeRequest?.status === 'open') {
    statusBadgeColor = 'yellow';
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setsearchValue] = useState('')

  /* QUERIES */

  const {
    data: requests,
    error: getRequestsError,
    isLoading: isTopUpRequestsLoading,
    refetch, isFetching
  } = useGetTopUpRequestsQuery({ currentPage, searchValue }, {
    pollingInterval: 20000,
    skipPollingIfUnfocused: true,
  });

  const [addNewTopUpRequest, newTopUpResponse] = useAddNewTopUpRequestMutation();

  // console.log({ activeRequest });

  // const {
  //   data: accounts,
  //   error: getAccountsError,
  //   isLoading: isAccountsDataLoading
  // } = useGetAllAccountsQuery();

  const {
    data: accounts,
    error: getAccountsError,
    isLoading: isAccountsDataLoading
  } = useGetAccountsByUserIDQuery({ userId: activeRequest?.clientId?.id }, {
    skip: !activeRequest
  });

  // const {
  //   data: companies,
  //   error: getCompaniesError,
  //   isLoading: isCompaniesDataLoading
  // } = useGetAllCompaniesQuery();

  const {
    data: companies,
    error: getCompaniesError,
    isLoading: isCompaniesDataLoading,
    refetch: refetchUserCompanies
  } = useGetCompaniesByUserIDQuery({ userId: activeRequest?.clientId?.id }, {
    skip: !activeRequest
  });

  const {
    data: transferList,
    error: transferListError,
    isLoading: isTransferListLoading
  } = useGetTransferListQuery();
  
  const {
    data: transactionsList,
    error: transactionsError,
    isLoading: isTransactionsListLoading
  } = useGetTransactionsQuery();

  const {
    data: savedBankList,
    isLoading: isSavedBankListLoading
  } = useGetBankListQuery();



  const [selectedOptions, setSelectedOptions] = useState(accounts?.data);

  const [uploadTopUpFile, uploadResponse] = useUploadTopUpFileMutation()
  const [replyToTopUpRequest, replyResponse] = useReplyToTopUpRequestMutation();
  const [updateTopUpStatus, updateStatusResponse] = useUpdateTopUpStatusMutation();
  const [removeTopUpRequest, removeResponse] = useRemoveTopUpRequestMutation();
  const [approveTopUpRequest, approvalResponse] = useApproveTopUpRequestMutation();
  const [updateTopUpRequest, editResponse] = useUpdateTopUpRequestMutation()
  const [transferAccountBalance, transferResponse] = useTransferAccountBalanceMutation();
  const [selectedAccountType, setselectedAccountType] = useState('');
  const [selectedCompanyDetails, setselectedCompanyDetails] = useState(null);
  const [selectedCompanyAccountTypes, setselectedCompanyAccountTypes] = useState([]);

  useEffect(() => {
    if (selectedAccountType) {
      setSelectedOptions(accounts?.data?.filter((account, index) => account.accountType == selectedAccountType && account?.company?.id == selectedCompanyDetails?._id && account.accountStatus == 1));
    }
  }, [selectedAccountType])



  //console.log({ activeRequest });

  const getCompanyDetails = (name) => {

    const details = companies?.filter(({ companyName }, index) => companyName == name)[0]?.commission;



    if (Object.keys(details)?.length > 0) {
      setselectedCompanyAccountTypes(Object.keys(details))
    }
  }

  useEffect(() => {
    const hasSingleType = selectedCompanyAccountTypes?.length == 1

    if (hasSingleType && requestFormRef.current) {
      selectedCompanyAccountTypes.includes('cc')
        ? requestFormRef.current.setFieldValue('accountType', 'Credit Card')
        : requestFormRef.current.setFieldValue('accountType', 'Credit Line')
    }


  }, [selectedCompanyAccountTypes])



  const [initialValues, setinitialValues] = useState({
    companyName: "",
    percentSplit: [
      {
        id: '',
        percent: '',
      },
    ],
    currency: "",
    amount: "",
    paymentEntityName: "",
    paymentProvider: "",
    paymentProviderOther: "",
    transferredFrom: "",
    transactionId: "",
    paymentReceipt: "",
    transferredTo: "",
    paymentDate: "",
    amountReceived: "",
    exemptGst: false,
    topupDate: ""
  });

  // console.log({ activeRequest });
  useEffect(() => {
    if (activeRequest) {
      const {
        percentSplit,
        currency,
        amount,
        paymentEntityName,
        paymentProvider,
        paymentProviderOther,
        transactionId,
        paymentReceipt,
        transferredTo,
        transferredFrom,
        paymentDate, amountReceived, company, accountType, exemptGst, topupDate } = activeRequest;

      const modifiedSplits = percentSplit.map(split => ({
        ...split,
        title: getAdAccountTitle(split.id)
      }));


      setinitialValues({
        companyName: company?.name,
        percentSplit: modifiedSplits,
        currency,
        amount,
        paymentEntityName,
        paymentProvider,
        paymentProviderOther,
        transactionId,
        paymentReceipt,
        transferredFrom,
        transferredTo,
        accountType: accountType == 'cl' ? 'Credit Line' : 'Credit Card',
        paymentDate: moment(paymentDate).toDate(),
        amountReceived: amountReceived ? amountReceived : 0,
        exemptGst,
        topupDate: moment(topupDate).toDate()
      })

      setfileSource(paymentReceipt);
      const filteredCompany = companies?.filter((item, index) => item._id == company?.id)
      if (filteredCompany) {
        setselectedCompanyDetails(filteredCompany[0])
      }

    }

  }, [activeRequest])

  useEffect(() => {
    if (searchValue != '') {
      setCurrentPage(1);
    }
  }, [searchValue])

  const companyOptions = companies?.filter((item, index) => item.clientId?.id == activeRequest?.clientId?.id && item.status == true).map(({ _id, companyName }, index) => (
    <option value={companyName} key={_id}>{companyName}</option>
  ))

  const companyAllOptions = companies?.filter((item, index) => item.status == true).map(({ _id, companyName }, index) => (
    <option value={companyName} key={_id}>{companyName}</option>
  ))

  //console.log({ companies });
  //console.log({ selectedOptions });


  useEffect(() => {
    if (uploadResponse?.isSuccess) {
      toast({
        position: "top-right",
        title: "File uploaded",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (uploadResponse.status == "rejected") {

      toast({
        position: "top-right",
        title: "Error",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [uploadResponse]);

  useEffect(() => {
    //console.log(newTopUpResponse);
    if (editResponse?.isSuccess) {
      editFormRef?.current?.resetForm();
      toast({
        position: "top-right",
        title: "Request placed",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (editResponse.status == "rejected") {
      editFormRef?.current?.resetForm();
      const {
        data: { message },
      } = editResponse.error;
      //console.log(message);
      const isDuplicateTransactionID = message?.includes('duplicate key');
      toast({
        position: "top-right",
        title: "Error",
        description: isDuplicateTransactionID ? 'Transaction ID already exists' : message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
  }, [editResponse]);

  useEffect(() => {
    //console.log(newTopUpResponse);
    if (newTopUpResponse?.isSuccess) {
      toast({
        position: "top-right",
        title: "Request placed",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (newTopUpResponse.status == "rejected") {
      const {
        data: { message },
      } = newTopUpResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
  }, [newTopUpResponse]);

  useEffect(() => {
    //console.log(newTopUpResponse);
    if (removeResponse?.isSuccess) {
      toast({
        position: "top-right",
        title: "Request removed",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (removeResponse.status == "rejected") {
      const {
        data: { message },
      } = removeResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [removeResponse]);

  useEffect(() => {
    // //console.log(replyResponse);
    if (replyResponse.status == "rejected") {

      toast({
        position: "top-right",
        title: "Error",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
    if (replyResponse?.data) {
      const { data: { _id } } = replyResponse.data;
      if (activeRequest?._id == _id) {
        setactiveRequest(replyResponse?.data?.data)
      }
      toast({
        position: "top-right",
        title: "Reply sent",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [replyResponse]);

  useEffect(() => {
    if (transferResponse.status == "rejected") {
      toast({
        position: "top-right",
        title: "Error",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
    if (transferResponse?.data) {

      toast({
        position: "top-right",
        title: "Request placed",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [transferResponse]);

  const columns = useMemo(
    () => [
        {
            Header: 'Order ID',
            accessor: 'order_id',
            Filter: '',
            filter: '',
            Cell: ({ row: { original } }) => (
                <Text>{original?.order_id}</Text>
            ),
        },
        {
            Header: "Ad Account",
            accessor: "",
            Filter: "",
            filter: "",
            Cell: ({ row: { original } }) => (
                <Flex direction={'column'}>
                     <Text fontSize={'14px'}>{original?.adsAccount?.name}</Text>
                     <Text fontSize={'12px'}>{original?.adsAccount?.adsAccountId}</Text>        
                </Flex>       
            )
        },
        {
            Header: "Company Name",
            accessor: "",
            Filter: "",
            filter: "",
            Cell: ({ row: { original } }) => {

                return (
                    <Text fontSize={'12px'}>{original.company?.name}</Text>
                )
            }
        },
        {
            Header: 'currency',
            accessor: 'currency',
            Filter: '',
            filter: '',
            Cell: ({ row: { original } }) => {

                return (
                    <Text fontSize={'12px'}>{original.order_currency}</Text>
                )
            }
        },
        {
            Header: 'Order amount',
            accessor: 'amount',
            Filter: '',
            filter: '',
            Cell: ({ row: { original } }) => {

                return (
                    <Text fontSize={'12px'}>{original.order_amount}</Text>
                )
            }
        },
        {
            Header: 'Payment Method',
            accessor: 'payment_method',
            Filter: '',
            filter: '',
            Cell: ({ row: { original } }) => {

                return (
                    <Text fontSize={'12px'}>{original.payment_method}</Text>
                )
            }
        },
        {
          Header: 'Order Status',
          accessor: 'order_status',
          Filter: '',
          filter: '',
          Cell: ({ row: { original } }) => {

              return (
                  <Text fontSize={'12px'}>{original.order_status}</Text>
              )
          }
        },
        // {
        //     Header: "Commission",
        //     accessor: "",
        //     Filter: "",
        //     filter: "",
        //     Cell: ({ row: { original } }) => {
        //         const totalCommision = original?.percentSplit.reduce((acc, currentValue) => acc + Number(currentValue.commission), 0);
        //         //console.log(original);
        //         return (
        //             <Text fontSize='sm' style={{ textTransform: 'capitalize' }}>{totalCommision}</Text>
        //         )
        //     }
        // },
        // {
        //     Header: "Topup",
        //     accessor: "",
        //     Filter: "",
        //     filter: "",
        //     Cell: ({ row: { original } }) => {
        //         const totalTopup = original?.percentSplit.reduce((acc, currentValue) => acc + Number(currentValue.topup), 0);
        //         //console.log(original);

        //         return (
        //             <>
        //                 <Text>
        //                     {totalTopup}&nbsp;
        //                     <Tooltip
        //                         fontSize='lg'
        //                         borderRadius={5}
        //                         label={
        //                             <Box fontSize={"sm"} p={"5px"}>
        //                                 {original?.percentSplit.map((account, index) => (
        //                                     <Box key={index}>
        //                                         <Box>
        //                                             <Text>Ad Account: {account.id}</Text>
        //                                             <Text>Topup amount: {account.topup}</Text>
        //                                             <Text>Commission amount: {account.commission}</Text>
        //                                             <Text>Commission %: {account.percent}</Text>
        //                                         </Box>
        //                                         {index > 0 ? (
        //                                             <Divider my={'10px'} />
        //                                         ) : null}
        //                                     </Box>
        //                                 ))}
        //                             </Box>
        //                         }
        //                     >
        //                         <InfoOutlineIcon color={'gray.600'} />
        //                     </Tooltip>
        //                 </Text>

        //             </>
        //         )
        //     }
        // },
        // {
        //     Header: "Status",
        //     accessor: "status",
        //     Filter: SelectColumnFilter,
        //     filter: 'includes',
        //     disableSortBy: true,
        //     Cell: ({ row: { original } }) => {
        //         let badgeColor = 'gray';
        //         if (original?.status == 'pending') {
        //             badgeColor = 'red';
        //         } else if (original?.status == 'approved') {
        //             badgeColor = 'orange';
        //         } else if (original?.status == 'toppedup') {
        //             badgeColor = 'green';
        //         } else if (original?.status == 'open') {
        //             badgeColor = 'yellow';
        //         }
        //         return (
        //             <>
        //                 <Badge
        //                     fontSize={"10px"}
        //                     marginRight={1}
        //                     colorScheme={badgeColor}
        //                 >
        //                     {original.status == 'toppedup' ? 'topped up' : original.status}
        //                 </Badge>
        //                 {original?.status != 'toppedup' ? (
        //                     <>
        //                         {original.clientRead ? <CircleIcon boxSize={2} color='green.500' /> : ""}
        //                     </>
        //                 ) : null}
        //             </>
        //         )
        //     }
        // },
        {
            Header: 'created',
            accessor: 'createdDate',
            Filter: '',
            filter: '',
            Cell: ({ row: { original } }) => (
                <Text>{moment(original.createdDate).format("DD-MM-YYYY hh:mm A")}</Text>
            )
        },
        {
            Header: 'updated',
            accessor: 'updatedDate',
            Filter: '',
            filter: '',
            Cell: ({ row: { original } }) => (
                <Text>{moment(original.updatedDate).format("DD-MM-YYYY hh:mm A")}</Text>
            )
        },
        {
            Header: "",
            accessor: "action",
            Filter: "",
            filter: "",
            disableSortBy: true,
            Cell: ({ row: { original } }) => (
                <Flex justifyContent={"end"} alignItems={"center"} gap={"20px"}>

                    <Tooltip label='View' fontSize='xs' >
                        <Box>
                            <Icon
                                _hover={{ color: "gray.500" }}
                                as={FiEye}
                                cursor={"pointer"}
                                onClick={() => {
                                    setactiveRequest(original);
                                    onDrawerOpen();
                                }}
                            />
                        </Box>
                    </Tooltip>
                </Flex>
            ),
        },
    ],
    []
)

  const transferColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'ticketNumber',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{original.ticketNumber}</Text>
        ),
      },
      {
        Header: 'from',
        accessor: '',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{original.fromAdsAccount?.name}</Text>
        ),
      },
      {
        Header: 'to',
        accessor: '',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{original.toAdsAccount?.name}</Text>
        ),
      },

      {
        Header: 'transfer date',
        accessor: 'transferDate',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{moment(original.transferDate).format("DD-MM-YYYY hh:mm A")}</Text>
        ),
      },
      {
        Header: 'created',
        accessor: 'createdDate',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{moment(original.createdDate).format("DD-MM-YYYY hh:mm A")}</Text>
        )
      },
      {
        Header: "Client Name",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          const { name, email } = original?.clientId;
          //console.log(original);
          return (
            <Text fontSize='sm' style={{ textTransform: 'capitalize' }}>{name ?? ''}</Text>
          )
        }
      },

    ],
    []
  )

  const transactionsColumns = useMemo(
    () => [
      {
        Header: 'Customer Name',
        accessor: 'customerName',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{original?.customerName || 'Test 3'}</Text>
        ),
      },
      {
        Header: 'Customer Phone',
        accessor: 'customerPhone',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{original?.customerPhone || '9876543210'}</Text>
        ),
      },
      {
        Header: 'Customer Email',
        accessor: 'customerEmail',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{original?.customerEmail || 'test@test.com'}</Text>
        ),
      },
      {
        Header: 'Order Id',
        accessor: 'id',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{original?.id || 'CFPay_saathi-midas_9l8q0k5ju0'}</Text>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => {
          let badgeColor = 'red';
          if (original?.status === 'PENDING') {
            badgeColor = 'yellow';
          } else if (original?.status === 'PAID') {
            badgeColor = 'green';
          }
      
          return (
            <>
              <Badge
                fontSize={"10px"}
                marginRight={1}
                colorScheme={badgeColor}
              >
                {original?.status}
              </Badge>
            </>
          );
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{original?.amount || '10'}</Text>
        ),
      },
      {
        Header: 'Vendor Settlement UTR',
        accessor: 'vendorSettlementUTR',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{original?.vendorSettlementUTR || '1731400537132432'}</Text>
        ),
      },
      {
        Header: 'Vendor Settlement Status',
        accessor: 'vendorSettlementStatus',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{original?.vendorSettlementStatus || 'SUCCESS'}</Text>
        ),
      },
      {
        Header: 'Transaction Id',
        accessor: 'transactionId',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{original?.transactionId || '5114914973689'}</Text>
        ),
      },
      {
        Header: 'created',
        accessor: 'createdAt',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{moment(original?.createdAt).format("DD-MM-YYYY hh:mm A")}</Text>
        )
      },
      {
        Header: 'updated',
        accessor: 'updatedAt',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{moment(original?.updatedAt).format("DD-MM-YYYY hh:mm A")}</Text>
        )
      },
    ],
    []
  )

  const initialTableState = {
    sortBy: [
      {
        id: "updatedDate",
        desc: true
      }
    ],
  };

  const formik = useFormik({
    initialValues: {
      currency: "",
      amount: "",
      paymentEntityName: "",
      paymentProvider: "",
      paymentProviderOther: "",
      transactionId: "",
      paymentReceipt: "",
      transferredTo: "",
      adAccountType: "new",
      singleAdAccount: "",
      transferDate: ""
    },
    validationSchema: topUpRequestSchema,
    onSubmit: async (values) => {
      //   console.log(JSON.stringify(values, null, 2));
      if (values) {
        const { currency, amount, paymentEntityName, paymentProvider, paymentProviderOther, transactionId, paymentReceipt, transferredTo } = values;
        const updatedPaymentProvider = paymentProvider == 'Other' ? paymentProviderOther : paymentProvider

        let formData = new FormData()
        formData.append('currency', currency);
        formData.append('amount', amount);
        formData.append('paymentProvider', updatedPaymentProvider);
        formData.append('paymentEntityName', paymentEntityName);
        formData.append('transactionId', transactionId);
        formData.append('paymentReceipt', paymentReceipt);
        formData.append('transferredTo', transferredTo);

        //  await addNewTopUpRequest(formData)
      }
      formik.resetForm();
      onClose();
      //setfileSource(null)
    },
  });

  const replyForm = useFormik({
    initialValues: {
      content: '',
      attachments: '',
    },
    validationSchema: replyToRequestSchema,
    onSubmit: async (values) => {
      const { content } = values
      const attachments_ = replyFiles?.map((file, index) => file.value)

      const payload = {
        content,
        attachments: attachments_,
        checked: true
      }

      if (values) {
        await replyToTopUpRequest({ requestId: activeRequest?._id, payload });
      }
      replyForm.resetForm();
      setreplyfiles([])
    },
  });

  const transferForm = useFormik({
    initialValues: {
      fromAdsAccount: "",
      toAdsAccount: "",
      amount: "",
      transferDate: new Date()
    },
    validationSchema: transferFormSchema,
    onSubmit: async (values) => {
      //console.log(values);

      if (values) {
        await transferAccountBalance(values)
      }
      transferForm.resetForm();
      onTransferModalClose();
    },
  });

  const handleDeleteCancel = () => {
    onDeleteModalClose();
    setrequestToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    onDeleteModalClose();
    await removeTopUpRequest(requestToDelete);
  };


  const handleFileUpload = (filename, acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      if (filename == 'paymentReceipt') {
        formik.setFieldValue('paymentReceipt', file)
      }
      if (filename == 'replyAttachments') {
        replyForm.setFieldValue('attachments', file)
      }
      const reader = new FileReader();
      reader.onload = async function (e) {
        //console.log({ filename, file });
        const formData = new FormData();
        formData.append('attachments', file)
        await uploadTopUpFile(formData)
          .unwrap()
          .then((payload) => {
            //console.log('fulfilled', payload)
            const { name, value } = payload?.data;
            if (filename == 'paymentReceipt') {
              setfileSource(value);
            }
            if (filename == 'replyAttachments') {
              setreplyfiles(files => [...files, { name, value }]);
            }


          })
          .catch((error) => {

          })
      };
      reader.readAsDataURL(file);
      return file;
    });
  }



  const removeFileSource = (filekey, index) => {
    if (filekey == 'attachments') {
      if (index < 0 || index >= files.length) {
        return;
      }
      const newArray = [...files];
      newArray.splice(index, 1);
      setfiles(newArray)
    }

    if (filekey == 'replyAttachments') {
      if (index < 0 || index >= replyFiles.length) {
        return;
      }
      const newArray = [...replyFiles];
      newArray.splice(index, 1);
      setreplyfiles(newArray)
    }
  }

  const messageHistory = activeRequest && activeRequest.messages?.map(({ content, author: { name, id }, createdAt, attachments }, index) => {

    return (
      <Box mb={'10px'} key={'request' + index}>
        <Box
          ml={id == clientId ? '20%' : '0'}
          mr={id != clientId ? '20%' : '0'}
          boxShadow='sm'
        >
          <Box
            background={id == clientId ? 'blue.50' : 'white'}
            p={'5px 10px'} borderRadius={5} borderColor={'gray.200'} borderWidth={1}>
            <Text fontSize={'sm'} color={"gray.600"}>{content}</Text>
            <Flex gap={'5px'}>
              {attachments?.length > 0 && attachments?.map((file, i) => (
                <LightBox fileSource={file} key={'attach_' + i} />
              ))}
            </Flex>
            <Text textAlign={'right'} mt={'5px'} fontSize={'9px'} color={'gray.600'}>{moment(createdAt).format("DD-MM-YYYY hh:mm A") + " • " + name}</Text>
          </Box>
        </Box>
      </Box>
    )
  })

  const removeUploadedFile = async (id) => {
    setfileSource(null)
  };

  const getAdAccountTitle = (id) => {
    const filteredAccount = accounts?.data?.filter((account) => {
      return account._id === id;
    })
    //console.log({ filteredAccount });

    return filteredAccount && filteredAccount[0]?.adsAccountName;
  }

  //console.log(getAdAccountTitle('65263d62fa6fa75d6e321709'));
  //console.log({ accounts });
  //console.log({ activeRequest });


  const bankOptions = savedBankList?.map((bank, item) => {
    const { accountNumber, bankName, country, _id } = bank
    return (
      <option key={_id} value={_id}>{accountNumber + ", " + bankName + ", " + country}</option>
    )
  })


  const convertAmountToNumber = (currencyString) => {
    const cleanedString = String(currencyString)?.replace(/[^\d.]/g, '');

    const numberValue = parseFloat(cleanedString);

    return numberValue;
  };

  const getAdAccountId = (id) => {
    const filteredAccount = accounts?.data?.filter((account) => {
      return account._id == id;
    })
    return filteredAccount && filteredAccount[0]?.adsAccountId;
  }

  // update company details when active request 

  useEffect(() => {

    if (activeRequest) {
      refetchUserCompanies()
    }

  }, [activeRequest]);

  useEffect(() => {
    if (companies && activeRequest) {
      setselectedCompanyDetails(companies?.filter(({ companyName }, index) => companyName == activeRequest?.company?.name)[0])
    }
  }, [companies])



  //console.log(accounts);


  return (

    <>
      {/* AD ACCOUNT TRANSFER FORM */}

      <Modal scrollBehavior={'inside'} size={'2xl'} isCentered={true} isOpen={isTransferModalOpen} onClose={() => { onTransferModalClose(); transferForm.resetForm() }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Ad account balance transfer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl display={'flex'} flexDirection={'column'} gap={'20px'}>
              <Box flex={1}>
                <FormLabel fontSize='sm' mb={'10px'}>
                  Confirm from ad account
                </FormLabel>
                <Select
                  flex={1}
                  name="fromAdsAccount"
                  placeholder='Select *'
                  onChange={transferForm.handleChange}
                  onBlur={transferForm.handleBlur}
                  value={transferForm.values.fromAdsAccount}
                  isInvalid={transferForm.touched.fromAdsAccount && transferForm.errors.fromAdsAccount ? true : false}
                >
                  {accounts?.data?.filter((account, index) => account.accountStatus == 1).map(({ adsAccountName, _id, adsAccountId }) => (
                    <option
                      value={_id}
                      key={_id}
                      disabled={_id == transferForm.values.toAdsAccount}
                    >
                      {adsAccountName + " " + `( ${adsAccountId} )`}
                    </option>
                  ))}
                </Select>
                {transferForm.touched.fromAdsAccount && transferForm.errors.fromAdsAccount && (
                  <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{transferForm.errors.fromAdsAccount}</Text>
                )}
              </Box>

              <Box flex={1}>
                <FormLabel fontSize='sm' mb={'10px'}>
                  Confirm to ad account
                </FormLabel>
                <Select
                  flex={1}
                  name="toAdsAccount"
                  placeholder='Select *'
                  onChange={transferForm.handleChange}
                  onBlur={transferForm.handleBlur}
                  value={transferForm.values.toAdsAccount}
                  isInvalid={transferForm.touched.toAdsAccount && transferForm.errors.toAdsAccount ? true : false}
                >
                  {accounts?.data?.filter((account, index) => account.accountStatus == 1).map(({ adsAccountName, _id, adsAccountId }) => (
                    <option
                      value={_id}
                      key={_id}
                      disabled={_id == transferForm.values.fromAdsAccount}
                    >
                      {adsAccountName + " " + `( ${adsAccountId} )`}
                    </option>
                  ))}
                </Select>
                {transferForm.touched.toAdsAccount && transferForm.errors.toAdsAccount && (
                  <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{transferForm.errors.toAdsAccount}</Text>
                )}
              </Box>
              <Box flex={1}>
                <FormLabel fontSize='sm' mb={'10px'}>
                  Amount
                </FormLabel>
                <Input
                  name='amount'
                  placeholder='Amount'
                  onChange={transferForm.handleChange}
                  onBlur={transferForm.handleBlur}
                  value={transferForm.values.amount}
                  isInvalid={transferForm.touched.amount && transferForm.errors.amount ? true : false}
                />
                {transferForm.touched.amount && transferForm.errors.amount && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {transferForm.errors.amount}
                  </Text>
                )}
              </Box>
              <Box flex={1}>
                <FormLabel fontSize='sm' mb={'10px'}>
                  Date of transfer
                </FormLabel>
                <SingleDatepicker
                  name="transferDate"
                  date={transferForm.values.transferDate}
                  maxDate={new Date()}
                  onDateChange={(date) => {
                    const timestamp = moment(date, "ddd MMM DD YYYY HH:mm:ss Z").valueOf();
                    transferForm.setFieldValue('transferDate', timestamp)
                  }}
                />
              </Box>

              <Flex gap={'20px'} mb={'10px'} mt={'20px'} justify={'center'}>
                <Button colorScheme='orange' onClick={transferForm.handleSubmit}>
                  Submit
                </Button>
                <Button onClick={() => { transferForm.resetForm(); onClose() }}>
                  Cancel
                </Button>
              </Flex>
            </FormControl>

          </ModalBody>
        </ModalContent>
      </Modal>

      {/* EDIT FORM MODAL */}

      <Modal
        size={"2xl"}
        isCentered={true}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          editFormRef?.current?.resetForm()
          removeUploadedFile();
          setactiveRequest(null);
        }}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Update Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isAccountsDataLoading || isCompaniesDataLoading ? (
              <Flex minH={'300px'} align={'center'} justify={'center'}>
                <Spinner />
              </Flex>
            ) : (
              <Formik
                innerRef={editFormRef}
                initialValues={initialValues}
                validationSchema={topUpRequestSchema}
                enableReinitialize={true}
                onSubmit={async (values, helpers) => {
                  //setfileSource(null)

                  const {
                    companyName: companyTitle,
                    percentSplit,
                    currency,
                    amount,
                    paymentEntityName,
                    paymentProvider,
                    paymentProviderOther,
                    transactionId,
                    paymentReceipt,
                    transferredFrom,
                    transferredTo,
                    paymentDate,
                    amountReceived,
                    accountType,
                    exemptGst,
                    topupDate
                  } = values;

                  const companyDetails = companies?.filter(({ companyName }, index) => companyName == companyTitle)[0];
                  const updatedAccountType = accountType == 'Credit Card' ? 'cc' : 'cl';
                  const modifiedSplits = percentSplit.map(split => ({
                    id: split.id,
                    name: split.name,
                    percent: split.percent,
                    adsId: getAdAccountId(split.id),
                  }));

                  const updatedAmountReceived = convertAmountToNumber(amountReceived)

                  const payload = {
                    company: {
                      id: companyDetails?._id,
                      name: companyDetails?.companyName,
                    },
                    percentSplit: modifiedSplits,
                    currency,
                    amount,
                    paymentEntityName,
                    paymentProvider,
                    paymentProviderOther,
                    transactionId,
                    paymentReceipt,
                    transferredFrom,
                    transferredTo,
                    paymentDate,
                    paymentReceipt: fileSource,
                    amountReceived: updatedAmountReceived,
                    accountType: updatedAccountType,
                    exemptGst,
                    topupDate
                  }

                  //console.log({ payload });

                  await updateTopUpRequest({ requestId: activeRequest?._id, payload })
                  helpers.resetForm();
                  setselectedCompanyDetails(null);
                  setfileSource(null);
                  onClose();
                }}
              >
                {({ values, errors, touched, setFieldValue, resetForm, handleChange, handleBlur, getFieldProps, handleSubmit }) => (
                  <Form >
                    <FormControl display={"flex"} flexDirection={"column"} gap={"20px"}>

                      <Flex gap={'20px'}>
                        <Box flex={1}>
                          <FormLabel fontSize='sm' mb={'10px'}>
                            Company Name <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                          </FormLabel>

                          <Select
                            flex={1}
                            name="companyName"
                            placeholder='Select'
                            onChange={(e) => {
                              handleChange(e);
                              if (!!e.target.value) {
                                //console.log(e.target.value);
                                getCompanyDetails(e.target.value);
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.companyName}
                            isInvalid={touched.companyName && errors.companyName ? true : false}
                          >
                            {companies && companyOptions}
                          </Select>


                          {touched.companyName && errors.companyName && (
                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.companyName}</Text>
                          )}
                        </Box>

                        <Box flex={1}>
                          <FormLabel fontSize='sm' mb={'10px'}>
                            Ad Account Type &nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                          </FormLabel>
                          <Select
                            flex={1}
                            name="accountType"
                            placeholder='Select'
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.value) {
                                let updatedType = e.target.value == 'Credit Card' ? 'cc' : 'cl';
                                setselectedAccountType(updatedType)
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.accountType}
                            isInvalid={touched.accountType && errors.accountType ? true : false}
                            isDisabled={!values.companyName}
                          >
                            <option value="Credit Card" >Credit Card</option>
                            <option value="Credit Line">Credit Line</option>
                          </Select>
                          {touched.accountType && errors.accountType && (
                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.accountType}</Text>
                          )}
                        </Box>

                      </Flex>

                      {!!accounts & accounts?.data?.length > 0 ? (
                        <Box flex={1}>
                          <FormLabel fontSize='sm' mb={'10px'}>
                            Ad Account&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                          </FormLabel>
                          <FieldArray name="percentSplit">
                            {({ insert, remove, push }) => (
                              <>
                                <Flex direction={'column'} gap={'20px'}>
                                  {values.percentSplit.length > 0 &&
                                    values.percentSplit.map((item, index) => (
                                      <Flex key={index} align={'center'} gap={'20px'}>
                                        <Box >
                                          <Box flex={1}>
                                            <Select
                                              name={`percentSplit.${index}.id`}
                                              onBlur={handleBlur}
                                              value={getFieldProps(`percentSplit.${index}.id`).value}
                                              onChange={(e) => {
                                                handleChange(e);
                                                const newSelectedOptions = [...selectedOptions];
                                                newSelectedOptions[index] = e.target.value;
                                                setSelectedOptions(newSelectedOptions);
                                              }}
                                            >
                                              <option value={''} defaultValue disabled>Select Ad account</option>
                                              {accounts?.data?.filter((account, index) => account.accountStatus == 1 && account?.clientId?.id == activeRequest?.clientId?.id && account?.status == 'created').map(({ adsAccountName, _id }) => (
                                                <option
                                                  value={_id}
                                                  key={_id}
                                                  disabled={selectedOptions?.includes(_id)}
                                                >
                                                  {adsAccountName}
                                                </option>
                                              ))}
                                            </Select>
                                            {touched.singleAdAccount && errors.singleAdAccount && (
                                              <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.singleAdAccount}</Text>
                                            )}
                                          </Box>

                                          <ErrorMessage
                                            name={`percentSplit.${index}.id`}
                                            component="Box"
                                          />
                                        </Box>
                                        <Box >
                                          <InputGroup>
                                            <Input
                                              name={`percentSplit.${index}.percent`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={getFieldProps(`percentSplit.${index}.percent`).value}
                                              placeholder={'% share'}
                                            />
                                            <InputRightAddon>
                                              %
                                            </InputRightAddon>
                                          </InputGroup>

                                          <ErrorMessage
                                            name={`percentSplit.${index}.id`}
                                            component="Box"
                                          />
                                        </Box>
                                        <Box>
                                          {index != 0 ? (
                                            <Icon
                                              color={'red'}
                                              _hover={{ color: "red.500" }}
                                              as={FiDelete}
                                              cursor={"pointer"}
                                              onClick={() => remove(index)}
                                            />
                                          ) : null}

                                        </Box>
                                      </Flex>
                                    ))}

                                </Flex>
                                <Button
                                  mt={'10px'}
                                  size={'xs'}
                                  onClick={() => push({ id: '', percent: '' })}
                                >
                                  Add more
                                </Button>
                              </>
                            )}
                          </FieldArray>
                        </Box>
                      ) : null}
                      <Flex gap={'20px'}>
                        <Box flex={1}>
                          <FormLabel fontSize='sm' mb={'10px'}>
                            Currency&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                          </FormLabel>
                          <Select
                            flex={1}
                            name='currency'
                            placeholder='Currency'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.currency}
                            isInvalid={
                              touched.currency && errors.currency
                                ? true
                                : false
                            }
                          >
                            <option value='USD'>USD</option>
                            <option value='EUR'>EUR</option>
                            <option value='INR'>INR</option>
                          </Select>
                          {touched.currency && errors.currency && (
                            <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                              {errors.currency}
                            </Text>
                          )}
                        </Box>
                        <Box flex={1}>
                          <FormLabel fontSize='sm' mb={'10px'}>
                            Amount&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                          </FormLabel>
                          <Input
                            name='amount'
                            placeholder='Amount'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.amount}
                            isInvalid={
                              touched.amount && errors.amount
                                ? true
                                : false
                            }
                          />
                          {touched.amount && errors.amount && (
                            <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                              {errors.amount}
                            </Text>
                          )}
                        </Box>
                      </Flex>
                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Bank Name/Payment Provider&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                        </FormLabel>
                        <Select
                          flex={1}
                          name='paymentProvider'
                          placeholder='Bank Name/Payment Provider'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.paymentProvider}
                          isInvalid={
                            touched.paymentProvider && errors.paymentProvider
                              ? true
                              : false
                          }
                        >
                          <option value='Wise'>Wise</option>
                          <option value='Payoneer'>Payoneer</option>
                          <option value='Mercury'>Mercury</option>
                          <option value='Bank transfer'>Bank transfer</option>
                          <option value='Others'> Others (please specify)</option>
                        </Select>
                        {touched.paymentProvider && errors.paymentProvider && (
                          <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {errors.paymentProvider}
                          </Text>
                        )}
                      </Box>
                      {values.paymentProvider == 'Others' ? (
                        <Box flex={1}>
                          <Textarea
                            name='paymentProviderOther'
                            placeholder='Other Payment Provider'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.paymentProviderOther}
                          />
                        </Box>
                      ) : null}
                      <Flex gap={'20px'}>
                        <Box flex={1}>
                          <FormLabel fontSize='sm' mb={'10px'}>
                            Payment Entity Name&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                          </FormLabel>
                          <Input
                            name='paymentEntityName'
                            placeholder='Payment Entity Name'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.paymentEntityName}
                            isInvalid={
                              touched.paymentEntityName && errors.paymentEntityName
                                ? true
                                : false
                            }
                          />
                        </Box>
                        <Box flex={1}>
                          <FormLabel fontSize='sm' mb={'10px'}>
                            Transaction ID&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                          </FormLabel>
                          <Input
                            flex={1}
                            name='transactionId'
                            placeholder='Transaction ID'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.transactionId}
                            isInvalid={
                              touched.transactionId && errors.transactionId
                                ? true
                                : false
                            }
                          />
                          {touched.transactionId && errors.transactionId && (
                            <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                              {errors.transactionId}
                            </Text>
                          )}
                        </Box>

                      </Flex>
                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Date of transfer
                        </FormLabel>
                        <SingleDatepicker
                          name="paymentDate"
                          date={values.paymentDate}
                          maxDate={new Date()}
                          onDateChange={(date) => {
                            const timestamp = moment(date, "ddd MMM DD YYYY HH:mm:ss Z").valueOf();
                            setFieldValue('paymentDate', timestamp)
                          }}
                        />
                      </Box>
                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Transferred from Account
                        </FormLabel>
                        <Select
                          flex={1}
                          name="transferredFrom"
                          // placeholder={values.transferredFrom}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.transferredFrom}
                          isInvalid={touched.transferredFrom && errors.transferredFrom ? true : false}
                        >
                          {selectedCompanyDetails?.bank?.map(({ _id, accountNumber, bankName }, index) => (
                            <option value={_id} key={_id}>{accountNumber + ", " + bankName}</option>
                          ))}


                        </Select>
                        {touched.transferredFrom && errors.transferredFrom && (
                          <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.transferredFrom}</Text>
                        )}

                      </Box>
                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Transferred to Account
                        </FormLabel>
                        <Select
                          flex={1}
                          name="transferredTo"
                          //placeholder={values.transferredTo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.transferredTo}
                          isInvalid={touched.transferredTo && errors.transferredTo ? true : false}
                        >

                          {bankOptions}

                        </Select>
                        {touched.transferredTo && errors.transferredTo && (
                          <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.transferredTo}</Text>
                        )}
                      </Box>
                      <Box>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Payment Receipt&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                          <Tooltip
                            borderRadius={5}
                            label={
                              <Box fontSize={"sm"} p={"5px"}>
                                <Text>Please upload an image as proof of payment/transaction</Text>
                              </Box>
                            }
                            fontSize='md'
                          >
                            <InfoOutlineIcon />
                          </Tooltip>
                        </FormLabel>
                        <FileUploadBox
                          fileSource={fileSource}
                          multiUpload={false}
                          fileKey='paymentReceipt'
                          onFileUpload={(name, files) => {
                            handleFileUpload(name, files);
                            if (files[0]) {
                              setFieldValue('paymentReceipt', files[0].path)
                            }
                          }}
                          onRemoveClick={removeUploadedFile}
                          isInvalid={touched.paymentReceipt && errors.paymentReceipt ? true : false}
                          isUploading={uploadResponse.isLoading}
                        />
                        {touched.paymentReceipt && errors.paymentReceipt && (
                          <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {errors.paymentReceipt}
                          </Text>
                        )}
                      </Box>

                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Amount received&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                        </FormLabel>
                        <Input
                          flex={1}
                          name='amountReceived'
                          placeholder='Amount received'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.amountReceived}
                          isInvalid={
                            touched.amountReceived && errors.amountReceived
                              ? true
                              : false
                          }
                        />
                        {touched.amountReceived && errors.amountReceived && (
                          <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {errors.amountReceived}
                          </Text>
                        )}
                      </Box>
                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Topup Date
                        </FormLabel>
                        <SingleDatepicker
                          name="topupDate"
                          date={values.topupDate}
                          maxDate={new Date()}
                          onDateChange={(date) => {
                            const timestamp = moment(date, "ddd MMM DD YYYY HH:mm:ss Z").valueOf();
                            setFieldValue('topupDate', timestamp)
                          }}
                        />
                      </Box>
                      <Box flex={1}>
                        <Checkbox
                          colorScheme={"orange"}
                          name="exemptGst"
                          isChecked={values.exemptGst}
                          onChange={handleChange}
                          size="sm"
                          alignItems={"baseline"}
                        >
                          <FormLabel fontSize={"14px"}>
                            Exempt GST
                          </FormLabel>
                        </Checkbox>
                      </Box>

                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Date of topup
                        </FormLabel>
                        <SingleDatepicker
                          name="topupDate"
                          date={values.topupDate}
                          maxDate={new Date()}
                          onDateChange={(date) => {
                            const timestamp = moment(date, "ddd MMM DD YYYY HH:mm:ss Z").valueOf();
                            setFieldValue('topupDate', timestamp)
                          }}
                        />
                      </Box>

                      <Flex gap={"20px"} mb={"10px"} mt={"20px"} justify={"center"}>
                        <Button
                          isDisabled={newTopUpResponse?.isLoading}
                          onClick={handleSubmit}
                          colorScheme='orange'
                        > {newTopUpResponse?.isLoading ? <Spinner /> : 'Submit'}</Button>
                        <Button

                          onClick={() => {
                            resetForm();
                            onClose();
                            removeUploadedFile()
                          }}
                        >
                          Cancel
                        </Button>
                      </Flex>
                    </FormControl>
                  </Form>
                )}

              </Formik>
            )}


          </ModalBody>
        </ModalContent>
      </Modal >

      {/* APPROVE REQUEST MODAL */}

      <Modal Modal
        scrollBehavior={"inside"}
        size={"md"}
        isCentered={true}
        isOpen={isApproveModalOpen}
        onClose={() => {
          onApproveModalClose();
          approveFormRef.current?.resetForm();
        }
        }
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={"2xl"} textAlign={"center"} mb={"20px"}>
              Approve{" "}
            </Text>

            <Formik
              innerRef={approveFormRef}
              validationSchema={approveTopUpSchema}
              initialValues={{
                approve: false,
                reply: "",
                amountReceived: "",
              }}
              onSubmit={async (values, { resetForm }) => {
                //console.log(values);
                if (values) {
                  const { approve, reply, amountReceived } = values;
                  const payload = {
                    approve,
                    reply: !approve ? reply : "",
                    amountReceived: !approve ? 0 : Number(amountReceived),
                  };
                  await approveTopUpRequest({
                    requestId: activeRequest._id,
                    payload,
                  });
                }
                settopUpToApprove(null);
                resetForm();
                onApproveModalClose();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
                resetForm,
              }) => (
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"20px"}
                >
                  <FormControl display={"flex"} flexDirection={"row"}>
                    <FormLabel fontSize={"sm"} mb="0">
                      Approved
                    </FormLabel>
                    <Switch
                      id={"approve"}
                      onChange={handleChange}
                      name={"approve"}
                      colorScheme="green"
                      isChecked={values.approve}
                    />
                    {touched.approve && errors.approve && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {errors.approve}
                      </Text>
                    )}
                  </FormControl>
                  <Box>
                    <Textarea
                      value={values.reply}
                      size={"sm"}
                      name={"reply"}
                      placeholder="Reply"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      mt={"10px"}
                    />
                    {touched.reply && errors.reply && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {errors.reply}
                      </Text>
                    )}
                  </Box>
                  <Box>
                    <Input
                      flex={1}
                      name="amountReceived"
                      placeholder="Amount Received"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amountReceived}
                      isInvalid={
                        touched.amountReceived && errors.amountReceived
                          ? true
                          : false
                      }
                    />
                    {touched.amountReceived && errors.amountReceived && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {errors.amountReceived}
                      </Text>
                    )}
                  </Box>

                  <Flex
                    gap={"20px"}
                    mb={"10px"}
                    mt={"20px"}
                    justify={"center"}
                  >
                    <Button onClick={handleSubmit}>Submit</Button>
                    <Button
                      onClick={() => {
                        resetForm();
                        onApproveModalClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </FormControl>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal >

      {/* REQUEST DETAILS MODAL */}

      < Modal
        size={"2xl"}
        isCentered={true}
        isOpen={isDetailsModalOpen}
        onClose={() => {
          onDetailsModalClose();
        }}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Request details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table variant="simple" size={"sm"}>
                <Tbody whiteSpace={'break-spaces'}>
                  <Tr>
                    <Td w={"240px"} color="gray.500">
                      Currency
                    </Td>
                    <Td>{activeRequest?.currency}</Td>
                  </Tr>
                  <Tr>
                    <Td w={"240px"} color="gray.500">
                      Amount
                    </Td>
                    <Td>{activeRequest?.amount}</Td>
                  </Tr>
                  <Tr>
                    <Td w={"240px"} color="gray.500">
                      Payment Provider
                    </Td>
                    <Td>{activeRequest?.paymentProviderOther ? activeRequest?.paymentProviderOther : activeRequest?.paymentProvider}</Td>
                  </Tr>
                  <Tr>
                    <Td w={"240px"} color="gray.500">
                      Payment Entity Name
                    </Td>
                    <Td>{activeRequest?.paymentEntityName}</Td>
                  </Tr>
                  <Tr>
                    <Td w={"240px"} color="gray.500">
                      Transaction ID
                    </Td>
                    <Td>{activeRequest?.transactionId}</Td>
                  </Tr>
                  <Tr>
                    <Td w={"240px"} color='gray.500'>
                      Transferred From
                    </Td>
                    <Td>{activeRequest?.transferredFrom} </Td>
                  </Tr>
                  <Tr>
                    <Td w={"240px"} color="gray.500">
                      Transferred To
                    </Td>
                    <Td>{activeRequest?.transferredTo}</Td>
                  </Tr>
                  <Tr>
                    <Td w={"240px"} color="gray.500">
                      Payment Date
                    </Td>
                    <Td>{moment(activeRequest?.paymentDate).format("DD-MM-YYYY hh:mm A")}</Td>
                  </Tr>
                  <Tr>
                    <Td w={"240px"} color="gray.500">
                      Payment Receipt
                    </Td>
                    <Td>
                      <Link href={activeRequest?.paymentReceipt} isExternal>
                        <Icon
                          as={FiFile}
                          boxSize={10}
                          _hover={{ color: 'gray.500' }}
                          color={iconColor}
                        />
                      </Link>
                    </Td>
                  </Tr>
                  {activeRequest?.percentSplit ? (
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Percentage Split
                      </Td>
                      <Td>
                        <UnorderedList styleType={'none'} ml={0}>
                          {activeRequest?.percentSplit?.map(({ name, percent, id }, index) => (
                            <ListItem key={id}>{name}: {percent}%</ListItem>
                          ))}
                        </UnorderedList>
                      </Td>
                    </Tr>
                  ) : null}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal >

      {/* DELETE CONFIRMATION MODAL */}

      < ConfirmDeleteModal
        copy="Are you sure you want to delete the request"
        isModalOpen={isDeleteModalOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />

      {/* VIEW CONVERSATION HISTORY */}

      < Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={() => {
          onDrawerClose();
          setactiveRequest(null);
        }}
        finalFocusRef={btnRef}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex align={"center"}>
              <Text>Request History</Text>

              <Badge ml={'10px'} fontSize={'10px'} colorScheme={statusBadgeColor}>{activeRequest?.status == 'toppedup' ? 'topped up' : activeRequest?.status}</Badge>
            </Flex>
            <Button
              colorScheme="gray"
              variant={"outline"}
              size={"xs"}
              onClick={() => onDetailsModalOpen()}
            >
              View details
            </Button>
          </DrawerHeader>

          <DrawerBody
            background={"gray.50"}
            borderWidth={1}
            borderColor={"gray.300"}
            borderLeftWidth={0}
            borderRightWidth={0}
          >
            <Box p={"10px"} ref={scrollBoxRef}>{messageHistory}</Box>
          </DrawerBody>

          <DrawerFooter>
            <Box flex={1}>
              <Flex mb={"20px"} gap={"10px"}>
                <>
                  {activeRequest?.status != 'pending' ? (
                    <Button
                      size={"xs"}
                      variant="outline"
                      colorScheme="yellow"
                      onClick={async () => {
                        await updateTopUpStatus({
                          requestId: activeRequest._id,
                          payload: { status: "pending", topupDate: moment().unix() },
                        });
                        onDrawerClose();
                      }}
                    >
                      Raise issue
                    </Button>
                  ) : null}

                  {userRole == 'media' || userRole == 'admin' ? (
                    <>
                      {activeRequest?.status != 'toppedup' && Number(activeRequest?.amountReceived) > 0 ? (
                        <Button
                          size={"xs"}
                          variant="outline"
                          colorScheme="green"
                          onClick={async () => {
                            await updateTopUpStatus({
                              requestId: activeRequest._id,
                              payload: { status: "toppedup", topupDate: moment().unix() },
                            });
                            onDrawerClose();
                          }}
                        >
                          Topped Up
                        </Button>
                      ) : null}
                    </>
                  ) : null}




                  {/* <Button
                  size={"xs"}
                  variant="outline"
                  colorScheme="gray"
                  onClick={async () => {
                    await updateTopUpStatus({
                      requestId: activeRequest._id,
                      payload: { status: "closed" },
                    });
                    onDrawerClose();
                  }}
                >
                  Close ticket
                </Button> */}
                </>
              </Flex>

              <Box gap={"10px"}>
                <Box flex={1} mb={"10px"}>
                  <Textarea
                    size="md"
                    name="content"
                    placeholder="Message"
                    onChange={replyForm.handleChange}
                    onBlur={replyForm.handleBlur}
                    value={replyForm.values.content}
                    isInvalid={
                      replyForm.touched.content && replyForm.errors.content
                        ? true
                        : false
                    }
                  />
                  {replyForm.touched.content && replyForm.errors.content && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {replyForm.errors.content}
                    </Text>
                  )}
                </Box>

                <Flex flex={1} justify={"space-between"}>
                  <MultiFileUploadButton
                    fileSource={replyFiles}
                    fileKey="replyAttachments"
                    onFilesSelected={handleFileUpload}
                    onRemoveClick={(index) =>
                      removeFileSource("replyAttachments", index)
                    }
                    isDisabled={!replyForm.values.content}
                  />

                  {replyForm.touched.attachments &&
                    replyForm.errors.attachments && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {replyForm.errors.attachments}
                      </Text>
                    )}
                  <Button
                    size={"sm"}
                    onClick={replyForm.handleSubmit}
                    isDisabled={!replyForm.values.content || uploadResponse?.isLoading}
                  >
                    Reply
                  </Button>
                </Flex>
              </Box>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer >

      {/* ADD REQUEST MODAL */}

      < Modal
        size={"2xl"}
        isCentered={true}
        isOpen={isRequestModalOpen}
        onClose={() => {
          onRequestModalClose();
          requestFormRef?.current?.resetForm()
          removeUploadedFile();
          setselectedCompanyDetails(null);
        }}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Place a Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              innerRef={requestFormRef}
              initialValues={{
                companyName: "",
                accountType: "",
                percentSplit: [
                  {
                    id: '',
                    percent: '',
                  },
                ],
                currency: "",
                amount: "",
                paymentEntityName: "",
                paymentProvider: "",
                paymentProviderOther: "",
                transactionId: "",
                paymentReceipt: "",
                transferredFrom: "",
                transferredTo: "",
                paymentDate: new Date(),
                topupDate: new Date()
              }}
              validationSchema={topUpRequestSchema}
              onSubmit={async (values, helpers) => {
                //setfileSource(null)
                const {
                  companyName: companyTitle,
                  percentSplit,
                  currency,
                  amount,
                  paymentEntityName,
                  paymentProvider,
                  paymentProviderOther,
                  transactionId,
                  paymentReceipt,
                  transferredTo,
                  transferredFrom,
                  paymentDate,
                  accountType,
                  topupDate
                } = values;

                const modifiedSplits = percentSplit.map(split => ({
                  ...split,
                  adsId: getAdAccountId(split.id),
                  name: getAdAccountTitle(split.id)
                }));

                const companyDetails = companies?.filter(({ companyName }, index) => companyName == companyTitle)[0];
                const updatedAccountType = accountType == 'Credit Card' ? 'cc' : 'cl';

                const payload = {
                  company: {
                    id: companyDetails?._id,
                    name: companyDetails?.companyName,
                  },
                  percentSplit: modifiedSplits,
                  currency,
                  amount,
                  paymentEntityName,
                  paymentProvider,
                  paymentProviderOther,
                  transactionId,
                  paymentReceipt,
                  transferredTo,
                  transferredFrom,
                  paymentDate,
                  paymentReceipt: fileSource,
                  accountType: updatedAccountType,
                  topupDate,
                  clientId: {
                    id: selectedCompanyDetails?.clientId?.id,
                    name: selectedCompanyDetails?.clientId?.name,
                    email: selectedCompanyDetails?.clientId?.email
                  },
                }
                //console.log({ payload });
                await addNewTopUpRequest(payload);
                helpers.resetForm();
                setselectedCompanyDetails(null);
                setfileSource(null);
                onRequestModalClose();
              }}

            >
              {({ values, errors, touched, setFieldValue, resetForm, handleChange, handleBlur, getFieldProps, handleSubmit }) => (
                <Form >
                  <FormControl display={"flex"} flexDirection={"column"} gap={"20px"}>
                    <Flex gap={'20px'}>
                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Company Name <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                        </FormLabel>
                        <Select
                          flex={1}
                          name="companyName"
                          placeholder='Select'
                          onChange={(e) => {
                            handleChange(e);
                            if (!!e.target.value) {
                              //console.log(e.target.value);
                              getCompanyDetails(e.target.value);
                              setFieldValue('accountType', '')
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.companyName}
                          isInvalid={touched.companyName && errors.companyName ? true : false}
                        >
                          {companies && companyAllOptions}
                        </Select>
                        {touched.companyName && errors.companyName && (
                          <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.companyName}</Text>
                        )}
                      </Box>

                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Ad Account Type &nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                        </FormLabel>
                        <Select
                          flex={1}
                          name="accountType"
                          placeholder='Select'
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value) {
                              let updatedType = e.target.value == 'Credit Card' ? 'cc' : 'cl';
                              setselectedAccountType(updatedType)
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.accountType}
                          isInvalid={touched.accountType && errors.accountType ? true : false}
                          isDisabled={!values.companyName}
                        >
                          {selectedCompanyAccountTypes.includes('cc') && (
                            <option value="Credit Card" >Credit Card</option>
                          )}
                          {selectedCompanyAccountTypes.includes('cl') && (
                            <option value="Credit Line">Credit Line</option>
                          )}
                        </Select>
                        {touched.accountType && errors.accountType && (
                          <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.accountType}</Text>
                        )}
                      </Box>

                    </Flex>

                    {selectedCompanyDetails?.bank?.length == 0 ? (
                      <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>Click <Link textDecoration={'underline'} href={`/companies/${selectedCompanyDetails?._id}/edit`} isExternal>
                        here
                      </Link> to add a bank account in order to proceed</Text>
                    ) : null}

                    {!!accounts & accounts?.data?.length > 0 ? (
                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Ad Account&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                        </FormLabel>
                        <FieldArray name="percentSplit">
                          {({ insert, remove, push }) => (
                            <>
                              <Flex direction={'column'} gap={'20px'}>
                                {values.percentSplit.length > 0 &&
                                  values.percentSplit.map((item, index) => (
                                    <Flex key={index + '_percentSplit'} align={'center'} gap={'20px'}>
                                      <Box >
                                        <Box flex={1}>
                                          <Select
                                            name={`percentSplit.${index}.id`}
                                            onBlur={handleBlur}
                                            value={getFieldProps(`percentSplit.${index}.id`).value}
                                            onChange={(e) => {
                                              handleChange(e);
                                              const newSelectedOptions = [...selectedOptions];
                                              newSelectedOptions[index] = e.target.value;
                                              setSelectedOptions(newSelectedOptions);
                                            }}
                                          >
                                            <option value={''} defaultValue disabled>Select Ad account</option>

                                            {accounts?.data?.filter((account, index) => account?.company.id == selectedCompanyDetails?._id && account.accountStatus == 1 && account.accountType == selectedAccountType).map(({ adsAccountName, _id }) => (
                                              <option
                                                value={_id}
                                                key={_id}
                                                disabled={selectedOptions?.includes(_id)}
                                              >
                                                {adsAccountName}
                                              </option>
                                            ))}
                                          </Select>
                                          {touched.singleAdAccount && errors.singleAdAccount && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.singleAdAccount}</Text>
                                          )}
                                        </Box>

                                        <ErrorMessage
                                          name={`percentSplit.${index}.id`}
                                          component="Box"
                                        />
                                      </Box>
                                      <Box >
                                        <InputGroup>
                                          <Input
                                            name={`percentSplit.${index}.percent`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={getFieldProps(`percentSplit.${index}.percent`).value}
                                            placeholder={'% share'}
                                          />
                                          <InputRightAddon>
                                            %
                                          </InputRightAddon>
                                        </InputGroup>

                                        <ErrorMessage
                                          name={`percentSplit.${index}.id`}
                                          component="Box"
                                        />
                                      </Box>
                                      <Box>
                                        {index != 0 ? (
                                          <Icon
                                            color={'red'}
                                            _hover={{ color: "red.500" }}
                                            as={FiDelete}
                                            cursor={"pointer"}
                                            onClick={() => remove(index)}
                                          />
                                        ) : null}

                                      </Box>
                                    </Flex>
                                  ))}

                              </Flex>
                              <Button
                                mt={'10px'}
                                size={'xs'}
                                onClick={() => push({ id: '', percent: '' })}
                              >
                                Add more
                              </Button>
                            </>
                          )}
                        </FieldArray>
                      </Box>
                    ) : null}
                    <Flex gap={'20px'}>
                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Currency&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                        </FormLabel>
                        <Select
                          flex={1}
                          name='currency'
                          placeholder='Select'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.currency}
                          isInvalid={
                            touched.currency && errors.currency
                              ? true
                              : false
                          }
                        >
                          <option value='USD'>USD</option>
                          <option value='EUR'>EUR</option>
                          <option value='INR'>INR</option>
                        </Select>
                        {touched.currency && errors.currency && (
                          <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {errors.currency}
                          </Text>
                        )}
                      </Box>
                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Amount&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                        </FormLabel>
                        <Input
                          name='amount'
                          placeholder={
                            values.accountType === 'Credit Line'
                              ? 'Transfer Amount'
                              : values.accountType === 'Credit Card'
                                ? 'Commission to Blink'
                                : 'Amount'
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.amount}
                          isInvalid={
                            touched.amount && errors.amount
                              ? true
                              : false
                          }
                        />
                        {touched.amount && errors.amount && (
                          <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {errors.amount}
                          </Text>
                        )}
                      </Box>



                    </Flex>


                    <Box flex={1}>
                      <FormLabel fontSize='sm' mb={'10px'}>
                        Bank Name/Payment Provider&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                      </FormLabel>
                      <Select
                        flex={1}
                        name='paymentProvider'
                        placeholder='Bank Name/Payment Provider'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.paymentProvider}
                        isInvalid={
                          touched.paymentProvider && errors.paymentProvider
                            ? true
                            : false
                        }
                      >
                        <option value='Wise'>Wise</option>
                        <option value='Payoneer'>Payoneer</option>
                        <option value='Mercury'>Mercury</option>
                        <option value='Bank transfer'>Bank transfer</option>
                        <option value='Others'> Others (please specify)</option>
                      </Select>
                      {values.paymentProvider == 'Payoneer' ? (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                          Processing fee of 3% will be applicable.
                        </Text>
                      ) : null}

                      {touched.paymentProvider && errors.paymentProvider && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                          {errors.paymentProvider}
                        </Text>
                      )}
                    </Box>



                    {values.paymentProvider == 'Others' ? (
                      <Box flex={1}>
                        <Textarea
                          name='paymentProviderOther'
                          placeholder='Other Payment Provider'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.paymentProviderOther}
                        />
                      </Box>
                    ) : null}
                    <Flex gap={'20px'}>
                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Payment Entity Name&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                        </FormLabel>
                        <Input
                          name='paymentEntityName'
                          placeholder='Payment Entity Name'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.paymentEntityName}
                          isInvalid={
                            touched.paymentEntityName && errors.paymentEntityName
                              ? true
                              : false
                          }
                        />
                        {touched.paymentEntityName && errors.paymentEntityName && (
                          <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {errors.paymentEntityName}
                          </Text>
                        )}
                      </Box>
                      <Box flex={1}>
                        <FormLabel fontSize='sm' mb={'10px'}>
                          Transaction ID&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                        </FormLabel>
                        <Input
                          flex={1}
                          name='transactionId'
                          placeholder='Transaction ID'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.transactionId}
                          isInvalid={
                            touched.transactionId && errors.transactionId
                              ? true
                              : false
                          }
                        />
                        {touched.transactionId && errors.transactionId && (
                          <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {errors.transactionId}
                          </Text>
                        )}
                      </Box>

                    </Flex>
                    <Box flex={1}>
                      <FormLabel fontSize='sm' mb={'10px'}>
                        Date of transfer
                      </FormLabel>
                      <SingleDatepicker
                        name="paymentDate"
                        date={values.paymentDate}
                        maxDate={new Date()}
                        onDateChange={(date) => {
                          const timestamp = moment(date, "ddd MMM DD YYYY HH:mm:ss Z").valueOf();
                          setFieldValue('paymentDate', timestamp)
                        }}
                      />
                    </Box>
                    <Box flex={1}>
                      <Select
                        flex={1}
                        name="transferredFrom"
                        placeholder='Transferred from'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.transferredFrom}
                        isInvalid={touched.transferredFrom && errors.transferredFrom ? true : false}
                      >
                        {selectedCompanyDetails?.bank?.map(({ _id, accountNumber, bankName }, index) => (
                          <option value={_id} key={_id}>{accountNumber + ", " + bankName}</option>
                        ))}
                      </Select>
                      {touched.transferredFrom && errors.transferredFrom && (
                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.transferredFrom}</Text>
                      )}

                    </Box>
                    <Box flex={1}>
                      <Select
                        flex={1}
                        name="transferredTo"
                        placeholder='Transferred to'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.transferredTo}
                        isInvalid={touched.transferredTo && errors.transferredTo ? true : false}
                      >

                        {bankOptions}

                      </Select>
                      {touched.transferredTo && errors.transferredTo && (
                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.transferredTo}</Text>
                      )}
                    </Box>

                    <Box>
                      <FormLabel fontSize='sm' mb={'10px'}>
                        Payment Receipt&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                        <Tooltip
                          borderRadius={5}
                          label={
                            <Box fontSize={"sm"} p={"5px"}>
                              <Text>Please upload an image as proof of payment/transaction</Text>
                            </Box>
                          }
                          fontSize='md'
                        >
                          <InfoOutlineIcon />
                        </Tooltip>
                      </FormLabel>
                      <FileUploadBox
                        fileSource={fileSource}
                        multiUpload={false}
                        fileKey='paymentReceipt'
                        onFileUpload={(name, files) => {
                          handleFileUpload(name, files);
                          if (files[0]) {
                            setFieldValue('paymentReceipt', files[0].path)
                          }
                        }}
                        onRemoveClick={removeUploadedFile}
                        isInvalid={touched.paymentReceipt && errors.paymentReceipt ? true : false}
                        isUploading={uploadResponse.isLoading}
                      />
                      {touched.paymentReceipt && errors.paymentReceipt && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                          {errors.paymentReceipt}
                        </Text>
                      )}
                    </Box>
                    <Box flex={1}>
                      <FormLabel fontSize='sm' mb={'10px'}>
                        Date of topup
                      </FormLabel>
                      <SingleDatepicker
                        name="topupDate"
                        date={values.topupDate}
                        maxDate={new Date()}
                        onDateChange={(date) => {
                          const timestamp = moment(date, "ddd MMM DD YYYY HH:mm:ss Z").valueOf();
                          setFieldValue('topupDate', timestamp)
                        }}
                      />
                    </Box>
                    <Flex gap={"20px"} mb={"10px"} mt={"20px"} justify={"center"}>
                      <Button
                        isDisabled={newTopUpResponse?.isLoading}
                        onClick={handleSubmit}
                      > {newTopUpResponse?.isLoading ? <Spinner /> : 'Submit'}</Button>
                      <Button

                        onClick={() => {
                          resetForm();
                          onClose();
                          removeUploadedFile()
                        }}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </FormControl>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal >

      <SidebarWithHeader>
        <Flex mb={"20px"}>
          <Heading as="h1" size="xl">
            Top up Requests
          </Heading>
          {/* {userRole == 'media' || userRole == 'admin' ? (
            <>
              <Spacer />
              <Button colorScheme='orange' onClick={onRequestModalOpen}>
                <AddIcon boxSize={3} />&nbsp;Add a Top Up
              </Button>
            </>
          ) : null} */}

        </Flex>
        <Flex flexDirection={"column"} flex={"1"}>
          <Tabs p={0} colorScheme='orange'>
            <TabList>
              <Tab fontWeight={'bold'}>REQUESTS</Tab>
              <Tab fontWeight={'bold'}>TRANSFERS</Tab>
              <Tab fontWeight={'bold'}>TRANSACTIONS</Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={0}>
                {isTopUpRequestsLoading ? (<Spinner />) : (
                  <>
                    <AppTableWithPagination
                      columns={columns}
                      data={requests?.data}
                      searchTerm={searchValue}
                      paginationMeta={requests?.meta}
                      onPageChange={(index) => setCurrentPage(index)}
                      onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                      searchEnabled={true}
                      isFetching={isFetching}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel px={0}>
                {isTransferListLoading ? (<Spinner />) : (
                  <>
                    {!!transferList && transferList?.length > 0 ? (
                      <AppTable
                        columns={transferColumns}
                        data={transferList}
                        searchEnabled={true}
                      />
                    ) : (
                      <Text>
                        No requests yet.
                      </Text>
                    )}
                  </>
                )}
              </TabPanel>
              <TabPanel px={0}>
                {isTransactionsListLoading ? (<Spinner />) : (
                  <>
                    <AppTableWithPagination
                      columns={transactionsColumns}
                      data={transactionsList?.payments || []}
                      searchTerm={searchValue}
                      paginationMeta={transactionsList?.meta || []}
                      onPageChange={(index) => setCurrentPage(index)}
                      onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                      searchEnabled={true}
                      isFetching={isFetching}
                    />
                  </>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </SidebarWithHeader>
    </>
  );
}

export default TopUpsForClient