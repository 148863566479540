import { ChevronDownIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
    Avatar,
    Box,
    Button,
    CloseButton,
    Collapse,
    Divider,
    Drawer,
    DrawerContent,
    Flex,
    HStack,
    Icon,
    IconButton,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Select,
    Spinner,
    Text,
    Tooltip,
    useColorMode,
    useColorModeValue,
    useDisclosure,
    useToast,
    VStack,
} from '@chakra-ui/react';
import LogoNavWhite from "assets/images/logo-main.png";
import LogoNav from "assets/images/logo_black.png";
import noBgLogo from "assets/images/noBgLogo.png";
import { IoMdSettings } from "react-icons/io";
import React, { useCallback, useEffect, useState } from 'react';
import {
    FiBriefcase,
    FiChevronDown,
    FiChevronLeft,
    FiChevronRight,
    FiChevronUp,
    FiCreditCard,
    FiFacebook,
    FiFolder,
    FiHelpCircle,
    FiMenu,
    FiUser
} from 'react-icons/fi';
import { MdCampaign, MdOutlineRocketLaunch } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { TiFlowChildren } from "react-icons/ti";
import { FaFacebook } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useGetActiveAccountsListQuery, useGetActiveAccountsQuery } from 'store/adAccounts/adAccountsApi';
import { api } from 'store/api';
import { logout, setActiveUserAdAccounts, setUserActiveAdAccountsList } from 'store/auth/authSlice';
import { useGetBmAdAccountsQuery, useGetBusinessAccountsQuery, useGetFbActiveOrDeactiveQuery, useLogoutRelationMutation } from 'store/BmAccounts/bmAccountApi';
import { selectFbData, selectFbDataRefetched, selectRefetched, selectSelectedBmAccounts, setFbData, setRefetched, setRefetchFbData, setSelectedAdAccounts, setSelectedBmAccounts } from 'store/BmAccounts/bmAccountSlice';
import { setAfter, setBefore, setCurrentPage, setSelectedAdsetId, setSelectedAdsId, setSelectedCampaignId } from 'store/campaigns/campaignsSlice';
import { useGetAllCompaniesQuery } from 'store/companies/companiesApi';
import { setActiveCompanies } from 'store/companies/companySlice';
import FileUploadStatusWidget from './FileUploadStatusWidget';


const adminLinkItems = [
    { name: "Users", icon: FiUser, path: "clients" },
    { name: "Companies", icon: FiBriefcase, path: "companies" },
    { name: "Ad Accounts", icon: FiFacebook, path: "accounts" },
    { name: "Top Ups", icon: FiCreditCard, path: "topup" },
    { name: "Tickets", icon: FiHelpCircle, path: "requests" },
    { name: "Dashboard", icon: FiBriefcase, path: "dashboard" },
    // { name: "My Drive", icon: FiFolder, path: "uploads" },
    // { name: "Adspresso", icon: MdOutlineRocketLaunch, path: 'adspresso-campaigns' },
    //{ name: "Campaigns", icon: MdCampaign, path: "manage-campaigns" },
    // { name: "Campaigns", icon: MdCampaign, path: "campaigns" },
    // { name: "Ad Rules", icon: TiFlowChildren, path: "rules" },
];

const clientLinkItems = [
    {
        section: "Adspresso Management", // New section for Adspresso
        items: [
            { name: "My Drive", icon: FiFolder, path: "uploads" },
            { name: "Adspresso", icon: MdOutlineRocketLaunch, path: 'adspresso-campaigns' },
            { name: "Campaigns", icon: MdCampaign, path: "campaigns" },
            { name: "Ad Rules", icon: TiFlowChildren, path: "rules" },
        ]
    },
    {
        section: "Rental Management", // New section for Rental Management
        items: [
            { name: "Companies", icon: FiBriefcase, path: "companies" },
            { name: "Ad Accounts", icon: FiFacebook, path: "accounts" },
            { name: "Top Ups", icon: FiCreditCard, path: "topup" },
            { name: "Tickets", icon: FiHelpCircle, path: "requests" },
        ]
    }
];

export default function SidebarWithHeader({
    noPadding,
    children,
}) {
    const location = useLocation();
    const slug = location.pathname.replace(/\//g, "");
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode, toggleColorMode } = useColorMode();
    const userInfo = useSelector(state => state.auth?.userInfo);
    const bmAccount = useSelector(selectSelectedBmAccounts);
    const isRefetched = useSelector(selectRefetched);
    const isFbDataRefetched = useSelector(selectFbDataRefetched);
    const [shouldPoll, setShouldPoll] = useState(false);
    const [shouldPollBusinessAccounts, setShouldPollBusinessAccounts] = useState(false);
    const dispatch = useDispatch();

    const [isCollapsed, setIsCollapsed] = useState(() => {
        // Getting value from localStorage, or default to false if not
        const savedState = localStorage.getItem('sidebarCollapsed');
        return savedState ? JSON.parse(savedState) : false;
    });

    const handleToggleSidebar = () => {
        setIsCollapsed((prevState) => {
            const newState = !prevState;
            // Save the new state to localStorage
            localStorage.setItem('sidebarCollapsed', JSON.stringify(newState));
            return newState;
        });
    };

    const {
        data: companies,
        refetch: refetchCompanies
    } = useGetAllCompaniesQuery('getCompanies', {
        skip: userInfo?.role !== "client" || slug == "uploads" || slug.includes("adspresso-campaigns") || slug == "campaigns" || slug == "rules" || slug == "bm-manager"
    });

    const {
        data: adAccounts,
        refetch: refetchAdaccounts
    } = useGetActiveAccountsQuery({ currentPage: 1, searchValue: '' }, {
        skip: userInfo?.role !== "client" || slug == "uploads" || slug.includes("adspresso-campaigns") || slug == "campaigns" || slug == "rules" || slug == "bm-manager"
    });

    const { data: activeAccountsData,
            refetch: refetchActiveAdaccounts 
    } = useGetActiveAccountsListQuery({},{
         skip: userInfo?.role !== "client" || slug == "uploads" || slug.includes("adspresso-campaigns") || slug == "campaigns" || slug == "rules" || slug == "bm-manager"
    });
      
    const { data: fBData, refetch: refetchFbData, isLoading: isFbDataLoading } = useGetFbActiveOrDeactiveQuery(
        {},
        {
            pollingInterval: shouldPoll ? 2000 : 0,
            skipPollingIfUnfocused: true,
        }
    );
    
    // const { data: businessAccountData, refetch: refetchBusinessAccounts, isLoading:isBusinessLoading } = useGetBusinessAccountsQuery(
    //     {},
    //     {
    //         pollingInterval: shouldPollBusinessAccounts ? 2000 : 0,
    //         skipPollingIfUnfocused: true,
    //     },{ skip: !fBData?.status === 'deactive'}
    // );
      
    // const { data: AdAccountData, refetch: refetchAdaccountData } = useGetBmAdAccountsQuery(
    //     {
    //         pollingInterval: 700000,
    //         skipPollingIfUnfocused: true,
    //     },
    //     { skip: !fBData?.status === 'deactive'}
    // );

    useEffect(() => {
        // If token is present, start polling
        if (token && fBData?.status !== 'active') {
            setShouldPoll(true);
        } else {
            // Stop polling if fBData.status is 'active' or token is not present
            setShouldPoll(false);
        }
    }, [token, fBData]); 

    // useEffect(() => {
    //     if (token && !businessAccountData?.length) {
    //         setShouldPollBusinessAccounts(true); // Stop polling once data is fetched
    //     }else{
    //         setShouldPollBusinessAccounts(false);
    //     }
    // }, [token, businessAccountData]);
    

    // useEffect(() => {
    //     if (isRefetched) {
    //         // refetchAdaccountData();
    //         dispatch(setRefetched(false));
    //     }
    // }, [isRefetched]);

    
    useEffect(() => {
        if (isFbDataRefetched) {
            refetchFbData();
            dispatch(setRefetchFbData(false));
        }
    }, [isFbDataRefetched]);
    
    useEffect(() => {
        if (fBData) {
            dispatch(setFbData(fBData));
            localStorage.setItem('fBData', JSON.stringify(fBData));
        }
    }, [fBData]);

    useEffect(() => {
        const storedFbData = localStorage.getItem('fBData');
        if (storedFbData) {
            const parsedFbData = JSON.parse(storedFbData);
            dispatch(setFbData(parsedFbData));
        }
    }, [dispatch]);
     
    useEffect(() => {
        if ((userInfo?.role === 'client' || userInfo?.role === 'admin' || userInfo?.role === 'media') && adAccounts?.data?.length) {
            dispatch(setActiveUserAdAccounts(adAccounts?.data))
        }
    }, [adAccounts])
    
    useEffect(() => {
        if ((userInfo?.role === 'client' || userInfo?.role === 'admin' || userInfo?.role === 'media') && companies?.data?.length) {
            dispatch(setActiveCompanies(companies?.data))
        }
    }, [companies])
    
    useEffect(() => {
        if ((userInfo?.role === 'client' || userInfo?.role === 'admin' || userInfo?.role === 'media') && activeAccountsData?.data?.length) {
            dispatch(setUserActiveAdAccountsList(activeAccountsData))
        }
    }, [activeAccountsData])
      
    // useEffect(() => {
    //     if ((userInfo?.role === 'client' || userInfo?.role === 'admin' || userInfo?.role === 'media') && AdAccountData?.data?.length) {
    //         dispatch(setSelectedAdAccounts(AdAccountData?.data))
    //     }
    // }, [AdAccountData])

    
    useEffect(() => {
        if (userInfo?.role === 'client' &&  slug == "requests" && slug == "accounts" && slug == "topup" && slug == "companies") {
            if (!companies?.data?.length) {
                refetchCompanies(); // Only refetch if companies are not available
            }
            if (!adAccounts?.data?.length) {
                refetchAdaccounts(); // Only refetch if adAccounts are not available
            }
            if (!activeAccountsData?.data?.length) {
                refetchActiveAdaccounts(); // Only refetch if activeAdaccounts are not available
            }
        }
    }, [userInfo, adAccounts, companies, activeAccountsData])

    const handleBusinessAccountUpdate = (updatedAccount) => {
        // if(updatedAccount){
        //     refetchAdaccountData();
        // }
      };
    

    return (
        <Box
            minH="100vh"
            bg={useColorModeValue('white', '#231f20')}
        >
            <FileUploadStatusWidget />
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
                onToggleSidebar={handleToggleSidebar}
                isCollapsed={isCollapsed}
                // businessAccountData={businessAccountData}
                onBusinessAccountChange={handleBusinessAccountUpdate}
                // isBusinessLoading={isBusinessLoading}
                
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* MOBILE NAV */}
            <MobileNav onOpen={onOpen} isCollapsed={isCollapsed} />
            <Box ml={{ base: 0, md: isCollapsed ? '80px' : '225px' }} p={{ base: noPadding ? '0px' : '20px', md: noPadding ? '0px' : '20px' }}>
                {children}
            </Box>
            <IconButton position={'fixed'} zIndex={3} bottom={[0, '10px']} left={[0, '20px']} w={3} mx={'auto'} mt={8} mb={2} icon={colorMode == 'dark' ? <SunIcon /> : <MoonIcon />} onClick={toggleColorMode} />
        </Box>
    );
}

const SidebarContent = ({ onClose, onToggleSidebar, businessAccountData=[], onBusinessAccountChange, isCollapsed, ...rest }) => {

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const queryParams = new URLSearchParams(location.search);
    const businessId = queryParams.get('business_id');
    const slug = location.pathname.replace(/\//g, "");
    const isOnboarded = useSelector((state) => state.auth?.isUserOnBoarded)
    const hasActiveAdAccount = useSelector((state) => state.auth?.hasActiveAdAccount)
    const fbDeactive = useSelector(selectFbData);
    const userInfo = useSelector(state => state.auth?.userInfo)
    const [selectedBusinessId, setSelectedBusinessId] = useState(businessId ?  businessId : null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const selectedBusiness = businessAccountData?.find(account => account.id === selectedBusinessId);
    const { colorMode, toggleColorMode } = useColorMode();
    const darkHeadingColor = useColorModeValue('gray.800', 'none');
    const bgColor = useColorModeValue('gray.50', 'gray.800');
    const [logoutBmRelation, { isLoading: isLoggingOut }] = useLogoutRelationMutation();
    
    const [isAdspressoCollapsed, setIsAdspressoCollapsed] = useState(() => {
        const savedState = localStorage.getItem("isAdspressoCollapsed");
        return savedState ? JSON.parse(savedState) : false;  // Default to collapsed
    });
    const [isRentalCollapsed, setIsRentalCollapsed] = useState(() => {
        const savedState = localStorage.getItem("isRentalCollapsed");
        return savedState ? JSON.parse(savedState) : true;  // Default to collapsed
    });

     // Using useCallback to avoid unnecessary re-renders
     const handleAdspressoCollapseToggle = useCallback(() => {
        setIsAdspressoCollapsed((prev) => {
            const newState = !prev;
            localStorage.setItem("isAdspressoCollapsed", JSON.stringify(newState)); // Save to localStorage
            return newState;
        });
    }, []);

    const handleRentalCollapseToggle = useCallback(() => {
        setIsRentalCollapsed((prev) => {
            const newState = !prev;
            localStorage.setItem("isRentalCollapsed", JSON.stringify(newState)); // Save to localStorage
            return newState;
        });
    }, []);

    const handleLogoutRelation = () => {
        logoutBmRelation()
          .unwrap()
          .then((response) => {
            dispatch(setSelectedBmAccounts(null));
            dispatch(setRefetchFbData(true))
            localStorage.removeItem('fBData');
            // localStorage.removeItem('businessAccountData');
    
            toast({
              position: 'top-right',
              title: 'Disconnected successfully ',
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
            navigate('/bm-manager', { replace: true });
            onClose();
          })
          .catch((error) => {
            toast({
              position: 'top-right',
              title: 'Error in disconnecting FB',
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
          });
    };

    useEffect(() => {
        if (businessId) {
            const selectedBmAccount = businessAccountData?.find(account => account.id === businessId);
            dispatch(setSelectedBmAccounts(selectedBmAccount));
        } else if (businessAccountData?.length && !selectedBusinessId) {
            // Default to first business account if no businessId is provided
            const defaultBusiness = businessAccountData[0];
            setSelectedBusinessId(defaultBusiness.id);
            dispatch(setSelectedBmAccounts(defaultBusiness));
        }
    }, [businessId, businessAccountData, selectedBusinessId]);

    const handleBusinessAccountChange = (account) => {
        if (account?.id !== selectedBusinessId) {
            setSelectedBusinessId(account?.id); // Only update the businessId
            dispatch(setSelectedBmAccounts(account));
            onBusinessAccountChange(account);
            // navigate(`${location.pathname}?business_id=${account?.id}`, { replace: true });
            setIsPopoverOpen(false);
        }
    };
      
    
    return (
        <Box
            transition="0.3s ease"
            bg={useColorModeValue('white', '#231f20')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            //w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            zIndex={2}
            {...rest}>
            <Flex h="20" alignItems="center" mx={isCollapsed ? "auto" : "8"} mb={'20px'} justifyContent={ isCollapsed ? "center" : "space-between"}>
                {/* <Link href='/'>
                    <Image w='200px' src={colorMode == 'dark' ? LogoNavWhite : LogoNav} alt='midas logo' />
                </Link> */}

                {isCollapsed ? (
                     <Link href={fbDeactive?.status === 'active' ? '/adspresso-campaigns' : '/bm-manager'}>
                        <Image w="40px" src={colorMode === 'dark' ? noBgLogo : noBgLogo} alt='midas logo' />
                    </Link>
                ) : (
                    <Link href={fbDeactive?.status === 'active' ? '/adspresso-campaigns' : '/bm-manager'}>
                        <Image w="160px" src={colorMode === 'dark' ? LogoNavWhite : LogoNav} alt='midas logo' />
                    </Link>
                )}
                <IconButton
                    aria-label="Toggle Sidebar"
                    icon={isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
                    onClick={onToggleSidebar}
                    variant="outline"
                    display={{ base: 'flex', md: 'flex' }}
                    _hover={{
                        bg: colorMode === 'light' ? 'gray.50' : 'gray.500'
                    }}
                    sx={{
                        position: 'absolute',
                        right: '-16px',
                        top:'60px',  
                        background: colorMode === 'light' ? 'white' : 'gray.800',
                        zIndex: 10000,
                    }}
                    size={'sm'}
                />

                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {userInfo && (
                <>
                    {userInfo?.role == "admin" || userInfo?.role == "media" || userInfo?.role == "finance" ? (
                        adminLinkItems.map(({ name, icon, path }) => (
                            <NavItem key={`${name}-${path}`} icon={icon} active={path === slug} path={path} isCollapsed={isCollapsed}>
                                {!isCollapsed && name}
                            </NavItem>
                        ))
                    ) : null}

                    {userInfo?.role == 'client' ? (
                        <>
                            {/* {isOnboarded ? ( */}
                                <>
                                    {/* {hasActiveAdAccount ? ( */}
                                        <>
                                            {/* Business Account Selector */}
                                            
                                            {
                                                fbDeactive?.status === 'active' && (
                                                    // businessAccountData?.data > 0 ? (
                                                    //     <Box zIndex={80}>
                                                    //         <Flex gap={2} alignItems={'center'} direction={'column'} mb={2}>
                                                    //             <Text fontWeight="bold">{!isCollapsed ? 'Business portfolios' : 'BP'}</Text>

                                                    //             {!isCollapsed && (
                                                    //                 <Popover isOpen={isPopoverOpen} onClose={() => setIsPopoverOpen(false)}>
                                                    //                     <PopoverTrigger>
                                                    //                         <Button
                                                    //                             width="200px"
                                                    //                             height="40px"
                                                    //                             borderRadius="md"
                                                    //                             border="1px"
                                                    //                             borderColor="gray.300"
                                                    //                             bg="white"
                                                    //                             fontSize={'14px'}
                                                    //                             _hover={{ bg: 'gray.50' }}
                                                    //                             _active={{ bg: 'none' }}
                                                    //                             display="flex"
                                                    //                             justifyContent="space-between"
                                                    //                             alignItems="center" 
                                                    //                             onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                                                    //                         >
                                                    //                             <Text noOfLines={1} whiteSpace="nowrap">
                                                    //                                 {(selectedBusiness ? selectedBusiness.name : 'Select Business').slice(0, 20)}
                                                    //                             </Text>
                                                    //                             <ChevronDownIcon />
                                                    //                         </Button>
                                                    //                     </PopoverTrigger>
                                                    //                     <PopoverContent width="380px" boxShadow="md" left={4} zIndex={100}>
                                                    //                         <PopoverArrow />
                                                    //                         <PopoverBody p={2} gap={4} display="flex" flexDirection="column" alignItems={'flex-start'}>
                                                    //                             <Box
                                                    //                                 maxHeight="180px"
                                                    //                                 overflowY="auto"
                                                    //                                 display="flex"
                                                    //                                 flexDirection="column"
                                                    //                                 alignItems={'flex-start'}
                                                    //                                 justifyContent={'flex-start'}
                                                    //                                 width="100%"
                                                    //                                 px={2}
                                                    //                             >
                                                    //                                 {businessAccountData.map((account) => (
                                                    //                                     <Button
                                                    //                                         key={account?.id}
                                                    //                                         onClick={() => handleBusinessAccountChange(account)}
                                                    //                                         bg={selectedBusiness?.id === account?.id ? "#ea7869" : 'none'}
                                                    //                                         color={selectedBusiness?.id === account?.id ? "white" : 'none'}
                                                    //                                         p="8px"
                                                    //                                         width={'100%'}
                                                    //                                         textAlign="left"
                                                    //                                         display="flex"
                                                    //                                         fontSize={'14px'}
                                                    //                                         alignItems="center"
                                                    //                                         fontWeight={'none'}
                                                    //                                         justifyContent="flex-start"
                                                    //                                         _hover={{
                                                    //                                             bg: selectedBusiness?.id === account?.id ? "#ea7869" : "#fce4e2",
                                                    //                                             cursor: "pointer",
                                                    //                                         }}
                                                    //                                     >
                                                    //                                         <Box
                                                    //                                             width="30px"
                                                    //                                             height="30px"
                                                    //                                             display="flex"
                                                    //                                             alignItems="center"
                                                    //                                             justifyContent="center"
                                                    //                                             borderRadius="5px"
                                                    //                                             backgroundColor={'gray.400'}
                                                    //                                             color="white"
                                                    //                                             fontWeight="bold"
                                                    //                                             fontSize="18px"
                                                    //                                             mr={2}
                                                    //                                         >
                                                    //                                             {account?.name?.charAt(0).toUpperCase()}
                                                    //                                         </Box>
                                                    //                                         {account.name.length > 20 ? `${account.name.slice(0, 36)}...` : account.name}
                                                    //                                     </Button>
                                                    //                                 ))}
                                                    //                             </Box>
                                                    //                             <Divider />
                                                    //                             <Box width="100%" px={2}>
                                                    //                                 <Button
                                                    //                                     bg='none'
                                                    //                                     border={'1px'}
                                                    //                                     borderColor={'gray.100'}
                                                    //                                     _hover={{
                                                    //                                         bg: 'gray.50',
                                                    //                                         borderColor: 'gray.400',
                                                    //                                     }}
                                                    //                                     onClick={handleLogoutRelation}
                                                    //                                     width={'100%'}
                                                    //                                     fontSize={'14px'}
                                                    //                                     isLoading={isLoggingOut}
                                                    //                                     loadingText="Disconnecting..."
                                                    //                                 >
                                                    //                                     {isLoggingOut ? "Disconnecting..." : "Disconnect from BM"}
                                                    //                                 </Button>
                                                    //                             </Box>
                                                    //                         </PopoverBody>
                                                    //                     </PopoverContent>
                                                    //                 </Popover>
                                                    //             )}
                                                    //         </Flex>
                                                    //     </Box>
                                                    // ) : (
                                                        // Show logout button if businessAccountData is empty
                                                        <Box zIndex={80} display="flex" justifyContent="center" alignItems="center" mb={2}>
                                                            {isCollapsed ? (
                                                                <Tooltip label={'Disconnect from BM'} fontSize="xs" placement={'right'}>
                                                                    <Button
                                                                        bg='none'
                                                                        border={'1px'}
                                                                        borderColor={'gray.100'}
                                                                        _hover={{
                                                                            bg: 'gray.50',
                                                                            borderColor: 'gray.400',
                                                                        }}
                                                                        onClick={handleLogoutRelation}
                                                                        width={'auto'}
                                                                        fontSize={'14px'}
                                                                        isLoading={isLoggingOut}
                                                                        loadingText=""
                                                                    >
                                                                        {isLoggingOut ? "" : "BM"}
                                                                    </Button>
                                                                </Tooltip>
                                                            ) : (
                                                                <Button
                                                                    bg='none'
                                                                    border={'1px'}
                                                                    borderColor={'gray.100'}
                                                                    _hover={{
                                                                        bg: 'gray.50',
                                                                        borderColor: 'gray.400',
                                                                    }}
                                                                    onClick={handleLogoutRelation}
                                                                    width={'90%'}
                                                                    fontSize={'14px'}
                                                                    isLoading={isLoggingOut}
                                                                    loadingText="Disconnecting..."
                                                                >
                                                                    {isLoggingOut ? "Disconnecting..." : "Disconnect from BM"}
                                                                </Button>
                                                            )}
                                                        </Box>

                                                    // )
                                                )
                                            }



                                           {/* Connect BM always visible */}
                                           {fbDeactive?.status === 'deactive' ? (
                                            <Box>
                                                {isCollapsed ? (
                                                    <Tooltip label={'Settings'} fontSize="xs" placement={'right'}>
                                                        <Box>
                                                            <NavItem
                                                                key=" Settings"
                                                                icon={IoMdSettings}
                                                                active={slug.startsWith("bm-manager")}
                                                                path={selectedBusinessId ? `bm-manager` : 'bm-manager'}
                                                                isCollapsed={isCollapsed}
                                                            >
                                                                Settings
                                                            </NavItem>
                                                        </Box>
                                                    </Tooltip>
                                                ):(
                                                    <NavItem
                                                        key="Settings"
                                                        icon={IoMdSettings}
                                                        active={slug.startsWith("bm-manager")}
                                                        path={selectedBusinessId ? `bm-manager` : 'bm-manager'}
                                                        isCollapsed={isCollapsed}
                                                        // fontWeight={'bold'}
                                                    >
                                                        Settings
                                                    </NavItem>
                                                )}
                                            </Box>
                                            ):null}
                                            {/* Adspresso Management Section */}
                                            <Box>
                                                <Flex
                                                    onClick={handleAdspressoCollapseToggle}
                                                    cursor="pointer"
                                                    m={2}
                                                    align="center"
                                                    justify="space-between"
                                                    p={3}
                                                    bg={isAdspressoCollapsed ? 'transparent' : bgColor}
                                                    borderRadius="lg"
                                                    gap={1}
                                                    >
                                                        <Text fontWeight="bold" fontSize="14px" color={isAdspressoCollapsed ? 'none' : darkHeadingColor}>
                                                            {isCollapsed ? 'AC' : 'Adspresso Control'}
                                                        </Text>
                                                        <IconButton
                                                             icon={isAdspressoCollapsed ? <FiChevronDown size={16} /> : <FiChevronUp size={16} />}
                                                            p={1}
                                                            borderRadius={'sm'}
                                                            size="xs" 
                                                            bg="transparent" 
                                                            _hover={{ bg: "none" }}
                                                        />
                                                </Flex>
                                                <Collapse in={!isAdspressoCollapsed}>
                                                    {clientLinkItems.find(item => item.section === "Adspresso Management")?.items.map(({ name, icon, path }, index) => (
                                                        <React.Fragment key={`${name}-${path}-${index}`}>
                                                            {isCollapsed ? (
                                                                <Tooltip label={name} fontSize="xs" placement={'right'}>
                                                                    <Box>
                                                                        <NavItem key={`${name}-${path}-${index}`} icon={icon} active={path === slug} path={`${path}`} isCollapsed={isCollapsed}  isDisabled={fbDeactive?.status === 'deactive'} >
                                                                            {name}
                                                                        </NavItem>
                                                                    </Box>
                                                            </Tooltip>
                                                        ) : (
                                                            <NavItem key={`${name}-${path}-${index}`} icon={icon} active={path === slug} path={`${path}`} isCollapsed={isCollapsed} fontSize={'14px'}  isDisabled={fbDeactive?.status === 'deactive'} >
                                                                {name}
                                                            </NavItem>
                                                        )}
                                                        </React.Fragment>
                                                    ))}
                                                </Collapse>
                                            </Box>

                                            {/* Rental Management Section */}
                                            <Box>
                                                <Flex
                                                    onClick={handleRentalCollapseToggle}
                                                    cursor="pointer"
                                                    m={2}
                                                    align="center"
                                                    justify="space-between"
                                                    p={3}
                                                    bg={isRentalCollapsed ? 'transparent' : bgColor}
                                                    borderRadius="lg"
                                                >
                                                    <Text fontWeight="bold" fontSize="14px" color={isRentalCollapsed ? 'none' : darkHeadingColor}>
                                                        {isCollapsed ? 'RC' : 'Rental Control'}
                                                    </Text>
                                                    <IconButton
                                                        icon={isRentalCollapsed ? <FiChevronDown size={16} /> : <FiChevronUp size={16} />}
                                                        p={1}
                                                        borderRadius={'sm'}
                                                        size="xs" 
                                                        bg="transparent" 
                                                        _hover={{ bg: "none" }}
                                                    />
                                                </Flex>
                                                <Collapse in={!isRentalCollapsed}>
                                                    {clientLinkItems.find(item => item.section === "Rental Management")?.items.map(({ name, icon, path },index) => (
                                                        <React.Fragment key={`${name}-${path}-${index}`}>
                                                            {isCollapsed ? (
                                                                <Tooltip label={name} fontSize="xs" placement={'right'}>
                                                                    <Box>
                                                                        <NavItem key={`${name}-${path}-${index}`} icon={icon} active={path === slug} path={path} isCollapsed={isCollapsed}>
                                                                            {name}
                                                                        </NavItem>
                                                                    </Box>
                                                            </Tooltip>
                                                        ) : (
                                                            <NavItem key={`${name}-${path}-${index}`} icon={icon} active={path === slug} path={path} isCollapsed={isCollapsed} fontSize={'14px'}>
                                                                {name}
                                                            </NavItem>
                                                        )}
                                                        </React.Fragment>
                                                    ))}
                                                </Collapse>
                                            </Box>
                                        </>
                                    {/* ) : (
                                        <>
                                            {clientLinkItems.filter(({ name }) => name !== 'Top Ups').map(({ name, icon, path }, index) => (
                                                <NavItem key={`${name}-${path}-${index}`} icon={icon} active={path === slug} path={path} isCollapsed={isCollapsed}>
                                                    {!isCollapsed && name}
                                                </NavItem>
                                            ))}
                                        </>
                                    )} */}
                                </>
                            {/* ) : (
                                <NavItem key={'companies'} icon={FiBriefcase} active={true} path={'companies'} isCollapsed={isCollapsed}>
                                   Companies
                                </NavItem>
                                null
                            )} */}
                        </>
                    ) : null}
                </>
            )}
        </Box>
    );
};


const NavItem = ({ icon, path, active, children, isCollapsed, isDisabled, ...rest }) => {
    const dispatch = useDispatch();
    const hoverBackground = useColorModeValue('gray.50', 'gray.800');
    const hoverIconColor = useColorModeValue('gray.800', 'gray.50');

    const handleClick = (e) => {
        if (isDisabled) {
            e.preventDefault();
            return;
        }

        dispatch(setSelectedCampaignId(null));
        dispatch(setSelectedAdsetId(null));
        dispatch(setSelectedAdsId(null));
        dispatch(setAfter(''));
        dispatch(setBefore(''));
        dispatch(setCurrentPage(0));
        localStorage.removeItem('after');
        localStorage.removeItem('before');
        localStorage.removeItem('selectedAdsAccountId');

    };

    return (
        <Link as={RouterLink} to={`/${path}`} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} onClick={handleClick}>
            <Flex
                align="center"
                p="3"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor={isDisabled ? 'not-allowed' : 'pointer'} 
                bg={active ? 'brand.200' : ''}
                color={active ? 'white' : hoverIconColor}
                _hover={{
                    bg: !active && !isDisabled ? hoverBackground : '', 
                }}
                opacity={isDisabled ? 0.5 : 1}  
                {...rest}>
                {icon && (
                    <Icon
                        mr={isCollapsed ? "0" : "4"}
                        fontSize="14"
                        _groupHover={{
                            color: !active && !isDisabled ? hoverIconColor : '',
                        }}
                        as={icon}
                    />
                )}
                {!isCollapsed && children}
            </Flex>
        </Link>
    );
};



const MobileNav = ({ onOpen, isCollapsed, ...rest }) => {
    const navigate = useNavigate();
    const userInfo = useSelector(state => state?.auth?.userInfo)
    const dispatch = useDispatch();

    const { colorMode, toggleColorMode } = useColorMode();

    //console.log(userInfo);
    return (
        <Flex
            ml={{ base: 0 }}
            px={{ base: 4, md: 4 }}
            w={'full'}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', '#231f20')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Link href='/' display={{ base: 'flex', md: 'none' }}>
                <Image w='200px' src={colorMode == 'dark' ? LogoNavWhite : LogoNav} alt='midas logo' />
            </Link>

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar
                                    name={`${userInfo?.firstName} ${userInfo?.lastName} `}
                                    size={'sm'}
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm" textTransform={'capitalize'}>{userInfo?.firstName} {userInfo?.lastName}</Text>
                                    <Text fontSize="xs" color={useColorModeValue('gray.500', 'gray.400')} textTransform={'capitalize'}>
                                        {userInfo?.role}
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList>
                            {userInfo?.role == 'admin' ? (
                                <MenuItem onClick={() => { navigate('/logs') }}>Logs</MenuItem>
                            ) : null}
                            <MenuItem onClick={() => { navigate('/change-password') }}>Change password</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => { dispatch(logout()); dispatch(api.util.resetApiState()); navigate('/signIn') }}>Sign out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};