import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Input, InputGroup, InputLeftElement, InputRightElement, List, ListItem, Select, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FaMapMarkerAlt } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { useGetGeoLocationsQuery, useGetLocationMetaMutation } from 'store/facebookServices/facebookServicesApi';
import { fetchCountryMeta } from 'utils/useLocationMeta';
import countries from 'i18n-iso-countries';

// Register the English locale for i18n-iso-countries
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));


const GeoLocationsSelector = ({ onLocationsChange, preFilledIncludedLocations, preFilledExcludedLocations, onBlur, selectedZipCodes, adsAccountData }) => {

    // const userInfo = useSelector((state) => state.auth?.userInfo);

    const flattenLocations = (locationsObj, inclusionType, adsAccountData, token) => {
        if (Object.entries(locationsObj)?.length === 0) {
            return [];
        }

        const flattenedLocations = Object.entries(locationsObj)
            .filter(([key]) => key !== "location_types") // Exclude 'location_types'
            .flatMap(([type, locations]) =>
                locations.map((location) => {
                    // console.log({ location });

                    if (type === "countries" && !location?.type) {
                        // location.type shows entry from adspresso db & type is for fb entry
                        const fullName = countries.getName(location, 'en');

                        return {
                            key: location,
                            country_code: location,
                            name: fullName || location, // Use the ISO code if the name isn't found
                            type: "country",
                            supports_region: true,
                            supports_city: true,
                            inclusionType,
                        };
                    }

                    // For other types, include all properties along with `inclusionType`
                    return { ...location, inclusionType };
                })
            )

        return flattenedLocations;
    };




    // Helper function to check if a location should be replaced
    const shouldRemoveLocation = (newLocation, existingLocation) => {
        //   console.log({ newLocation, existingLocation });

        const isSameCountry = newLocation.country_code === existingLocation.country_code;
        const isSameInclusionType = newLocation.inclusionType === existingLocation.inclusionType;

        // If a country exists and a zip code from the same country is added, remove the country
        const isCountryRemovedByZip =
            newLocation.type === "zip" &&
            existingLocation.type === "country" &&
            isSameCountry &&
            isSameInclusionType;

        // If a zip code exists and a country from the same country is added, remove the zip code
        const isZipRemovedByCountry =
            newLocation.type === "country" &&
            existingLocation.type === "zip" &&
            isSameCountry &&
            isSameInclusionType;

        // Check for identical locations (no removal)
        const isSameLocation =
            newLocation.key === existingLocation.key &&
            newLocation.type === existingLocation.type;

        // Check if regions and cities are being replaced by countries and vice versa
        const isRegionOrCityReplacedByCountry =
            (newLocation.type === "country" && (existingLocation.type === "region" || existingLocation.type === "city")) &&
            isSameCountry &&
            isSameInclusionType;

        const isCountryReplacedByRegionOrCity =
            (newLocation.type === "region" || newLocation.type === "city") &&
            existingLocation.type === "country" &&
            isSameCountry &&
            isSameInclusionType;

        // Final conditions to determine if the location should be removed
        return (
            isCountryRemovedByZip ||
            isZipRemovedByCountry ||
            isRegionOrCityReplacedByCountry ||
            isCountryReplacedByRegionOrCity ||
            isSameLocation
        );
    };


    // Remove locations of different specificity when a new one is selected
    const filterSelectedLocations = (newLocation, selectedLocations) => {

        // Handle exclusion logic directly for exclude type
        if (newLocation.inclusionType === "exclude") {
            // First, remove any location that conflicts with the new exclusion location
            const updatedLocations = selectedLocations.filter(
                (location) => !shouldRemoveLocation(newLocation, location)
            );

            // Add the exclusion location
            return [...updatedLocations, newLocation];
        }

        // Handle inclusion type by filtering out locations that should be replaced
        const updatedLocations = selectedLocations.filter(
            (location) => !shouldRemoveLocation(newLocation, location)
        );

        // Include the new location
        return [...updatedLocations, newLocation];
    };

    const [selectedLocations, setSelectedLocations] = useState([]);

    const processZipCodes = (zipcodes) => {
        setSelectedLocations((prevSelectedLocations) => {
            let updatedLocations = [...prevSelectedLocations];

            zipcodes.forEach((zipcode) => {
                const isDuplicate = updatedLocations.some(
                    (location) => location.key === zipcode.key && location.type === "zip"
                );

                if (!isDuplicate) {
                    // Remove country from updatedLocations if the zip code belongs to the same country
                    updatedLocations = updatedLocations.filter(
                        (location) =>
                            !(location.type === "country" && location.country_code === zipcode.country_code)
                    );

                    // Add the zip code to updatedLocations
                    updatedLocations.push(zipcode);
                }
            });

            return updatedLocations;
        });
    };



    // console.log({ selectedLocations });


    useEffect(() => {
        const fetchAndCombineLocations = async () => {
            const flattenedPreFilledIncludedLocations = preFilledIncludedLocations
                ? await flattenLocations(preFilledIncludedLocations, 'include')
                : [];
            const flattenedPreFilledExcludedLocations = preFilledExcludedLocations
                ? await flattenLocations(preFilledExcludedLocations, 'exclude')
                : [];

            // Combine the arrays
            setSelectedLocations([
                ...flattenedPreFilledIncludedLocations,
                ...flattenedPreFilledExcludedLocations,
            ]);
        };

        fetchAndCombineLocations();
    }, [preFilledIncludedLocations, preFilledExcludedLocations]);


    const inputRef = useRef(null);
    const popupRef = useRef(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [locationOptions, setLocationOptions] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [inclusionType, setInclusionType] = useState('include'); // Default to include

    const { data: locations, isLoading, isFetching, refetch } = useGetGeoLocationsQuery({ searchTerm: debouncedSearchTerm, adsAccountId: adsAccountData }, {});
    // const [getLocationMeta] = useGetLocationMetaMutation()
    // console.log({ flattenedZipcodes });
    // console.log({ selectedLocations });
    // console.log({ flattenedPreFilledIncludedLocations });

    useEffect(() => {
        if (selectedZipCodes?.length) {
            let flattenedZipcodes_ = flattenLocations({ 'zips': selectedZipCodes }, 'include');

            // const filteredZipCodes = processZipCodes(flattenedZipcodes_);
            processZipCodes(flattenedZipcodes_);

            //console.log({ filteredZipCodes });

            // setSelectedLocations((prev) => [...prev, ...filteredZipCodes])
        }
    }, [selectedZipCodes])


    useEffect(() => {
        if (searchTerm?.length === 0) {
            setDebouncedSearchTerm('');
            setShowPopup(false);
            return;
        }

        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 800);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm?.length > 0 && searchTerm === debouncedSearchTerm) {
            refetch();
        }
    }, [searchTerm, debouncedSearchTerm]);

    useEffect(() => {
        if (locations?.data?.length > 0) {
            setShowPopup(true);
            setLocationOptions(locations?.data);
        } else {
            setShowPopup(false);
        }
    }, [locations, isFetching]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target) &&
                popupRef.current &&
                !popupRef.current.contains(event.target)
            ) {
                setShowPopup(false);
                setLocationOptions([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLocationSelect = (location) => {
        //  console.log({ location });

        const locationWithInclusionType = { ...location, inclusionType };

        setSelectedLocations(prevLocations => {
            const updatedLocations = filterSelectedLocations(locationWithInclusionType, prevLocations);
            const allLocations = [...updatedLocations, locationWithInclusionType];

            const finalLocations = allLocations.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.key === value.key
                ))
            );

            // Call the parent function to notify about the updated selected locations
            setTimeout(() => onLocationsChange(finalLocations), 0);

            return finalLocations;
        });

        setSearchTerm('');
        setDebouncedSearchTerm('');
        setShowPopup(false);
        setLocationOptions([]);
    };

    const removeLocation = (id) => {
        setSelectedLocations((prevLocations) => {
            const updatedLocations = prevLocations.filter((location) => location.key !== id);
            setTimeout(() => onLocationsChange(updatedLocations), 0);
            return updatedLocations;
        });
    };

    const hasIncludeEntry = selectedLocations?.some(
        (location) => location?.inclusionType === "include"
    );

    useEffect(() => {
        if (hasIncludeEntry === false) {
            setInclusionType('include')
        }
    }, [hasIncludeEntry])

    useEffect(() => {
        if (selectedLocations?.length) {
            onLocationsChange(selectedLocations)
        }
    }, [selectedLocations])


    const filterUniqueLocations = (locations) => {
        // Filter duplicates based on `key` and `inclusionType`
        return locations.filter((location, index, self) =>
            index === self.findIndex((t) => (
                t.key === location.key && t.inclusionType === location.inclusionType
            ))
        );
    };

    // Filter based on the inclusion type and duplicates before rendering
    const filteredLocations = filterUniqueLocations(selectedLocations);

    return (
        <Box>
            <Box mt="10px" mb={'5px'} maxHeight="200px" overflowY="auto">
                {filteredLocations?.map((location, index) => (
                    <Flex key={`${location.key}-${index}`} bg={'white'} align={'center'} justify={'space-between'} width={'full'} py={'5px'} px={'10px'} borderRadius={'md'} mb={'5px'}>
                        <Flex flex={1} pr={'10px'} align={'center'}>
                            <FaMapMarkerAlt color={location.inclusionType == 'include' ? '#00A500' : '#FA353B'} size="16px" />
                            <Flex justify={'space-between'} flex={1} align={'center'}>
                                <Text ml={'5px'} fontSize={'14px'} fontWeight={'bold'}>{location.name} </Text>
                            </Flex>
                        </Flex>
                        <CloseIcon _hover={{ cursor: 'pointer', color: 'red.400' }} boxSize={3} color='gray.500' onClick={() => removeLocation(location?.key)} />
                    </Flex>
                ))}
            </Box>

            {/* Dropdown for inclusion/exclusion */}
            <Flex>
                <Box position={'relative'}>
                    <FaMapMarkerAlt size="14px" color={inclusionType == 'include' ? '#00A500' : '#FA353B'} style={{ position: 'absolute', left: 0, top: 8, left: 5 }} />
                    <Select
                        value={inclusionType}
                        onChange={(e) => setInclusionType(e.target.value)}
                        mb={2}
                        size={'sm'}
                        maxW={'auto'}
                        style={{ paddingLeft: '22px' }}
                        borderRadius={'md'}
                        borderRight={0}
                        borderRightRadius={0}
                    >
                        <option value="include"> Include</option>
                        <option value="exclude" disabled={!hasIncludeEntry}>Exclude</option>
                    </Select>
                </Box>

                <Box position="relative" flex={1}>
                    <InputGroup alignItems={'center'}>
                        <InputLeftElement height={'32px'}>
                            <SearchIcon boxSize={4} color='gray.500' />
                        </InputLeftElement>
                        <Input
                            id='geo_locations'
                            name='geo_locations'
                            ref={inputRef}
                            value={searchTerm}
                            onChange={(e) => { setSearchTerm(e.target.value); }}
                            placeholder="Search locations"
                            autoComplete="off"
                            size={'sm'}
                            borderRadius={'md'}
                            borderLeftRadius={0}
                            onBlur={onBlur}
                            onFocus={() => {
                                setLocationOptions([]);
                                if (locationOptions?.length > 0) {
                                    setShowPopup(true);
                                }
                            }}
                        />
                        <InputRightElement height={'32px'}>
                            {isFetching ? (
                                <Spinner boxSize={3} />
                            ) : (
                                searchTerm?.length > 0 && (
                                    <CloseIcon boxSize={3} color='gray.500' onClick={() => setSearchTerm('')} />
                                )
                            )}
                        </InputRightElement>
                    </InputGroup>

                    {isLoading ? (
                        <Flex align={'center'} justify={'center'} minH={'200px'} minW={'200px'}>
                            <Spinner />
                        </Flex>
                    ) : (
                        showPopup && locationOptions.length > 0 && (
                            <List
                                ref={popupRef}
                                position="absolute"
                                top="100%"
                                width="100%"
                                border="1px solid #ccc"
                                backgroundColor="white"
                                zIndex="1000"
                                maxHeight="200px"
                                overflowY="auto"
                            >
                                {locationOptions.map((location) => (
                                    <ListItem
                                        key={location.region_id + location.name}
                                        p={'5px'}
                                        px={'10px'}
                                        cursor="pointer"
                                        _hover={{ backgroundColor: '#f0f0f0' }}
                                        onClick={() => handleLocationSelect(location)}
                                    >
                                        <Flex justify={'space-between'}>
                                            <Text fontSize={'14px'}>{location.name}</Text>
                                            <Text fontSize={'12px'}>{location.type}</Text>
                                        </Flex>
                                    </ListItem>
                                ))}
                            </List>
                        )
                    )}
                </Box>
            </Flex>
        </Box>
    );
};

export default GeoLocationsSelector;
