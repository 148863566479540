import {
    Box,
    Button,
    Flex,
    FormControl,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Text,
    Textarea,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Spinner,
} from "@chakra-ui/react";
import Papa from "papaparse";
import { useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useMatchBulkLocationsMutation } from "store/facebookServices/facebookServicesApi";

const countryOptions = [
    { value: "AF", label: "Afghanistan" },
    { value: "AL", label: "Albania" },
    { value: "DZ", label: "Algeria" },
    { value: "AS", label: "American Samoa" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguilla" },
    { value: "AQ", label: "Antarctica" },
    { value: "AG", label: "Antigua and Barbuda" },
    { value: "AR", label: "Argentina" },
    { value: "AM", label: "Armenia" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Australia" },
    { value: "AT", label: "Austria" },
    { value: "AZ", label: "Azerbaijan" },
    { value: "BS", label: "Bahamas" },
    { value: "BH", label: "Bahrain" },
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Belarus" },
    { value: "BE", label: "Belgium" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Benin" },
    { value: "BM", label: "Bermuda" },
    { value: "BT", label: "Bhutan" },
    { value: "BO", label: "Bolivia" },
    { value: "BA", label: "Bosnia and Herzegovina" },
    { value: "BW", label: "Botswana" },
    { value: "BR", label: "Brazil" },
    { value: "IO", label: "British Indian Ocean Territory" },
    { value: "BN", label: "Brunei Darussalam" },
    { value: "BG", label: "Bulgaria" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "CV", label: "Cabo Verde" },
    { value: "KH", label: "Cambodia" },
    { value: "CM", label: "Cameroon" },
    { value: "CA", label: "Canada" },
    { value: "KY", label: "Cayman Islands" },
    { value: "CF", label: "Central African Republic" },
    { value: "TD", label: "Chad" },
    { value: "CL", label: "Chile" },
    { value: "CN", label: "China" },
    { value: "CO", label: "Colombia" },
    { value: "KM", label: "Comoros" },
    { value: "CG", label: "Congo" },
    { value: "CD", label: "Congo, Democratic Republic of the" },
    { value: "CR", label: "Costa Rica" },
    { value: "CI", label: "Côte d'Ivoire" },
    { value: "HR", label: "Croatia" },
    { value: "CU", label: "Cuba" },
    { value: "CY", label: "Cyprus" },
    { value: "CZ", label: "Czech Republic" },
    { value: "DK", label: "Denmark" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "Dominican Republic" },
    { value: "EC", label: "Ecuador" },
    { value: "EG", label: "Egypt" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Equatorial Guinea" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estonia" },
    { value: "SZ", label: "Eswatini" },
    { value: "ET", label: "Ethiopia" },
    { value: "FJ", label: "Fiji" },
    { value: "FI", label: "Finland" },
    { value: "FR", label: "France" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambia" },
    { value: "GE", label: "Georgia" },
    { value: "DE", label: "Germany" },
    { value: "GH", label: "Ghana" },
    { value: "GR", label: "Greece" },
    { value: "GD", label: "Grenada" },
    { value: "GT", label: "Guatemala" },
    { value: "GN", label: "Guinea" },
    { value: "GW", label: "Guinea-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haiti" },
    { value: "HN", label: "Honduras" },
    { value: "HU", label: "Hungary" },
    { value: "IS", label: "Iceland" },
    { value: "IN", label: "India" },
    { value: "ID", label: "Indonesia" },
    { value: "IR", label: "Iran" },
    { value: "IQ", label: "Iraq" },
    { value: "IE", label: "Ireland" },
    { value: "IL", label: "Israel" },
    { value: "IT", label: "Italy" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japan" },
    { value: "JO", label: "Jordan" },
    { value: "KZ", label: "Kazakhstan" },
    { value: "KE", label: "Kenya" },
    { value: "KI", label: "Kiribati" },
    { value: "KP", label: "Korea (North)" },
    { value: "KR", label: "Korea (South)" },
    { value: "KW", label: "Kuwait" },
    { value: "KG", label: "Kyrgyzstan" },
    { value: "LA", label: "Lao PDR" },
    { value: "LV", label: "Latvia" },
    { value: "LB", label: "Lebanon" },
    { value: "LS", label: "Lesotho" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Libya" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lithuania" },
    { value: "LU", label: "Luxembourg" },
    { value: "MG", label: "Madagascar" },
    { value: "MW", label: "Malawi" },
    { value: "MY", label: "Malaysia" },
    { value: "MV", label: "Maldives" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Marshall Islands" },
    { value: "MR", label: "Mauritania" },
    { value: "MU", label: "Mauritius" },
    { value: "MX", label: "Mexico" },
    { value: "FM", label: "Micronesia" },
    { value: "MD", label: "Moldova" },
    { value: "MC", label: "Monaco" },
    { value: "MN", label: "Mongolia" },
    { value: "ME", label: "Montenegro" },
    { value: "MA", label: "Morocco" },
    { value: "MZ", label: "Mozambique" },
    { value: "MM", label: "Myanmar" },
    { value: "NA", label: "Namibia" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Netherlands" },
    { value: "NZ", label: "New Zealand" },
    { value: "NI", label: "Nicaragua" },
    { value: "NE", label: "Niger" },
    { value: "NG", label: "Nigeria" },
    { value: "NO", label: "Norway" },
    { value: "OM", label: "Oman" },
    { value: "PK", label: "Pakistan" },
    { value: "PW", label: "Palau" },
    { value: "PS", label: "Palestine" },
    { value: "PA", label: "Panama" },
    { value: "PG", label: "Papua New Guinea" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Peru" },
    { value: "PH", label: "Philippines" },
    { value: "PL", label: "Poland" },
    { value: "PT", label: "Portugal" },
    { value: "QA", label: "Qatar" },
    { value: "RO", label: "Romania" },
    { value: "RU", label: "Russia" },
    { value: "RW", label: "Rwanda" },
    { value: "KN", label: "Saint Kitts and Nevis" },
    { value: "LC", label: "Saint Lucia" },
    { value: "VC", label: "Saint Vincent and the Grenadines" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Sao Tome and Principe" },
    { value: "SA", label: "Saudi Arabia" },
    { value: "SN", label: "Senegal" },
    { value: "RS", label: "Serbia" },
    { value: "SC", label: "Seychelles" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SG", label: "Singapore" },
    { value: "SK", label: "Slovakia" },
    { value: "SI", label: "Slovenia" },
    { value: "SB", label: "Solomon Islands" },
    { value: "SO", label: "Somalia" },
    { value: "ZA", label: "South Africa" },
    { value: "ES", label: "Spain" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SD", label: "Sudan" },
    { value: "SR", label: "Suriname" },
    { value: "SE", label: "Sweden" },
    { value: "CH", label: "Switzerland" },
    { value: "SY", label: "Syria" },
    { value: "TW", label: "Taiwan" },
    { value: "TJ", label: "Tajikistan" },
    { value: "TZ", label: "Tanzania" },
    { value: "TH", label: "Thailand" },
    { value: "TL", label: "Timor-Leste" },
    { value: "TG", label: "Togo" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad and Tobago" },
    { value: "TN", label: "Tunisia" },
    { value: "TR", label: "Turkey" },
    { value: "TM", label: "Turkmenistan" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ukraine" },
    { value: "AE", label: "United Arab Emirates" },
    { value: "GB", label: "United Kingdom" },
    { value: "US", label: "United States" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Uzbekistan" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Vietnam" },
    { value: "YE", label: "Yemen" },
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabwe" }
];


export default function BulkLocationSelector({ isVisible, onClose, onSubmit, adsAccountId }) {

    const [selectedCountry, setSelectedCountry] = useState("");
    const [postalCodes, setPostalCodes] = useState([]);
    const [regions, setregions] = useState([]);
    const [locationType, setlocationType] = useState('');
    const [zipsWithCountry, setzipsWithCountry] = useState([]);
    const [selectedLocations, setselectedLocations] = useState([]);
    const [isFirstScreen, setIsFirstScreen] = useState(true);

    const [file, setFile] = useState(null);

    const matchPayload = locationType === 'zips' ? { zips: zipsWithCountry } : { qs: regions };


    const [matchLocation, { data: matchedLocations, isLoading, isFetching, refetch }] = useMatchBulkLocationsMutation({ skip: !locationType });


    const onDrop = (acceptedFiles) => {
        const csvFile = acceptedFiles[0];
        setFile(csvFile);
        readCSVFile(csvFile);
    };

    // Function to read and parse the CSV file
    const readCSVFile = (file) => {
        Papa.parse(file, {
            header: true,
            complete: (result) => {
                // console.log({ result });

                // Filter out empty entries
                const filteredData = result.data.map(item => item[""]).filter(value => value !== "");
                if (locationType === 'zips') {
                    setPostalCodes(filteredData)
                } else if (locationType === 'region') {



                    setregions(filteredData)
                }
            },
            error: (error) => {
                console.error("Error reading CSV file:", error);
            }
        });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "text/csv": [".csv"]
        },
        multiple: false,
    });


    const matchZips = () => {
        if (locationType === 'zips') {

            if (postalCodes?.length && selectedCountry) {
                let zips = postalCodes?.map(code => `${selectedCountry}:${code}`);
                //setzipsWithCountry(JSON.stringify(zips))

                setIsFirstScreen(false);
                matchLocation({ locationType, matchPayload: { zips: zips }, adsAccountId }).unwrap()
                    .then((response) => {
                        // console.log({ response });

                        if (response?.data?.zips) {
                            setselectedLocations(Object.values(response?.data?.zips));
                        }
                    })
            }
        } else if (locationType === 'region') {
            setIsFirstScreen(false);
            matchLocation({ locationType, matchPayload, adsAccountId }).unwrap()
                .then((response) => {
                    // console.log(response?.data);
                    if (response?.data) {
                        setselectedLocations(response?.data);
                    }
                })
        }

    };

    const handleBack = () => {
        setIsFirstScreen(true);
    };

    const handleAddLocations = () => {
        // console.log({ selectedLocations });
        onSubmit(selectedLocations);
        resetModal();
    }

    const resetModal = () => {
        onClose();
        setSelectedCountry('');
        setPostalCodes([]);
        setzipsWithCountry([]);
        setIsFirstScreen(true);
        setselectedLocations([]);
        setlocationType('');
        setregions([]);
    }


    return (
        <>
            <Modal isOpen={isVisible} onClose={resetModal} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader size="sm">Add locations in bulk</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody minH={'300px'} overflowY="auto">
                        {isFirstScreen ? (
                            <>
                                <Text fontSize="sm">
                                    Type or paste your locations below. You can put each location on a new line or separate them using commas or semicolons.
                                </Text>
                                <FormControl id="countries" mb="20px" mt="20px">
                                    <Text fontSize={'14px'} color={'gray.800'} mb={'5px'} fontWeight={'bold'}>Location type</Text>
                                    <Select
                                        placeholder="Select"
                                        value={locationType}
                                        onChange={(e) => setlocationType(e.target.value)}
                                        size="sm"
                                        borderRadius="5px"
                                    >
                                        <option value={'zips'}>
                                            Postal Codes
                                        </option>
                                        <option value={'region'}>
                                            Counties or regions
                                        </option>
                                    </Select>
                                </FormControl>
                                {locationType === 'zips' ? (
                                    <>
                                        <FormControl id="countries" mb="20px" mt="20px">
                                            <Text fontSize={'14px'} color={'gray.800'} mb={'5px'} fontWeight={'bold'}>Default country</Text>
                                            <Select
                                                placeholder="Select"
                                                value={selectedCountry}
                                                onChange={(e) => setSelectedCountry(e.target.value)}
                                                size="sm"
                                                borderRadius="5px"
                                            >
                                                {countryOptions.map((country) => (
                                                    <option key={country.value} value={country.value}>
                                                        {country.label}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <FormControl id="postalCodes" mb={4}>
                                            <Textarea
                                                placeholder="Enter postal codes"
                                                value={postalCodes?.length ? postalCodes?.join(', ') : ''}
                                                onChange={(e) => {
                                                    let zipString = e.target.value;
                                                    // Split only by commas and then trim each postal code
                                                    const zipArray = zipString.split(',').map(zip => zip.trim());
                                                    setPostalCodes(zipArray);
                                                }}
                                                rows={8}
                                                isDisabled={!selectedCountry}
                                                fontSize="sm"
                                            />
                                        </FormControl>

                                    </>
                                ) : null}


                                {locationType === 'region' ? (
                                    <>
                                        <FormControl id="countriesRegions" mb={4}>
                                            <Textarea
                                                placeholder="Examples Washington, New York, New Jersey"
                                                value={regions?.length ? regions?.join(', ') : ''}
                                                onChange={(e) => {
                                                    let regionString = e.target.value;
                                                    const regionArray = regionString?.split(',').map(region => region.trim());
                                                    setregions(regionArray);
                                                }}
                                                rows={8}
                                                isDisabled={locationType !== 'region'}
                                                fontSize="sm"
                                            />
                                        </FormControl>
                                    </>
                                ) : null}

                                {locationType ? (
                                    <FormControl id="fileUpload" mb={4}>
                                        <Box
                                            {...getRootProps()}
                                            style={{
                                                border: '2px dashed #ccc',
                                                padding: '20px',
                                                textAlign: 'center',
                                                cursor: (locationType == 'zips' && !selectedCountry) ? 'not-allowed' : 'pointer',
                                                borderRadius: '5px',
                                                backgroundColor: (locationType == 'zips' && !selectedCountry) ? '#f0f0f0' : 'transparent',
                                                pointerEvents: (locationType == 'zips' && !selectedCountry) ? 'none' : 'auto',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {isDragActive ? (
                                                <Text fontSize="xs">Drop the CSV file here ...</Text>
                                            ) : (
                                                <Text fontSize="xs">Drag & drop to upload, <br /> Or choose file from your device</Text>
                                            )}
                                            {file && <Text mt="10px" fontSize="xs">Selected file: {file.name}</Text>}
                                        </Box>
                                        {file && (
                                            <Flex mt="10px">
                                                <Button
                                                    size="xs"
                                                    onClick={() => {
                                                        setPostalCodes([]);
                                                        setregions([]);
                                                        setFile(null);
                                                    }}
                                                >
                                                    Remove All Files
                                                </Button>
                                            </Flex>
                                        )}
                                    </FormControl>
                                ) : null}

                            </>
                        ) : (
                            <Box>
                                {isLoading ? (
                                    <Spinner />
                                ) : (
                                    <>
                                        {selectedLocations?.length == 0 ? (
                                            <Text fontSize="sm">No locations found</Text>
                                        ) : (
                                            <>
                                                <Text fontSize="14px" fontWeight="bold" mb="10px">
                                                    Matched Locations
                                                </Text>

                                                <TableContainer>
                                                    <Table variant="simple" size="sm"
                                                        style={{
                                                            display: 'block',
                                                            maxHeight: '400px',
                                                            overflowY: 'auto',
                                                            width: '100%',
                                                        }}>
                                                        <Thead>
                                                            <Tr>
                                                                {locationType == 'zips' ? (
                                                                    <Th> Code</Th>
                                                                ) : null}

                                                                {locationType == 'region' ? (
                                                                    <Th> Name</Th>
                                                                ) : null}
                                                                {locationType == 'zips' ? (
                                                                    <>
                                                                        <Th>City</Th>
                                                                        <Th>Region</Th>
                                                                    </>
                                                                ) : null}

                                                                <Th>Country</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {selectedLocations?.map((location, index) => (
                                                                <Tr key={index}>
                                                                    <Td>{location?.name}</Td>
                                                                    {locationType == 'zips' ? (
                                                                        <>
                                                                            <Td>{location?.primary_city}</Td>
                                                                            <Td>{location?.region}</Td>
                                                                        </>
                                                                    ) : null}

                                                                    <Td>{location?.country_name}</Td>
                                                                </Tr>
                                                            ))}
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                        )}
                                    </>
                                )}


                            </Box>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        {isFirstScreen ? (
                            <Flex gap={'10px'}>
                                <Button
                                    colorScheme="orange"
                                    size="sm"
                                    onClick={matchZips}
                                    isDisabled={(!postalCodes.length && locationType === 'zips') || (!regions.length && locationType === 'region')}
                                >
                                    Match
                                </Button>
                                <Button variant="ghost" size="sm" onClick={resetModal}>
                                    Cancel
                                </Button>
                            </Flex>
                        ) : (
                            <Flex gap={'10px'}>
                                <Button variant="ghost" size="sm" onClick={() => handleBack()}>
                                    Back
                                </Button>
                                <Button colorScheme="orange" size="sm" onClick={() => handleAddLocations()} isDisabled={selectedLocations?.length == 0}>
                                    Add locations
                                </Button>

                            </Flex>
                        )}

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
