import React from 'react';
import { Flex, Box, Text, Input, Button, Select, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Tooltip } from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCreateCampaignMutation } from 'store/campaigns/campaignsApi';
import { InfoOutlineIcon } from '@chakra-ui/icons';

function CreateCampaignForm({ adsAccountData, onClose, isOpen, onSuccess }) {
    const toast = useToast();

    const [createCampaign] = useCreateCampaignMutation();

    const formik = useFormik({
        initialValues: {
            name: '',
            objective: 'OUTCOME_LEADS',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Campaign name is required'),
            objective: Yup.string().required('Objective is required'),
        }),
        onSubmit: (values) => {
            const { name, objective } = values;
            const payload = {
                name: name,
                objective: objective,
                special_ad_categories: [
                    "NONE"
                ]
            };

            createCampaign({payload, adsAccountId:adsAccountData?.account_id })
                .unwrap()
                .then(() => {
                    onSuccess();
                    toast({
                        position: "top-right",
                        title: "Campaign created successfully",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                    onClose(); // Close the modal
                })
                .catch((error) => {
                    toast({
                        position: "top-right",
                        title: "Error creating campaign",
                        status: "error",
                        description: error?.data?.message,
                        duration: 4000,
                        isClosable: true,
                    });
                    console.error('Error creating campaign:', error);
                });
        }
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create Campaign</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <form onSubmit={formik.handleSubmit}>
                        <Box  bg="gray.50" p={4} borderRadius="md">
                            <Flex direction="column">

                                {/* Campaign Name */}
                                <Box mb={4}>
                                    <Flex>
                                        <Text mb={2}>Campaign Name</Text>
                                        <Text display="inline-flex" fontSize="18px" color="red.400" mb={2}>*&nbsp;</Text>
                                    </Flex>
                                    <Input
                                        placeholder="Enter campaign name"
                                        bg="#fff"
                                        name="name"
                                        size="sm"
                                        borderRadius="md"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <Text fontSize="12px" color="red.500" mt={1}>{formik.errors.name}</Text>
                                    )}
                                </Box>

                                {/* objective */}
                                <Box mb={4}>
                                    <Flex alignItems={'center'}> 
                                        <Text mb={2}>Choose a campaign objectives</Text>
                                        <Text display="inline-flex" fontSize="18px" color="red.400" mb={2}>*&nbsp;</Text>
                                        <Tooltip
                                            borderRadius={5}
                                            label={
                                                <Box fontSize="12px" p={'5px'}>
                                                    <Text textAlign={'justify'}>Choose what you want people to do when they see your ads. Your campaign objective should align with your overall business goals.</Text>
                                                </Box>
                                            }
                                            fontSize="md"
                                        >
                                            <InfoOutlineIcon pointerEvents="all" mb={2} />
                                        </Tooltip>
                                    </Flex>
                                    <Select
                                        name="objective"
                                        value={formik.values.objective}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        size="sm"
                                        bg="#fff"
                                        borderRadius="md"
                                    >
                                        <option value="OUTCOME_LEADS">Leads</option>
                                        <option value="OUTCOME_SALES">Sales</option>
                                    </Select>
                                    {formik.touched.objective && formik.errors.objective && (
                                        <Text fontSize="12px" color="red.500" mt={1}>{formik.errors.objective}</Text>
                                    )}
                                </Box>
                            </Flex>
                        </Box>
                    </form>
                </ModalBody>

                {/* Modal Footer with the submit button */}
                <ModalFooter gap={2} justifyContent={'flex-start'}>
                    <Button size={'sm'} onClick={onClose}> 
                        Cancel
                    </Button>
                    <Button
                        colorScheme="orange"
                        isDisabled={!formik.isValid}
                        type="submit"
                        size="sm"
                        onClick={formik.handleSubmit}
                    >
                        Continue
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default CreateCampaignForm;
