import { api } from '../api'

export const adRuleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createAdRule: builder.mutation({
      query: (payload) => ({
        url: '/adrules',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['AdRule'],
    }),
    getAdRules: builder.query({
      query: ({ pageSize, searchValue, adsAccountId, after, before }) => {
        let url = `/adrules/${adsAccountId}?`;

        if (pageSize) {
            url += `pageSize=${pageSize}&`;
        }
        if (searchValue) {
            url += `search=${searchValue}&`;
        }
        if (after) {
            url += `after=${after}&`;
        }
        if (before) {
            url += `before=${before}&`;
        }
        // Remove the trailing "&" if it exists
        url = url.endsWith('&') ? url.slice(0, -1) : url;

        return { url };
      },
      transformResponse: (response, meta, arg) => response.data,
    }),
    getAdRuleResults: builder.query({
      async queryFn({ AdRuleIds, adsAccountId }, _queryApi, _extraOptions, fetchWithBQ) {

        try {
          // Fetching all results for the list of AdRuleIds
          const requests = AdRuleIds.map((AdRuleId) =>
            fetchWithBQ(`/adrules/${AdRuleId}/history?adsAccountId=${adsAccountId}`)
          );
          const results = await Promise.all(requests);
          
          // Handle errors
          const hasErrors = results.some((result) => result.error);
          if (hasErrors) {
            return { error: { message: 'One or more requests failed.' } };
          }

          // Combining all the data from the results
          const data = results.map((result) => result.data);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateAdRule: builder.mutation({
      query: ({ AdRuleId, payload }) => ({
        url: `/adrules/${AdRuleId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: ['AdRule'],
    }),
    updateAdRuleStatus: builder.mutation({
      query: ({ AdRuleId, payload }) => ({
        url: `/adrules/update/status/${AdRuleId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: ['AdRule'],
    }),
    deleteAdRule: builder.mutation({
      query: ({ AdRuleId, adsAccountId }) => ({
          url: `/adrules/${AdRuleId}?adsAccountId=${adsAccountId}`,
          method: 'DELETE',
      }),
      invalidatesTags: ['AdRule'],
    }),    
  }),
})

export const {
  useCreateAdRuleMutation,
  useGetAdRulesQuery,
  useUpdateAdRuleMutation,
  useGetAdRuleResultsQuery,
  useUpdateAdRuleStatusMutation,
  useDeleteAdRuleMutation
} = adRuleApi;