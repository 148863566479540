import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Icon,
    Input,
    Select,
    Spinner,
    Text,
    Textarea,
    Tooltip,
    useToast,
    Menu,
    MenuList,
    MenuItem,
    MenuButton
} from '@chakra-ui/react';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { useGetAdCreativeQuery, useUpdateAdMutation } from 'store/campaigns/adsApi';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import InstagramAccountUpdateSelector from 'components/adspresso/InstagramAccountUpdateSelector';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Ad name is required'),
    cpa: Yup.number()
        .typeError('must be a number')
        .positive('must be a positive number'),
    creative: Yup.object().shape({
        name: Yup.string().required('Creative name is required'),
        object_story_spec: Yup.object().shape({
            link_data: Yup.object().shape({
                // message: Yup.string().required('Primary text is required'),
                link: Yup.string().url('Invalid URL').required('Website URL is required'),
                call_to_action: Yup.object().shape({
                    type: Yup.string().required('Call to action is required'),
                }),
                child_attachments: Yup.array().of(
                    Yup.object().shape({
                        link: Yup.string().url('Invalid URL').required('Website URL is required'),
                        picture: Yup.string().required('Creative is required'),
                        //description: Yup.string().required('Description is required'),
                    })
                ).min(1, 'At least one creative is required'),
            }),
            page_id: Yup.string().required('Facebook Page ID is required'),
        }),
    }),
});

const CarouselAdUpdateForm = ({ adData, adsAccountData, onClose, onSuccess, call_to_action_options }) => {
    const toast = useToast();
    const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
    const [selectedCreativeIndex, setSelectedCreativeIndex] = useState(null);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [isMediaTypeSelectionOpen, setIsMediaTypeSelectionOpen] = useState(false);
    const { data: adCreative, refetch, isLoading } = useGetAdCreativeQuery(
        {
            creativeId: adData?.id,
            adsAccountId: adsAccountData?.account_id
        },
        { skip: !adData?.id }
    );

    const [updateAd] = useUpdateAdMutation();

    const formik = useFormik({
        initialValues: {
            name: adData?.name || "",
            hash: adData?.hash || '',
            cpa: adData?.cpa || '',
            creative: {
                name: adCreative?.name || "",
                object_story_spec: {
                    link_data: {
                        message: adCreative?.object_story_spec?.link_data?.message || "",
                        link: adCreative?.object_story_spec?.link_data?.link || "",
                        call_to_action: {
                            type: adCreative?.object_story_spec?.link_data?.call_to_action?.type || "SHOP_NOW",
                            value: {
                                link: adCreative?.object_story_spec?.link_data?.call_to_action?.value?.link
                            }
                        },
                        child_attachments: adCreative?.object_story_spec?.link_data?.child_attachments || [
                            {
                                link: "", name: "", description: "", image_hash: "", video_id: "", picture: "", hash: "",
                                call_to_action: {
                                    type: "",
                                    value: {
                                        link: ""
                                    }
                                }
                            },
                        ],
                    },
                    page_id: adCreative?.object_story_spec?.page_id || "",
                    instagram_actor_id: adCreative?.object_story_spec?.instagram_actor_id || '',
                },
            },
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting, resetForm }) => {
    
            const formatPhoneNumber = (phoneNumber) => {
                if (!phoneNumber.startsWith('tel:+')) {
                    return `tel:+${phoneNumber}`;
                }
                return phoneNumber;
            };
    
            // Function to check if creative has changed
            const isCreativeChanged = () => {
                const original = adCreative || {};
                const updated = values.creative || {};
    
                return (
                    original.name !== updated.name ||
                    original.object_story_spec?.link_data?.message !== updated.object_story_spec?.link_data?.message ||
                    original.object_story_spec?.link_data?.link !== updated.object_story_spec?.link_data?.link ||
                    original.object_story_spec?.link_data?.call_to_action?.type !== updated.object_story_spec?.link_data?.call_to_action?.type ||
                    original.object_story_spec?.link_data?.call_to_action?.value?.link !== updated.object_story_spec?.link_data?.call_to_action?.value?.link ||
                    original.object_story_spec?.link_data?.child_attachments !== updated.object_story_spec?.link_data?.child_attachments ||
                    original.object_story_spec?.page_id !== updated.object_story_spec?.page_id ||
                    original.object_story_spec?.instagram_actor_id !== updated.object_story_spec?.instagram_actor_id
                );
            };
    
            const hasCreativeChanged = isCreativeChanged();
    
            const payload = {
                name: values.name,
                hash: values.hash,
                ...(typeof values.cpa === 'string' && values.cpa.trim() && { cpa: values.cpa }),
                ...(hasCreativeChanged && {
                    creative: {
                        name: values.creative.name,
                        object_story_spec: {
                            link_data: {
                                ...(values.creative.object_story_spec.link_data.message?.trim() && {
                                    message: values.creative.object_story_spec.link_data.message
                                }),
                                link: values.creative.object_story_spec.link_data.link,
                                call_to_action: values.creative.object_story_spec.link_data.call_to_action?.type === 'CALL_NOW'
                                    ? {
                                        type: values.creative.object_story_spec.link_data.call_to_action.type,
                                        value: {
                                            link: formatPhoneNumber(values.creative.object_story_spec?.link_data?.call_to_action?.value?.link)
                                        }
                                    }
                                    : {
                                        type: values.creative.object_story_spec.link_data.call_to_action.type
                                    },
                                child_attachments: values.creative.object_story_spec.link_data.child_attachments
                                    .map(child => {
                                        const { image_hash, id, call_to_action, ...rest } = child;
    
                                        const cleanedRest = Object.fromEntries(
                                            Object.entries(rest).filter(([key, value]) => value && value !== "")
                                        );
    
                                        if (values.creative.object_story_spec.link_data.call_to_action?.type === 'CALL_NOW') {
                                            return {
                                                ...cleanedRest,
                                                call_to_action: {
                                                    type: values.creative.object_story_spec.link_data.call_to_action?.type,
                                                    value: {
                                                        link: formatPhoneNumber(call_to_action.value?.link)
                                                    }
                                                }
                                            };
                                        }
    
                                        return {
                                            ...cleanedRest,
                                            call_to_action: {
                                                type: values.creative.object_story_spec.link_data.call_to_action?.type,
                                            }
                                        };
                                    }),
                            },
                            page_id: values.creative.object_story_spec.page_id,
                            instagram_actor_id: values.creative.object_story_spec.instagram_actor_id,
                        },
                        degrees_of_freedom_spec: {
                            creative_features_spec: {
                                standard_enhancements: {
                                    enroll_status: "OPT_OUT"
                                }
                            }
                        },
                    },
                }),
                adsAccount: {
                    id: adsAccountData?.id,
                    adsAccountId: adsAccountData?.account_id,
                    adsAccountName: adsAccountData?.name,
                    businessName: adsAccountData?.business?.name,
                    businessId: adsAccountData?.business?.id
                },
            };
    
            updateAd({ creativeId: adData.id, payload })
                .unwrap()
                .then(response => {
                    onSuccess();
                    toast({
                        position: "top-right",
                        title: "Ad updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating ad",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    resetForm();
                    onClose();
                });
        },
    });
    

    const handleOpenDriveModal = (mediaType, index) => {
        setSelectedMedia(mediaType);
        setSelectedCreativeIndex(index);
        setIsDriveModalOpen(true);
    };

    const handleMediaSelect = (file) => {
        if (file && file.length > 0) {
            const selectedFile = file[0];

            // Update the specific creative in the Formik state
            formik.setFieldValue('creative.object_story_spec.link_data.child_attachments', formik.values.creative.object_story_spec.link_data.child_attachments.map((creative, index) => {
                if (index === selectedCreativeIndex) {
                    return {
                        ...creative,
                        ...(selectedMedia === 'video' && {
                            video_id: selectedFile?.creativeId,
                            hash: selectedFile?.hash,
                            picture: selectedFile?.picture,
                        }),
                        ...(selectedMedia === 'image' && {
                            image_hash: selectedFile?.creativeId,
                            hash: selectedFile?.hash,
                            picture: selectedFile?.url,
                        }),
                    };
                }
                return creative;
            }));
        }
        setIsDriveModalOpen(false);
        setSelectedCreativeIndex(null);
    };

    const handleAddCreativeClick = () => {
        setIsMediaTypeSelectionOpen(!isMediaTypeSelectionOpen);
    };

    const handleMediaTypeSelection = (type) => {
        setSelectedMedia(type);
        setIsMediaTypeSelectionOpen(false);

        const newAttachment = {
            link: "",
            name: "",
            description: "",
            picture: "",
            id: "",
            call_to_action: {
                type: 'SHOP_NOW',
                value: {
                    link: ''
                }
            }
        };

        const pageId = formik.values.creative.object_story_spec.page_id;

        if (formik.values.creative.object_story_spec.link_data.call_to_action.type === 'CALL_NOW') {
            newAttachment.call_to_action = {
                type: 'CALL_NOW',
                value: {
                    link: '',
                }
            };
            newAttachment.link = pageId ? `https://facebook.com/${pageId}/` : '';
        }

        if (type === 'image') {
            newAttachment.image_hash = type;
            newAttachment.id = type;
        } else if (type === 'video') {
            newAttachment.video_id = type;
            newAttachment.id = type;
        }

        formik.setFieldValue('creative.object_story_spec.link_data.child_attachments', [
            ...(formik.values.creative.object_story_spec.link_data.child_attachments || []),
            newAttachment
        ]);
    };


    if (isLoading) {
        return  <Flex justify="center" align="center" height="60vh">
                    <Spinner size="md" />
                </Flex>
    }

    // console.log(formik.errors)
    // console.log(formik.values)

    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex direction={'column'}>
                <Flex direction={'column'} gap={'20px'} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    {/* Ad Name Section */}
                    <Flex direction={'column'} gap={'10px'}>
                        <Text fontWeight="bold">Ad name</Text>
                        <Input
                            name="name"
                            placeholder="Enter your ad name here..."
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={'10px'}>
                        <Text fontWeight="bold">Facebook Page</Text>
                        <FaceBookPageSelector
                            adsAccount={adsAccountData}
                            selectedPageId={formik.values.creative.object_story_spec.page_id}
                            onSelectedPage={(pageId) => {
                                formik.setFieldValue('creative.object_story_spec.page_id', pageId);
                            }}
                        />
                        {formik.errors.creative?.object_story_spec?.page_id && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.page_id}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Instagram accounts</Text>
                        <InstagramAccountUpdateSelector
                            pageID={formik.values.creative?.object_story_spec?.page_id}
                            adsAccount={adsAccountData}
                            selectedInstPageId={formik.values.creative?.object_story_spec?.instagram_actor_id}
                            onSelectedPage={(pageId) => {
                                formik.setFieldValue('creative.object_story_spec.instagram_actor_id', pageId);
                            }}
                        />
                        {formik.errors.creative?.object_story_spec?.instagram_actor_id && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.instagram_actor_id}</Text>
                        )}
                    </Flex>
                </Flex>
                <Flex direction={'column'} gap={'15px'} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    {/* Creative Attachments Section */}
                    {formik.values.creative.object_story_spec.link_data.child_attachments.map((creative, index) => {

                        return (
                            <Flex direction={'column'} key={index} borderWidth={1} p={4} borderRadius="md" gap={'20px'}>
                                <Flex direction={'column'} gap={'10px'}>
                                    <Flex justifyContent="space-between" alignItems="center" >
                                        <Text fontWeight="bold">Headline</Text>
                                        <Tooltip label="Delete" fontSize="xs">
                                            <Box>
                                                <Icon
                                                    color={'red.500'}
                                                    _hover={{ color: 'gray.400' }}
                                                    as={MdDelete}
                                                    cursor="pointer"
                                                    boxSize={5}
                                                    onClick={() => {
                                                        const updatedAttachments = formik.values.creative.object_story_spec.link_data.child_attachments.filter((_, i) => i !== index);
                                                        formik.setFieldValue('creative.object_story_spec.link_data.child_attachments', updatedAttachments);
                                                    }}
                                                />
                                            </Box>
                                        </Tooltip>
                                    </Flex>
                                    <Input
                                        name={`creative.object_story_spec.link_data.child_attachments.${index}.name`}
                                        placeholder="Enter a name"
                                        size='sm'
                                        borderRadius="md"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={creative.name}
                                    />
                                    {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.name && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.name && (
                                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.name}</Text>
                                    )}
                                </Flex>

                                <Flex direction="column" gap={'20px'}>
                                    {/* Description Section */}
                                    <Flex direction="column" gap={'10px'} >
                                        <Text fontWeight="bold">Description</Text>
                                        <Input
                                            name={`creative.object_story_spec.link_data.child_attachments.${index}.description`}
                                            placeholder="Enter a description"
                                            size='sm'
                                            borderRadius="md"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={creative.description}
                                        />
                                        {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.description && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.description && (
                                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.description}</Text>
                                        )}
                                    </Flex>

                                    {formik.values.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.call_to_action?.type === "CALL_NOW" ?
                                        (
                                            <>
                                                {/* <Flex direction="column" gap={1}>
                                                    <Text fontWeight="bold">Call to Action</Text>

                                                    <Select
                                                        name={`creative.object_story_spec.link_data.child_attachments.${index}.call_to_action.type`}
                                                        bg="#fff"
                                                        size="sm"
                                                        borderRadius="md"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={creative?.call_to_action?.type}
                                                        isDisabled
                                                    >
                                                        <option value="CALL_NOW">Call Now</option>
                                                    </Select>
                                                </Flex> */}

                                                <Flex direction="column" gap={1}>
                                                    <Text fontWeight="bold">Phone Number</Text>
                                                    <PhoneInput
                                                        name={`creative.object_story_spec.link_data.child_attachments.${index}.call_to_action.value.link`}
                                                        value={creative.call_to_action.value.link}
                                                        onChange={(value) => formik.setFieldValue(`creative.object_story_spec.link_data.child_attachments.${index}.call_to_action.value.link`, value)}
                                                        country={'in'}
                                                    />
                                                </Flex>
                                            </>
                                        ) : (
                                            <>
                                            {/* <Flex direction="column" gap={1}>
                                                <Text fontWeight="bold">Call to Action</Text>

                                                <Select
                                                    name={`creative.object_story_spec.link_data.child_attachments.${index}.call_to_action.type`}
                                                    bg="#fff"
                                                    size="sm"
                                                    borderRadius="md"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={creative?.call_to_action?.type}
                                                >
                                                   {call_to_action_options?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                                </Select>
                                            </Flex> */}

                                            <Flex direction="column" gap={'10px'}>
                                                <Text fontWeight="bold">Link URL</Text>
                                                <Input
                                                    name={`creative.object_story_spec.link_data.child_attachments.${index}.link`}
                                                    placeholder="Enter a URL"
                                                    size='sm'
                                                    borderRadius="md"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={creative.link}
                                                />
                                                {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.link && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.link && (
                                                    <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.link}</Text>
                                                )}
                                            </Flex>
                                            </>
                                        )}
                                </Flex>

                                <Flex direction={'column'} gap={'10px'}>
                                    <Text fontWeight="bold">Creative</Text>
                                    <Flex direction="row" gap={'10px'} alignItems={'center'}>
                                        {/* Conditionally render either an image or the add icon */}
                                        {((creative?.id === 'image' && !creative?.picture) || (creative?.id === 'video' && !creative?.picture)) ? (
                                            <AddIcon />
                                        ) : (
                                            <img
                                                src={creative?.video_id ? creative?.picture : creative?.picture || ''}
                                                alt={creative?.name}
                                                style={{ width: '100px', height: '100px', objectFit: 'cover', border: '0.5px solid grey', borderRadius: '5px' }}
                                            />
                                        )}

                                        <Button
                                            bg={'gray.50'}
                                            size="xs"
                                            borderWidth={1}
                                            onClick={() => handleOpenDriveModal(creative?.video_id ? 'video' : 'image', index)}
                                        >
                                            {creative?.id === 'image' && !creative?.picture ?
                                                "Add Image" :
                                                creative?.id === 'video' && !creative?.picture ?
                                                    "Add Video" :
                                                    (creative?.video_id ? "Change Video" : "Change Image")
                                            }
                                        </Button>
                                    </Flex>
                                    {formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.picture && (
                                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.picture}</Text>
                                    )}
                                </Flex>

                            </Flex>
                        )
                    })}

                    <Flex justifyContent="flex-end" >
                        <Menu>
                            <MenuButton
                                as={Button}
                                onClick={handleAddCreativeClick}
                                colorScheme='orange'
                                _expanded={{ bg: 'orange.600' }}
                                rightIcon={<ChevronDownIcon />}
                                size='sm'
                                sx={{ minWidth: "200px" }}
                            >
                                {'Add Creative'}
                            </MenuButton>
                            <MenuList sx={{
                                minWidth: "160px",
                                position: "absolute",
                                left: 10
                            }}>
                                <MenuItem
                                    onClick={() => handleMediaTypeSelection('image')}
                                    fontSize="12px"
                                >
                                    Add image creative
                                </MenuItem>
                                <MenuItem
                                    onClick={() => handleMediaTypeSelection('video')}
                                    fontSize="12px"
                                >
                                    Add video creative
                                </MenuItem>
                            </MenuList>

                        </Menu>
                    </Flex>

                </Flex>
                <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Box>
                        <Flex direction={'column'} gap={'20px'}>
                            {/* Primary Text Section */}
                            <Flex direction="column" gap={'10px'}>
                                <Text fontWeight="bold">Primary Text</Text>
                                <Textarea
                                    name="creative.object_story_spec.link_data.message"
                                    placeholder="Enter a text"
                                    bg="#fff"
                                    size='sm'
                                    borderRadius="md"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.creative.object_story_spec.link_data.message}
                                />
                                {formik.touched.creative?.object_story_spec?.link_data?.message && formik.errors.creative?.object_story_spec?.link_data?.message && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.message}</Text>
                                )}
                            </Flex>

                            <Flex direction="column" gap={'10px'}>
                                <Text fontWeight="bold">Call to Action</Text>

                                {formik.values.creative.object_story_spec.link_data.call_to_action.type === 'CALL_NOW' ? (
                                    <Select
                                        name="creative.object_story_spec.link_data.call_to_action.type"
                                        bg="#fff"
                                        size="sm"
                                        borderRadius="md"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                                        isDisabled
                                    >
                                        <option value="CALL_NOW">Call Now</option>
                                    </Select>
                                ) : (
                                    <Select
                                        name="creative.object_story_spec.link_data.call_to_action.type"
                                        bg="#fff"
                                        size="sm"
                                        borderRadius="md"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                                    >
                                        {call_to_action_options?.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Select>
                                )}

                                {formik.touched.creative?.object_story_spec?.link_data?.call_to_action?.type &&
                                    formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.type && (
                                        <Text fontSize="12px" color="red.500">
                                            {formik.errors.creative.object_story_spec.link_data.call_to_action.type}
                                        </Text>
                                    )}
                            </Flex>
                            {formik.values.creative.object_story_spec.link_data.call_to_action.type === 'CALL_NOW' ? (
                                <Flex direction="column" gap={'10px'}>
                                    <Text fontWeight="bold">Phone Number</Text>
                                    <PhoneInput
                                        name="creative.object_story_spec.link_data.call_to_action.value.link"
                                        value={formik.values.creative.object_story_spec.link_data.call_to_action?.value?.link}
                                        onChange={(value) => formik.setFieldValue('creative.object_story_spec.link_data.call_to_action.value.link', value)}
                                        country={'in'}
                                    />
                                    {formik.touched?.creative?.object_story_spec?.link_data?.call_to_action?.value?.link && formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.value?.link && (
                                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.value?.link}</Text>
                                    )}
                                </Flex>
                            ) : (
                                <Flex direction="column" gap={'10px'}>
                                    <Text fontWeight="bold">Website URL</Text>
                                    <Input
                                        name="creative.object_story_spec.link_data.link"
                                        placeholder="Enter a webiste url"
                                        bg="#fff"
                                        size='sm'
                                        borderRadius="md"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.creative.object_story_spec.link_data.link}
                                    />
                                    {formik.touched.creative?.object_story_spec?.link_data?.link && formik.errors.creative?.object_story_spec?.link_data?.link && (
                                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.link}</Text>
                                    )}
                                </Flex>
                            )}

                        </Flex>
                    </Box>
                </Flex>

                <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Flex direction="column" gap={'10px'}>
                        <Text fontWeight="bold">CPA</Text>
                        <Input
                            name="cpa"
                            placeholder="Enter CPA"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.cpa}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.cpa && formik.errors.cpa && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.cpa}</Text>
                        )}
                    </Flex>
                </Flex>
            </Flex>
            <Flex>
                <Button
                    size="sm"
                    colorScheme="orange"
                    isLoading={formik.isSubmitting}
                    type="submit"
                    mb={'20px'}
                >
                    Save
                </Button>
            </Flex>

            {/* Drive Modal */}
            <DriveModal
                isDriveModalOpen={isDriveModalOpen}
                selectedAdAccounts={[{
                    id: adsAccountData?.id,
                    adsAccountId: adsAccountData?.account_id,
                    adsAccountName: adsAccountData?.name,
                    businessName: adsAccountData?.business?.name,
                    businessId: adsAccountData?.business?.id
                }]}
                onClose={() => setIsDriveModalOpen(false)}
                onSubmit={handleMediaSelect}
                uploadPurpose="update"
                mediaType={selectedMedia}
            />
        </form>
    );
};

export default CarouselAdUpdateForm;
