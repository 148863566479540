import {
    Badge,
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    ListItem,
    Divider,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    Spacer,
    Spinner,
    Table, TableContainer,
    Tbody, Td,
    Text,
    Textarea,
    Tooltip,
    Tr,
    UnorderedList,
    useDisclosure,
    useToast,
    Link,
    InputGroup,
    InputRightAddon,
    RadioGroup,
    Radio,
    Stack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState, useCallback } from 'react';

import { AddIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import AppTable from 'components/AppTable';
import CircleIcon from 'components/CircleIcon';
import FileUploadBox from 'components/FileUploadBox';
import LegendItem from 'components/LegendItem';
import LightBox from 'components/LightBox';
import MultiFileUploadButton from 'components/MultiFileUploadButton';
import SelectColumnFilter from 'components/SelectColumnFilter';
import SidebarWithHeader from 'components/SidebarWithHeader';
import { ErrorMessage, FieldArray, Form, Formik, useFormik } from 'formik';
import moment from "moment";
import { FiDelete, FiEye } from "react-icons/fi";
import { useSelector } from 'react-redux';
import { useGetAllAccountsQuery } from 'store/adAccounts/adAccountsApi';
import { useGetCompaniesByUserIDQuery, useGetBankListQuery, useGetAllCompaniesQuery } from 'store/companies/companiesApi';
import {
    useAddNewTopUpRequestMutation, useGetTopUpRequestsQuery,
    useReplyToTopUpRequestMutation,
    useUpdateTopUpStatusMutation,
    useUploadTopUpFileMutation, usePrefetch
} from 'store/topUp/topUpApi';
import { replyToRequestSchema, topUpRequestSchema } from 'utils/schema';
import AppTableWithPagination from 'components/AppTableWithPagination';
import { data } from 'browserslist';
import usePolling from 'components/hook/usePolling';

function TopUpsForClient() {
    const btnRef = useRef()
    const toast = useToast()
    const requestFormRef = useRef()
    const isOnboarded = useSelector((state) => state.auth?.isUserOnBoarded);
    const companies = useSelector(state => state.company?.activeCompanies);

    //console.log({ isOnboarded });

    const [activeRequest, setactiveRequest] = useState(null);
    const [fileSource, setfileSource] = useState(null);
    const [replyFiles, setreplyfiles] = useState([]);
    const [hasActiveAdAccount, sethasActiveAdAccount] = useState(false);
    const userInfo = useSelector((state) => state.auth?.userInfo);
    const { _id: clientId } = userInfo;
    const [selectedCompanyAccountTypes, setselectedCompanyAccountTypes] = useState([]);

    const scrollBoxRef = useRef();
    const scrollToBottom = () => {
        scrollBoxRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    useEffect(() => {
        if (activeRequest) {
            setTimeout(() => {
                scrollToBottom();
            }, 10);
        }
    }, [activeRequest]);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isDetailsModalOpen,
        onOpen: onDetailsModalOpen,
        onClose: onDetailsModalClose
    } = useDisclosure();

    const {
        isOpen: isBankDetailsModalOpen,
        onOpen: onBankDetailsModalOpen,
        onClose: onBankDetailsModalClose
    } = useDisclosure();

    const {
        isOpen: isDrawerOpen,
        onOpen: onDrawerOpen,
        onClose: onDrawerClose
    } = useDisclosure()

    let statusBadgeColor = 'gray';

    if (activeRequest?.status == "pending") {
        statusBadgeColor = "red";
    } else if (activeRequest?.status == "approved") {
        statusBadgeColor = "orange";
    } else if (activeRequest?.status == "toppedup") {
        statusBadgeColor = "green";
    } else if (activeRequest?.status == "open") {
        statusBadgeColor = "yellow";
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setsearchValue] = useState('')
    const { isPollingActive, isTabVisible } = usePolling();

    /* QUERIES */

    const {
        data: requests,
        error: getRequestsError,
        isLoading: isTopUpRequestsLoading,
        refetch: isRequestRefetch,
        isFetching
    } = useGetTopUpRequestsQuery({ currentPage, searchValue }, {
        pollingInterval: isPollingActive && isTabVisible ? 20000 : false,
        skipPollingIfUnfocused: true,
    });

    const [addNewTopUpRequest, newTopUpResponse] = useAddNewTopUpRequestMutation();

    const {
        data: accounts,
        error: getAccountsError,
        isLoading: isAccountsDataLoading,
    } = useGetAllAccountsQuery();

    const { 
        data: savedBankList,
        isLoading: isSavedBankListLoading
    } = useGetBankListQuery();

    const [uploadTopUpFile, uploadResponse] = useUploadTopUpFileMutation();
    const [replyToTopUpRequest, replyResponse] = useReplyToTopUpRequestMutation();
    const [updateTopUpStatus, updateStatusResponse] = useUpdateTopUpStatusMutation();

    const [selectedOptions, setSelectedOptions] = useState(accounts?.data);
    const [selectedAccountType, setselectedAccountType] = useState('');
    const [selectedCompany, setselectedCompany] = useState(null);
    const [selectedBankOptions, setselectedBankOptions] = useState(savedBankList);

    useEffect(() => {
        if (isPollingActive && isTabVisible) {
            isRequestRefetch();
        }
    }, [isPollingActive, isTabVisible, isRequestRefetch]);

    useEffect(() => {
        if (selectedAccountType) {
            setSelectedOptions(accounts?.data?.filter((account, index) => account.accountType == selectedAccountType && account.accountStatus == 1));
        }

    }, [selectedAccountType])

    useEffect(() => {
        if (selectedCompany) {
            setselectedBankOptions(savedBankList?.filter((bank, index) => bank.entity?.toLowerCase() == selectedCompany?.entity))
            if (selectedAccountType) {
                setselectedBankOptions(savedBankList?.filter((bank, index) => bank.accountType.includes(selectedAccountType)))
            }
        }
        // console.log({ savedBankList });

    }, [selectedCompany, selectedAccountType])

    // console.log({ savedBankList });


    // const {
    //     data: companies,
    //     error: getCompaniesError,
    //     isLoading: isCompaniesDataLoading,
    //     refetch: refetchUserCompanies
    // } = useGetAllCompaniesQuery();

    // const {
    //     data: companies,
    //     error: getCompaniesError,
    //     isLoading: isCompaniesDataLoading,
    //     refetch: refetchUserCompanies
    // } = useGetCompaniesByUserIDQuery({ userId: activeRequest?.clientId?.id }, {
    //     //skip: !activeRequest
    // });

    useEffect(() => {
        if (accounts?.data?.length > 0) {
            let activeAccounts = accounts?.data?.filter((account, index) => account.accountStatus && account.accountStatus == 1)
            if (activeAccounts?.length > 0) {
                sethasActiveAdAccount(true)
            }
        }
    }, [accounts])

    useEffect(() => {
        if (searchValue != '') {
            setCurrentPage(1);
        }
    }, [searchValue])



    const companyOptions = companies?.filter((item, index) => item.clientId?.id == clientId && item.status).map(({ _id, companyName }, index) => (

        <option value={companyName} key={_id}>{companyName}</option>
    ))

    //console.log({ companies });

    const getCompanyDetails = (name) => {

        const details = companies?.filter(({ companyName }, index) => companyName == name)[0]?.commission
        setselectedCompany(companies?.filter(({ companyName }, index) => companyName == name)[0])
        //console.log(companies?.filter(({ companyName }, index) => companyName == name)[0]);
        if (Object.keys(details)?.length > 0) {
            if (Object.keys(details)?.length == 1) {
                setselectedAccountType(...Object.keys(details))
            }
            setselectedCompanyAccountTypes(Object.keys(details))
        }
    }

    //console.log(selectedCompany);


    useEffect(() => {
        const hasSingleType = selectedCompanyAccountTypes?.length == 1

        if (hasSingleType && requestFormRef.current) {
            selectedCompanyAccountTypes.includes('cc')
                ? requestFormRef.current.setFieldValue('accountType', 'Credit Card')
                : requestFormRef.current.setFieldValue('accountType', 'Credit Line')
        }

    }, [selectedCompanyAccountTypes])

    useEffect(() => {
        return () => {
            setselectedCompany(null);
            setselectedCompanyAccountTypes([])
        }
    }, [])

    //console.log({ selectedCompany });
    //console.log(accounts?.filter((account, index) => account.accountStatus == 1));

    const getAdAccountTitle = (id) => {
        const filteredAccount = accounts?.data?.filter((account) => {
            return account._id == id;
        })
        return filteredAccount && filteredAccount[0]?.adsAccountName;
    }

    const getAdAccountId = (id) => {
        const filteredAccount = accounts?.data?.filter((account) => {
            return account._id == id;
        })
        return filteredAccount && filteredAccount[0]?.adsAccountId;
    }

    useEffect(() => {
        //console.log(newTopUpResponse);
        if (newTopUpResponse?.isSuccess) {
            requestFormRef?.current?.resetForm();
            toast({
                position: "top-right",
                title: "Request placed",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
        if (newTopUpResponse.status == "rejected") {
            requestFormRef?.current?.resetForm();
            const {
                data: { message },
            } = newTopUpResponse.error;
            //console.log(message);
            const isDuplicateTransactionID = message?.includes('duplicate key');
            toast({
                position: "top-right",
                title: "Error",
                description: isDuplicateTransactionID ? 'Transaction ID already exists' : message,
                status: "error",
                duration: 8000,
                isClosable: true,
            });
        }
    }, [newTopUpResponse]);

    useEffect(() => {
        if (uploadResponse?.isSuccess) {
            toast({
                position: "top-right",
                title: "File uploaded",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
        if (uploadResponse.status == "rejected") {

            toast({
                position: "top-right",
                title: "Error",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [uploadResponse]);

    useEffect(() => {
        // //console.log(replyResponse);
        if (replyResponse.status == "rejected") {
            toast({
                position: "top-right",
                title: "Error",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
        if (replyResponse?.data) {
            //console.log("1");
            const {
                data: { _id },
            } = replyResponse.data;
            if (activeRequest?._id == _id) {
                setactiveRequest(replyResponse?.data?.data);
            }
            toast({
                position: "top-right",
                title: "Reply sent",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [replyResponse]);

    const columns = useMemo(
        () => [
            {
                Header: 'Order ID',
                accessor: 'order_id',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (
                    <Text>{original?.order_id}</Text>
                ),
            },
            {
                Header: "Ad Account",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex direction={'column'}>
                         <Text fontSize={'14px'}>{original?.adsAccount?.name}</Text>
                         <Text fontSize={'12px'}>{original?.adsAccount?.adsAccountId}</Text>        
                    </Flex>       
                )
            },
            {
                Header: "Company Name",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    return (
                        <Text fontSize={'12px'}>{original.company?.name}</Text>
                    )
                }
            },
            {
                Header: 'currency',
                accessor: 'currency',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {

                    return (
                        <Text fontSize={'12px'}>{original.order_currency}</Text>
                    )
                }
            },
            {
                Header: 'Order amount',
                accessor: 'amount',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {

                    return (
                        <Text fontSize={'12px'}>{original.order_amount}</Text>
                    )
                }
            },
            {
                Header: 'Payment Method',
                accessor: 'payment_method',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {

                    return (
                        <Text fontSize={'12px'}>{original.payment_method}</Text>
                    )
                }
            },
            {
                Header: 'Order Status',
                accessor: 'order_status',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {

                    return (
                        <Text fontSize={'12px'}>{original.order_status}</Text>
                    )
                }
            },
            // {
            //     Header: "Commission",
            //     accessor: "",
            //     Filter: "",
            //     filter: "",
            //     Cell: ({ row: { original } }) => {
            //         const totalCommision = original?.percentSplit.reduce((acc, currentValue) => acc + Number(currentValue.commission), 0);
            //         //console.log(original);
            //         return (
            //             <Text fontSize='sm' style={{ textTransform: 'capitalize' }}>{totalCommision}</Text>
            //         )
            //     }
            // },
            // {
            //     Header: "Topup",
            //     accessor: "",
            //     Filter: "",
            //     filter: "",
            //     Cell: ({ row: { original } }) => {
            //         const totalTopup = original?.percentSplit.reduce((acc, currentValue) => acc + Number(currentValue.topup), 0);
            //         //console.log(original);

            //         return (
            //             <>
            //                 <Text>
            //                     {totalTopup}&nbsp;
            //                     <Tooltip
            //                         fontSize='lg'
            //                         borderRadius={5}
            //                         label={
            //                             <Box fontSize={"sm"} p={"5px"}>
            //                                 {original?.percentSplit.map((account, index) => (
            //                                     <Box key={index}>
            //                                         <Box>
            //                                             <Text>Ad Account: {account.id}</Text>
            //                                             <Text>Topup amount: {account.topup}</Text>
            //                                             <Text>Commission amount: {account.commission}</Text>
            //                                             <Text>Commission %: {account.percent}</Text>
            //                                         </Box>
            //                                         {index > 0 ? (
            //                                             <Divider my={'10px'} />
            //                                         ) : null}
            //                                     </Box>
            //                                 ))}
            //                             </Box>
            //                         }
            //                     >
            //                         <InfoOutlineIcon color={'gray.600'} />
            //                     </Tooltip>
            //                 </Text>

            //             </>
            //         )
            //     }
            // },
            // {
            //     Header: "Status",
            //     accessor: "status",
            //     Filter: SelectColumnFilter,
            //     filter: 'includes',
            //     disableSortBy: true,
            //     Cell: ({ row: { original } }) => {
            //         let badgeColor = 'gray';
            //         if (original?.status == 'pending') {
            //             badgeColor = 'red';
            //         } else if (original?.status == 'approved') {
            //             badgeColor = 'orange';
            //         } else if (original?.status == 'toppedup') {
            //             badgeColor = 'green';
            //         } else if (original?.status == 'open') {
            //             badgeColor = 'yellow';
            //         }
            //         return (
            //             <>
            //                 <Badge
            //                     fontSize={"10px"}
            //                     marginRight={1}
            //                     colorScheme={badgeColor}
            //                 >
            //                     {original.status == 'toppedup' ? 'topped up' : original.status}
            //                 </Badge>
            //                 {original?.status != 'toppedup' ? (
            //                     <>
            //                         {original.clientRead ? <CircleIcon boxSize={2} color='green.500' /> : ""}
            //                     </>
            //                 ) : null}
            //             </>
            //         )
            //     }
            // },
            {
                Header: 'created',
                accessor: 'createdDate',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (
                    <Text>{moment(original.createdDate).format("DD-MM-YYYY hh:mm A")}</Text>
                )
            },
            {
                Header: 'updated',
                accessor: 'updatedDate',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (
                    <Text>{moment(original.updatedDate).format("DD-MM-YYYY hh:mm A")}</Text>
                )
            },
            {
                Header: "",
                accessor: "action",
                Filter: "",
                filter: "",
                disableSortBy: true,
                Cell: ({ row: { original } }) => (
                    <Flex justifyContent={"end"} alignItems={"center"} gap={"20px"}>

                        <Tooltip label='View' fontSize='xs' >
                            <Box>
                                <Icon
                                    _hover={{ color: "gray.500" }}
                                    as={FiEye}
                                    cursor={"pointer"}
                                    onClick={() => {
                                        setactiveRequest(original);
                                        onDrawerOpen();
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    </Flex>
                ),
            },
        ],
        []
    )

    const initialTableState = {
        sortBy: [
            {
                id: "updatedDate",
                desc: true
            }
        ],
    };

    const formik = useFormik({
        initialValues: {
            currency: "",
            amount: "",
            paymentEntityName: "",
            paymentProvider: "",
            paymentProviderOther: "",
            transactionId: "",
            paymentReceipt: "",
            transferredTo: "",
            accountType: "new",
            singleAdAccount: "",
            paymentDate: ""
        },
        validationSchema: topUpRequestSchema,
        onSubmit: async (values) => {
            //console.log(JSON.stringify(values, null, 2));
            if (values) {
                const { currency, amount, paymentEntityName, paymentProvider, paymentProviderOther, transactionId, paymentReceipt, transferredTo } = values;
                const updatedPaymentProvider = paymentProvider == 'Other' ? paymentProviderOther : paymentProvider

                let formData = new FormData()
                formData.append('currency', currency);
                formData.append('amount', amount);
                formData.append('paymentProvider', updatedPaymentProvider);
                formData.append('paymentEntityName', paymentEntityName);
                formData.append('transactionId', transactionId);
                formData.append('paymentReceipt', paymentReceipt);
                formData.append('transferredTo', transferredTo);

                await addNewTopUpRequest(formData)
            }
            formik.resetForm();
            onClose();
            setfileSource(null)
        },
    });

    const replyForm = useFormik({
        initialValues: {
            content: '',
            attachments: '',
        },
        validationSchema: replyToRequestSchema,
        onSubmit: async (values) => {
            const { content } = values
            const attachments_ = replyFiles?.map((file, index) => file.value)

            const payload = {
                content,
                attachments: attachments_,
                checked: true
            }

            if (values) {
                await replyToTopUpRequest({ requestId: activeRequest?._id, payload });
            }
            replyForm.resetForm();
            setreplyfiles([])
        },
    });

    const handleFileUpload = (filename, acceptedFiles) => {
        acceptedFiles.map((file, index) => {
            if (filename == 'paymentReceipt') {
                formik.setFieldValue('paymentReceipt', file)
            }
            if (filename == 'replyAttachments') {
                replyForm.setFieldValue('attachments', file)
            }
            const reader = new FileReader();
            reader.onload = async function (e) {
                //console.log({ filename, file });
                const formData = new FormData();
                formData.append('attachments', file)
                await uploadTopUpFile(formData)
                    .unwrap()
                    .then((payload) => {
                        //console.log('fulfilled', payload)
                        const { name, value } = payload?.data;
                        if (filename == 'paymentReceipt') {
                            setfileSource(value);
                        }
                        if (filename == 'replyAttachments') {
                            setreplyfiles(files => [...files, { name, value }]);
                        }

                    })
            };
            reader.readAsDataURL(file);
            return file;
        });
    }

    const removeFileSource = (filekey, index) => {

        if (filekey == 'replyAttachments') {
            if (index < 0 || index >= replyFiles.length) {
                return;
            }
            const newArray = [...replyFiles];
            newArray.splice(index, 1);
            setreplyfiles(newArray)
        }
    }

    const messageHistory = activeRequest && activeRequest.messages?.map(({ content, author: { name, id }, createdAt, attachments }, index) => {

        return (
            <Box mb={'10px'} key={'request' + index}>
                <Box
                    ml={id == clientId ? '20%' : '0'}
                    mr={id != clientId ? '20%' : '0'}
                    boxShadow='sm'
                >
                    <Box
                        background={id == clientId ? 'blue.50' : 'white'}
                        p={'5px 10px'} borderRadius={5} borderColor={'gray.200'} borderWidth={1}>
                        <Text fontSize={'sm'} color={"gray.600"}>{content}</Text>
                        <Flex gap={'5px'}>
                            {attachments?.length > 0 && attachments?.map((file, i) => (
                                <LightBox fileSource={file} key={'attach_' + i} />
                            ))}
                        </Flex>
                        <Text textAlign={'right'} mt={'5px'} fontSize={'9px'} color={'gray.600'}>{moment(createdAt).format("DD-MM-YYYY hh:mm A") + " • " + name}</Text>
                    </Box>
                </Box>
            </Box>
        )
    })

    const removeUploadedFile = async (id) => {
        setfileSource(null)
    };

    const bankOptions = selectedBankOptions?.map((bank, item) => {
        const { accountNumber, bankName, country, _id } = bank
        return (
            <option value={_id} key={_id}>{accountNumber + ", " + bankName + ", " + country}</option>
        )
    })

    //console.log({ selectedCompany });

    // useEffect(() => {

    //     if (activeRequest) {
    //         refetchUserCompanies()
    //     }

    // }, [activeRequest]);

    useEffect(() => {
        if (companies && activeRequest) {
            setselectedCompany(companies?.filter(({ companyName }, index) => companyName == activeRequest?.company?.name)[0])
        }
    }, [companies])


    return (
        <>

            {/* BANK DETAILS MODAL */}

            <Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isBankDetailsModalOpen}
                onClose={() => {
                    onBankDetailsModalClose();
                }}
                scrollBehavior={'inside'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader mb={'20px'} textAlign={"center"}>Bank Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody whiteSpace={'break-spaces'}>
                        <Text mb={'10px'} fontWeight={'bold'}>FOR CC ( Credit Card Account) _ UAE Bank</Text>
                        <TableContainer mb={'40px'}>
                            <Table variant='simple' size={"sm"}>

                                <Tbody>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Bank Name
                                        </Td>
                                        <Td>National Bank of Ras Al Khaimah  <br></br>
                                            Address: 302, Building 08, Dubai Media City, <br></br>
                                            Dubai, Pincode 0000</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            For USD
                                        </Td>
                                        <Td>
                                            Account Name: BLINK DIGITAL MIDDLE EAST FZ LLC<br></br>
                                            Account No: 0333134789002<br></br>
                                            IBAN: AE650400000333134789002<br></br>
                                            Currency: USD<br></br>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            For AED
                                        </Td>
                                        <Td>
                                            Account Name: BLINK DIGITAL MIDDLE EAST FZ LLC<br></br>
                                            Account No: 0333134789001<br></br>
                                            IBAN: AE920400000333134789001<br></br>
                                            Currency: AED<br></br>
                                            BIC/SWIFT: NRAKAEAK
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>

                        <Text mb={'10px'} fontWeight={'bold'}>FOR CL ( Credit Line Account ) _ Indian Bank</Text>
                        <TableContainer>
                            <Table variant='simple' size={"sm"}>

                                <Tbody>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Bank Name
                                        </Td>
                                        <Td><Text> Kotak Mahindra Bank Ltd.<br></br>
                                            Bank Address: Maker Mahal,<br></br>
                                            Turner Road, Bandra West,<br></br>
                                            MUMBAI - 400050.</Text></Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            EUR/USD/GBP
                                        </Td>
                                        <Td>
                                            Account type - COMPANY<br></br>
                                            Beneficiary Name: BLINK DIGITAL INDIA PRIVATE LIMITED<br></br>
                                            <br></br>

                                            Bank Name: Kotak Mahindra Bank Ltd.,<br></br>
                                            Bank Address: Maker Mahal, Turner Road,<br></br>
                                            Bandra West, MUMBAI - 400050.<br></br>
                                            Bank Account Type - EEFC Account - Private Limited (Company)<br></br>
                                            Swift Code : KKBKINBBXXX<br></br>
                                            IFSC Code : KKBK0000654<br></br>

                                            <br></br>
                                            For payments in Currency USD - 7712789742<br></br>
                                            For payments in Currency GBP - 7712789759<br></br>
                                            For payments in Currency EUR - 7712789766<br></br>
                                            <br></br>

                                            RBI payment Purpose code is P1007 - Advertising Service
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            For AED
                                        </Td>
                                        <Td>
                                            Account Name: BLINK DIGITAL MIDDLE EAST FZ LLC<br></br>
                                            Account No: 0333134789001<br></br>
                                            IBAN: AE920400000333134789001<br></br>
                                            Currency: AED<br></br>
                                            BIC/SWIFT : NRAKAEAK
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                </ModalContent>
            </Modal >


            {/* REQUEST DETAILS MODAL */}

            <Modal Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isDetailsModalOpen}
                onClose={() => {
                    onDetailsModalClose();
                }
                }
                scrollBehavior={'inside'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={"center"}>Request details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody whiteSpace={'break-spaces'}>
                        <TableContainer>
                            <Table variant='simple' size={"sm"}>
                                <Tbody>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Currency
                                        </Td>
                                        <Td>{activeRequest?.order_currency}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Amount
                                        </Td>
                                        <Td>{activeRequest?.order_amount}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Payment Method
                                        </Td>
                                        <Td>{activeRequest?.payment_method}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Order Status
                                        </Td>
                                        <Td>{activeRequest?.order_status}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Order ID
                                        </Td>
                                        <Td>{activeRequest?.order_id}</Td>
                                    </Tr>
                                    {/* <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Transferred From
                                        </Td>
                                        <Td>{activeRequest?.transferredFrom}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Transferred To
                                        </Td>
                                        <Td>{activeRequest?.transferredTo}</Td>
                                    </Tr> */}
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Payment Date
                                        </Td>
                                        <Td>{moment(activeRequest?.topupDate).format("DD-MM-YYYY hh:mm A")}</Td>
                                    </Tr>
                                    {/* <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Payment Receipt
                                        </Td>
                                        <Td><LightBox fileSource={activeRequest?.paymentReceipt} /></Td>
                                    </Tr> */}
                                    {/* {activeRequest?.percentSplit ? (
                                        <Tr>
                                            <Td w={"240px"} color='gray.500'>
                                                Percentage Split
                                            </Td>
                                            <Td>
                                                <UnorderedList styleType={'none'} ml={0}>
                                                    {activeRequest?.percentSplit?.map(({ name, percent, id }, index) => (
                                                        <ListItem key={id}>{name}: {percent}%</ListItem>
                                                    ))}
                                                </UnorderedList>
                                            </Td>
                                        </Tr>
                                    ) : null} */}


                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                </ModalContent>
            </Modal >

            {/* VIEW CONVERSATION HISTORY */}

            <Drawer
                isOpen={isDrawerOpen}
                placement='right'
                onClose={() => {
                    onDrawerClose();
                    setactiveRequest(null)
                }}
                finalFocusRef={btnRef}
                size={'md'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        <Flex align={'center'}>
                            <Text>Request History</Text>
                            <Badge ml={'10px'} fontSize={'10px'} colorScheme={statusBadgeColor}>{activeRequest?.status == 'toppedup' ? 'topped up' : activeRequest?.status}</Badge>
                        </Flex>
                        <Button colorScheme='gray' variant={'outline'} size={'xs'} onClick={() => onDetailsModalOpen()}>View details</Button>
                    </DrawerHeader>

                    <DrawerBody background={'gray.50'} borderWidth={1} borderColor={'gray.300'} borderLeftWidth={0} borderRightWidth={0}>
                        <Box p={'10px'} ref={scrollBoxRef}>
                            {messageHistory}
                            {activeRequest?.messages?.length == 0 ? (
                                <Text fontSize={'xs'}>No messages yet</Text>
                            ) : null}
                        </Box>
                    </DrawerBody>
                    {activeRequest?.status != 'toppedup' ? (
                        <DrawerFooter>
                            <Box flex={1}>
                                <Box gap={'10px'}>
                                    <Box flex={1} mb={'10px'}>
                                        <Textarea
                                            size='md'
                                            name='content'
                                            placeholder='Message'
                                            onChange={replyForm.handleChange}
                                            onBlur={replyForm.handleBlur}
                                            value={replyForm.values.content}
                                            isInvalid={replyForm.touched.content && replyForm.errors.content ? true : false}
                                        />
                                        {replyForm.touched.content && replyForm.errors.content && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{replyForm.errors.content}</Text>
                                        )}
                                    </Box>

                                    <Flex flex={1} justify={'space-between'}>
                                        <MultiFileUploadButton
                                            fileSource={replyFiles}
                                            fileKey='replyAttachments'
                                            onFilesSelected={handleFileUpload}
                                            onRemoveClick={(index) => removeFileSource('replyAttachments', index)}
                                            isDisabled={!replyForm.values.content}
                                        />

                                        {replyForm.touched.attachments && replyForm.errors.attachments && (
                                            <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                                {replyForm.errors.attachments}
                                            </Text>
                                        )}
                                        <Button
                                            size={'sm'}
                                            onClick={replyForm.handleSubmit}
                                            isDisabled={!replyForm.values.content || uploadResponse?.isLoading}
                                        >Reply</Button>
                                    </Flex>
                                </Box>
                            </Box>
                        </DrawerFooter>
                    ) : null}


                </DrawerContent>
            </Drawer >

            {/* ADD REQUEST MODAL */}

            < Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isOpen}
                onClose={() => {
                    onClose();
                    requestFormRef?.current?.resetForm()
                    removeUploadedFile();
                    setselectedCompany(null);
                }}
                scrollBehavior={'inside'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={"center"}>Place a Request</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            innerRef={requestFormRef}
                            initialValues={{
                                companyName: "",
                                accountType: "",
                                percentSplit: [
                                    {
                                        id: '',
                                        percent: '',
                                    },
                                ],
                                currency: "",
                                amount: "",
                                // paymentEntityName: "",
                                // paymentProvider: "",
                                // paymentProviderOther: "",
                                // transactionId: "",
                                // paymentReceipt: "",
                                // transferredFrom: "",
                                // transferredTo: "",
                                adsAccount:null,
                                paymentOption: "",
                                upiOption: "link",  
                                paymentDate: new Date()
                            }}
                            validationSchema={topUpRequestSchema}
                            onSubmit={async (values, helpers) => {
                                //setfileSource(null)
                                const {
                                    companyName: companyTitle,
                                    percentSplit,
                                    currency,
                                    amount,
                                    paymentEntityName,
                                    paymentProvider,
                                    paymentProviderOther,
                                    transactionId,
                                    paymentReceipt,
                                    transferredTo,
                                    transferredFrom,
                                    paymentDate,
                                    accountType,
                                    adsAccount,
                                    paymentOption,
                                    upiOption,
                                } = values;

                                const modifiedSplits = percentSplit.map(split => ({
                                    ...split,
                                    adsId: getAdAccountId(split.id),
                                    name: getAdAccountTitle(split.id)
                                }));

                                const companyDetails = companies?.filter(({ companyName }, index) => companyName == companyTitle)[0];
                                const updatedAccountType = accountType == 'Credit Card' ? 'cc' : 'cl';

                                let paymentMethod = {};
                                if (paymentOption === "netbanking") {
                                paymentMethod = { netbanking: { channel: "link" } };
                                } else if (paymentOption === "card") {
                                paymentMethod = { card: { channel: "link" } };
                                } else if (paymentOption === "upi") {
                                paymentMethod = { upi: { channel: upiOption } };
                                }

                                const payload = {
                                    company: {
                                        id: companyDetails?._id,
                                        name: companyDetails?.companyName,
                                    },
                                    // percentSplit: modifiedSplits,
                                    order_currency: currency,
                                    order_amount: amount,
                                    // paymentEntityName,
                                    // paymentProvider,
                                    // paymentProviderOther,
                                    // transactionId,
                                    // paymentReceipt,
                                    // transferredTo,
                                    // transferredFrom,
                                    // paymentDate,
                                    // paymentReceipt: fileSource,
                                    adsAccount: adsAccount ? {
                                        adsAccountId: adsAccount?.adsAccountId,
                                        name: adsAccount?.adsAccountName
                                    } : null,
                                    accountType: updatedAccountType,
                                    payment_method: paymentMethod, 
                                }
                                // console.log({ payload });
                                try {
                                    const response = await addNewTopUpRequest(payload);
                                    
                                    const orderPay = response?.data?.data?.order_pay;
                                    const paymentMethod = response?.data?.data?.payment_method;
                                  
                                    if (orderPay) {
                                      let url = orderPay.url;
                                  
                                      if (!url && paymentMethod === 'upi' && orderPay.payload?.default) {
                                        url = orderPay.payload.default;
                                      }

                                      if (url) {
                                        window.location.replace(url);
                                      } else {
                                        console.error('No valid payment URL found for this order.');
                                      }
                                    } else {
                                      console.error('order_pay is missing in the response.');
                                    }
                                  
                                  } catch (error) {
                                    console.error('Error during submission:', error);
                                  } finally {
                                    helpers.resetForm();
                                    setselectedCompany(null);
                                    setfileSource(null);
                                    onClose();
                                  }                                  
                            }}

                        >
                            {({ values, errors, touched, setFieldValue, resetForm, handleChange, handleBlur, getFieldProps, handleSubmit }) => {
                                return(
                                <Form >
                                    <FormControl display={"flex"} flexDirection={"column"} gap={"20px"}>
                                        <Flex gap={'20px'}>
                                            <Box flex={1}>
                                                <FormLabel fontSize='sm' mb={'10px'}>
                                                    Company Name <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                                                </FormLabel>
                                                <Select
                                                    flex={1}
                                                    name="companyName"
                                                    placeholder='Select'
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        if (!!e.target.value) {
                                                            //console.log(e.target.value);
                                                            getCompanyDetails(e.target.value);
                                                        }
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.companyName}
                                                    isInvalid={touched.companyName && errors.companyName ? true : false}
                                                >
                                                    {companies && companyOptions}
                                                </Select>
                                                {touched.companyName && errors.companyName && (
                                                    <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.companyName}</Text>
                                                )}
                                            </Box>

                                            <Box flex={1}>
                                                <FormLabel fontSize='sm' mb={'10px'}>
                                                    Ad Account Type &nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                                                </FormLabel>
                                                <Select
                                                    flex={1}
                                                    name="accountType"
                                                    placeholder='Select'
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        if (e.target.value) {
                                                            let updatedType = e.target.value == 'Credit Card' ? 'cc' : 'cl';
                                                            setselectedAccountType(updatedType)
                                                        }
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.accountType}
                                                    isInvalid={touched.accountType && errors.accountType ? true : false}
                                                    isDisabled={!values.companyName}
                                                >
                                                    {selectedCompanyAccountTypes.includes('cc') && (
                                                        <option value="Credit Card" >Credit Card</option>
                                                    )}
                                                    {selectedCompanyAccountTypes.includes('cl') && (
                                                        <option value="Credit Line">Credit Line</option>
                                                    )}
                                                </Select>
                                                {touched.accountType && errors.accountType && (
                                                    <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.accountType}</Text>
                                                )}
                                            </Box>

                                        </Flex>

                                        {/* {selectedCompany?.bank?.length == 0 ? (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>Click <Link textDecoration={'underline'} href={`/companies/${selectedCompany?._id}/edit`} isExternal>
                                                here
                                            </Link> to add a bank account in order to proceed</Text>
                                        ) : null} */}

                                        {accounts?.data?.length > 0 ? (
                                            // <Box flex={1}>
                                            //     <FormLabel fontSize='sm' mb={'10px'}>
                                            //         Ad Account&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                                            //     </FormLabel>
                                            //     <FieldArray name="percentSplit">
                                            //         {({ insert, remove, push }) => (
                                            //             <>
                                            //                 <Flex direction={'column'} gap={'20px'}>
                                            //                     {values.percentSplit.length > 0 &&
                                            //                         values.percentSplit.map((item, index) => (
                                            //                             <Flex key={index} align={'center'} gap={'20px'}>
                                            //                                 <Box >
                                            //                                     <Box flex={1}>
                                            //                                         <Select
                                            //                                             name={`percentSplit.${index}.id`}
                                            //                                             onBlur={handleBlur}
                                            //                                             value={getFieldProps(`percentSplit.${index}.id`).value}
                                            //                                             onChange={(e) => {
                                            //                                                 handleChange(e);
                                            //                                                 const newSelectedOptions = [...selectedOptions];
                                            //                                                 newSelectedOptions[index] = e.target.value;
                                            //                                                 setSelectedOptions(newSelectedOptions);
                                            //                                             }}
                                            //                                             isDisabled={!values.accountType || selectedOptions?.length == 0}
                                            //                                         >
                                            //                                             <option value={''} defaultValue disabled>Select Ad account</option>
                                            //                                             {accounts?.data?.filter((account, index) => account.accountStatus == 1 && account.accountType == selectedAccountType).map(({ adsAccountName, _id }) => (
                                            //                                                 <option
                                            //                                                     value={_id}
                                            //                                                     key={_id}
                                            //                                                     disabled={selectedOptions?.includes(_id)}
                                            //                                                 >
                                            //                                                     {adsAccountName}
                                            //                                                 </option>
                                            //                                             ))}
                                            //                                         </Select>
                                            //                                         {touched.singleAdAccount && errors.singleAdAccount && (
                                            //                                             <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.singleAdAccount}</Text>
                                            //                                         )}
                                            //                                     </Box>

                                            //                                     <ErrorMessage
                                            //                                         name={`percentSplit.${index}.id`}
                                            //                                         component="Box"
                                            //                                     />
                                            //                                 </Box>
                                            //                                 <Box >

                                            //                                     <InputGroup>
                                            //                                         <Input
                                            //                                             name={`percentSplit.${index}.percent`}
                                            //                                             onChange={handleChange}
                                            //                                             onBlur={handleBlur}
                                            //                                             value={getFieldProps(`percentSplit.${index}.percent`).value}
                                            //                                             placeholder={'% share'}
                                            //                                             isDisabled={!values.accountType || selectedOptions?.length == 0}
                                            //                                         />
                                            //                                         <InputRightAddon>
                                            //                                             %
                                            //                                         </InputRightAddon>
                                            //                                     </InputGroup>


                                            //                                     <ErrorMessage
                                            //                                         name={`percentSplit.${index}.percent`}
                                            //                                         component="p"
                                            //                                         color={'red'}
                                            //                                         fontSize={'12px'}
                                            //                                     />

                                            //                                 </Box>

                                            //                                 <Box>
                                            //                                     {index != 0 ? (
                                            //                                         <Icon
                                            //                                             color={'red'}
                                            //                                             _hover={{ color: "red.500" }}
                                            //                                             as={FiDelete}
                                            //                                             cursor={"pointer"}
                                            //                                             onClick={() => remove(index)}
                                            //                                         />
                                            //                                     ) : null}

                                            //                                 </Box>
                                            //                             </Flex>
                                            //                         ))}

                                            //                 </Flex>
                                            //                 <Button
                                            //                     mt={'10px'}
                                            //                     size={'xs'}
                                            //                     onClick={() => push({ id: '', percent: '' })}
                                            //                     isDisabled={!values.accountType || selectedOptions?.length == 0}
                                            //                 >
                                            //                     Add more
                                            //                 </Button>
                                            //                 {touched.percentSplit && touched.percentSplit[0]?.percent && errors.percentSplit && (
                                            //                     <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                            //                         {JSON.stringify(errors.percentSplit).replace(/['"]+/g, '')}
                                            //                     </Text>
                                            //                 )}
                                            //                 {/* <Text>{JSON.stringify(touched.percentSplit)}</Text> */}
                                            //             </>
                                            //         )}
                                            //     </FieldArray>
                                            // </Box>


                                            <Flex gap={'20px'}>
                                                <Box flex={0.5}>
                                                <FormLabel fontSize='sm' mb={'10px'}>
                                                    Ad Account&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                                                </FormLabel>
                                                <Select
                                                    name="adsAccount"
                                                    onBlur={handleBlur}
                                                    value={values.adsAccount ? values.adsAccount.adsAccountId : ''}
                                                    onChange={(e) => {
                                                        const selectedAccount = accounts.data.find(account => account.adsAccountId === e.target.value);
                                                        setFieldValue("adsAccount", selectedAccount || null);
                                                    }}
                                                    isInvalid={touched.adsAccount && errors.adsAccount ? true : false}
                                                    isDisabled={!selectedAccountType}
                                                    >
                                                    <option value="" disabled>Select Ad account</option>
                                                     {accounts?.data?.filter((account, index) => account.accountStatus == 1 && account.accountType == selectedAccountType).map(({ adsAccountName, _id, adsAccountId  }) => (
                                                        <option
                                                            value={adsAccountId}
                                                            key={_id}
                                                            disabled={selectedOptions?.includes(_id)}
                                                        >
                                                            {adsAccountName}
                                                        </option>
                                                    ))}
                                                </Select>
                                                {touched.adsAccount && errors.adsAccount && (
                                                    <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.adsAccount}</Text>
                                                )}
                                                </Box>

                                                <Box flex={0.5}>
                                                    <FormLabel fontSize='sm' mb={'10px'}>
                                                        Payment Method &nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                                                    </FormLabel>
                                                    <Select
                                                        flex={1}
                                                        name="paymentOption"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.paymentOption}
                                                        isInvalid={touched.paymentOption && errors.paymentOption ? true : false}
                                                    >
                                                    <option value="" disabled>Select Payment Option</option>
                                                    <option value="netbanking">Net Banking</option>
                                                    <option value="card">Card</option>
                                                    <option value="upi">UPI</option>
                                                    </Select>
                                                    {touched.paymentOption && errors.paymentOption && (
                                                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.paymentOption}</Text>
                                                    )}
                                                </Box>
                                            </Flex>
                                        ) : null}

                                        {values.paymentOption === 'upi' && (
                                            <Flex gap={'20px'}>
                                                <Box flex={1}>
                                                    <FormLabel fontSize="sm" mb={'10px'}>
                                                        Select UPI Method
                                                    </FormLabel>
                                                    <RadioGroup
                                                        name="upiOption"
                                                        onChange={(value) => setFieldValue("upiOption", value)}
                                                        value={values.upiOption}
                                                        colorScheme='orange'
                                                    >
                                                        <Stack spacing={4} direction="row">
                                                            <Radio value="link">Link</Radio>
                                                            <Radio value="qrcode">QR Code</Radio>
                                                        </Stack>
                                                    </RadioGroup>
                                                    {touched.upiOption && errors.upiOption && (
                                                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.upiOption}</Text>
                                                    )}
                                                </Box>
                                            </Flex>
                                         )}
                                        
                                        <Flex gap={'20px'}>
                                            <Box flex={1}>
                                                <FormLabel fontSize='sm' mb={'10px'}>
                                                    Currency&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                                                </FormLabel>
                                                <Select
                                                    flex={1}
                                                    name='currency'
                                                    placeholder='Select'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.currency}
                                                    isInvalid={
                                                        touched.currency && errors.currency
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    {/* <option value='USD'>USD</option>
                                                    <option value='EUR'>EUR</option> */}
                                                    <option value='INR'>INR</option>
                                                </Select>
                                                {touched.currency && errors.currency && (
                                                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                                        {errors.currency}
                                                    </Text>
                                                )}
                                            </Box>
                                            <Box flex={1}>
                                                <FormLabel fontSize='sm' mb={'10px'}>
                                                    Amount&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                                                </FormLabel>
                                                <Input
                                                    name='amount'
                                                    placeholder={
                                                        values.accountType === 'Credit Line'
                                                            ? 'Transfer Amount'
                                                            : values.accountType === 'Credit Card'
                                                                ? 'Commission to Blink'
                                                                : 'Amount'
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.amount}
                                                    isInvalid={
                                                        touched.amount && errors.amount
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {touched.amount && errors.amount && (
                                                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                                        {errors.amount}
                                                    </Text>
                                                )}
                                            </Box>

                                            {/* {values.accountType == 'Credit Line' ? (
                                                <Box flex={1}>
                                                    <FormLabel fontSize='sm' mb={'10px'} mt={'5px'}>
                                                        Amount Received
                                                    </FormLabel>
                                                    <Input
                                                        name='amount'
                                                        placeholder='Amount Received'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.amountReceived}
                                                        isInvalid={touched.amountReceived && errors.amountReceived ? true : false}
                                                    />
                                                    {touched.amountReceived && errors.amountReceived && (
                                                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                                            {errors.amountReceived}
                                                        </Text>
                                                    )}
                                                </Box>
                                            ) : null} */}

                                        </Flex>
                                        {/* <Box flex={1}>
                                            <Box 
                                                className="saathi-pg-container"
                                                p="4"
                                                borderRadius="md"
                                                boxShadow="lg"
                                            >
                                                <Link 
                                                    id="saathi-pg-link" 
                                                    href="https://payments-test.cashfree.com/forms/saathi-midas" 
                                                    target="_parent"
                                                    display="block"
                                                    width="100%"
                                                >
                                                    <Button 
                                                        className="saathi-pg-pay-button" 
                                                        width="full" 
                                                        colorScheme="orange" 
                                                        variant="solid"
                                                        color="#fff"
                                                    >
                                                        Pay Now
                                                    </Button>
                                                </Link>
                                            </Box>
                                        </Box> */}
                                        {/* <Box flex={1} >
                                            <FormLabel fontSize='sm' mb={'20px'}>
                                                Blink's Commission :
                                                {values.accountType == 'Credit Card' ? (
                                                    <Text>{selectedCompany?.cc?.retroComission}%</Text>
                                                ) : null}
                                                {values.accountType == 'Credit Line' ? (
                                                    <Text>{selectedCompany?.cl?.retroComission}%</Text>
                                                ) : null}
                                            </FormLabel>
                                            <FormLabel fontSize='sm' >
                                                Top Up Amount : <Text></Text>
                                            </FormLabel>
                                        </Box> */}

                                        {/* <Box flex={1}>
                                            <FormLabel fontSize='sm' mb={'10px'}>
                                                Bank Name/Payment Provider&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                                            </FormLabel>
                                            <Select
                                                flex={1}
                                                name='paymentProvider'
                                                placeholder='Bank Name/Payment Provider'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.paymentProvider}
                                                isInvalid={
                                                    touched.paymentProvider && errors.paymentProvider
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <option value='Wise'>Wise</option>
                                                <option value='Payoneer'>Payoneer</option>
                                                <option value='Mercury'>Mercury</option>
                                                <option value='Bank transfer'>Bank transfer</option>
                                                <option value='Others'> Others (please specify)</option>
                                            </Select>
                                            {values.paymentProvider == 'Payoneer' ? (
                                                <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                                    Processing fee of 3% will be applicable.
                                                </Text>
                                            ) : null}

                                            {touched.paymentProvider && errors.paymentProvider && (
                                                <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                                    {errors.paymentProvider}
                                                </Text>
                                            )}
                                        </Box> */}

                                        {/* {values.paymentProvider == 'Others' ? (
                                            <Box flex={1}>
                                                <Textarea
                                                    name='paymentProviderOther'
                                                    placeholder='Other Payment Provider'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.paymentProviderOther}
                                                />
                                            </Box>
                                        ) : null} */}
                                        {/* <Flex gap={'20px'}>
                                            <Box flex={1}>
                                                <FormLabel fontSize='sm' mb={'10px'}>
                                                    Payment Entity Name&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                                                </FormLabel>
                                                <Input
                                                    name='paymentEntityName'
                                                    placeholder='Payment Entity Name'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.paymentEntityName}
                                                    isInvalid={
                                                        touched.paymentEntityName && errors.paymentEntityName
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {touched.paymentEntityName && errors.paymentEntityName && (
                                                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                                        {errors.paymentEntityName}
                                                    </Text>
                                                )}
                                            </Box>
                                            <Box flex={1}>
                                                <FormLabel fontSize='sm' mb={'10px'}>
                                                    Transaction ID&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                                                </FormLabel>
                                                <Input
                                                    flex={1}
                                                    name='transactionId'
                                                    placeholder='Transaction ID'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.transactionId}
                                                    isInvalid={
                                                        touched.transactionId && errors.transactionId
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {touched.transactionId && errors.transactionId && (
                                                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                                        {errors.transactionId}
                                                    </Text>
                                                )}
                                            </Box>

                                        </Flex>
                                        <Box flex={1}>
                                            <FormLabel fontSize='sm' mb={'10px'}>
                                                Date of transfer
                                            </FormLabel>
                                            <SingleDatepicker
                                                name="paymentDate"
                                                date={values.paymentDate}
                                                maxDate={new Date()}
                                                onDateChange={(date) => {
                                                    const timestamp = moment(date, "ddd MMM DD YYYY HH:mm:ss Z").valueOf();
                                                    setFieldValue('paymentDate', timestamp)
                                                }}
                                            />
                                        </Box> */}
                                        {/* <Box flex={1}>
                                            <Select
                                                flex={1}
                                                name="transferredFrom"
                                                placeholder='Transferred from'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.transferredFrom}
                                                isInvalid={touched.transferredFrom && errors.transferredFrom ? true : false}
                                            >
                                                {selectedCompany?.bank.map(({ _id, accountNumber, bankName }, index) => (
                                                    <option value={_id} key={_id}>{accountNumber + ", " + bankName}</option>
                                                ))}
                                            </Select>
                                            {touched.transferredFrom && errors.transferredFrom && (
                                                <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.transferredFrom}</Text>
                                            )}

                                        </Box> */}
                                        {/* <Box flex={1}>
                                            <Select
                                                flex={1}
                                                name="transferredTo"
                                                placeholder='Transferred to'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.transferredTo}
                                                isInvalid={touched.transferredTo && errors.transferredTo ? true : false}
                                            > */}
                                                {/* {selectedCompany?.entity == 'indian' ? (
                                                    <option value='50200004290372, HDFC Bank Ltd, India'>50200004290372, HDFC Bank Ltd, India </option>
                                                ) :
                                                    <>
                                                        <option value='6811452274, Kotak Mahindra Bank Ltd, India'>6811452274, Kotak Mahindra Bank Ltd, India </option>
                                                        <option value='7712789742, Kotak Mahindra Bank Ltd, India'>7712789742, Kotak Mahindra Bank Ltd, India </option>
                                                        <option value='7712789766, Kotak Mahindra Bank Ltd, India'>7712789766, Kotak Mahindra Bank Ltd, India </option>
                                                        <option value='7712789759, Kotak Mahindra Bank Ltd, India'>7712789759, Kotak Mahindra Bank Ltd, India </option>
                                                        <option value='0333134789001, National Bank of Ras Al Khaimah (RAKBANK), United Arab Emirates'>0333134789001, National Bank of Ras Al Khaimah (RAKBANK), United Arab Emirates </option>
                                                        <option value='0333134789002, National Bank of Ras Al Khaimah (RAKBANK), United Arab Emirates'>0333134789002, National Bank of Ras Al Khaimah (RAKBANK), United Arab Emirates </option>
                                                        <option value='0114100055, ABN AMRO Bank, Netherlands'>0114100055, ABN AMRO Bank, Netherlands</option>

                                                    </>} */}
                                            {/* 
                                                {bankOptions}Ad Account

                                            </Select>
                                            {touched.transferredTo && errors.transferredTo && (
                                                <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.transferredTo}</Text>
                                            )}
                                        </Box> */}
                                        {/* <Box>
                                            <FormLabel fontSize='sm' mb={'10px'}>
                                                Payment Receipt&nbsp;<Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>&nbsp;
                                                <Tooltip
                                                    borderRadius={5}
                                                    label={
                                                        <Box fontSize={"sm"} p={"5px"}>
                                                            <Text>Please upload an image as proof of payment/transaction</Text>
                                                        </Box>
                                                    }
                                                    fontSize='md'
                                                >
                                                    <InfoOutlineIcon />
                                                </Tooltip>
                                            </FormLabel>
                                            <FileUploadBox
                                                fileSource={fileSource}
                                                multiUpload={false}
                                                fileKey='paymentReceipt'
                                                onFileUpload={(name, files) => {
                                                    handleFileUpload(name, files);
                                                    if (files[0]) {
                                                        setFieldValue('paymentReceipt', files[0].path)
                                                    }
                                                }}
                                                onRemoveClick={removeUploadedFile}
                                                isInvalid={touched.paymentReceipt && errors.paymentReceipt ? true : false}
                                                isUploading={uploadResponse.isLoading}
                                            />
                                            {touched.paymentReceipt && errors.paymentReceipt && (
                                                <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                                    {errors.paymentReceipt}
                                                </Text>
                                            )}
                                        </Box> */}
                                        <Flex gap={"20px"} mb={"10px"} mt={"20px"} justify={"center"}>
                                            <Button
                                                colorScheme='orange'
                                                isDisabled={newTopUpResponse?.isLoading}
                                                onClick={handleSubmit}
                                                width="100px" 
                                            > {newTopUpResponse?.isLoading ? <Spinner /> : 'Pay Now'}</Button>
                                            <Button

                                                onClick={() => {
                                                    resetForm();
                                                    onClose();
                                                    removeUploadedFile()
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Flex>
                                    </FormControl>
                                </Form>
                            )}}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal >

            <SidebarWithHeader>
                <Flex mb={'10px'}>
                    <Heading as='h1' size='xl'>
                        Top up Requests
                    </Heading>
                    {isOnboarded ? (
                        <>
                            <Spacer />
                            <Button colorScheme='orange' onClick={onOpen}>
                                <AddIcon boxSize={3} />&nbsp;Add a Top Up
                            </Button>
                        </>
                    ) : null}
                </Flex>
                <Flex flexDirection={'column'} flex={'1'} >
                    {isTopUpRequestsLoading ? <Spinner /> : (
                        <>
                            <Flex justifyContent={'space-between'}>
                                <Flex direction={'column'}>
                                    <LegendItem color="green.300" label="Open - Your request has been sent to our team." />
                                    <Flex align="center" mb={2}>
                                        <Box w={4} h={2} bg={'yellow.300'} mr={2} />
                                        <Text align={'center'} fontSize={'xs'}>In Review - Your request is In Review. Click
                                            <Icon
                                                boxSize={3}
                                                as={FiEye}
                                                pt={'2px'}
                                                mb={'-2px'}
                                                mx={1}
                                            />to learn more.
                                        </Text>
                                    </Flex>
                                    <LegendItem color="gray.300" label="Closed - Your request has been solved, and the ticket has been closed." />
                                </Flex>
                                <Button alignSelf={'center'} variant={'outline'} colorScheme='orange' size={'xs'} onClick={onBankDetailsModalOpen}>View Bank Details</Button>
                            </Flex>

                            <AppTableWithPagination
                                columns={columns}
                                data={requests?.data || []}
                                searchTerm={searchValue}
                                paginationMeta={requests?.meta || []}
                                onPageChange={(index) => setCurrentPage(index)}
                                onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                                searchEnabled={true}
                                isFetching={isFetching}
                            />
                            {!searchValue && requests?.data?.length == 0 ? (
                                <Text>When you want to top up your ad account, please make the payment and send a request so we can verify the same.</Text>
                            ) : null}
                        </>
                    )}

                </Flex>
            </SidebarWithHeader>
        </>
    )
}

export default TopUpsForClient