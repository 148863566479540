import {
    Box, Flex,
    Select,
    Spinner,
    Text
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetInstagramByAdaccountQuery, useGetInstagramByPageIDQuery, useGetInstagramPageDetailsQuery } from 'store/BmAccounts/bmAccountApi';

function InstagramAccountUpdateSelector({ isNewAdCreating = false, adsAccount, onSelectedPage, selectedInstPageId, pageID }) {

    const { account_id, name } = adsAccount || {};
    const [selectedPage, setselectedPage] = useState(selectedInstPageId || '');
    const userInfo = useSelector((state) => state.auth?.userInfo);

    useEffect(() => {
        if (selectedInstPageId) {
            setselectedPage(selectedInstPageId);
        }
    }, [selectedInstPageId])


    // get list of instagram accounts 
    const { data: instagramAccounts, isLoading: isInstaLoading } = useGetInstagramByAdaccountQuery(
        { adsAccountId: account_id }, {}
    );

    // get pagebacked instagram accounts
    const { data: pageBackedInstaAccounts, isLoading: isFacebookIdLoading } = useGetInstagramByPageIDQuery(
        { pageId: pageID },
        { skip: !pageID }
    );

    const isPageBackedInstaAvailable = pageBackedInstaAccounts?.page_backed_instagram_accounts?.data?.[0];

    const isPageIdPresent = pageBackedInstaAccounts?.page_backed_instagram_accounts?.data?.some(account => account.id === selectedInstPageId);

    // get instagram page details if pageId in not in pageBackedInstaAccounts
    const { data: instaPageDetails, isLoading: isPageDetailsLoading } = useGetInstagramPageDetailsQuery(
        { instaId: selectedInstPageId },
        { skip: !selectedInstPageId }
    );

    // Check if the selected Instagram account is in the list of Instagram accounts
    const isSelectedAccountInList = instagramAccounts?.some(account => account.id === selectedInstPageId);

    // console.log({ selectedPage });
    // console.log({ isPageIdPresent });
    // console.log({ instaPageDetails });
    // console.log(pageBackedInstaAccounts?.page_backed_instagram_accounts?.data[0]?.id);

    const handleEventPageChange = (event) => {
        const pageId = event.target.value;
        setselectedPage(pageId);
        onSelectedPage(pageId, account_id);
    };

    return (
        <Box>
            <Flex gap={'20px'}>
                <Box flex={1}>
                    <Text fontWeight={'bold'} fontSize={'12px'}>{name}</Text>
                    <Text fontSize={'12px'}>{account_id}</Text>
                </Box>

                <Box flex={1}>
                    {isInstaLoading ? (
                        <Spinner size="sm" />
                    ) : (
                        <>
                            {instagramAccounts?.length > 0 ? (
                                <Select
                                    placeholder="Select"
                                    value={selectedPage}
                                    onChange={handleEventPageChange}
                                    borderRadius={'md'}
                                    size={'sm'}
                                    isDisabled={userInfo?.role !== 'client'}
                                >
                                    {instagramAccounts?.map((account) => (
                                        <option key={account?.id} value={account?.id}>
                                            {account?.username}
                                        </option>
                                    ))}

                                    {/* {!isSelectedAccountInList && ( */}
                                    <option 
                                        value={pageBackedInstaAccounts?.page_backed_instagram_accounts?.data[0]?.id}
                                        disabled={!isPageBackedInstaAvailable}>
                                        {/* {isNewAdCreating ? 'Use Facebook Page' : isPageIdPresent ? 'Use Facebook Page' : instaPageDetails?.username} */}
                                        Use Facebook Page
                                    </option>
                                    {/* )} */}
                                </Select>
                            ) : (
                                <>
                                    <Select
                                        placeholder="Select"
                                        value={selectedPage}
                                        onChange={handleEventPageChange}
                                        borderRadius={'md'}
                                        size={'sm'}
                                        isDisabled={userInfo?.role !== 'client'}
                                    >

                                        <option value={pageBackedInstaAccounts?.page_backed_instagram_accounts?.data[0]?.id}
                                         disabled={!isPageBackedInstaAvailable}>
                                            Use Facebook Page
                                        </option>
                                    </Select>
                                </>
                            )}
                        </>
                    )}
                </Box>
            </Flex>
        </Box>
    );
}

export default InstagramAccountUpdateSelector;
