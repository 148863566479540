import { createSlice } from '@reduxjs/toolkit';

const savedCampaignId = localStorage.getItem('selectedCampaignId');
const savedAdsetId = localStorage.getItem('selectedAdsetId');
const savedAdsId = localStorage.getItem('selectedAdsId');
const savedAfter = localStorage.getItem('after') || '';
const savedBefore = localStorage.getItem('before') || '';
const savedCurrentPage = localStorage.getItem('currentPage') ? parseInt(localStorage.getItem('currentPage'), 10) : 0;

const initialState = {
  selectedCampaignId: savedCampaignId ? savedCampaignId : null,
  selectedAdsetId: savedAdsetId ? savedAdsetId : null,
  selectedAdsId: savedAdsId ? savedAdsId : null,
  campaignsForInsights: [],
  AdsetForInsights:[],
  AdsForInsights:[],
  after: savedAfter, 
  after1: '',
  before: savedBefore, 
  before1: '',
  currentPage: isNaN(savedCurrentPage) ? 0 : savedCurrentPage,
  currentPage1: ''
};

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setSelectedCampaignId: (state, action) => {
      state.selectedCampaignId = action.payload;
      if (action.payload === null) {
        localStorage.removeItem('selectedCampaignId'); // Remove from local storage
      } else {
        localStorage.setItem('selectedCampaignId', action.payload); // Set the new value to local storage
      }
    },
    setSelectedAdsetId: (state, action) => {
      state.selectedAdsetId = action.payload;
      if (action.payload === null) {
        localStorage.removeItem('selectedAdsetId'); // Remove from local storage
      } else {
        localStorage.setItem('selectedAdsetId', action.payload); // Set the new value to local storage
      }
    },
    setSelectedAdsId: (state, action) => {
      state.selectedAdsId = action.payload;
      if (action.payload === null) {
        localStorage.removeItem('selectedAdsId'); // Remove from local storage
      } else {
        localStorage.setItem('selectedAdsId', action.payload); // Set the new value to local storage
      }
    },
    setCampaignsForInsights: (state, action) => {
      state.campaignsForInsights = action.payload; 
    },
    setAdsetsForInsights: (state, action) => {
      state.AdsetForInsights = action.payload; 
    },
    setAdsForInsights: (state, action) => {
      state.AdsForInsights = action.payload; 
    },
    setAfter: (state, action) => {
      const { componentKey = 'default', value } = action.payload;

      if (componentKey === 'Drive') {
        state.after1 = value;
      } else {
        state.after = action.payload;
        localStorage.setItem('after', action.payload); // Store in localStorage for the default component
      }
    },
    setBefore: (state, action) => {

      const { componentKey = 'default', value } = action.payload;

      if (componentKey === 'Drive') {
        state.before1 = value;
      } else {
        state.before = action.payload;
        localStorage.setItem('before', action.payload); // Save to localStorage
      }
    },
    setCurrentPage: (state, action) => {
      const newPage = Math.max(0, action.payload); 
      const { componentKey = 'default', value } = action.payload;

      if (componentKey === 'Drive') {
        state.currentPage1 = value;
      } else {
        state.currentPage = newPage;
        localStorage.setItem('currentPage', newPage); // Save currentPage to localStorage
      }
    },
  },
});

export const { setSelectedCampaignId, setSelectedAdsetId, setSelectedAdsId, setCampaignsForInsights, setAdsetsForInsights,setAdsForInsights, setAfter,
  setBefore,setCurrentPage } = campaignsSlice.actions;

export const selectSelectedCampaignId = (state) => state.campaigns.selectedCampaignId;
export const selectSelectedAdsetId = (state) => state.campaigns.selectedAdsetId;
export const selectSelectedAdsId = (state) => state.campaigns.selectedAdsId;
export const selectCampaignsForInsights = (state) => state.campaigns.campaignsForInsights;
export const selectAdsetsForInsights = (state) => state.campaigns.AdsetForInsights;
export const selectAdsForInsights = (state) => state.campaigns.AdsForInsights;
export const selectAfter = (state) => state.campaigns.after;
export const selectAfter1 = (state) => state.campaigns.after1;
export const selectBefore = (state) => state.campaigns.before;
export const selectBefore1 = (state) => state.campaigns.before1;
export const selectCurrentPage = (state) => state.campaigns.currentPage;
export const selectCurrentPage1 = (state) => state.campaigns.currentPage1;

export default campaignsSlice.reducer;
