import { AddIcon, CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { useCreateAdRuleMutation } from 'store/adRules/adRuleApi';
import { removeEmptyValues } from 'utils/functions';
import { createRuleSchema } from 'utils/schema';
import AdSelection from './AdSelection';
import currencySymbolMap from 'currency-symbol-map'; 

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const value = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
      times.push({ value, label: value });
    }
  }
  return times;
};

const timeOptions = generateTimeOptions();

const convertTimeToMinutes = (time) => {
  if (!time) return "0"; // Default to 0 if no time is provided
  const [hours, minutes] = time.split(':').map(Number);
  return (hours * 60 + minutes).toString();
};

function CreateRuleForm({ isOpen, onClose, adAccounts, onSuccess }) {

  const toast = useToast();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedAds, setSelectedAds] = useState({});
  const [selectedAdsets, setSelectedAdsets] = useState({});
  const currencyCode = adAccounts?.currency;
  const currencySymbol = currencySymbolMap(currencyCode);

  const [customSchedules, setCustomSchedules] = useState(daysOfWeek.map(() => ({
    days: [],
    timeSlots: [{ startTime: '', endTime: '' }]
  })));

  const [createAdRule, { isLoading: isAdRuleCreating, error: createError }] = useCreateAdRuleMutation();

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSelectedAdsChange = (newSelectedAds) => {
    setSelectedAds(newSelectedAds);  
  };

  // console.log({selectedAdsets})
  // console.log({selectedAds})

  const handleSelectedAdsetChange = (newSelectedAdsets) => {
    setSelectedAdsets(newSelectedAdsets);
  }

  const createRuleFormik = useFormik({
    initialValues: {
      ruleName: '',
      applyTo: 'AD',
      action: 'PAUSE',
      budgetAmount: '', 
      currencySymbol: 'PERCENTAGE', 
      maxLifetimeBudgetCap: '', 
      actionFrequency: '720',
      conditionField: 'cost_per',
      conditionOperator: 'GREATER_THAN',
      conditionValue: '',
      conditionValue1: '',
      conditionValue2: '',
      timeRange: 'MAXIMUM',
      schedule: 'SEMI_HOURLY',
      notification: true,
      customSchedules: [],
    },
    validationSchema: createRuleSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {

      const formatValue = (value) => {
        if (value && !isNaN(value)) {
          return parseFloat(value) * 100;
        }
        return value;
      };

      const filters = [
        { field: "entity_type", operator: "EQUAL", value: values.applyTo },
        { field: "time_preset", operator: "EQUAL", value: values.timeRange },
        ...((values.applyTo === 'ADSET') && (values.action !== 'PAUSE' && values.action !== 'UNPAUSE') ? [{
          field: "adset.budget_reset_period", 
          operator: "IN", 
          value: (values.action === 'INCREASE_DAY' || values.action === 'DECREASE_DAY') ? ['DAY'] 
                 : (values.action === 'INCREASE_LIFETIME' || values.action === 'DECREASE_LIFETIME') ? ['LIFETIME'] 
                 : []
        }] : []),
        {
          field: values.conditionField,
          value: values.conditionOperator === 'IN_RANGE' || values.conditionOperator === 'NOT_IN_RANGE'
            ? [formatValue(values.conditionValue1), formatValue(values.conditionValue2)]
            : formatValue(values.conditionValue),
          operator: values.conditionOperator,
        },
      ];

      const selectedAdIds = Object.keys(selectedAds).filter(adId => selectedAds[adId]);
      const selectedAdsetIds = Object.keys(selectedAdsets).filter(adsetId => selectedAdsets[adsetId]);

      // Apply filters based on `applyTo` value
      if (values.applyTo === 'AD' && selectedAdIds.length > 0) {
        filters.push({
          field: "ad.id",
          value: selectedAdIds,
          operator: "IN"
        });
      }

      if (values.applyTo === 'ADSET' && selectedAdsetIds.length > 0) {
        filters.push({
          field: "adset.id",
          value: selectedAdsetIds,
          operator: "IN"
        });
      }

      const rulePayload = {
        name: values.ruleName,
        schedule_type: values.schedule,
        evaluation_type: "SCHEDULE",
        schedule: values.customSchedules.flatMap(schedule =>
          schedule.days.length > 0 ?
            schedule.timeSlots.map(timeSlot => ({
              days: schedule.days.map(day => daysOfWeek.indexOf(day)),
              start_minute: convertTimeToMinutes(timeSlot.startTime),
              end_minute: convertTimeToMinutes(timeSlot.endTime),
            })) : []
        ),
        filters,
        execution_type: (values.action === 'INCREASE_DAY' || values.action === 'DECREASE_DAY' || values.action === 'INCREASE_LIFETIME' || values.action === 'DECREASE_LIFETIME') ? 'CHANGE_BUDGET' : values.action,
        execution_options: (values.action === 'INCREASE_DAY' || values.action === 'DECREASE_DAY' || values.action === 'INCREASE_LIFETIME' || values.action === 'DECREASE_LIFETIME') ? [
          {
            field: "change_spec",
            value: {
              amount: values?.currencySymbol == 'ACCOUNT_CURRENCY' 
                ? (values.budgetAmount * 100).toString() 
                : (values.action === 'DECREASE_LIFETIME' || values.action === 'DECREASE_DAY' ? (-values.budgetAmount).toString() : values.budgetAmount.toString()),          
            
              limit: (values.maxLifetimeBudgetCap * 100).toString() || '',  
              unit: values.currencySymbol,
              target_field: null,
            },
            operator: "EQUAL",
          },
          {
            field: "action_frequency",
            value: values.actionFrequency || "60", 
            operator: "EQUAL",
          },
          {
            field: "alert_preferences",
            value: {
              instant: { trigger: "CHANGE" },
            },
            operator: "EQUAL",
          },
        ] : [],
        adsAccount: {
          id: adAccounts?.id,
          adsAccountId: adAccounts?.account_id,
          adsAccountName: adAccounts?.name,
          businessName: adAccounts?.business?.name,
          businessId: adAccounts?.business?.id,
        }
      };
      const payload = removeEmptyValues(rulePayload);

      createAdRule(payload).unwrap()
        .then(response => {
          onSuccess();
          toast({
            position: "top-right",
            title: "Ad Rule created ",
            status: "success",
            duration: 4000,
            isClosable: true,
          });

        })
        .catch(error => {
          toast({
            position: "top-right",
            title: "Error creating Ad Rule",
            status: "error",
            description: error?.data?.message,
            duration: 4000,
            isClosable: true,
          });

        })
        .finally(() => {
          setSubmitting(false);
          resetForm();
          setSelectedAds({});
          setSelectedAdsets({});
        });
      onClose();
    },
  });

  useEffect(() => {
    if (isOpen) {
      setCurrentStep(0);
    }
  }, [isOpen]);
  
  const handleDayChange = (index, day) => {
    const newSchedules = [...customSchedules];
    if (newSchedules[index].days.includes(day)) {
      newSchedules[index].days = newSchedules[index].days.filter(d => d !== day);
    } else {
      newSchedules[index].days.push(day);
    }
    setCustomSchedules(newSchedules);
    createRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleAddTimeSlot = (scheduleIndex) => {
    const newSchedules = [...customSchedules];
    newSchedules[scheduleIndex].timeSlots.push({ startTime: '', endTime: '' });
    setCustomSchedules(newSchedules);
    createRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleTimeSlotChange = (scheduleIndex, slotIndex, field, value) => {
    const newSchedules = [...customSchedules];

    // Update the time slot based on the field
    newSchedules[scheduleIndex].timeSlots[slotIndex][field] = value;

    if (field === 'startTime') {
      // If both are empty, set endTime to same value(which is start time)
      if (newSchedules[scheduleIndex].timeSlots[slotIndex].endTime === '') {
        newSchedules[scheduleIndex].timeSlots[slotIndex].endTime = value;
      }

      if (newSchedules[scheduleIndex].timeSlots[slotIndex].endTime < value) {
        newSchedules[scheduleIndex].timeSlots[slotIndex].endTime = value;
      }
    } else if (field === 'endTime') {
      if (newSchedules[scheduleIndex].timeSlots[slotIndex].startTime > value) {
        newSchedules[scheduleIndex].timeSlots[slotIndex].startTime = value;
      }
    }

    setCustomSchedules(newSchedules);
    createRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleRemoveTimeSlot = (scheduleIndex, slotIndex) => {
    const newSchedules = [...customSchedules];
    newSchedules[scheduleIndex].timeSlots.splice(slotIndex, 1);
    setCustomSchedules(newSchedules);
    createRuleFormik.setFieldValue('customSchedules', newSchedules);
  };
  // console.log('create rule formik',createRuleFormik.values);

  return (
      <Modal isOpen={isOpen} onClose={() => { onClose(); createRuleFormik.resetForm(); setSelectedAds({}); setSelectedAdsets({}); setCurrentStep(0); }} scrollBehavior="inside" isCentered size={"4xl"} >
        <ModalOverlay />
        <ModalContent overflow='hidden' maxHeight={'100vh'}>
          <form onSubmit={createRuleFormik.handleSubmit}>
            <ModalHeader boxShadow="md" p={'12px'}>
              <Text fontWeight="bold" fontSize={'20px'}>Create rule:custom rule</Text>
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody flexGrow={1} maxHeight="calc(100vh - 200px)" overflowY="auto">
              {currentStep === 0 && (
                <AdSelection
                  adAccounts={adAccounts}
                  selectedAds={selectedAds}
                  selectedAdsets={selectedAdsets}
                  onSelectedAdsChange={handleSelectedAdsChange}
                  onSelectedAdsetsChange={handleSelectedAdsetChange}
                />
              )}

              {currentStep === 1 && (
                <>
                  <Text mb={4} fontSize={'14px'} >
                    Automatically update campaigns, ad sets, or ads in bulk by creating automated rules.
                  </Text>

                  <Flex direction="column" gap={4}>
                    {/* Rule name */}
                    <Flex direction="column" gap={1}>
                      <Text fontWeight="bold">Rule name</Text>
                      <Input
                        name="ruleName"
                        placeholder="Rule name"
                        fontSize="sm"
                        value={createRuleFormik.values.ruleName}
                        onChange={createRuleFormik.handleChange}
                        onBlur={createRuleFormik.handleBlur}
                      />
                      {createRuleFormik.touched.ruleName && createRuleFormik.errors.ruleName && (
                        <Text color="red.500" fontSize="12px">{createRuleFormik.errors.ruleName}</Text>
                      )}
                  </Flex>

                  {/* Apply rule to */}
                  <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Apply rule to</Text>
                    <Select
                      name="applyTo"
                      fontSize="sm"
                      value={createRuleFormik.values.applyTo}
                      onChange={createRuleFormik.handleChange}
                      onBlur={createRuleFormik.handleBlur}
                    >
                    {/* Show All active ad sets if none are selected */}
                    {Object.values(selectedAdsets).some((isSelected) => isSelected) ? (
                      <option value="ADSET">
                        {Object.values(selectedAdsets).filter((isSelected) => isSelected).length > 0
                          ? `${Object.values(selectedAdsets).filter((isSelected) => isSelected).length} ad set${Object.values(selectedAdsets).filter((isSelected) => isSelected).length > 1 ? 's' : ''}`
                          : "All active ad sets"}
                      </option>
                    ) : (
                      <option value="ADSET">All active ad sets</option>
                    )}

                    {/* Show All active ads if none are selected */}
                    <option value="AD">
                      {Object.values(selectedAds).some((isSelected) => isSelected) ? (
                        `${Object.values(selectedAds).filter((isSelected) => isSelected).length} ad${Object.values(selectedAds).filter((isSelected) => isSelected).length > 1 ? 's' : ''}`
                      ) : (
                        "All active ads"
                      )}
                    </option>

                    </Select>
                    {createRuleFormik.touched.applyTo && createRuleFormik.errors.applyTo && (
                      <Text color="red.500" fontSize="sm">{createRuleFormik.errors.applyTo}</Text>
                    )}
                  </Flex>

                  {/* Action */}
                  <Flex direction="column" gap={2}>
                    <Text fontWeight="bold">Action</Text>

                    {/* Main action select */}
                    <Flex direction="row" align="center" gap={2} justifyContent="space-between">
                      <Select
                        name="action"
                        fontSize="sm"
                        value={createRuleFormik.values.action}
                        onChange={createRuleFormik.handleChange}
                        onBlur={createRuleFormik.handleBlur}
                        width={
                          (createRuleFormik.values.action === "PAUSE" && createRuleFormik.values.applyTo === "ADSET" || 'AD') ||
                          (createRuleFormik.values.action === "UNPAUSE" && createRuleFormik.values.applyTo === "ADSET" || 'AD')
                            ? "100%"
                            : "500px"
                        }
                      >
                        {createRuleFormik.values.applyTo === "AD" ? (
                          <>
                            <option value="PAUSE">Turn off ads</option>
                            <option value="UNPAUSE">Turn on ads</option>
                          </>
                        ):(
                          <>
                            <option value="PAUSE">Turn off ad sets</option>
                            <option value="UNPAUSE">Turn on ad sets</option>
                          </>
                        )}

                        {createRuleFormik.values.applyTo === "ADSET" && (
                          <>
                             <option value="INCREASE_DAY">Increase daily budget by</option>
                             <option value="DECREASE_DAY">Decrease daily budget by</option>
                             <option value="INCREASE_LIFETIME">Increase lifetime budget by</option>
                             <option value="DECREASE_LIFETIME">Decrease lifetime budget by</option>
                          </>
                        )}
                      </Select>

                      {/* Show input + select only for budget actions */}
                      {(
                          ['INCREASE_DAY', 'DECREASE_DAY', 'INCREASE_LIFETIME', 'DECREASE_LIFETIME'].includes(createRuleFormik.values.action) &&
                          createRuleFormik.values.applyTo === 'ADSET'
                        ) && (
                        <Flex direction="column">
                          <Flex alignItems={'center'} justifyItems={'center'}>
                          {/* Budget Amount Input */}
                          <InputGroup width="150px">
                            <Input
                              name="budgetAmount"
                              value={createRuleFormik.values.budgetAmount}
                              onChange={(e) => {
                                const value = e.target.value;

                                // Apply only when 'PERCENTAGE'
                                if (createRuleFormik.values.currencySymbol === 'PERCENTAGE') {
                                  if (value && !isNaN(value) && Number(value) > 100) {
                                    createRuleFormik.setFieldValue("budgetAmount", "");
                                  } else {
                                    createRuleFormik.handleChange(e);
                                  }
                                } else {
                                  createRuleFormik.handleChange(e);
                                }
                              }}
                              onBlur={createRuleFormik.handleBlur}
                              placeholder="0.00"
                              borderRightRadius={0}
                              borderRight={0}
                              fontSize="12px"
                            />
                            <InputRightAddon bg="#fff" borderRightRadius={0} fontSize="12px">
                              {createRuleFormik.values.currencySymbol === "ACCOUNT_CURRENCY" ? currencyCode : ''}
                            </InputRightAddon>
                          </InputGroup>

                          {/* Currency Symbol Select */}
                          <Select
                            name="currencySymbol"
                            fontSize="sm"
                            value={createRuleFormik.values.currencySymbol}
                            onChange={createRuleFormik.handleChange}
                            onBlur={createRuleFormik.handleBlur}
                            width="120px"
                            borderLeft={0}
                            borderLeftRadius={0}
                          >
                            <option value="PERCENTAGE">%</option>
                            <option value="ACCOUNT_CURRENCY">{currencySymbol}</option>
                          </Select>
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                    <Flex justifyContent={'flex-end'} mr={'210px'} mt={'-8px'}>
                      {createRuleFormik.touched.budgetAmount && createRuleFormik.errors.budgetAmount && (
                          <Text color="red.500" fontSize="12px">{createRuleFormik.errors.budgetAmount}</Text>
                      )}
                    </Flex>

                    {createRuleFormik.touched.action && createRuleFormik.errors.action && (
                      <Text color="red.500" fontSize="12px">{createRuleFormik.errors.action}</Text>
                    )}

                    {/* Conditional Budget and Frequency Info */}
                    {(
                        ['INCREASE_DAY', 'DECREASE_DAY', 'INCREASE_LIFETIME', 'DECREASE_LIFETIME'].includes(createRuleFormik.values.action) &&
                        createRuleFormik.values.applyTo === 'ADSET'
                      ) && (
                      <>
                        <Flex mt={2} mb={2}>
                          <Text fontSize="12px">
                              Your rule will apply to ad sets that are active at the time the rule runs. 
                              Only ad sets with {['DECREASE_LIFETIME', 'INCREASE_LIFETIME'].includes(createRuleFormik.values.action) ? 'lifetime' : 'daily'} budgets can be managed by this rule action.
                          </Text>
                        </Flex>
                        {(['DECREASE_DAY', 'DECREASE_LIFETIME'].includes(createRuleFormik.values.action) &&
                          createRuleFormik.values.currencySymbol === 'PERCENTAGE' &&
                          createRuleFormik.values.budgetAmount >= 50 &&
                          createRuleFormik.values.budgetAmount <= 100) && (
                            <Flex alignItems="center" mb={4} gap={2} bg={'gray.50'} p={2} borderRadius={'5px'}>
                              <InfoOutlineIcon pointerEvents="all" mb={5} />
                              <Text fontSize="12px">
                                Decreasing your budget by this percentage each time an ad set meets rule conditions could cause it to be under-delivered.
                              </Text>
                            </Flex>
                          )}

                        <Flex direction="row" gap={4} justifyContent="space-between">
                          {/* Maximum Lifetime Budget Cap */}
                          <Flex direction="column" width="100%">
                            <Flex alignItems="center" gap={1}>
                              <Text fontWeight="bold" mb={1}>
                                {createRuleFormik.values.action === 'INCREASE_LIFETIME' 
                                  ? 'Maximum Lifetime Budget Cap'
                                  : createRuleFormik.values.action === 'DECREASE_LIFETIME'
                                  ? 'Minimum Lifetime Budget Floor'
                                  : createRuleFormik.values.action === 'INCREASE_DAY'
                                  ? 'Maximum Day Budget Cap'
                                  : createRuleFormik.values.action === 'DECREASE_DAY'
                                  ? 'Minimum Day Budget Floor'
                                  : 'Budget Cap/Floor'}
                              </Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="sm" p="5px">
                                   <Text>
                                    {['DECREASE_DAY', 'DECREASE_LIFETIME'].includes(createRuleFormik.values.action) 
                                      ? 'This is the lowest amount the ' 
                                      : 'This is the highest amount the '}
                                    {['DECREASE_DAY', 'INCREASE_DAY'].includes(createRuleFormik.values.action)
                                      ? 'daily budget can reach for each ad set managed by your rule.' 
                                      : 'lifetime budget can reach for each ad set managed by your rule.'}
                                    
                                    <br />

                                    {['DECREASE_DAY', 'DECREASE_LIFETIME'].includes(createRuleFormik.values.action)
                                      ? 'You may see a partial decrease if an ad set\'s '
                                      : 'You may see a partial increase if an ad set\'s '} 

                                    {['DECREASE_LIFETIME', 'INCREASE_LIFETIME'].includes(createRuleFormik.values.action)
                                      ? 'lifetime budget reaches the cap before the full percentage or currency amount set by you is applied.'
                                      : 'daily budget reaches the cap before the full percentage or currency amount set by you is applied.'} 

                                    
                                    {['DECREASE_DAY', 'INCREASE_DAY'].includes(createRuleFormik.values.action) 
                                      ? ' (daily budget)' 
                                      : ''}
                                  </Text>

                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon mb={1} pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <InputGroup>
                              <Input
                                name="maxLifetimeBudgetCap"
                                value={createRuleFormik.values.maxLifetimeBudgetCap}
                                onChange={createRuleFormik.handleChange}
                                onBlur={createRuleFormik.handleBlur}
                                placeholder="Enter budget cap"
                                fontSize="sm"
                              />
                              <InputRightAddon bg="#fff" fontSize="12px">
                                {currencyCode}
                              </InputRightAddon>
                            </InputGroup>
                            {createRuleFormik.touched.maxLifetimeBudgetCap && createRuleFormik.errors.maxLifetimeBudgetCap && (
                              <Text color="red.500" fontSize="12px">{createRuleFormik.errors.maxLifetimeBudgetCap}</Text>
                            )}
                          </Flex>

                          {/* Action Frequency */}
                          <Flex direction="column" width="100%">
                            <Flex alignItems="center" gap={1}>
                              <Text fontWeight="bold" mb={1}>Action Frequency</Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="sm" p="5px">
                                    <Text>
                                      The minimum amount of time until the same action can be taken on an object by a rule. The default value is once every 12 hours.
                                    </Text>
                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon mb={1} pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <Select
                              name="actionFrequency"
                              fontSize="sm"
                              value={createRuleFormik.values.actionFrequency}
                              onChange={createRuleFormik.handleChange}
                              onBlur={createRuleFormik.handleBlur}
                            >
                              <option value="60">Once hourly</option>
                              <option value="720">Once every 12 hours (default)</option>
                              <option value="1440">Once daily</option>
                              <option value="10080">Once weekly</option>
                              <option value="20160">Every 2 weeks</option>
                            </Select>
                          </Flex>
                        </Flex>
                      </>
                    )}
                  </Flex>


                    {/* Conditions */}
                    <Flex direction="column">
                      <Flex alignItems={'center'} gap={1}>
                        <Text fontWeight="bold">Conditions</Text>
                        <Tooltip
                          borderRadius={5}
                          label={
                            <Box fontSize="sm" p="5px">
                              <Text>These will be used to evaluate whether the rule's action should be taken on each of your selected ad sets.</Text>
                            </Box>
                          }
                          fontSize="md"
                        >
                          <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                      </Flex>
                      <Text fontSize={'12px'}>ALL of the following match</Text>
                      <Flex gap={2} mt={"5px"}>
                        <Flex flexBasis="30%">
                          <Select
                            name="conditionField"
                            fontSize="sm"
                            value={createRuleFormik.values.conditionField}
                            onChange={createRuleFormik.handleChange}
                          >
                            <option value="spent">Spent</option>
                            <option value="cost_per">Cost per result</option>
                            <option value="website_purchase_roas">Website purchase ROAS</option>
                            <option value="cpc">CPC</option>
                            <option value="cpm">CPM</option>
                            <option value="ctr">CTR</option>
                          </Select>
                        </Flex>

                        <Flex flexBasis="30%">
                          <Select
                            name="conditionOperator"
                            fontSize="sm"
                            value={createRuleFormik.values.conditionOperator}
                            onChange={(e) => {
                              createRuleFormik.handleChange(e);
                              if (['IN_RANGE', 'NOT_IN_RANGE'].includes(e.target.value)) {
                                createRuleFormik.setFieldValue('conditionValue', '');
                                createRuleFormik.setFieldValue('conditionValue1', '');
                                createRuleFormik.setFieldValue('conditionValue2', '');

                                createRuleFormik.setFieldTouched('conditionValue', false);
                                createRuleFormik.setFieldTouched('conditionValue1', false);
                                createRuleFormik.setFieldTouched('conditionValue2', false);
                              } else if (['GREATER_THAN', 'LESS_THAN'].includes(e.target.value)) {
                                createRuleFormik.setFieldValue('conditionValue', '');
                                createRuleFormik.setFieldTouched('conditionValue', false);
                              }
                            }}
                          >
                            <option value="GREATER_THAN">is greater than</option>
                            <option value="LESS_THAN">is smaller than</option>
                            <option value="IN_RANGE">is between</option>
                            <option value="NOT_IN_RANGE">is not between</option>
                          </Select>
                        </Flex>

                        <Flex direction="column" flexBasis={['GREATER_THAN', 'LESS_THAN'].includes(createRuleFormik.values.conditionOperator) ? "20%" : "0%"}>
                          {['GREATER_THAN', 'LESS_THAN'].includes(createRuleFormik.values.conditionOperator) && (
                            <Input
                              name="conditionValue"
                              placeholder={currencySymbol}
                              fontSize="sm"
                              value={createRuleFormik.values.conditionValue}
                              onChange={createRuleFormik.handleChange}
                              onBlur={createRuleFormik.handleBlur}
                            />
                          )}
                          {createRuleFormik.touched.conditionValue && createRuleFormik.errors.conditionValue && (
                            <Text color="red.500" fontSize="12px">{createRuleFormik.errors.conditionValue}</Text>
                          )}
                        </Flex>

                        <Flex flexBasis={['IN_RANGE', 'NOT_IN_RANGE'].includes(createRuleFormik.values.conditionOperator) ? "30%" : "0%"}>
                          {['IN_RANGE', 'NOT_IN_RANGE'].includes(createRuleFormik.values.conditionOperator) && (
                            <Flex>
                              <Flex direction="column">
                                <Input
                                  name="conditionValue1"
                                  placeholder=""
                                  fontSize="sm"
                                  value={createRuleFormik.values.conditionValue1}
                                  onChange={createRuleFormik.handleChange}
                                  onBlur={createRuleFormik.handleBlur}
                                />
                                {createRuleFormik.touched.conditionValue1 && createRuleFormik.errors.conditionValue1 && (
                                  <Text color="red.500" fontSize="12px">{createRuleFormik.errors.conditionValue1}</Text>
                                )}
                              </Flex>
                              <Flex direction="column" justifyContent={'center'} >
                                <Text mx={2}>-</Text>
                              </Flex>
                              <Flex direction="column">
                                <Input
                                  name="conditionValue2"
                                  placeholder=""
                                  fontSize="sm"
                                  value={createRuleFormik.values.conditionValue2}
                                  onChange={createRuleFormik.handleChange}
                                  onBlur={createRuleFormik.handleBlur}
                                />
                                {createRuleFormik.touched.conditionValue2 && createRuleFormik.errors.conditionValue2 && (
                                  <Text color="red.500" fontSize="12px">{createRuleFormik.errors.conditionValue2}</Text>
                                )}
                              </Flex>
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>

                    {/* Time range */}
                    <Flex direction="column" gap={1} mt={'5px'}>
                      <Flex alignItems={'center'} gap={1}>
                        <Text fontWeight="bold">Time range</Text>
                        <Tooltip
                          borderRadius={5}
                          label={
                            <Box fontSize="sm" p="5px">
                              <Text>This is the number of days of data you'd like to apply your rule to.</Text>
                            </Box>
                          }
                          fontSize="md"
                        >
                          <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                      </Flex>

                      <Select
                        name="timeRange"
                        fontSize="sm"
                        value={createRuleFormik.values.timeRange}
                        onChange={createRuleFormik.handleChange}
                        onBlur={createRuleFormik.handleBlur}
                      >
                        <option value="MAXIMUM">Maximum</option>
                        <option value="TODAY">Today</option>
                        <option value="LAST_7_DAYS">Last 7 days</option>
                        {/* <option value="last_14_days">Last 14 days</option>
                        <option value="last_30_days">Last 30 days</option> */}
                      </Select>
                      {createRuleFormik.touched.timeRange && createRuleFormik.errors.timeRange && (
                        <Text color="red.500" fontSize="sm">{createRuleFormik.errors.timeRange}</Text>
                      )}
                    </Flex>

                    {/* Schedule */}
                    <Flex direction="column" gap={1}>
                      <Flex alignItems={'center'} gap={1}>
                        <Text fontWeight="bold">Schedule</Text>
                        <Tooltip
                          borderRadius={5}
                          label={
                            <Box fontSize="sm" p="5px">
                              <Text>This will determine how frequently to check the rule. Your rule will be checked in your ad account's time zone.</Text>
                            </Box>
                          }
                          fontSize="md"
                        >
                          <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                      </Flex>
                      <RadioGroup
                        name="schedule"
                        value={createRuleFormik.values.schedule}
                        onChange={(value) => {
                          createRuleFormik.setFieldValue('schedule', value);
                          if (value === 'CUSTOM') {
                            const initialCustomSchedules = daysOfWeek.map(() => ({
                              days: [],
                              timeSlots: [{ startTime: '', endTime: '' }],
                            }));
                            createRuleFormik.setFieldValue('customSchedules', initialCustomSchedules);
                            setCustomSchedules(initialCustomSchedules);
                          } else {
                            // Clear customSchedules 
                            createRuleFormik.setFieldValue('customSchedules', []);
                            setCustomSchedules([]);
                          }
                        }}
                      >
                        <Stack spacing={5} direction='column'>
                          <Flex direction="column">
                            <Flex align="center">
                              <Radio colorScheme='orange' value='SEMI_HOURLY' />
                              <Text fontWeight="bold" ml={2}>Continuously</Text>
                            </Flex>
                            <Text fontSize={'sm'} ml={6}>Rule runs as often as possible (usually every 30-60 minutes).</Text>
                          </Flex>

                          <Flex direction="column">
                            <Flex align="center">
                              <Radio colorScheme='orange' value='DAILY' />
                              <Text fontWeight="bold" ml={2}>Daily </Text>
                            </Flex>
                            <Text fontSize={'sm'} ml={6}>at 12.00 am Kolkata Time</Text>
                          </Flex>

                          <Flex direction="column">
                            <Flex align="center">
                              <Radio colorScheme='orange' value='CUSTOM' />
                              <Text fontWeight="bold" ml={2}>Custom </Text>
                            </Flex>
                            <Text fontSize={'sm'} ml={6}>Adjust the rule schedule to run on specific days and at specific times of day.</Text>
                            {createRuleFormik.values.schedule === 'CUSTOM' && (
                              <Flex direction="column" mt={'12px'} ml={'12px'}>
                                {customSchedules.map((schedule, index) => (
                                  <Flex key={index} direction="column" gap={2}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      <Flex flexBasis="20%">
                                        <Checkbox
                                          isChecked={schedule.days.includes(daysOfWeek[index])}
                                          onChange={() => handleDayChange(index, daysOfWeek[index])}
                                          colorScheme={'orange'}
                                        >
                                          {daysOfWeek[index]}
                                        </Checkbox>
                                      </Flex>
                                      <Flex flexBasis="35%" style={{ width: '200px' }} justify="center">
                                        <ReactSelect
                                          styles={{
                                            control: (base) => ({
                                              ...base,
                                              width: '200px',
                                              fontSize: "small",
                                            }),
                                            option: (base, state) => ({
                                              ...base,
                                              backgroundColor: state.isSelected ? '#E0EDF7' : state.isFocused ? '#F2F2F2' : 'white',
                                              color: 'black',
                                              fontSize: "small"
                                            }),
                                            indicatorSeparator: () => ({
                                              display: 'none',
                                            }),
                                          }}
                                          options={timeOptions}
                                          isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                          value={schedule.timeSlots[0]?.startTime ? timeOptions.find(option => option.value === schedule.timeSlots[0]?.startTime) : null}
                                          onChange={(selectedOption) => handleTimeSlotChange(index, 0, 'startTime', selectedOption.value)}
                                          placeholder="00:00"
                                        />
                                      </Flex>
                                      <Text fontSize="sm">to</Text>
                                      <Flex flexBasis="35%" justifyContent="center">
                                        <ReactSelect
                                          styles={{
                                            control: (base) => ({
                                              ...base,
                                              width: '200px',
                                              fontSize: "small"
                                            }),
                                            option: (base, state) => ({
                                              ...base,
                                              backgroundColor: state.isSelected ? '#E0EDF7' : state.isFocused ? '#F2F2F2' : 'white',
                                              color: 'black',
                                              fontSize: "small"
                                            }),
                                            indicatorSeparator: () => ({
                                              display: 'none',
                                            }),
                                          }}
                                          options={timeOptions}
                                          isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                          value={schedule.timeSlots[0]?.endTime ? timeOptions.find(option => option.value === schedule.timeSlots[0]?.endTime) : null}
                                          onChange={(selectedOption) => handleTimeSlotChange(index, 0, 'endTime', selectedOption.value)}
                                          placeholder="00:00"
                                        />
                                      </Flex>
                                      <Flex flexBasis="10%" justifyContent="center">
                                        <IconButton
                                          icon={<AddIcon />}
                                          onClick={() => handleAddTimeSlot(index)}
                                          aria-label="Add time slot"
                                          colorScheme="orange"
                                        />
                                      </Flex>
                                    </Stack>

                                    {/* Render additional time slots */}
                                    <Flex direction="column" mt={2}>
                                      {schedule.timeSlots.slice(1).map((slot, slotIndex) => (
                                        <Flex key={slotIndex} gap={2} alignItems="center" mb={2}>
                                          <Flex flexBasis="20%">
                                            {/* Keeping the day checkbox empty to align with the first row */}
                                          </Flex>
                                          <Flex flexBasis="35%" justifyContent="center">
                                            <ReactSelect
                                              styles={{
                                                control: (base) => ({
                                                  ...base,
                                                  width: '200px',
                                                  fontSize: "small"
                                                }),
                                                option: (base, state) => ({
                                                  ...base,
                                                  backgroundColor: state.isSelected ? '#E0EDF7' : state.isFocused ? '#F2F2F2' : 'white',
                                                  color: 'black',
                                                  fontSize: "small"
                                                }),
                                                indicatorSeparator: () => ({
                                                  display: 'none',
                                                }),
                                              }}
                                              options={timeOptions}
                                              isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                              value={slot.startTime ? timeOptions.find(option => option.value === slot.startTime) : null}
                                              onChange={(selectedOption) => handleTimeSlotChange(index, slotIndex + 1, 'startTime', selectedOption.value)}
                                              placeholder="00:00"
                                            />
                                          </Flex>
                                          <Text fontSize="sm">to</Text>
                                          <Flex flexBasis="35%" justifyContent="center">
                                            <ReactSelect
                                              styles={{
                                                control: (base) => ({
                                                  ...base,
                                                  width: '200px',
                                                  fontSize: "small"
                                                }),
                                                option: (base, state) => ({
                                                  ...base,
                                                  backgroundColor: state.isSelected ? '#E0EDF7' : state.isFocused ? '#F2F2F2' : 'white',
                                                  color: 'black',
                                                  fontSize: "small"
                                                }),
                                                indicatorSeparator: () => ({
                                                  display: 'none',
                                                }),
                                              }}
                                              options={timeOptions}
                                              isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                              value={slot.endTime ? timeOptions.find(option => option.value === slot.endTime) : null}
                                              onChange={(selectedOption) => handleTimeSlotChange(index, slotIndex + 1, 'endTime', selectedOption.value)}
                                              placeholder="00:00"
                                            />
                                          </Flex>
                                          <Flex flexBasis="10%" justifyContent="center">
                                            <IconButton
                                              icon={<CloseIcon />}
                                              onClick={() => handleRemoveTimeSlot(index, slotIndex + 1)}
                                              aria-label="Remove time slot"
                                              colorScheme="red"
                                            />
                                          </Flex>
                                        </Flex>
                                      ))}
                                    </Flex>
                                  </Flex>
                                ))}
                              </Flex>
                            )}
                          </Flex>
                        </Stack>
                      </RadioGroup>
                      {createRuleFormik.touched.schedule && createRuleFormik.errors.schedule && (
                        <Text color="red.500" fontSize="sm">{createRuleFormik.errors.schedule}</Text>
                      )}
                    </Flex>

                    {/* Notification */}
                    <Flex direction="column" gap={2}>
                      <Flex alignItems={'center'} gap={1}>
                        <Text fontWeight="bold">Notification</Text>
                        <Tooltip
                          borderRadius={5}
                          label={
                            <Box fontSize="sm" p="5px">
                              <Text>This is how you'll be notified that rule conditions have been met.</Text>
                            </Box>
                          }
                          fontSize="md"
                        >
                          <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                      </Flex>
                      <Stack spacing={2}>
                        <Flex gap={2}>
                          <Checkbox
                            colorScheme={"orange"}
                            name="notification"
                            isChecked={createRuleFormik.values.notification}
                            onChange={createRuleFormik.handleChange}
                            isDisabled
                          />
                          <Flex direction="column" mt={'12px'}>
                            <Text fontSize="14px" fontWeight="bold">On Facebook</Text>
                            <Text fontSize="12px">You'll receive a notification when conditions for this rule have been met.</Text>
                          </Flex>
                        </Flex>
                      </Stack>
                    </Flex>

                    {/* Subscriber */}
                    <Flex direction="column" gap={1} mb={2}>
                      <Flex alignItems={'center'} gap={1}>
                        <Text fontWeight="bold">Subscriber</Text>
                        <Tooltip
                          borderRadius={5}
                          label={
                            <Box fontSize="sm" p="5px">
                              <Text>Only people with access to this ad account can receive rule results.</Text>
                            </Box>
                          }
                          fontSize="md"
                        >
                          <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                      </Flex>
                      <Text>{adAccounts?.clientId?.name}</Text>
                    </Flex>
                  </Flex>
                </>

              )}

            </ModalBody>
            <ModalFooter css={{ boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.2)' }} p={'12px'}>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Flex gap={2} align="center">
                  {currentStep === 1 && (
                    <>
                      <Button size={'sm'} onClick={() => {
                        onClose();
                        createRuleFormik.resetForm();
                        setSelectedAds({});
                        setSelectedAdsets({});
                        setCurrentStep(0);
                      }}>
                        Cancel
                      </Button>

                      <Button type="submit" size={'sm'} colorScheme="orange"
                        isDisabled={!createRuleFormik.dirty || !createRuleFormik.isValid || createRuleFormik.isSubmitting}
                        onClick={() => {
                          onClose();
                          setCurrentStep(0);
                        }}>
                        Create
                      </Button>
                    </>
                  )}
                </Flex>

                <Flex gap={2}>
                  {currentStep > 0 && (
                    <Button size={'sm'} onClick={handleBack}>Back</Button>
                  )}

                  {currentStep === 0 && (
                    <Button size={'sm'} colorScheme="orange" onClick={handleNext}>Next</Button>
                  )}
                </Flex>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
  );
}

export default CreateRuleForm;

