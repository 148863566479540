// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-picker-input{
    font-size: 14px;
    padding: 4px 8px;
    border: 1px solid #c8c8c8;
    border-radius: 5px;
    width: fit-content;
    width: 160px;
}

.dark-mode .rdrStaticRange{
    color: #849095;
}

.rdrDefinedRangesWrapper{
    width: auto;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }`, "",{"version":3,"sources":["webpack://./src/styles/customStyles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB,EAAE,WAAW,EACf,oBAAoB;IAC3C,iBAAiB,EAAE,oBAAoB;EACzC","sourcesContent":[".date-picker-input{\n    font-size: 14px;\n    padding: 4px 8px;\n    border: 1px solid #c8c8c8;\n    border-radius: 5px;\n    width: fit-content;\n    width: 160px;\n}\n\n.dark-mode .rdrStaticRange{\n    color: #849095;\n}\n\n.rdrDefinedRangesWrapper{\n    width: auto;\n}\n\n.prevent-select {\n    -webkit-user-select: none; /* Safari */\n    -ms-user-select: none; /* IE 10 and IE 11 */\n    user-select: none; /* Standard syntax */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
