import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import { useState } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from "react-redux";
import { useCreateAdMutation } from 'store/campaigns/adsApi';
import { selectSelectedAdsetId } from 'store/campaigns/campaignsSlice';
import * as Yup from 'yup';

const CreateSingleAdForm = ({ call_to_action_options, adFormik, adsAccountData, onSuccess, onClose, onSave}) => {
  
  const toast = useToast();
  const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const selectedAdsetId = useSelector(selectSelectedAdsetId);

  const [createAd] = useCreateAdMutation();

  const validationSchema = Yup.object().shape({
      cpa: Yup.number()
          .typeError('must be a number')
          .positive('must be a positive number'),
      creative: Yup.object().shape({
          object_story_spec: Yup.object().shape({
              link_data: Yup.object().shape({
                  // message: Yup.string().required('Primary text is required'),
                  name : Yup.string().required('Headline is required'),
                  // description: Yup.string().required('Description is required'),
                  // link: Yup.string().url('Invalid URL').required('Link URL is required'),
                  image_hash: Yup.string().required('Creative is required'),
                  call_to_action: Yup.object().shape({
                      type: Yup.string().required('Call to action type is required'),
                      value: Yup.object().shape({
                          link: Yup.string().when('type', {
                              is: 'CALL_NOW',
                              then: Yup.string().matches(/tel:\+?[0-9]{10,15}/, 'Phone number must be at least 10 digits')
                          }),
                      }),
                  }),
              }),
          })
      }),
      image_url: Yup.string().url('Invalid URL'),
  });

  const formik = useFormik({
      initialValues: {
          name: adFormik.values.name || '',
          hash: '',
          cpa:  '',
          creative: {
              name: '',
              object_story_spec: {
                  link_data: {
                      call_to_action: {
                          type: "SHOP_NOW",
                          value: {},
                      },
                      message: '',
                      name: '',
                      description: '',
                      link: '',
                      image_hash: '',
                      video_id: ''
                  },
                  page_id: adFormik.values?.accounts[0]?.page_id,
                  instagram_actor_id: adFormik.values?.accounts[0]?.instagram_actor_id,
              },
          },
          image_url: '',
          picture: '',
      },
      validationSchema,
      enableReinitialize: true,
      onSubmit: (values, { setSubmitting }) => {

          const formatPhoneNumber = (phoneNumber) => {

              if (!phoneNumber.startsWith('tel:+')) {
                  return `tel:+${phoneNumber}`;
              }
              return phoneNumber;
          };

          const adcreativePayload = mediaType === 'video' ? {
            adcreative: {
                name: values.creative.object_story_spec.link_data.name,
                hash: values.hash,
                object_story_spec: {
                    video_data: {
                        image_url: values?.image_url,
                        ...(values.creative.object_story_spec.link_data.message?.trim() && { 
                            message: values.creative.object_story_spec.link_data.message 
                          }),
                        video_id: values.creative.object_story_spec.link_data.image_hash,
                         // title: values.creative.object_story_spec.video_data.title,
                        link_description: values.creative.object_story_spec.link_data.description,
                        call_to_action: {
                            type: values.creative.object_story_spec.link_data.call_to_action.type,
                            value: {
                                link: values.creative.object_story_spec.link_data.call_to_action.type === "CALL_NOW"
                                    ? formatPhoneNumber(values.creative.object_story_spec.link_data.call_to_action.value.link)
                                    : values.creative.object_story_spec.link_data.link,
                            },
                        },
                    },
                    page_id: values.creative.object_story_spec.page_id,
                    instagram_actor_id: values.creative.object_story_spec.instagram_actor_id,
                },
                degrees_of_freedom_spec: {
                    creative_features_spec: {
                        standard_enhancements: {
                            enroll_status: "OPT_OUT",
                        }
                    }
                },
            },
        } : {
            adcreative: {
                name: values.creative.object_story_spec.link_data.name,
                hash: values.hash,
                image_url: values?.image_url,
                object_story_spec: {
                    link_data: {
                        ...(values.creative.object_story_spec.link_data.message?.trim() && { 
                            message: values.creative.object_story_spec.link_data.message 
                        }),
                        description: values.creative.object_story_spec.link_data.description,
                        image_hash: values.creative.object_story_spec.link_data.image_hash,
                        //   name: values.creative.object_story_spec.link_data.name,
                        ...(values.creative.object_story_spec.link_data.link && {
                            link: values.creative.object_story_spec.link_data.link,
                        }),
                        call_to_action: {
                            type: values.creative.object_story_spec.link_data.call_to_action.type,
                            ...(values.creative.object_story_spec.link_data.call_to_action.type === 'CALL_NOW' && {
                                value: {
                                    link: formatPhoneNumber(values.creative.object_story_spec.link_data.call_to_action.value.link),
                                }
                            }),
                        },
                    },
                    page_id: values.creative.object_story_spec.page_id,
                    instagram_actor_id: values.creative.object_story_spec.instagram_actor_id,
                },
                degrees_of_freedom_spec: {
                    creative_features_spec: {
                        standard_enhancements: {
                            enroll_status: "OPT_OUT",
                        },
                    },
                },
            },
        };
        
        const payload = {
            ...adcreativePayload,
            name: values.name,
            cpa: Number(values.cpa),
            adset_id: selectedAdsetId?.adset_id,
            adsAccount: {
                id: adsAccountData?.id,
                adsAccountId: adsAccountData?.account_id,
                adsAccountName: adsAccountData?.name,
                businessName: adsAccountData?.business?.name,
                businessId: adsAccountData?.business?.id,
            },
        };
        

          createAd({ payload })
              .unwrap()
              .then(response => {
                  onSuccess();
                  toast({
                      position: "top-right",
                      title: "Ad Created",
                      status: "success",
                      duration: 4000,
                      isClosable: true,
                  });
              })
              .catch(error => {
                  toast({
                      position: "top-right",
                      title: "Error creating Ad",
                      status: "error",
                      duration: 4000,
                      isClosable: true,
                  });
              })
              .finally(() => {
                  setSubmitting(false);
                  onClose();
              });
      },
  });

  const handleMediaTypeChange = (type) => {
      setMediaType(type)
      setIsDriveModalOpen(true);
  };

  const handleMediaSelect = (file) => {
      if (file && file.length > 0) {
          const selectedFile = file[0];

          formik.setFieldValue('hash', selectedFile?.hash);
          formik.setFieldValue('image_url', selectedFile?.url ? selectedFile?.url : selectedFile?.picture);
          formik.setFieldValue('creative.object_story_spec.link_data.image_hash', selectedFile?.creativeId);
      }
      setIsDriveModalOpen(false);
  };


  return (
    <Box direction={'column'} gap={4} width={'100%'}>
        <form onSubmit={formik.handleSubmit}>
            
                <Flex direction={'column'} gap={'20px'} borderRadius="md" bg="gray.50" p={4} mb={4}>

                    <Flex direction="column">
                      <Select
                        placeholder={adFormik?.values?.format === 'CAROUSEL' ? "Add cards" : "Add media"}
                        width={'150px'}
                        name="mediaOption"
                        borderRadius={'md'}
                        value={mediaType || ""}
                        onChange={(e) => {
                          handleMediaTypeChange(e.target.value);
                        }}
                        size="sm"
                      >
                        <option value="image">{adFormik?.values?.format === 'CAROUSEL' ? "Add image cards" : "Add image"}</option>
                        <option value="video">{adFormik?.values?.format === 'CAROUSEL' ? "Add video cards" : "Add video"}</option>
                      </Select>
                    </Flex>
                    
                    {formik.touched.creative?.object_story_spec?.link_data?.image_hash && formik.errors.creative?.object_story_spec?.link_data?.image_hash && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.image_hash}</Text>
                    )}

                    {formik.values.image_url && (
                      <Box role="group" position="relative" w="80px" h={'80px'} _hover={{ cursor: "pointer", transition: "all 0.3s ease" }}>
                        <Image
                          src={formik.values.image_url}
                          alt={formik.values.name}
                          objectFit="cover"
                          width="100%"
                          height="100%"
                          borderRadius="3px"
                        />            

                        <Icon
                          top={'-5px'}
                          right={'-5px'}
                          position="absolute"
                          color={'red.500'}
                          _hover={{ color: 'gray.400' }}
                          as={IoMdCloseCircle}
                          cursor="pointer"
                          boxSize={4}
                          onClick={() => 
                            formik.setFieldValue('image_url', '')
                          }
                          bg={'white'}
                          borderRadius={'50%'}
                        />
                      </Box>
                    )}

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Primary Text</Text>
                        <Textarea
                            name="creative.object_story_spec.link_data.message"
                            placeholder="Message"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.object_story_spec.link_data.message}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.object_story_spec?.link_data?.message && formik.errors.creative?.object_story_spec?.link_data?.message && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.message}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Headline</Text>
                        <Input
                            name="creative.object_story_spec.link_data.name"
                            placeholder="Enter a headline"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.object_story_spec.link_data.name}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.object_story_spec?.link_data?.name && formik.errors.creative?.object_story_spec?.link_data?.name && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.name}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Description</Text>
                        <Textarea
                            name="creative.object_story_spec.link_data.description"
                            placeholder="Include additional details"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.object_story_spec.link_data.description}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.object_story_spec?.link_data?.description && formik.errors.creative?.object_story_spec?.link_data?.description && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.description}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Call to Action</Text>

                        {formik.values.creative.object_story_spec.link_data.call_to_action.type === 'CALL_NOW' ? (
                            <Select
                                name="creative.object_story_spec.link_data.call_to_action.type"
                                bg="#fff"
                                size="sm"
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                                isDisabled
                            >
                                <option value="CALL_NOW">Call Now</option>
                            </Select>
                        ) : (
                            <Select
                                name="creative.object_story_spec.link_data.call_to_action.type"
                                bg="#fff"
                                size="sm"
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                            >
                                {call_to_action_options?.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Select>
                        )}

                        {formik.touched.creative?.object_story_spec?.link_data?.call_to_action?.type &&
                            formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.type && (
                                <Text fontSize="12px" color="red.500">
                                    {formik.errors.creative.object_story_spec.link_data.call_to_action.type}
                                </Text>
                            )}
                    </Flex>

                  {selectedAdsetId?.destination_type === 'CALLS' ? (
                      <Flex direction="column" gap={1}>
                          <Text fontWeight="bold">Phone Number</Text>
                          <PhoneInput
                              name="creative.object_story_spec.link_data.call_to_action.value.link"
                              value={formik.values.creative.object_story_spec.link_data.call_to_action.value.link}
                              onChange={(value) => formik.setFieldValue('creative.object_story_spec.link_data.call_to_action.value.link', value)}
                              country={'in'}
                          />
                          {formik.touched.creative?.object_story_spec?.link_data?.call_to_action?.value?.link && formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.value?.link && (
                              <Text fontSize="12px" color="red.500">{formik.errors.creative.object_story_spec.link_data.call_to_action.value.link}</Text>
                          )}
                      </Flex>
                  ):null}

                  {selectedAdsetId?.destination_type === 'WEBSITE' ? (
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Link URL</Text>
                        <Input
                            name="creative.object_story_spec.link_data.link"
                            placeholder="Enter a URL"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.object_story_spec.link_data.link}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.object_story_spec?.link_data?.link && formik.errors.creative?.object_story_spec?.link_data?.link && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.link}</Text>
                        )}
                    </Flex>
                  ):null}
                </Flex>

                <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">CPA</Text>
                        <Input
                            name="cpa"
                            placeholder="Enter CPA"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.cpa}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.cpa && formik.errors.cpa && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.cpa}</Text>
                        )}
                    </Flex>
                </Flex>

                <Flex mb={4}>
                    <Button
                        size="sm"
                        colorScheme="orange"
                        isLoading={formik.isSubmitting}
                        type="submit"
                        onClick={onSave}
                    >
                        Save
                    </Button>
                </Flex>
        </form>

          {/* Drive Modal */}
          <DriveModal
              isDriveModalOpen={isDriveModalOpen}
              selectedAdAccounts={[{
                      id: adsAccountData?.id,
                      adsAccountId: adsAccountData?.account_id,
                      adsAccountName: adsAccountData?.name,
                      businessName: adsAccountData?.business?.name,
                      businessId: adsAccountData?.business?.id
              }]}
              onClose={() => setIsDriveModalOpen(false)}
              onSubmit={handleMediaSelect}
              uploadPurpose="create"
              mediaType={mediaType}
          />
    </Box>
  );
};

export default CreateSingleAdForm;
