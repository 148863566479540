import { Box, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import ReactSelect from 'react-select';

const selectStyles = {
    control: (provided) => ({
        ...provided,
        width: '100%',
        minWidth: 350,
        maxWidth: 350,
        margin: 0,
        height: 32,
        minHeight: 32,
        fontSize: "12px",
        boxShadow: 'none',
        borderColor: "#aaa",
        "&:hover": { borderColor: "#aaa", boxShadow: 'none' },
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: 32,
        minHeight: 32,
    }),
    menu: (provided) => ({
        ...provided,
        marginLeft: 0,
        maxWidth: '607px',
        boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
        boxShadow: "0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1)",
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: 200,
        overflowY: 'auto',
        fontSize: "12px"
    }),
    option: (provided, state) => {
        return {
            ...provided,
            backgroundColor: state.isSelected ? "#ea7869" : state.isFocused ? "#fce4e2" : "white",
            color: state.isSelected ? "white" : "black",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            "&:active": { backgroundColor: "#ea7869" },
        };
    },
    singleValue: (provided) => ({
        ...provided,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis', // Truncate the selected value
    }),
};

function SearchAdaccountAdspresso({ accountOptions, onAccountChange, onSearchInputChange, onSearchInputTouched, onInitialisation, AdsAccountIdFromParams, isAdAccountLoading }) {
    const [searchAdAccountValue, setSearchAdAccountValue] = useState('');
    const [selectedAccountValue, setSelectedAccountValue] = useState(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false); 

    // Filter accounts based on searchAdAccountValue
    const filteredAccounts = accountOptions
        ?.filter(account =>
            account?.name?.toLowerCase().includes(searchAdAccountValue.toLowerCase()) ||
            account?.account_id?.includes(searchAdAccountValue)
        )
        .map(account => ({
            value: account?.account_id,
            // label: (
            //     <div>
            //         <div>{account?.name?.length > 38 ? account?.name?.slice(0, 38)+ '...' : account?.name}</div>
            //         <div style={{ fontSize: 'smaller' }}>{account?.account_id}</div>
            //     </div>
            // ),
            label: account?.name?.length > 38 ? account?.name?.slice(0, 38) + '...' : account?.name,  // Use plain string
        })) || [];


        // useEffect(() => {
        //     if (filteredAccounts && filteredAccounts.length > 0) {
        //         // If no account is selected, check for saved account in localStorage or URL
        //         if (!selectedAccountValue) {
        //             // First, check if there's a saved account ID in localStorage
        //             const storedAccountId = localStorage.getItem('selectedAdsAccountId');
        //             const accountFromParams = AdsAccountIdFromParams;  // AdsAccountIdFromParams is from the URL
        
        //             let initialAccountId = storedAccountId || accountFromParams; // Give preference to URL param first
        //             // If an account ID is found (either from localStorage or URL), set it as the selected account
        //             if (initialAccountId) {
        //                 const account = filteredAccounts.find(acc => acc.value === initialAccountId);
        //                 if (account) {
        //                     setSelectedAccountValue(account);
        //                     onInitialisation(account.value); // Send the initial account value
        //                 }
        //             } else {
        //                 // // Default to the first filtered account if no saved account found
        //                 // const defaultAccount = filteredAccounts[0];
        //                 // setSelectedAccountValue(defaultAccount);
        //                 // onInitialisation(defaultAccount?.value);
        //             }
        //         }
        //     } else {
        //         setSelectedAccountValue(null);
        //         onInitialisation(null);
        //     }
        // }, [filteredAccounts, selectedAccountValue, AdsAccountIdFromParams, onInitialisation]); 
                  

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchAdAccountValue?.length > 2) {
                onSearchInputChange(searchAdAccountValue);
                onSearchInputTouched(true);
            } else if (searchAdAccountValue?.length === 0) {
                onSearchInputChange('');
            }
        }, 400);

        return () => clearTimeout(delayDebounceFn);
    }, [searchAdAccountValue, onSearchInputChange]);

    const handleSelectChange = (value) => {
        setSelectedAccountValue(value);
        onAccountChange(value);

        // Save the selected ad account id to localStorage
        if (value) {
            localStorage.setItem('selectedAdsAccountId', value.value);
        }
    };

    return (
        <Box>
            <ReactSelect
                className="react-select"
                options={filteredAccounts}
                styles={selectStyles}
                inputValue={searchAdAccountValue}
                onInputChange={(inputValue) => setSearchAdAccountValue(inputValue)}
                onChange={handleSelectChange}
                autoFocus
                tabSelectsValue={false}
                backspaceRemovesValue={false}
                isClearable={false}
                closeMenuOnSelect={true}
                menuIsOpen={menuIsOpen}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => setMenuIsOpen(false)}
                components={{
                    DropdownIndicator: () => (
                        <Box mr={2}>
                            {isAdAccountLoading ? (
                                <Spinner size="sm" />
                            ) : (
                                <FiSearch />
                            )}
                        </Box>
                    ),
                    IndicatorSeparator: () => null,
                }}
                placeholder={isAdAccountLoading ? "Loading..." : "Search for an ad account..."}
                value={menuIsOpen ? "Search for an ad account..." : selectedAccountValue}
            />
        </Box>
    );
}

export default SearchAdaccountAdspresso;
