import {
    Box, Flex,
    Select,
    Text,
    Spinner,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetOwnedPagesQuery, useGetClientPagesQuery, useGetPagesQuery } from 'store/BmAccounts/bmAccountApi';
import { selectSelectedBmAccounts } from 'store/BmAccounts/bmAccountSlice';
import { useGetFacebookPagesQuery } from 'store/facebookServices/facebookServicesApi';

function FaceBookPageSelector({ adsAccount, onSelectedPage, selectedPageId, adData }) {

    const { account_id, name } = adsAccount || {};
    const [selectedPage, setselectedPage] = useState(selectedPageId || '');
    const userInfo = useSelector((state) => state.auth?.userInfo);
    const bmAccount = useSelector(selectSelectedBmAccounts);
    // const { data: facebookPages, isLoading } = useGetFacebookPagesQuery(account_id);

    // const { data: systemTokenOwnedPages = [], isLoading:isOwnedLoading } = useGetOwnedPagesQuery(
    //     { search: '', businessId: bmAccount?.businessId, system_token: true },
    //     { skip: !bmAccount?.businessId }
    //   );
    
    //   const { data: systemTokenClientPages = [], isLoading:isClientLoading } = useGetClientPagesQuery(
    //     { search: '', businessId: bmAccount?.businessId, system_token: true },
    //     { skip: !bmAccount?.businessId}
    //   );

    //   const facebookPages = [
    //     ...systemTokenOwnedPages,
    //     ...systemTokenClientPages.filter(page => !systemTokenOwnedPages.some(ownedPage => ownedPage.id === page.id))  // Avoid duplicates
    //   ];

    const { data: facebookPages = [], isLoading:isFbPageLoading } = useGetPagesQuery(
        { search: '',  },
    );
    // console.log({ facebookPages });


    useEffect(() => {
        setselectedPage(selectedPageId || '');
    }, [selectedPageId]);


    const handleEventPageChange = (event) => {
        const pageId = event.target.value;
        setselectedPage(pageId);
        onSelectedPage(pageId, account_id);
    };

    return (
        <Box>
            <Flex gap={'20px'}>
                {/* <Box flex={1}>
                    <Text fontWeight={'bold'} fontSize={'12px'}>{name}</Text>
                    <Text fontSize={'12px'}>{account_id}</Text>
                </Box> */}

                <Box flex={1}>
                    {isFbPageLoading ? (
                        <Spinner size="sm" />
                    ) : (
                        <Select
                            placeholder="Facebook page"
                            value={selectedPage}
                            onChange={handleEventPageChange}
                            borderRadius={'md'}
                            size={'sm'}
                            isDisabled={userInfo?.role !== 'client'}
                        >
                            {facebookPages?.map((page) => (
                                <option key={page?.id} value={page?.id}>
                                    {page?.name}
                                </option>
                            ))}
                        </Select>
                    )}
                </Box>
            </Flex>
        </Box>
    )
}

export default FaceBookPageSelector