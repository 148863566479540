import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, CheckboxGroup, Divider, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { TbAddressBook } from "react-icons/tb";
import { useSelector } from 'react-redux';
import { useAssignPixelsMutation, useGetClientPixelsQuery, useGetOwnedPixelsQuery, useGetPixelsBySharedAccountsQuery } from 'store/BmAccounts/bmAccountApi';
import { selectSelectedAdAccounts, selectSelectedBmAccounts } from 'store/BmAccounts/bmAccountSlice';
import SearchInput from './SearchInput';

function Pixels() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bmAccount = useSelector(selectSelectedBmAccounts);
  const selectedAdAccounts = useSelector(selectSelectedAdAccounts);
  const [searchValue, setSearchValue] = useState('');
  const [selectedAdAccountsData, setSelectedAdAccountsData] = useState([]);
  const [selectedPixelId, setSelectedPixelId] = useState(null);

  const boxColor = useColorModeValue('white', 'transparent');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.600');

  // owned pixels (normal and system token)
  const { data: normalOwnedPixels = [], isFetching: isOwnedFetching, isLoading: isOwnedLoading } = useGetOwnedPixelsQuery(
    { search: searchValue, businessId: bmAccount?.businessId },
    { skip: !bmAccount?.businessId }
  );

  // client pixels (normal and system token)
  const { data: normalClientPixels = [], isFetching: isClientFetching, isLoading: isClientLoading } = useGetClientPixelsQuery(
    { search: searchValue, businessId: bmAccount?.businessId },
    { skip: !bmAccount?.businessId }
  );

  const { data: shared_accounts = [], refetch: refetchSharedAccounts, isLoading: sharedAccountsLoading } = useGetPixelsBySharedAccountsQuery(
    { 
      businessId: bmAccount?.businessId,
      pixelId: selectedPixelId 
    },
    { 
      skip: !bmAccount?.businessId || !selectedPixelId || !isOpen
    }
  );  

  // Combine owned and client pixels, avoiding duplicates
  const combinedPixels = [
    ...normalOwnedPixels,
    ...normalClientPixels.filter(pixel => !normalOwnedPixels.some(ownedPixel => ownedPixel.id === pixel.id))  // Avoid duplicates
  ];

  const [assignPixels] = useAssignPixelsMutation();
  
  const handleAssignPixels = (pixelId) => {

    const payload = {
      account_ids:selectedAdAccountsData.map(account => account.account_id),
      business_id:bmAccount?.businessId
    }

    assignPixels({ pixelId, payload })
      .unwrap()
      .then((response) => {
        refetchSharedAccounts();
        toast({
          position: 'top-right',
          title: 'Assigned pixel successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        onClose(); 
      })
      .catch((error) => {
        toast({
          position: 'top-right',
          title: 'Error in assigning pixel',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };
  
  const handlePixelAssignClick = (pixelId) => {
    setSelectedAdAccountsData([]);
    onOpen();
    setSelectedPixelId(pixelId);
  };

  if (!bmAccount?.businessId) {
    return <Flex justifyContent="center" alignItems="center" h={'50vh'} width={'100%'}>
             <Text fontSize={'14px'}>No Data available</Text>
           </Flex>
  } 

  return (
    <Flex direction={'column'}>
      <Box display={'flex'} p={4}>
        <Flex alignItems={'center'} gap={2} flex={0.4}>
          <Text fontSize={'lg'} fontWeight={'bold'}>Pixels</Text>
          <Tooltip
            borderRadius={5}
            placement={'right-start'}
            label={
              <Box fontSize="12px" p="5px">
                <Text>These are the pixels connected to this business portfolio.</Text>
              </Box>
            }
            fontSize="md"
          >
            <InfoOutlineIcon pointerEvents="all" />
          </Tooltip>
        </Flex>
        <Flex flex={0.6}>
          <SearchInput
            data={combinedPixels}
            isFetching={isOwnedFetching || isClientFetching}
            searchEnabled={true}
            onSearchInputChange={setSearchValue}
            searchTerm={searchValue}
          />
        </Flex>
      </Box>

      <Flex justifyContent={'center'}>
        {isOwnedLoading || isClientLoading  ? (
          <Flex justifyContent="center" alignItems="center" h={'50vh'} width={'100%'}>
            <Spinner size="md" />
          </Flex>
        ) : combinedPixels?.length === 0 ? (
          <Flex justifyContent="center" alignItems="center" h={'50vh'} width={'100%'}>
            <Text fontSize={'14px'}>No pixels available</Text>
          </Flex>
        ) : (
          <Box h={'62vh'} overflowY="auto" width={'100%'}>
            <Table variant="unstyled">
              <Thead position={'sticky'} top={'-1px'} zIndex={1} bg={boxColor}>
                <Tr borderTop="1px solid" borderColor={'gray.100'} boxShadow="md">
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {combinedPixels?.map((pixel) => (
                  <Tr
                    key={pixel.id}
                    borderTop={'1px solid'}
                    borderColor={'gray.100'}
                    _hover={{
                      bg: hoverBgColor,
                    }}
                  >
                    <Td fontSize={'14px'}>{pixel?.id}</Td>
                    <Td fontSize={'14px'}>{pixel?.name}</Td>
                    <Td fontSize={'14px'}>
                        <Button
                          bg={'none'}
                          border={'1px'}
                          borderColor={'gray.100'}
                          borderRadius={'md'}
                          size="sm"
                          onClick={() => handlePixelAssignClick(pixel?.id)}
                        >
                          <AiOutlineUserAdd />
                          &nbsp;Assign assets
                        </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Flex>

       {/* Modal for Assign Pixels */}
       <Modal isOpen={isOpen} onClose={onClose} isCentered size={'2xl'} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'16px'} pb={0}>Add assets</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
             {/* Ad Account Checkboxes */}
            <Box>
              <Text fontSize="12px" fontWeight="bold" mb={2} color="gray">Ad Accounts</Text>
              <CheckboxGroup
                colorScheme="orange"
                value={selectedAdAccountsData.map(account => account?.account_id)}
                onChange={(newValue) => {
                  const selectedAccounts = selectedAdAccounts.filter(account => newValue.includes(account?.account_id));
                  setSelectedAdAccountsData(selectedAccounts);
                }}
              >
                <Box maxH="250px" overflowY="auto">
                  <Flex direction="column" gap={2}>
                  {selectedAdAccounts.length > 0 ? (
                    selectedAdAccounts.map(account => (
                      <Checkbox
                        pl={2}
                        key={account.account_id}
                        value={account.account_id}
                        isChecked={selectedAdAccountsData.some(selected => selected?.account_id === account?.account_id)}
                      >
                        {account.name}
                      </Checkbox>
                    ))
                  ) : (
                    <Text fontSize="14px" color="gray.800">
                      No Ad accounts are assigned yet.
                    </Text>
                  )}
                  </Flex>
                </Box>
              </CheckboxGroup>
            </Box>
            <Divider mt={4} />

            {/* Shared Accounts */}
            {sharedAccountsLoading ? (
              <Flex justifyContent="center" alignItems="center" height="100px">
                <Spinner size="md" />
              </Flex>
            ) : shared_accounts.length > 0 ? (
              <Box mt={4}>
                <Text fontSize="14px" fontWeight="bold" mb={2}>Connected assets</Text>
                <Text fontSize="12px" fontWeight="bold" mb={2} color="gray">Ad Accounts</Text>
                <Box maxH="250px" overflowY="auto">
                  <Flex direction="column">
                    {shared_accounts.map(sharedAccount => (
                      <Box
                        key={sharedAccount.account_id}
                        p={2}
                        _hover={{ bg: 'gray.50', cursor: 'pointer' }}
                      >
                        <Flex alignItems="center">
                          <TbAddressBook style={{ marginRight: '8px' }} />
                          <Text fontSize="14px">{sharedAccount.name}</Text>
                        </Flex>
                      </Box>
                    ))}
                  </Flex>
                </Box>
              </Box>
            ) : 
            <Box mt={4}>
              <Text fontSize="14px" fontWeight="bold" mb={2}>Connected assets</Text>
              {/* <Text fontSize="12px" fontWeight="bold" mb={2} color="gray">Ad Accounts</Text> */}
              <Box maxH="250px" overflowY="auto"> 
                  <Text fontSize={'14px'}>No assets connected yet</Text>
              </Box>
            </Box>
          }
          </ModalBody>

          <ModalFooter gap={2}>
            <Button size={'sm'} onClick={() => {
              onClose();
            }}>
              Cancel
            </Button>
            <Button
              colorScheme="orange"
              size={'sm'}
              onClick={() => handleAssignPixels(selectedPixelId)}
            >
              Assign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Flex>
  );
}

export default Pixels;

