import { Avatar, Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Spinner, Stack, Text, Tooltip, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import InstagramAccounts from 'components/bmControl/BmInstagramAccounts';
import SidebarWithHeader from 'components/SidebarWithHeader';
import { useEffect, useState } from 'react';
import { FaFacebook } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetUserNameQuery, useLogoutRelationMutation } from 'store/BmAccounts/bmAccountApi';
import { selectFbData, selectSelectedBmAccounts, setRefetchFbData, setSelectedBmAccounts } from 'store/BmAccounts/bmAccountSlice';
import AdAccounts from '../components/bmControl/BmAdAccounts';
import Pages from '../components/bmControl/BmPages';
import Pixels from '../components/bmControl/BmPixels';

const NavItem = ({ active, children, onClick, ...rest }) => {
  const hoverBackground = useColorModeValue('gray.100', 'gray.800');
  const hoverIconColor = useColorModeValue('gray.800', 'gray.50');

  return (
    <Box
      onClick={onClick}
      display="flex"
      alignItems="center"
      p={2}
      borderRadius="md"
      role="group"
      cursor="pointer"
      bg={active ? 'brand.200' : ''}
      color={active ? 'white' : hoverIconColor}
      _hover={{ bg: !active ? hoverBackground : 'brand.300' }}
      {...rest}
    >
      {children}
    </Box>
  );
};

function BmManager() {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { navItem } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlToken = params.get('token');
  const bmAccount = useSelector(selectSelectedBmAccounts);
  const fBData = useSelector(selectFbData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedNav, setSelectedNav] = useState(navItem || 'ad_accounts');
  const token = useSelector((state) => state.auth.userInfo?.token);

  const backgroundColor = useColorModeValue('gray.50', 'gray.800');
  const buttonBgColor = useColorModeValue('white', 'transparent');
  const boxColor = useColorModeValue('white', 'gray.700');

  const { data: userData } = useGetUserNameQuery(
    {
      pollingInterval: 50000,
      skipPollingIfUnfocused: true,
    },
    {
      businessId: bmAccount?.businessId ?? '',
      skip: !bmAccount?.businessId || fBData?.status === 'deactive',
    }
  );

  const [logoutBmRelation, { isLoading: isLoggingOut }] = useLogoutRelationMutation();


  useEffect(() => {
    if (bmAccount?.businessId && selectedNav) {
      navigate(`/bm-manager/${selectedNav}?business_id=${bmAccount?.businessId}`, { replace: true });
    }
  }, [bmAccount?.businessId, selectedNav, navigate]);

  const handleLogoutRelation = () => {
    logoutBmRelation({ existing_client_business_id: bmAccount?.businessId })
      .unwrap()
      .then((response) => {
        dispatch(setRefetchFbData(true))
        dispatch(setSelectedBmAccounts(null));

        localStorage.removeItem('fBData');
        // localStorage.removeItem('businessAccountData');

        toast({
          position: 'top-right',
          title: 'Logged out successfully ',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        navigate('/bm-manager', { replace: true });
        onClose();
      })
      .catch((error) => {
        toast({
          position: 'top-right',
          title: 'Error in logout FB',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const handleOpenFbBmModal = () => {
    if (token) {
      const fbRedirectUrl = `${process.env.REACT_APP_API_URL}facebook?state=${token}`;
      window.location.href = fbRedirectUrl;
    } else {
      toast({
        title: 'Authentication Error',
        description: 'Please log in to connect to Facebook.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // const renderRightPanel = () => {
  //   switch (selectedNav) {
  //     case 'ad_accounts':
  //       return <AdAccounts />;
  //     case 'pages':
  //       return <Pages />;
  //     case 'pixels':
  //       return <Pixels />;
  //     case 'instagram_accounts':
  //       return <InstagramAccounts />;
  //     default:
  //       return <AdAccounts />;
  //   }
  // };

  if (urlToken && fBData?.status !== 'active') {
    return (
      <SidebarWithHeader>
        <Flex p={4}>
          <Spinner size="md" />
        </Flex>
      </SidebarWithHeader>
    );
  }

  return (
    <SidebarWithHeader>
      <Box>
        <Flex direction={'column'} gap={2} flex={0.2}>
          <Box p={4} bg={ fBData?.status === 'active' ? backgroundColor : buttonBgColor }>
            {fBData?.status === 'active' ? (
              <Flex justifyContent={'space-between'} alignItems="center">
                <Flex gap={4}>
                  {/* Display the details of the selected business account */}
                  {bmAccount?.businessId && (
                    <>
                    <Flex gap={4} align="center">
                      {bmAccount?.businessId && (
                          <Flex gap={2} align="center">
                              <Box
                                width="50px"
                                height="50px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="5px"
                                backgroundColor={'gray.400'}
                                color="white"
                                fontWeight="bold"
                                fontSize="20px"
                              >
                                {bmAccount?.businessName?.charAt(0).toUpperCase()}
                              </Box>
                            <Box>
                              <Text fontWeight={'bold'}>{bmAccount?.businessName}</Text>
                              <Text fontSize={'12px'}>Business portfolio ID: {bmAccount?.businessId}</Text>
                            </Box>
                          </Flex>
                      )}
                    </Flex>
                    </>
                  )}
                </Flex>
                
                <Flex>
                  <Stack direction='row' spacing={4}>
                    <Tooltip
                      placement='bottom-start'
                      borderRadius={5}
                      label={
                        <Box fontSize="sm" p="5px">
                          <Text fontWeight={'bold'}>You're logged in as</Text>
                          <Text>{userData?.name}</Text>
                        </Box>
                      }
                      fontSize="md"
                    >
                      <Avatar boxSize={8} onClick={onOpen}>
                        <FaFacebook size='0.8em' color="blue" style={{ position: 'absolute', padding:'1px', backgroundColor:'#fff', borderRadius:'5px', bottom: -2, right: -2 }} />
                      </Avatar>
                    </Tooltip>
                  </Stack>
                </Flex>
              </Flex>
            ) : (
              <Button
                bg="rgb(25,119,242)"
                color="white"
                _hover={{
                  bg: "rgb(24, 102, 192)", 
                }}
                onClick={handleOpenFbBmModal}
              >
                <FaFacebook size={18} />
                &nbsp;Connect with Facebook
              </Button>
            )}
          </Box>
        </Flex>

        {/* {fBData?.status === 'active' ? (
          <Flex direction="column" bg={backgroundColor}> */}
            <>
              <Flex height={'74vh'}>
                {/* Left Navigation Panel */}
                {/* <Flex direction={'column'} gap={2} p={4} flex={'20%'}>
                  <NavItem
                    onClick={() => setSelectedNav('ad_accounts')}
                    active={selectedNav === 'ad_accounts'}
                    fontSize={'14px'}
                  >
                    Ad accounts
                  </NavItem>
                  <NavItem
                    onClick={() => setSelectedNav('pages')}
                    active={selectedNav === 'pages'}
                    fontSize={'14px'}
                  >
                    Pages
                  </NavItem>
                  <NavItem
                    onClick={() => setSelectedNav('pixels')}
                    active={selectedNav === 'pixels'}
                    fontSize={'14px'}
                  >
                    Pixels
                  </NavItem>
                  <NavItem
                    onClick={() => setSelectedNav('instagram_accounts')}
                    active={selectedNav === 'instagram_accounts'}
                    fontSize={'14px'}
                  >
                    Instagram accounts
                  </NavItem>
                </Flex> */}

                {/* Right Panel */}
                {/* <Box flex={'78%'} bg={boxColor} h={'95%'} borderRadius={'sm'} boxShadow={'sm'}>
                  {renderRightPanel()}
                </Box> */}

                <Box flex={'2%'}></Box>
              </Flex>
            </>
          {/* </Flex>
        ) : (
          <Flex p={4}>
            <Text>
              You are not logged into Facebook BM yet. Please log in to continue.
            </Text>
          </Flex>
        )} */}
      </Box>

      {/* Drawer Modal for Logout */}
      <Drawer isOpen={isOpen} onClose={onClose} placement="right">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Current profile</DrawerHeader>
          <DrawerBody>
            <Flex alignItems={'center'} gap={2}>
              <Avatar boxSize={8}>
                <FaFacebook size='0.8em' color="blue" style={{ position: 'absolute', padding:'1px', backgroundColor:'#fff', borderRadius:'5px', bottom: -2, right: -2 }} />
              </Avatar>
              <Text>{userData?.name}</Text>
            </Flex>
          </DrawerBody>
          <DrawerFooter>
            <Button 
              bg='none'
              border={'1px'}
              borderColor={'gray.100'}   
              _hover={{
                bg: backgroundColor, 
                borderColor: 'gray.400', 
              }}
              onClick={handleLogoutRelation} 
              width={'100%'}
              isLoading={isLoggingOut}
              loadingText="Disconnecting..."
            >
              {isLoggingOut ? "Disconnecting..." : "Disconnect from BM Profile"}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </SidebarWithHeader>
  );
}

export default BmManager;
