import { InfoOutlineIcon } from '@chakra-ui/icons'
import { Box, Flex, Select, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'

function AttributeSetting() {
  return (
    <Box>
        <Flex direction="column" gap={2} mb={2}>
            <Flex alignItems={'center'}>
                <Text fontWeight="bold">Click-through</Text>
                <Tooltip
                    borderRadius={5}
                    label={
                        <Box fontSize="12px" p={'5px'}>
                            <Text fontWeight={'bold'} mb={2} fontSize={'14px'}>Click-through</Text>
                            <Text>Click-through conversions are counted when someone clicks your ad and takes an action within a set period of time. For example, with 7-day click, our system will learn from conversions that happen within 7 days and show ads to people most likely to convert within 7 days.</Text>
                        </Box>
                    }
                    fontSize="md"
                >
                    <InfoOutlineIcon pointerEvents="all" ml={2} />
                </Tooltip>
            </Flex>
            <Select
                name="Click-through"
                value={''}
                onChange={''}
                onBlur={''}
                borderRadius="md"
                size={'sm'}
            >
                <option value={''}>1 day</option>
                <option value={''}>7 days</option>
            </Select>
        </Flex>
        <Flex direction="column" gap={2} mb={2}>
            <Flex alignItems={'center'}>
                <Text fontWeight="bold">Engaged-view (for video only)</Text>
                <Tooltip
                    borderRadius={5}
                    label={
                        <Box fontSize="12px" p={'5px'}>
                            <Text fontWeight={'bold'} mb={2} fontSize={'14px'}>Engaged-view</Text>
                            <Text>Engaged-view conversions are counted when a video ad is played for at least 10 seconds, or for 97% of its total length if it's shorter than 10 seconds, and someone takes an action within 1 day. For example, with 1-day engaged-view, our system will learn from conversions that happen within 1 day and show ads to people most likely to convert in a day.</Text>
                            <Text>Engaged-view is available for all placements except Facebook in-stream video ads, which cannot be skipped.</Text>
                        </Box>
                    }
                    fontSize="md"
                >
                    <InfoOutlineIcon pointerEvents="all" ml={2} />
                </Tooltip>
            </Flex>
            <Select
                name="Click-through"
                value={''}
                onChange={''}
                onBlur={''}
                borderRadius="md"
                size={'sm'}
            >
                <option value={''}>None</option>
                <option value={''}>1 day</option>
            </Select>
        </Flex>
        <Flex direction="column" gap={2} mb={2}>
            <Flex alignItems={'center'}>
                <Text fontWeight="bold">View-through</Text>
                <Tooltip
                    borderRadius={5}
                    label={
                        <Box fontSize="12px" p={'5px'}>
                            <Text fontWeight={'bold'} mb={2} fontSize={'14px'}>View-through</Text>
                            <Text>View-through conversions are counted when someone sees your ad, doesn't click it, but takes an action within your set period of time. For example, with 1-day view, our system will learn from conversions that happen within 1 day and show ads to people most likely to convert within a day.</Text>
                        </Box>
                    }
                    fontSize="md"
                >
                    <InfoOutlineIcon pointerEvents="all" ml={2} />
                </Tooltip>
            </Flex>
            <Select
                name="Click-through"
                value={''}
                onChange={''}
                onBlur={''}
                borderRadius="md"
                size={'sm'}
            >
                <option value={''}>None</option>
                <option value={''}>1 day</option>
            </Select>
        </Flex>
    </Box>
  )
}

export default AttributeSetting
