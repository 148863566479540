import { api } from '../api'

export const facebookServicesApi = api.injectEndpoints({

    endpoints: (builder) => ({
        getPixelById: builder.query({
            query: (pixelId) => `/ads-accounts/adspixels/${pixelId}/adspixel`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getPixelByAdaccountId: builder.query({
            query: (adsAccountId) => `/ads-accounts/pixels/${adsAccountId}`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        createPixel: builder.mutation({
            query: ({ adsAccountId, payload }) => ({
                url: `/ads-accounts/${adsAccountId}/adspixels`,
                method: "POST",
                body: payload,
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => {
                const error = new Error(response.data.message);
                error.status = response.status;
                error.message = response.data.message;
                return error;
            },
        }),
        getFacebookPages: builder.query({
            query: (adsAccountId) => `/ads-accounts/pages/${adsAccountId}`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getInstagramAccounts: builder.query({
            query: (adsAccountId) => `/ads-accounts/instagrams/${adsAccountId}`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getGeoLocations: builder.query({
            query: ({ searchTerm, adsAccountId }) => ({
                url: `adsets/search?q=${searchTerm}&type=adgeolocation&limit=10&adsAccountId=${adsAccountId}`,
                method: 'POST',
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        getLocationMeta: builder.mutation({
            query: ({ adsAccountId, country }) => ({
                url: `/adsets/search?type=adgeolocationmeta&adsAccountId=${adsAccountId}&countries=['${country}']`,
                method: "POST"
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => {
                const error = new Error(response.data.message);
                error.status = response.status;
                error.message = response.data.message;
                return error;
            },
        }),
        getDetailedTargeting: builder.query({
            query: ({ searchTerm, adsAccountId }) => `adsets/targetingsearch/${adsAccountId}?q=${searchTerm}&limit=20`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getLocales: builder.query({
            query: ({ searchTerm, adsAccountId }) => ({
                url: `adsets/search?type=adlocale&q=${searchTerm}&adsAccountId=${adsAccountId}&limit=20`,
                method: 'POST',
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        matchBulkLocations: builder.mutation({
            query: ({ locationType, matchPayload, adsAccountId }) => {
                let url_ = locationType === 'region' ?
                    `adsets/search?adsAccountId=${adsAccountId}&location_types=["region"]`
                    : `adsets/search?adsAccountId=${adsAccountId}&type=adgeolocationmeta`;

                return {
                    url: url_,
                    method: 'POST',
                    body: { ...matchPayload }
                }
            },
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => {
                const error = new Error(response.data.message);
                error.status = response.status;
                error.message = response.data.message;
                return error;
            },
        }),
    }),
});

export const {
    useGetPixelByAdaccountIdQuery,
    useGetPixelByIdQuery,
    useCreatePixelMutation,
    useGetFacebookPagesQuery,
    useGetInstagramAccountsQuery,
    useGetGeoLocationsQuery,
    useGetDetailedTargetingQuery,
    useGetLocalesQuery,
    useMatchBulkLocationsMutation,
    useGetLocationMetaMutation
} = facebookServicesApi;
