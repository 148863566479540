

export const fetchCountryMeta = async (adsAccountId, country, token) => {

    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/adsets/search?type=adgeolocationmeta&adsAccountId=${adsAccountId}&countries=['${country}']`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`, // Add the authorization header
                'Content-Type': 'application/json'
            }
        });


        if (!response.ok) {
            throw new Error(`Failed to fetch metadata for country: ${country}`);
        }

        const data = await response.json();
        return data; // Assuming the response has a `name` key for country name
    } catch (error) {
        console.error(`Error fetching metadata for country ${country}:`, error);
        return { name: country }; // Fallback to the original country name
    }
};
