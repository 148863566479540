import React, { useState, useEffect } from 'react';
import {
  Flex, Box, InputGroup, InputLeftElement, Input, Spinner, Checkbox, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text, Button, Icon
} from '@chakra-ui/react';
import { SearchIcon } from "@chakra-ui/icons";
import { FiChevronsLeft, FiChevronLeft, FiChevronRight, FiChevronsRight } from 'react-icons/fi';
import { useGetAdsListQuery } from 'store/campaigns/adsApi';

const AdSelection = ({ adAccounts, selectedAds, selectedAdsets, onSelectedAdsChange, onSelectedAdsetsChange }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
  
  const { data: adsList, isLoading } = useGetAdsListQuery({
    currentPage,
    searchValue: debouncedSearchValue,
    adsAccountId: adAccounts?.account_id,
    adset_ids: [],
    start_date:'',
    end_date:'',
    date_preset:'',
  }, { skip: !adAccounts?.account_id });

  // Handle pagination
  useEffect(() => {
    if (adsList) {
      setTotalPages(adsList?.meta?.totalPages);
    }
  }, [adsList]);

  // Handle search input debounce
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 400);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  // Update selections change
  useEffect(() => {
    if (onSelectedAdsChange) {
      onSelectedAdsChange(selectedAds);
    }
  }, [selectedAds, onSelectedAdsChange]);

  useEffect(() => {
    if (onSelectedAdsetsChange) {
      onSelectedAdsetsChange(selectedAdsets); // Adset selection changes
    }
  }, [selectedAdsets, onSelectedAdsetsChange]);

  const handleAdsSelection = (adId) => {
 
    const newSelectedAds = { ...selectedAds };
    const ad = adsList.data.find(ad => ad?.id === adId);
    newSelectedAds[adId] = !newSelectedAds[adId];
  
    // Check if the adset of this ad needs to be selected or deselected
    const adsetId = ad?.adset?.id;
    const adsetAds = adsList.data.filter(ad => ad?.adset?.id === adsetId);
    
    const allAdsSelected = adsetAds.every(ad => newSelectedAds[ad?.id]);
    const newSelectedAdsets = { ...selectedAdsets };
  
    // If all ads in the adset are selected, select the adset
    if (allAdsSelected) {
      newSelectedAdsets[adsetId] = true;
    } else {
      delete newSelectedAdsets[adsetId];
    }
    // Update the selectedAds and selectedAdsets
    onSelectedAdsChange(newSelectedAds);
    onSelectedAdsetsChange(newSelectedAdsets);
  };
  

  const handleAdsetSelection = (adsetId, ads) => {
    const newSelectedAds = { ...selectedAds };
    const isAdsetSelected = selectedAdsets[adsetId];

    // Toggle adset selection, and update all ads within this adset
    if (isAdsetSelected) {
      ads.forEach(ad => delete newSelectedAds[ad.id]);
    } else {
      ads.forEach(ad => { newSelectedAds[ad.id] = true; });
    }

    onSelectedAdsetsChange({
      ...selectedAdsets,
      [adsetId]: !isAdsetSelected,
    });
    onSelectedAdsChange(newSelectedAds);
  };

  const handleCampaignSelection = (campaignId, adsets) => {

    const newSelectedAds = { ...selectedAds };
    const newSelectedAdsets = { ...selectedAdsets };

    // Check if the campaign is already selected
    const isCampaignSelected = Object.values(adsets).every(adset =>
      selectedAdsets[adset?.adset?.id] || adset?.ads.every(ad => selectedAds[ad?.id])
    );

    // Toggle campaign selection, and update all adsets and ads under this campaign
    Object.values(adsets).forEach(({ adset, ads }) => {

      const isAdsetSelected = !isCampaignSelected;
      newSelectedAdsets[adset?.id] = isAdsetSelected;

      ads.forEach(ad => {
        newSelectedAds[ad?.id] = isAdsetSelected;
      });
    });
      
    onSelectedAdsetsChange(newSelectedAdsets)
    onSelectedAdsChange(newSelectedAds);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  return (
    <Flex direction={'column'} p={4} gap={4} height="100vh">
      {/* Search Bar */}
      <InputGroup>
        <InputLeftElement pointerEvents='none' children={<SearchIcon color='gray.300' />} />
        <Input
          fontSize={'14px'}
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          mb={4}
        />
      </InputGroup>

      {/* Ads List */}
      <Flex direction={'column'}>
        {isLoading ? (
          <Flex justify="center" align="center" height="100%">
            <Spinner size="sm" />
          </Flex>
        ) : adsList?.data ? (
          Object.values(
            adsList.data.reduce((acc, ad) => {
              if (!acc[ad.campaign.id]) {
                acc[ad.campaign.id] = { campaign: ad.campaign, adsets: {} };
              }
              if (!acc[ad.campaign.id].adsets[ad.adset.id]) {
                acc[ad.campaign.id].adsets[ad.adset.id] = { adset: ad.adset, ads: [] };
              }
              acc[ad.campaign.id].adsets[ad.adset.id].ads.push(ad);
              return acc;
            }, {})
          ).map(({ campaign, adsets }, campaignIndex) => {
            const isCampaignSelected = Object.values(adsets).every(adset =>
              selectedAdsets[adset?.adset?.id] || adset?.ads?.every(ad => selectedAds[ad?.id])
            );

            return (
              <Accordion allowToggle key={`campaign-${campaign.id}-${campaignIndex}`}>
                <AccordionItem>
                  <AccordionButton>
                    <Flex flex="1" textAlign="left" p={1} gap={2}>
                      <Checkbox
                        colorScheme={'orange'}
                        isChecked={isCampaignSelected}
                        onChange={() => handleCampaignSelection(campaign.id, adsets)}
                      />
                      <Text>{campaign.name || "Campaign name is not available"}</Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Accordion allowToggle>
                      {Object.values(adsets).map(({ adset, ads }, adsetIndex) => {
                        const isAdsetSelected = selectedAdsets[adset?.id] ?? false;
                        return (
                          <AccordionItem key={`adset-${adset.id}-${adsetIndex}`}>
                            <AccordionButton>
                              <Flex flex="1" textAlign="left" gap={2}>
                                <Checkbox
                                  colorScheme={'orange'}
                                  isChecked={isAdsetSelected}
                                  onChange={() => handleAdsetSelection(adset?. id, ads)}
                                />
                                <Text>{adset.name || "Adset name is not available"}</Text>
                              </Flex>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4} ml={2}>               
                                {ads.map((ad, adIndex) => (
                                  <Flex direction="row" key={adIndex}>
                                    <Flex>
                                      <Checkbox
                                        colorScheme="orange"
                                        key={`ad-${ad.id}-${adIndex}`}
                                        isChecked={!!selectedAds[ad.id]}
                                        onChange={() => handleAdsSelection(ad.id)}
                                      />
                                    </Flex>
                                    <Flex align="center" direction="row" wrap="nowrap" p={2}>
                                        {ad?.imageUrl && (
                                          <img
                                            src={ad?.imageUrl}
                                            alt={`${ad?.name}`}
                                            style={{
                                              width: '45px',
                                              height: '35px',
                                              objectFit: 'cover',
                                              border: '0.5px solid grey',
                                              marginRight: '10px',
                                            }}
                                          />
                                        )}
                                        <Text fontSize={'12px'}>{ad.name || "Ad name is not available"}</Text>
                                    </Flex>
                                  </Flex>
                                ))}
                            </AccordionPanel>
                          </AccordionItem>
                        );
                      })}
                    </Accordion>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            );            
          })
        ) : (
          <Text>No ads available.</Text>
        )}
      </Flex>

      {/* Pagination */}
      {totalPages > 1 && (
        <Flex align={'center'} justify={['start', 'end']} gap={'5px'}>
          <Box mr={2} fontSize='sm'>
            Page{' '}
            <Text fontWeight={'bold'} display={'inline-flex'}>
              {currentPage} of {totalPages}
            </Text>
          </Box>
          <Button size={'xs'} onClick={() => setCurrentPage(1)} isDisabled={currentPage === 1}>
            <Icon boxSize={4} as={FiChevronsLeft} />
          </Button>
          <Button size={'xs'} onClick={handlePreviousPage} isDisabled={currentPage === 1}>
            <Icon boxSize={4} as={FiChevronLeft} />
          </Button>
          <Button size={'xs'} onClick={handleNextPage} isDisabled={currentPage >= totalPages}>
            <Icon boxSize={4} as={FiChevronRight} />
          </Button>
          <Button size={'xs'} onClick={() => setCurrentPage(totalPages)} isDisabled={currentPage >= totalPages}>
            <Icon boxSize={4} as={FiChevronsRight} />
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default AdSelection;

