import { AddIcon, CloseIcon, InfoOutlineIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FaPlayCircle } from "react-icons/fa";
import {
    useFetchImagesQuery, useFetchVideosQuery, useUploadImageMutation,
    useUploadVideoMutation,
} from 'store/driveUpload/uploadApi';
import { truncateName } from 'utils/functions';
import Pagination from './PaginationForDrive';
import { selectAfter1,selectBefore1, setAfter, setBefore, selectCurrentPage1, setCurrentPage } from 'store/campaigns/campaignsSlice';
import { useDispatch, useSelector } from 'react-redux';


function DriveModal({ isDriveModalOpen, selectedAdAccounts, mediaType, mediaFormat, onClose, onSubmit, uploadPurpose, adFormik }) {

    const inputRef = useRef();
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showError, setShowError] = useState(false);
    const after = useSelector(selectAfter1);
    const before = useSelector(selectBefore1);
    const currentPage = useSelector(selectCurrentPage1);
    const [uploadStatus, setUploadStatus] = useState({});
    const [videoUploadCompleted, setVideoUploadCompleted] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const selectedAdAccount = selectedAdAccounts[activeTabIndex];
    const [missingSourceVideos, setMissingSourceVideos] = useState([]);
    const [retryCounts, setRetryCounts] = useState({})

    const { data: images, isLoading: isImagesDataLoading, isFetching: isImagesFetching, refetch: refetchImages } = useFetchImagesQuery(
        {  
            pageSize:'24',
            ...(searchValue ? {} : { after, before }),
            searchValue: debouncedSearchValue,
            adsAccountId: selectedAdAccount?.adsAccountId 
        },
        { skip: !selectedAdAccount?.adsAccountId || mediaType !== 'image' || !isDriveModalOpen }
    );

    const { data: videos, isLoading: isVideosDataLoading, refetch: refetchVideos, isFetching: isVideosFetching } = useFetchVideosQuery(
        {  
            pageSize:'24',
            ...(searchValue ? {} : { after, before }),
            searchValue: debouncedSearchValue,
            adsAccountId: selectedAdAccount?.adsAccountId 
        },
        { skip: !selectedAdAccount?.adsAccountId ||  mediaType !== 'video' || !isDriveModalOpen}
    );
    const [uploadImage, { isLoading: isImageLoading }] = useUploadImageMutation();
    const [uploadVideo, { isLoading: isVideoLoading }] = useUploadVideoMutation();

    const isAllAccountsSelected = selectedAdAccounts?.every(adAccount =>
        selectedFiles.some(file => file?.adsAccount?.adsAccountId === adAccount.adsAccountId)
    );

    useEffect(() => {
        // Reseting files when the modal opens
        if (isDriveModalOpen) {
            setSelectedFiles([]);
        }
    }, [isDriveModalOpen]);

    useEffect(() => {
        if (videoUploadCompleted) {
            setSpinnerVisible(true);
            const timer = setTimeout(() => {
                setSpinnerVisible(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [videoUploadCompleted]);

    useEffect(() => {
        if (videos?.data) {
            const missingVideos = videos?.data.filter((video) => !video?.source);
            setMissingSourceVideos(missingVideos); // Add missing videos to the list for polling
        }
    }, [videos?.data]);

    useEffect(() => {
        if (missingSourceVideos?.length > 0) {
          const intervalId = setInterval(() => {
  
            missingSourceVideos?.forEach((video) => {
              // Only refetch if source is still missing and retry count is below 5
              if (!video?.source) {
                const currentRetryCount = retryCounts[video?._id] || 0;
    
                if (currentRetryCount < 1) {
                  
                  // Call the refetch function
                  refetchVideos();
    
                  // Increment retry count and update state
                  setRetryCounts(prev => {
                    const newRetryCounts = { ...prev, [video?._id]: currentRetryCount + 1 };
                    return newRetryCounts;
                  });
                } else {
                  //console.log(`Video has reached max retry count of 5. Stopping retries.`);
                }
              }
            });
          }, 5000); // Poll every 5 seconds
    
          // Clean up the interval on component unmount
          return () => clearInterval(intervalId);
        }
      }, [missingSourceVideos, refetchVideos]);

    // callback for video upload
    const handleVideoUploadSuccess = (file) => {
        const videoId = file?.data?._id;
        const currentRetryCount = retryCounts[videoId] || 0;

        if (currentRetryCount < 1) {
            setRetryCounts(prev => ({
                ...prev,
                [videoId]: currentRetryCount + 1,
            }));
            refetchVideos();
        } else {
            //console.log(`Video has reached max retry count of 5. Stopping retries.`);
        }
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 800);

        return () => {
            clearTimeout(handler);
        };
    }, [searchValue]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchValue?.length > 2 || searchValue?.length === "") {
                setSearchValue(searchValue);
            }
        }, 4000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchValue]);

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false)
            }, 3000);
        }
    }, [showError])



    const handleSelection = (file) => {

        if (uploadPurpose === 'update') {
            //  console.log('update');
            setSelectedFiles([file]); // Only allow one file to be selected
        } else if (uploadPurpose === 'create') {
            // console.log('create');
            if (selectedFiles.includes(file)) {
                setSelectedFiles(selectedFiles.filter(f => f !== file)); // Deselect if already selected
            } else {
                setSelectedFiles([...selectedFiles, file]); // Add to selection
            }
        }
    };

    const handleDirectFileUpload = async (file) => {
        const formData = new FormData();

        if (file.type.startsWith("image/")) {
            // Process image upload
            const { _id, ...accountData } = selectedAdAccount;
            formData.append('filename', file);
            formData.append('adsAccounts', JSON.stringify([accountData])); //sending array of objects for the images

            try {
                const response = await uploadImage({ payload: formData }).unwrap();
                //console.log(`Image upload successful for file ${file.name}`, response);
                setUploadStatus(prev => ({
                    ...prev,
                    [file.name]: { status: "done", progress: 100 },
                }));
            } catch (error) {
                console.error(`Error uploading image ${file.name}:`, error);
                setUploadStatus(prev => ({
                    ...prev,
                    [file.name]: { status: "failed", progress: 0 },
                }));
            }
        } else if (file.type.startsWith("video/")) {
            // Process video upload

            const { _id, ...accountData } = selectedAdAccount;
            formData.append('source', file);
            formData.append('adsAccount', JSON.stringify(accountData)); // Sending single object for video

            try {
                const response = await uploadVideo({ payload: formData }).unwrap();
                setUploadStatus(prev => ({
                    ...prev,
                    [file.name]: { status: "done", progress: 100 },
                }));
                setVideoUploadCompleted(true);
                handleVideoUploadSuccess(response);
            } catch (error) {
                console.error(`Error uploading video ${file.name}:`, error);
                setUploadStatus(prev => ({
                    ...prev,
                    [file.name]: { status: "failed", progress: 0 },
                }));
            }
        }    
    }

    const imageList = images?.data?.map((file, index) => {
        const isSelected = selectedFiles.includes(file);

        return (
            <Flex direction={'column'} key={index}>
                <Flex
                    direction="column"
                    bgColor={'gray.50'}
                    borderRadius={'lg'}
                    borderBottomColor={'unset'}
                    borderBottomWidth={0}
                    pb={0}
                    border={isSelected ? '2px solid #3577E5' : '1px solid transparent'}
                >
                    <Box
                        w="100%"
                        h="100px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        borderRadius={2}
                        p={"2px"}
                        onClick={() => handleSelection(file)}
                    >
                        {file.url ? (
                            <Image
                                src={file.url}
                                alt={file.name}
                                objectFit="cover"
                                width="100%"
                                height="100%"
                                borderRadius="2px"
                            />
                        ) : (
                            null
                        )}
                    </Box>

                </Flex >
                <Text fontSize={'10px'} ml={2}>{truncateName(file.name)}</Text>
            </Flex>
        );
    })

    const videoList = videos?.data?.map((file, index) => {
        const isSelected = selectedFiles.includes(file);

        return (
            <Flex direction={'column'} key={index}>
                <Flex
                    direction="column"
                    bgColor={'gray.50'}
                    borderRadius={'lg'}
                    borderBottomColor={'unset'}
                    borderBottomWidth={0}
                    pb={0}
                    border={isSelected ? '2px solid #3577E5' : '1px solid transparent'}
                >
                    <Box
                        w="100%"
                        h="100px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        borderRadius={2}
                        p={"2px"}
                        onClick={() => handleSelection(file)}
                    >
                        {file?.source ? (
                            <Box w="100%" h="100%" position="relative">
                                <video
                                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '2px' }}
                                    src={file?.source}
                                    muted
                                />
                                <Box
                                    position="absolute"
                                    top="50%"
                                    left="50%"
                                    transform="translate(-50%, -50%)"
                                    bg="rgba(0, 0, 0, 0.5)"
                                    borderRadius="50%"
                                >
                                    <FaPlayCircle color="white" size="35px" />
                                </Box>
                            </Box>
                        ) : (
                            null
                        )}
                    </Box>
                </Flex>
                <Text fontSize={'10px'} ml={2}>{truncateName(file.name)}</Text>
            </Flex>

        );
    })

    const selectedFileThumbnails = selectedFiles?.map((file, index) => {

        return (
            <Flex
                direction="column"
                key={index}
                bgColor={'gray.50'}
                borderRadius={'lg'}
                borderBottomColor={'unset'}
                borderBottomWidth={0}
                pb={0}

            >
                <Box
                    w="60px"
                    h="60px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    borderRadius={3}
                    p={"4px"}
                    onClick={() => handleSelection(file)}
                >
                    {file.url ? (
                        <Image
                            src={file.url}
                            alt={file.name}
                            objectFit="cover"
                            width="100%"
                            height="100%"
                            borderRadius="3px"
                        />
                    ) : (
                        null
                    )}

                    {file.source ? (
                        <Box w="40px" h="40px" position="relative">
                            <video
                                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '3px' }}
                                src={file.source}
                                muted
                            />
                            <Box
                                position="absolute"
                                top="50%"
                                left="50%"
                                transform="translate(-50%, -50%)"
                                bg="rgba(0, 0, 0, 0.5)"
                                borderRadius="50%"
                            >
                                <FaPlayCircle color="white" size="15px" />
                            </Box>
                        </Box>
                    ) : (
                        null
                    )}
                </Box>
            </Flex >
        );
    })


    const PageMeta = useMemo(() => {
        const currentMeta = mediaType === 'image' ? images?.meta : mediaType === 'video' ? videos?.meta : null;
        
        return currentMeta || {}; 
    }, [images, videos]);

    const handlePageChange = ({ after, before, currentPage }) => {
        if (after) {
            dispatch(setAfter({ componentKey: 'Drive', value: after }));
            dispatch(setBefore({componentKey: 'Drive', value: ''}));
        } else if (before) {
            dispatch(setBefore({ componentKey: 'Drive', value: before }));
            dispatch(setAfter({componentKey: 'Drive', value: ''}));
        }

        dispatch(setCurrentPage({ componentKey: 'Drive', value: currentPage }));
    };

    useEffect(() => {
        if(activeTabIndex){
            dispatch(setAfter({ componentKey: 'Drive', value: '' }));
            dispatch(setBefore({ componentKey: 'Drive', value: '' }));
            dispatch(setCurrentPage({ componentKey: 'Drive', value: 0 }));
        }
      }, [activeTabIndex, dispatch]); 

      
    const handleClose = () => {
        // Dispatch actions to reset the state
        dispatch(setAfter({componentKey: 'Drive', value: ''}));
        dispatch(setBefore({componentKey: 'Drive', value: ''}));
        dispatch(setCurrentPage({ componentKey: 'Drive', value: 0 }));
    
        if (onClose) {
          onClose();
        }
    };

    return (
        <>
            <Modal isOpen={isDriveModalOpen} onClose={handleClose} size={'4xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{adFormik?.values?.format === 'carousel' ? 'Select up to 10 media' : 'Select media'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody minH={'60vh'}>
                        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'}>
                            <Box width={'100%'}>
                                <Tabs onChange={(index) => setActiveTabIndex(index)} isLazy>
                                    <TabList gap={"10px"}>
                                        {selectedAdAccounts.map((account, index) => (
                                            <Tab fontSize={'14px'} _selected={{ color: "brand.200", borderBottom: '2px' }} key={index}>{truncateName(account.adsAccountName)}</Tab>
                                        ))}
                                    </TabList>
                                    <TabPanels >
                                        {selectedAdAccounts.map((_, index) => (
                                            <TabPanel key={index} p={0} mt={'20px'}>
                                                <Flex mb={'20px'} justifyContent={'space-between'} gap={'20px'}>
                                                    <InputGroup size='sm'>
                                                        <InputLeftElement
                                                            pointerEvents='none'
                                                            children={<SearchIcon color='gray.300' />}
                                                        />
                                                        <Input
                                                            ref={inputRef}
                                                            type="text"
                                                            value={searchValue || ""}
                                                            onChange={e => {
                                                                setSearchValue(inputRef.current.value);
                                                            }}
                                                            placeholder={`Search...`}
                                                        />
                                                        {searchValue &&
                                                            <InputRightElement
                                                                cursor={'pointer'}
                                                                children={isImagesFetching || isVideosFetching ? <Spinner /> : <CloseIcon fontSize={14} _hover={{ color: 'gray.600' }} color='gray.300' />}
                                                                onClick={() => { setSearchValue(''); setShowError(false); }} />}
                                                    </InputGroup>
                                                    {showError ? (
                                                        <Flex flexDirection={'column'} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                                                            <Text mt={4} fontSize={'sm'} color='red.500'>No results found. Try a different search term.</Text>
                                                        </Flex>
                                                    ) : null}
                                                    <Flex mb={'0px'} justifyContent={'flex-end'} >
                                                        <FormControl display={'flex'} justifyContent={'flex-end'}>
                                                            <FormLabel htmlFor="file-upload" mb="0" display={'flex'} justifyContent={'flex-end'}>
                                                                <Button as={'span'} colorScheme="orange" size={'sm'} >
                                                                    <AddIcon boxSize={3} />
                                                                    &nbsp;{isImageLoading || (isVideoLoading || spinnerVisible) ? (
                                                                        <Spinner size={'xs'} />
                                                                    ) : 'Upload'}
                                                                </Button>
                                                            </FormLabel>
                                                            <input
                                                                id="file-upload"
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                accept={mediaType === 'image' ? 'image/*' : mediaType === 'video' ? 'video/*' : '*/*'}
                                                                onChange={(e) => { handleDirectFileUpload(e.target.files[0]) }}
                                                            />
                                                        </FormControl>
                                                    </Flex>
                                                </Flex>
                                                <Flex direction={'column'}>
                                                    {mediaType === 'image' ? (
                                                        <>
                                                            {(isImagesDataLoading || isImagesFetching) ? (
                                                                <Spinner mt={'20px'} />
                                                            ) : (
                                                                <>
                                                                    {images?.data?.length > 0 ? (
                                                                        <Grid templateColumns={'repeat( auto-fit, minmax(95px, 95px) )'} gap='10px'>
                                                                            {imageList}
                                                                        </Grid>
                                                                    ) : (
                                                                        <Text>No Images yet</Text>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : null}
                                                    {mediaType === 'video' ? (
                                                        <>
                                                            {isVideosDataLoading || isVideosFetching ? (
                                                                <Spinner mt={'20px'} />
                                                            ) : (
                                                                <>
                                                                    {videos?.data?.length > 0 ? (
                                                                        <Grid templateColumns={'repeat( auto-fit, minmax(95px, 95px) )'} gap='10px'>
                                                                            {videoList}
                                                                        </Grid>
                                                                    ) : (
                                                                        <Text>No videos yet</Text>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </Flex>
                                            </TabPanel>
                                        ))}
                                    </TabPanels>
                                </Tabs>
                            </Box>
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Flex direction={'column'} justifyContent={'flex-start'} width={'100%'} gap={'20px'}>
                            <Flex justifyContent={'flex-end'}>
                                 {/* Pagination Component */}
                                 <Pagination
                                    PageMeta={PageMeta}
                                    initialState={{ pageIndex: currentPage }}
                                    onPageChange={handlePageChange}
                                />
                            </Flex>
                            {mediaFormat === 'CAROUSEL' && selectedFiles?.length > 0 ? (
                                <Flex justifyContent={'flex-start'} gap={'10px'} direction={'column'} bg={'gray.50'} p={'10px'}>
                                    <Text fontSize={'12px'}>Selected {mediaType}{mediaFormat === 'CAROUSEL' ? 's' : null} </Text>
                                    <Flex gap={'5px'}>
                                        {selectedFileThumbnails}
                                    </Flex>
                                </Flex>
                            ) : null}

                            <Flex justifyContent={'space-between'}>
                                <Box>
                                    {!isAllAccountsSelected ? (
                                        <Flex alignItems={'center'} gap={'5px'}>
                                            <InfoOutlineIcon color={'#ea7869'} pointerEvents="all" fontSize={'12px'} />
                                            <Text fontSize={'12px'}>  Please select at least single media from single ad account</Text>
                                        </Flex>
                                    ) : null}
                                </Box>
                                <Flex>
                                    <Button mr={3} onClick={onClose} size={'sm'}>
                                        Close
                                    </Button>
                                    <Button
                                        colorScheme={'orange'}
                                        size={'sm'}
                                        isDisabled={selectedFiles?.length === 0}
                                        onClick={() => {
                                            if (selectedFiles?.length > 0) {
                                                onSubmit(selectedFiles, mediaType);
                                                onClose();
                                            }
                                        }}
                                    >Submit</Button>
                                </Flex>
                            </Flex>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DriveModal