import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Radio, RadioGroup, Stack, Text, Tooltip } from '@chakra-ui/react';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';
import InstagramAccountUpdateSelector from 'components/adspresso/InstagramAccountUpdateSelector';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { selectSelectedAdsetId } from 'store/campaigns/campaignsSlice';
import CreateCarouselAdForm from './CreateCarouselAdForm';
import CreateSingleAdForm from './CreateSingleAdForm';

const call_to_action_options = [
    { value: 'SHOP_NOW', label: 'Shop Now' },
    { value: 'LEARN_MORE', label: 'Learn More' },
    { value: 'APPLY_NOW', label: 'Apply Now' },
];

function CreateAdForm({ adsAccountData, isOpen, onClose, onSuccess }) {

    const selectedAdsetId = useSelector(selectSelectedAdsetId);

    const adFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            accounts: [],
            format: 'SINGLE',
        },
        onSubmit: (values, { setSubmitting, resetForm }) => {

        },
    });

    const renderCreativeComponent = () => {

        if (adFormik.values.format === 'CAROUSEL') {
            return (
                <CreateCarouselAdForm
                    adFormik={adFormik}
                    onClose={onClose}
                    adsAccountData={adsAccountData}
                    onSave={handleSave}
                    onSuccess={onSuccess}
                    call_to_action_options={call_to_action_options}
                />
            );
        } else if (adFormik.values.format === 'SINGLE') {
            return (
                <CreateSingleAdForm
                    adFormik={adFormik}
                    onClose={onClose}
                    adsAccountData={adsAccountData}
                    onSave={handleSave}
                    onSuccess={onSuccess}
                    call_to_action_options={call_to_action_options}
                />
            );
        }
    };

    const handleInstagramPageSelection = (pageId, adsAccountId) => {

        const updatedAccounts = adFormik.values.accounts.map(obj =>
            obj.adsAccountId === adsAccountId ? { ...obj, instagram_actor_id: pageId } : obj
        );

        if (!adFormik.values.accounts.find(obj => obj.adsAccountId === adsAccountId)) {
            updatedAccounts.push({ adsAccountId, instagram_actor_id: pageId });
        }
        adFormik.setFieldValue('accounts', updatedAccounts);
    };

    const handleFacebookPageSelection = (pageId, adsAccountId) => {
        // console.log({ pageId, adsAccountId });

        let updatedAccounts;

        if (pageId) {
            // If pageId is not empty, update or add the account
            updatedAccounts = adFormik.values.accounts.map(obj =>
                obj.adsAccountId === adsAccountId ? { ...obj, page_id: pageId } : obj
            );

            // If the account is not found, add it
            if (!adFormik.values.accounts.find(obj => obj.adsAccountId === adsAccountId)) {
                updatedAccounts.push({ adsAccountId, page_id: pageId });
            }
        } else {
            // If pageId is empty, remove the entry with the matching adsAccountId
            updatedAccounts = adFormik.values.accounts.filter(
                obj => obj.adsAccountId !== adsAccountId
            );
        }

        // Set the updated accounts in Formik
        adFormik.setFieldValue('accounts', updatedAccounts);
    };

    const handleSave = () => {
        const facebookPageSelected = adFormik.values.accounts.some(account => account.page_id);

        if (!facebookPageSelected) {
            adFormik.setFieldError('accounts', 'Facebook page required');
        }

        if (!adFormik.values.name) {
            adFormik.setFieldError('name', 'Ad name is required');
        }
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered scrollBehavior='inside'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create Ad</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={adFormik.handleSubmit}>
                        {/* Ad Name Section */}
                        <Flex direction="column" mb={4} bg={'gray.50'} p={4} borderRadius="md" gap={4}>
                            <Flex direction={'column'} gap={2}>
                                <Flex>
                                    <Text fontWeight="bold">Ad name</Text>
                                    <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                </Flex>
                                <Input
                                    placeholder="Enter your ad name here..."
                                    name="name"
                                    value={adFormik.values.name}
                                    onChange={adFormik.handleChange}
                                    onBlur={adFormik.handleBlur}
                                    size={'sm'}
                                    borderRadius={'md'}
                                />
                                <Text color="red.500" fontSize={'12px'}>{adFormik.errors.name}</Text>
                            </Flex>

                            {/* Identity Section */}
                            <Flex direction="column" gap={2}>
                                <Text fontWeight="bold">Identity</Text>
                                <Flex alignItems={'center'}>
                                    <Text fontWeight="bold">Facebook pages</Text>
                                    <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Your Facebook Pages or Instagram accounts represents your business in ads. </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>
                                <FaceBookPageSelector
                                    adsAccount={adsAccountData}
                                    selectedPageId={''}
                                    onSelectedPage={(page) => handleFacebookPageSelection(page, selectedAdsetId?.adsAccount)}
                                />
                                <Text color="red.500" fontSize={'12px'}>{adFormik.errors.accounts}</Text>

                                <Divider />
                                <Text fontWeight="bold">Instagram accounts</Text>

                                <InstagramAccountUpdateSelector
                                    pageID={adFormik.values.accounts[0]?.page_id}
                                    adsAccount={adsAccountData}
                                    selectedInstPageId={adFormik.values.creative?.object_story_spec?.instagram_actor_id}
                                    onSelectedPage={(page) => handleInstagramPageSelection(page, selectedAdsetId?.adsAccount)}
                                    isNewAdCreating={true}
                                />

                            </Flex>
                        </Flex>

                        {/* Ad Setup Section */}
                        <Flex direction="column" bg={'gray.50'} pl={4} pt={4} borderRadius="md" gap={2}>

                            {/* Creative Source Section */}
                            <Flex direction="column" borderRadius="md" gap={2}>
                                <Flex align={'center'}>
                                    <Text fontWeight="bold">Creative source &nbsp;</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Choose how you'd like to provide the media for your ad. </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>

                            </Flex>

                            {/* Format Section */}
                            <Flex direction="column" mb={4} borderRadius="md" gap={2}>
                                <Flex align={'center'}>
                                    <Text fontWeight="bold">Format &nbsp;</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Choose how you'd like to structure your ad. </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>

                                <RadioGroup
                                    onChange={(value) => {
                                        adFormik.setFieldValue("format", value);
                                        adFormik.setFieldValue('mediaOption', '')
                                    }}
                                    value={adFormik.values.format}
                                >
                                    <Stack spacing={5} direction='column'>
                                        {/* Single image or video option */}
                                        <Flex direction="column">
                                            <Flex align="center">
                                                <Radio colorScheme='orange' value='SINGLE' />
                                                <Text fontWeight="bold" ml={2}>Single image or video</Text>
                                            </Flex>
                                            <Text fontSize={'sm'} ml={6}>One image or video, or a slideshow with multiple images</Text>
                                        </Flex>

                                        {/* Carousal format option */}
                                        <Flex direction="column">
                                            <Flex align="center">
                                                <Radio colorScheme='orange' value='CAROUSEL' />
                                                <Text fontWeight="bold" ml={2}>Carousel</Text>
                                            </Flex>
                                            <Text fontSize={'sm'} ml={6}>Two or more scrollable images or videos</Text>
                                        </Flex>
                                    </Stack>
                                </RadioGroup>
                            </Flex>


                            {/* Ad creative Section */}
                            <Flex direction="column" >
                                <Box mb={'10px'} >
                                    <Flex alignItems={'center'}>
                                        <Text fontWeight="bold" >Ad creative &nbsp;</Text>
                                        <Tooltip
                                            borderRadius={5}
                                            label={
                                                <Box fontSize="sm" p="5px">
                                                    <Text>Add a variety of creative elements and group the ones that can be shown together. We'll combine the right elements to show each person the version of your ad that they're most likely to engage with. </Text>
                                                </Box>
                                            }
                                            fontSize="md"
                                        >
                                            <InfoOutlineIcon pointerEvents="all" />
                                        </Tooltip>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Flex>
                    </form>

                    {/* Media selection */}
                    <Flex direction="column">
                        <Flex alignItems="center" justifyContent={'space-between'} bg={'gray.50'}>
                            <Flex direction='row' pl={4}>
                                <Text fontWeight="bold">Media</Text>
                                <Text display="inline-flex" fontSize="18px" color="red.400">*</Text>
                            </Flex>
                        </Flex>

                        <Flex alignItems="center" width={'100%'}>
                            {renderCreativeComponent()}
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default CreateAdForm
