import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  Flex, Text, Box, Select, Button, useToast,
  Switch,
  FormControl,
  Tooltip,
  Icon,
  useDisclosure,
  Spinner,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Table, Tbody, Tr, Td,
  ModalFooter,
  Thead,
  Th
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { IoBulbOutline } from "react-icons/io5";
import SidebarWithHeader from 'components/SidebarWithHeader';
import CreateRuleForm from "components/adRules/CreateRuleForm";
import { useGetAdRulesQuery, useUpdateAdRuleStatusMutation, useDeleteAdRuleMutation, useGetAdRuleResultsQuery } from "store/adRules/adRuleApi";
import moment from 'moment';
import { toZonedTime, format } from "date-fns-tz";
import { FiDelete, FiEdit2 } from "react-icons/fi";
import DeleteConfirmationModal from "components/genericForms/DeleteConfirmationModal";
import UpdateRuleForm from "components/adRules/UpdateRuleForm";
import { useGetAdsQuery } from "store/campaigns/adsApi";
import { useGetAdsetsQuery } from "store/campaigns/adsetFormApi";
import currencySymbolMap from 'currency-symbol-map';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { selectAfter, setAfter, setBefore, selectBefore, selectCurrentPage, setCurrentPage } from "store/campaigns/campaignsSlice";
import AppTableWithPageMeta from "components/AppTableWithPageMeta";
import { selectSelectedAdAccounts, selectAdAccountAssigned, selectAdAccountLoading, selecthasAdAccountChecked, selectSelectedBmAccounts } from "store/BmAccounts/bmAccountSlice";
import SearchAdaccount from "components/bmControl/SearchAdaccount";
import { useGetBmAdAccountsQuery } from "store/BmAccounts/bmAccountApi";


function AdRules() {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const params = new URLSearchParams(location.search);
  const adsAccountIdFromParams = searchParams.get('adsAccountId');
  const businessId = searchParams.get('business_id');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchValue, setsearchValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedAdsAccountId, setSelectedAdsAccountId] = useState('');
  const [selectedAccountData, setSelectedAccountData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const after = useSelector(selectAfter);
  const before = useSelector(selectBefore);
  const currentPage = useSelector(selectCurrentPage);
  const [switchStates, setSwitchStates] = useState({});
  const [selectedAdRule, setselectedAdRule] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const hasActiveAdAccount = useSelector((state) => state.auth?.hasActiveAdAccount);
  const activeAccountsData = useSelector((state) => state.auth?.activeAccountsData);
  const selectedAdAccounts = useSelector(selectSelectedAdAccounts);
  const hasAdAccountAssigned = useSelector(selectAdAccountAssigned);
  const hasAdAccountChecked = useSelector(selecthasAdAccountChecked);
  const bmAccount = useSelector(selectSelectedBmAccounts);
  const adAccountLoading = useSelector(selectAdAccountLoading);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [adsList, setAdsList] = useState([]);
  const [adsetList, setAdsetList] = useState([]);
  const [isAdsLoading, setIsAdsLoading] = useState(false);
  const [isAdsetLoading, setIsAdsetLoading] = useState(false);
  const [isLoadingAccountChange, setIsLoadingAccountChange] = useState(false);
  const [loadingTimeoutReached, setLoadingTimeoutReached] = useState(false);
  const [adRuleResultsIds, setAdRuleResultsIds] = useState([]);
  const [AdrulesListWithResults, setAdruleListWithResults] = useState([]);

  const { data: AdAccountData, refetch: refetchAdaccountData, isLoading: isAdAccountLoading } = useGetBmAdAccountsQuery(
    {
      // businessId: businessId || '',
      search: searchTerm ? searchTerm : ''
    },
    // { skip: !businessId}
  );

  const { data: adRulesList, isFetching, refetch: refetchadRulesList, isLoading: isAdRulesListLoading, error: AdrulesListError } = useGetAdRulesQuery({
    pageSize: '10',
    ...(searchValue ? {} : { after, before }),
    searchValue,
    adsAccountId: selectedAdsAccountId,
  }, { skip: !selectedAdsAccountId });

  const { data: adRulesResults, isLoading: isAdRulesResultsLoading } = useGetAdRuleResultsQuery({
    adsAccountId: selectedAdsAccountId,
    AdRuleIds: adRuleResultsIds,
  }, { skip: !selectedAdsAccountId || !adRuleResultsIds.length });

  // console.log({adRulesList})
  // console.log({adRulesResults})

  const shouldSkipAds = !selectedAccountData?.business?.id ||
    !isDetailsModalOpen ||
    (modalContent?.type !== "AD" || !Array.isArray(modalContent?.ids) || modalContent?.ids?.length === 0);

  const shouldSkipAdsets = !selectedAccountData?.account_id ||
    !isDetailsModalOpen ||
    (modalContent?.type !== "ADSET" || !Array.isArray(modalContent?.ids) || modalContent?.ids?.length === 0);

  const { data: adsData, isLoading: adsLoading } = useGetAdsQuery(
    {
      businessId: selectedAccountData?.business?.id,
      adsAccountId: selectedAccountData?.account_id,
      adsIds: modalContent?.type === "AD" ? modalContent?.ids : [],
    },
    {
      skip: shouldSkipAds
    }
  );

  const { data: adsetData, isLoading: adsetLoading } = useGetAdsetsQuery(
    {
      adsAccountId: selectedAccountData?.account_id,
      adsetIds: modalContent?.type === "ADSET" ? modalContent?.ids : [],
    },
    {
      skip: shouldSkipAdsets
    }
  );
  // console.log({adsList})
  // console.log({adsetList})

  useEffect(() => {
    if (businessId) {
      refetchAdaccountData();
      setSearchTerm('')
    }
  }, [businessId]);

  useEffect(() => {
    if (hasSearched && searchTerm === '') {
      refetchAdaccountData();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (selectedAdsAccountId) {
      setIsLoadingAccountChange(true);
    }
  }, [selectedAdsAccountId]);

  useEffect(() => {

    const timer = setTimeout(() => {
      if (isAdRulesListLoading || isAdRulesResultsLoading) {
        setLoadingTimeoutReached(true);
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (adRulesList?.data || adRulesResults) {
      setLoadingTimeoutReached(false);
      setIsLoadingAccountChange(false);
    }
  }, [adRulesList, adRulesResults]);


  const [updateAdRuleStatus] = useUpdateAdRuleStatusMutation({});
  const [deleteAdRule] = useDeleteAdRuleMutation();

  useEffect(() => {
    if (adRulesList?.data) {
      const newAdRuleIds = adRulesList?.data.map(adRule => adRule?.id).filter(Boolean);
      setAdRuleResultsIds(newAdRuleIds);
    }
  }, [adRulesList]);


  useEffect(() => {
    if (adsData) {
      setAdsList(adsData);
      setIsAdsLoading(false);
    }
  }, [adsData]);

  useEffect(() => {
    if (adsetData) {
      setAdsetList(adsetData?.data);
      setIsAdsetLoading(false);
    }
  }, [adsetData]);

  const openDetailsModal = (content) => {

    setModalContent(content);
    setIsDetailsModalOpen(true);
    if (content?.type === 'AD' && content?.count !== 0 && !adsList.length) {
      setIsAdsLoading(true);
    } else if (content?.type === 'ADSET' && content?.count !== 0 && !adsetList.length) {
      setIsAdsetLoading(true);
    }
  };

  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setModalContent(null);
    setAdsList([]);
    setAdsetList([]);
  };

  useEffect(() => {
    if (selectedAdsAccountId) {
      params.set('adsAccountId', selectedAdsAccountId);
    }
    navigate({ search: params.toString() }, { replace: true });
  }, [selectedAdsAccountId]);


  useEffect(() => {
    const storedAccountId = localStorage.getItem('selectedAdsAccountId') || adsAccountIdFromParams;
    if (storedAccountId) {
        setSelectedAdsAccountId(storedAccountId);
    } else if (selectedAdAccounts?.length > 0) {
        const defaultAccountId = selectedAdAccounts[0].account_id;
        setSelectedAdsAccountId(defaultAccountId);
    }
}, [selectedAdAccounts, adsAccountIdFromParams]);

  useEffect(() => {
    const storedPage = params.get('page_id');

    if (storedPage && !isNaN(storedPage)) {
      const parsedPage = parseInt(storedPage, 10);
      if (parsedPage >= 0 && parsedPage !== currentPage) {
        dispatch(setCurrentPage(parsedPage));
      }
    } else if (currentPage !== 0) {
      dispatch(setCurrentPage(0));
    }
  }, [location.search]);

  useEffect(() => {
    if (currentPage) {
      params.set('page_id', currentPage);
    }
    navigate({ search: params.toString() }, { replace: true });
  }, [selectedAdsAccountId, currentPage]);


  useEffect(() => {
    if (selectedAdsAccountId) {
      const account = AdAccountData?.data?.find(acc => acc?.account_id === selectedAdsAccountId);
      setSelectedAccountData(account);
    }
  }, [selectedAdsAccountId, selectedAccountData, AdAccountData]);

  useEffect(() => {
    if (adRulesList?.data) {
      const initialSwitchStates = {};
      adRulesList.data.forEach(adRule => {
        initialSwitchStates[adRule.adrule_id] = adRule.status;
      });
      setSwitchStates(initialSwitchStates);
    }
  }, [adRulesList]);

  useEffect(() => {
    if (adRulesList?.data || adRulesResults) {

      const adRulesWithRuleResults = adRulesList?.data.map((adrule, index) => {
        const ruleResultData = adRulesResults && adRulesResults[index] ? adRulesResults[index].data : [];

        return {
          ...adrule,
          ruleResult: ruleResultData,
        };
      });

      setAdruleListWithResults(adRulesWithRuleResults);
    }
  }, [adRulesList, adRulesResults]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSuccess = () => {
    refetchadRulesList()
  };
  const handleSwitchChange = (AdRuleId, currentState) => {

    const newStatus = currentState === 'ENABLED' ? 'DISABLED' : 'ENABLED';

    setSwitchStates(prev => ({ ...prev, [AdRuleId]: newStatus }));
    updateAdRuleStatus({
      AdRuleId,
      payload: {
        status: newStatus,
        adsAccount: {
          id: selectedAccountData?.id,
          adsAccountId: selectedAccountData?.account_id,
          adsAccountName: selectedAccountData?.name,
          businessName: selectedAccountData?.business?.name,
          businessId: selectedAccountData?.business?.id,
        }
      }
    }).unwrap()
      .then(() => {
        refetchadRulesList();
        toast({
          position: "top-right",
          title: `Ad Rule ${newStatus === 'ENABLED' ? 'Enabled' : 'Disabled'}`,
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top-right",
          title: `Error updating Adrule status`,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        console.error('Error updating Adrule status:', error);

        // Revert switch state if API call fails
        setSwitchStates(prev => ({ ...prev, [AdRuleId]: currentState }));
      });
  };

  const handleAdaccountChange = (value) => {
    if (value?.value !== selectedAdsAccountId) {
      setSelectedAdsAccountId(value?.value);
      params.set('page_id', 0);
      navigate({ search: params.toString() }, { replace: true });
      setAdRuleResultsIds([]);
      dispatch(setAfter(''));
      dispatch(setBefore(''));
      dispatch(setCurrentPage(0));
      localStorage.removeItem('after')
      localStorage.removeItem('page')
      localStorage.removeItem('currentPage')
    }
  }

  const handleSearchChange = useCallback((value) => {
    setSearchTerm(value);
  }, []);

  const handleDeleteAdRule = (AdRuleId) => {

    deleteAdRule({ AdRuleId: AdRuleId?.id, adsAccountId: selectedAccountData?.account_id, })
      .unwrap()
      .then(response => {
        refetchadRulesList();
        toast({
          position: "top-right",
          title: "Ad Rule deleted",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          position: "top-right",
          title: "Error deleting Ad Rule",
          status: "error",
          description: error?.data?.message,
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Rule name",
        accessor: "Rule name",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {

          return (
            <Flex gap={'10px'} align={'center'}>
              <Tooltip label={''} fontSize='xs' >
                <FormControl width={'fit-content'}>
                  <Switch
                    colorScheme='orange'
                    size={'sm'}
                    isChecked={original.status === 'ENABLED'}
                    onChange={() => handleSwitchChange(original?.id, original?.status)}
                  />
                </FormControl>
              </Tooltip>
              <Text
                // color="orange.500"
                // fontWeight={'semibold'}
                _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  setselectedAdRule(original);
                  onOpen();
                }}
              >
                {original.name.length > 30 ? `${original.name.slice(0, 30)}...` : original.name}
              </Text>
            </Flex>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>{original.status === "ENABLED" ? "Enabled" : "Disabled"}</Text>
        ),
      },
      {
        Header: "Applied to",
        accessor: "Applied to",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          const entityTypeFilter = original.filters.find(filter => filter.field === "entity_type");
          const adsetFilter = original.filters.find(filter => filter.field === "adset.id");
          const adFilter = original.filters.find(filter => filter.field === "ad.id");

          let displayText = "";
          let modalContent = {};

          if (entityTypeFilter?.value === "AD") {
            const adCount = adFilter?.value ? adFilter.value.length : 0;
            displayText = adCount > 0 ? `${adCount} Ad${adCount > 1 ? 's' : ''}` : "All active ads";
            modalContent = {
              type: "AD",
              count: adCount,
              ids: adFilter?.value || []
            };
          } else if (entityTypeFilter?.value === "ADSET") {
            const adsetCount = adsetFilter?.value ? adsetFilter.value.length : 0;
            displayText = adsetCount > 0 ? `${adsetCount} Ad Set${adsetCount > 1 ? 's' : ''}` : "All active adsets";
            modalContent = {
              type: "ADSET",
              count: adsetCount,
              ids: adsetFilter?.value || []
            };
          } else {
            displayText = "All active ads";
            modalContent = {
              type: "AD",
              count: 0,
              ids: []
            };
          }

          return (
            <Text
              color="orange.500"
              fontWeight={'semibold'}
              _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => openDetailsModal(modalContent)}
            >
              {displayText}
            </Text>
          );
        },
      },
      {
        Header: "Action & condition",
        accessor: "Action & condition",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {

          if (original?.execution_type === "PAUSE") {
            return <Text>Turn off ads</Text>;
          } else if (original?.execution_type === "UNPAUSE") {
            return <Text>Turn on ads</Text>;
          }

          const currencySymbol = currencySymbolMap(selectedAccountData?.currency);

          // Handle CHANGE_BUDGET
          if (original?.execution_type === "CHANGE_BUDGET") {
            const budgetResetPeriodFilter = original?.filters?.find(
              (filter) => filter.field === 'adset.budget_reset_period'
            );

            // Find the change spec in execution options to get the amount and unit
            const changeSpec = original?.execution_options?.find(option => option.field === 'change_spec');
            const amount = parseFloat(changeSpec?.value?.amount) || 0;
            const unit = changeSpec?.value?.unit;

            let actionDescription = '';

            // to use "Increase" or "Decrease"
            const action = amount < 0 ? "Decrease" : "Increase";

            const displayAmount = Math.abs(amount);

            if (budgetResetPeriodFilter) {
              // Handle LIFETIME reset period
              if (budgetResetPeriodFilter.value.includes('LIFETIME')) {
                if (unit === 'PERCENTAGE') {
                  actionDescription = `${action} lifetime budget by ${displayAmount}%`;
                } else if (unit === 'ACCOUNT_CURRENCY') {
                  actionDescription = `${action} lifetime budget by ${(displayAmount / 100)}${currencySymbol}`;
                } else {
                  actionDescription = `${action} lifetime budget by $${displayAmount}`;
                }
              }

              // Handle DAY reset period
              if (budgetResetPeriodFilter.value.includes('DAY')) {
                if (unit === 'PERCENTAGE') {
                  actionDescription = `${action} daily budget by ${displayAmount}%`;
                } else if (unit === 'ACCOUNT_CURRENCY') {
                  actionDescription = `${action} daily budget by ${(displayAmount / 100)}${currencySymbol}`;
                } else {
                  actionDescription = `${action} daily budget by ${displayAmount}`;
                }
              }
            }

            return <Text>{actionDescription}</Text>;
          }

          // Default, if no conditions match
          return <Text>No action</Text>;
        },
      },
      {
        Header: "Rule results",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          const entityTypeFilter = original.filters.find(filter => filter.field === "entity_type");
          const entityValue = entityTypeFilter?.value === 'AD' ? 'ads' : 'ad sets';
          const ruleResults = original?.ruleResult?.data || [];
          const timeZone = selectedAccountData?.timezone_name || "Asia/Kolkata";


          const result = ruleResults.length === 0
            ? <Text>Never</Text>
            : (
              <Box>
                <Text>{`No changes to ${entityValue}`}</Text>
                <Text fontSize={'12px'}>
                  {
                    ruleResults.length > 0
                      ? (() => {
                        const lastTimestamp = ruleResults[ruleResults.length - 1].timestamp;

                        // Convert the UTC timestamp to timezone
                        const zonedDate = toZonedTime(lastTimestamp, timeZone);
                        const timezoneAbbreviation = format(zonedDate, 'zzz', { timeZone: timeZone });
                        const formattedDate = format(zonedDate, 'MMM dd,yyyy');
                        return `${formattedDate}${' in '}${timezoneAbbreviation}`;
                      })()
                      : ''
                  }
                </Text>
              </Box>
            );

          return <Box>{result}</Box>;
        }
      },
      {
        Header: "Created by",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Box gap={'10px'}>
            <Text>{original.created_by?.name}</Text>
            <Text fontSize={'12px'}>{moment(original.createdDate).format("DD-MM-YYYY")}</Text>
          </Box>
        )
      },
      {
        Header: "Actions",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Flex gap={'10px'} alignItems={'center'}>
            <Tooltip label='Edit' fontSize='xs' >
              <Flex>
                <Icon
                  _hover={{ color: "gray.500" }}
                  as={FiEdit2}
                  cursor={"pointer"}
                  onClick={() => {
                    setselectedAdRule(original);
                    onOpen();
                  }}
                />
              </Flex>
            </Tooltip>
            <Tooltip label='Delete' fontSize='xs' >
              <Flex>
                <Icon
                  color={'red.500'}
                  _hover={{ color: 'gray.400' }}
                  as={FiDelete}
                  cursor={"pointer"}
                  onClick={() => {
                    setselectedAdRule(original);
                    setIsConfirmationModalOpen(true);
                  }}
                />
              </Flex>
            </Tooltip>
          </Flex>
        )
      },
    ],
    [selectedAccountData]
  );

  const confirmDelete = () => {
    if (selectedAdRule) {
      handleDeleteAdRule(selectedAdRule);
      setIsConfirmationModalOpen(false);
      setselectedAdRule(null);
    }
  };

  const handlePageChange = ({ after, before, currentPage }) => {
    if (after) {
      dispatch(setAfter(after));
      dispatch(setBefore(''));
    } else if (before) {
      dispatch(setBefore(before));
      dispatch(setAfter(''));
    }
    dispatch(setCurrentPage(currentPage));
  };

  // if (isAdAccountLoading) {
  //   return (
  //     <SidebarWithHeader>
  //       <Flex justify="center" align="center" height="80vh">
  //         <Spinner size="md" />
  //       </Flex>
  //     </SidebarWithHeader>
  //   );
  // }


  return (
    <>
      {/* {(hasAdAccountAssigned) ? ( */}
      <SidebarWithHeader>
        <>
          <DeleteConfirmationModal
            isOpen={isConfirmationModalOpen}
            onClose={() => setIsConfirmationModalOpen(false)}
            onConfirm={confirmDelete}
            message="Are you sure you want to delete this rule? This action can't be undone."
          />

          {/* Applied to Modal */}
          <Modal isOpen={isDetailsModalOpen} onClose={closeDetailsModal} size="3xl" isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Group</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex direction={'column'} gap={2}>
                  <Text fontSize={'14px'}>
                    {modalContent?.type === "AD"
                      ? (modalContent?.count || 0) > 0
                        ? `${modalContent?.count} Ad Managed by this Rule:`
                        : "All Active Ads Managed by this Rule"
                      : (modalContent?.count || 0) > 0
                        ? `${modalContent?.count} Ad Set Managed by this Rule:`
                        : "All Active Ad Set Managed by this Rule"
                    }
                  </Text>

                  {/* Ads */}
                  {isAdsLoading && modalContent?.type === "AD" ? (
                    <Flex justify="center" align="center">
                      <Spinner size="sm" />
                    </Flex>
                  ) : modalContent?.type === "AD" && adsList?.length > 0 && modalContent?.count > 0 ? (
                    <Flex direction={'column'} gap={4}>
                      <Flex gap={2} bg={'gray.50'} p={3} borderRadius={'sm'}>
                        <IoBulbOutline />
                        <Text fontSize={'12px'} letterSpacing={'-0.3px'}>
                          Your rule will be applied to {adsList?.length} Ad in your account at the time that the rule is running.
                        </Text>
                      </Flex>
                      <Flex>
                        <Table variant="simple" width="100%" border="1px solid #e0e0e0">
                          <Thead>
                            <Tr>
                              <Th padding="8px" width="100%" border="1px solid #e0e0e0">Ad Name</Th>
                            </Tr>
                          </Thead>
                          <Tbody style={{ maxHeight: '200px', overflowY: 'auto', display: 'block' }}>
                            {adsList?.map((ad, index) => (
                              <Tr key={index} style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                <Td padding="6px">
                                  <Text fontSize="12px" padding="0">{ad?.name}</Text>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Flex>
                    </Flex>
                  ) : modalContent?.type === "AD" ? (
                    <Flex gap={2} bg={'gray.50'} p={3} borderRadius={'sm'}>
                      <IoBulbOutline />
                      <Text fontSize={'12px'} letterSpacing={'-0.3px'}>
                        Your rule will be applied to all active Ads in your account at the time the rule is running.
                      </Text>
                    </Flex>
                  ) : null}

                  {/* Ad Sets */}
                  {isAdsetLoading && modalContent?.type === "ADSET" ? (
                    <Flex justify="center" align="center">
                      <Spinner size="sm" />
                    </Flex>
                  ) : modalContent?.type === "ADSET" && adsetList?.length > 0 && modalContent?.count > 0 ? (
                    <Flex direction={'column'} gap={4}>
                      <Flex gap={2} bg={'gray.50'} p={3} borderRadius={'sm'}>
                        <IoBulbOutline />
                        <Text fontSize={'12px'} letterSpacing={'-0.3px'}>
                          Your rule will be applied to {adsetList?.length} Ad Set in your account at the time that the rule is running.
                        </Text>
                      </Flex>
                      <Flex>
                        <Table variant="simple" width="100%" border="1px solid #e0e0e0">
                          <Thead>
                            <Tr>
                              <Th padding="8px" width="100%" border="1px solid #e0e0e0">Ad Set Name</Th>
                            </Tr>
                          </Thead>
                          <Tbody style={{ maxHeight: '200px', overflowY: 'auto', display: 'block' }}>
                            {adsetList?.map((adset, index) => (
                              <Tr key={index} style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                <Td padding="6px">
                                  <Text fontSize="12px" padding="0">{adset?.name}</Text>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Flex>
                    </Flex>
                  ) : modalContent?.type === "ADSET" ? (
                    <Flex gap={2} bg={'gray.50'} p={3} borderRadius={'sm'}>
                      <IoBulbOutline />
                      <Text fontSize={'12px'} letterSpacing={'-0.3px'}>
                        Your rule will be applied to all active Ad Set in your account at the time the rule is running.
                      </Text>
                    </Flex>
                  ) : null}

                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button size={'sm'} onClick={closeDetailsModal}>
                  {modalContent?.count ? 'Cancel' : 'OK'}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Flex direction={'column'} gap={4}>
            <Flex gap={'10px'} flex={1} align={'center'}>
              <Text fontWeight={'bold'}>Ad accounts</Text>
              <Box maxWidth={'400px'}>
                {/* <Select
                   value={selectedAdsAccountId}
                   onChange={(e) => {
                    params.set('page_id', 0);
                    navigate({ search: params.toString() }, { replace: true });
                    setSelectedAdsAccountId(e.target.value)
                    setAdRuleResultsIds([]);
                    dispatch(setAfter(''));
                    dispatch(setBefore(''));
                    dispatch(setCurrentPage(0));
                    localStorage.removeItem('after')
                    localStorage.removeItem('page')
                    localStorage.removeItem('currentPage')
                   }}
                   size={'sm'}
                   borderRadius={'md'}
                 >
                   {selectedAdAccounts?.map(account => (
                     <option key={account?.id} value={account?.account_id}>
                       {`${account?.name} (${account?.account_id})`}
                     </option>
                   ))}
                 </Select> */}
                <SearchAdaccount
                  onInitialisation={setSelectedAdsAccountId}
                  accountOptions={AdAccountData?.data}
                  onAccountChange={handleAdaccountChange}
                  onSearchInputChange={handleSearchChange}
                  onSearchInputTouched={setHasSearched}
                  AdsAccountIdFromParams={adsAccountIdFromParams}
                  isAdAccountLoading={isAdAccountLoading}
                />
              </Box>
            </Flex>

            <Flex>
              <Button colorScheme="orange" onClick={openModal} size="sm">
                <AddIcon boxSize={3} />
                &nbsp;Create Rule
              </Button>
            </Flex>
            {hasAdAccountAssigned ? (
              <>
                {AdrulesListError ? (
                  <Flex justifyContent={'center'} alignItems={'center'} height={'50vh'}>
                    <Text color="red.500">
                      {/* {"Sorry, Ad rules not available at this moment. Try again later."} */}
                      {AdrulesListError?.data?.message}
                    </Text>
                  </Flex>
                ) : (
                  (isAdRulesListLoading || isAdRulesResultsLoading)) ? (
                  <>
                      <Spinner mt="20px" />
                  </>
                ) : (
                  <AppTableWithPageMeta
                    columns={columns}
                    data={AdrulesListWithResults || []}
                    pageMeta={adRulesList?.meta || {}}
                    initialState={{ pageIndex: currentPage }}
                    onPageChange={handlePageChange}
                    onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                    searchEnabled={true}
                    isFetching={isFetching}
                    total={'noTotal'}
                  />
                )}
              </>
            ) :
              <Flex justify="center" align="center" height="80vh">
                {/* <Text>No data available</Text> */}
              </Flex>
            }
          </Flex>

          <CreateRuleForm isOpen={isModalOpen} onClose={closeModal} adAccounts={selectedAccountData} onSuccess={handleSuccess} />
          <UpdateRuleForm isOpen={isOpen} onClose={onClose} adAccounts={selectedAccountData} onSuccess={handleSuccess} ruleData={selectedAdRule} />

        </>
      </SidebarWithHeader>
      {/* ) : (
      <SidebarWithHeader>
      <Flex>
        <Text>
          Your Ad account is not set up yet. Please request an Ad account.
        </Text>
      </Flex>
      </SidebarWithHeader>
    )} */}
    </>
  )
}

export default AdRules