import { InfoIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Collapse, Flex, Icon, Input, Select, Text, Textarea, Tooltip, useToast } from '@chakra-ui/react';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { FiChevronUp } from "react-icons/fi";
import { MdDelete, MdEdit } from "react-icons/md";
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { useCreateAdMutation } from 'store/campaigns/adsApi';
import { selectSelectedAdsetId } from 'store/campaigns/campaignsSlice';
import { object, string, number } from "yup";

function CreateCarouselAdForm({ adFormik, onClose, call_to_action_options, adsAccountData, onSuccess, onSave }) {
  const toast = useToast();
  const [isDriveOpen, setIsDriveOpen] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [mediaFilesState, setMediaFilesState] = useState({});
  const [maxCards] = useState(10);
  const selectedAdsetId = useSelector(selectSelectedAdsetId);

  const [createAd, {isLoading: isAdsCreating} ] = useCreateAdMutation();

  const toggleAccordion = (id) => {
    setOpenAccordionId(openAccordionId === id ? null : id);
  };


  const handleInputChange = (fileId, field, value) => {

    setMediaFilesState(prevState => ({
      ...prevState,
      [fileId]: {
        ...prevState[fileId],
        [field]: value,
      },
    }));
  };

  const handleMediaTypeChange = (type) => {
    setMediaType(type);
    setIsDriveOpen(true);
  }

  useEffect(() => {
    if (mediaFiles.length > 0) {
      setOpenAccordionId(mediaFiles[0]._id);
    }
  }, [mediaFiles]);

  const handleFileSelect = (files) => {

    if (files.length > 0) {
        setMediaFiles(prevFiles => {
          // Add new files only if the total number of files is less than maxCards
          const newFiles = [...prevFiles, ...files].slice(0, maxCards);
          return newFiles;
        });
      }
  };

  const truncateName = (name, maxLength = 14) => {
    if (name?.length > maxLength) {
      return name?.substring(0, maxLength) + '...';
    }
    return name;
  };

  let schema = object().shape({
    cpa: number()
          .typeError('must be a number')
          .positive('must be a positive number'),
    destinationType: string(),
    type: string()
      .max(300, 'select atleast one type')
      .required('Type is required'),
    // message: string().when('applyForAll', {
    //   is: true,
    //   then: () => string().required('Required'),
    //   otherwise: () => string().notRequired(),
    // }),
    // message: string().required('Text required'),
    link: string()
      .url('Please add a valid URL')
    .when('destinationType', {
      is: 'WEBSITE',
      then: () => string().required('Website URL is required'),
      otherwise: () => string().notRequired(),
    }),
    phoneNumber: string().
      when('destinationType', {
        is: 'PHONE_CALL',
        then: () => string().required('Phone number is required'),
        otherwise: () => string().notRequired(),
      })
      .min(10, 'Phone number is too short')
      .max(15, 'Phone number is too long'),
  })

  const singleMediaFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      destinationType: selectedAdsetId?.destination_type,
      message: '',
      name: adFormik.values?.name,
      description: '',
      link: '',
      type: 'SHOP_NOW',
      mediaFiles: [],
      phoneNumber: '',
      cpa:'',
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {

      const { name, message, type, link, phoneNumber, cpa } = values;

      const payload = {
        name: name,
        cpa: Number(cpa),
        adset_id: selectedAdsetId?.adset_id,
        adcreative: {
          name: name ,
          object_story_spec: {
              link_data: {
                ...(message && { message: message }),
                  link: phoneNumber?.length ? `tel:+${phoneNumber}` : link,
                  call_to_action: {
                      type: phoneNumber?.length ? 'CALL_NOW' : type,
                  },
                  child_attachments: mediaFiles?.map((file) => {
                      if (file?.creativeType === 'video') {
                          return {
                              link: mediaFilesState[file.id]?.link,
                              picture: file?.picture,
                              name: mediaFilesState[file.id]?.name ,
                              video_id: file?.creativeId,
                              description: mediaFilesState[file.id]?.description,
                              hash: file?.hash,
                          };
                      } else if (file?.creativeType === 'image') {
                          return {
                              link: mediaFilesState[file.id]?.link,
                              picture: file?.url,
                              name: mediaFilesState[file.id]?.name,
                              //image_hash: file?.creativeId,
                              description: mediaFilesState[file.id]?.description,
                              hash: file?.hash,
                          };
                      }
                      return null; 
                  }).filter(Boolean), // Remove null values from the array
              },
              instagram_actor_id: adFormik.values?.accounts[0]?.instagram_actor_id,
              page_id: adFormik.values?.accounts[0]?.page_id,
          },
          degrees_of_freedom_spec: {
              creative_features_spec: {
                  standard_enhancements: {
                      enroll_status: "OPT_OUT",
                  },
              },
          },
        },
        adsAccount: {
          id: adsAccountData?.id,
          adsAccountId: adsAccountData?.account_id,
          adsAccountName: adsAccountData?.name,
          businessName: adsAccountData?.business?.name,
          businessId: adsAccountData?.business?.id,
        },
      };
      createAd({ payload })
              .unwrap()
              .then(response => {
                  onSuccess();
                  toast({
                      position: "top-right",
                      title: "Ad Created",
                      status: "success",
                      duration: 4000,
                      isClosable: true,
                  });
              })
              .catch(error => {
                  toast({
                      position: "top-right",
                      title: "Error creating Ad",
                      status: "error",
                      duration: 4000,
                      isClosable: true,
                  });
              })
              .finally(() => {
                  setMediaType(null);
                  setMediaFiles([]); // Clear the media files
                  resetForm();
                  onClose();
          });

    },
  });


  const handleDeleteFile = (indexToDelete) => {
    setMediaFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
  };

  useEffect(() => {
    singleMediaFormik.setFieldValue('mediaFiles', mediaFiles)
  }, [mediaFiles])

  const isAnyUrlMissing = mediaFiles.some(file => {
    const fileId = file?.id;
    const fileState = mediaFilesState[fileId] || {};
    return !fileState.link || fileState.link.trim() === '';
  });


  return (
    <>
      <DriveModal
        isDriveModalOpen={isDriveOpen}
        mediaType={mediaType}
        onClose={() => {
          setIsDriveOpen(false);
          setMediaType(null);
        }}
        onSubmit={handleFileSelect}
        mediaFormat={adFormik?.values?.format}
        selectedAdAccounts={[{
          id: adsAccountData?.id,
          adsAccountId: adsAccountData?.account_id,
          adsAccountName: adsAccountData?.name,
          businessName: adsAccountData?.business?.name,
          businessId: adsAccountData?.business?.id
        }]}
        uploadPurpose="create"
        adFormik={adFormik}
      />
      <Flex direction={'column'} width={'100%'} gap={2}>
      <Flex direction="column" gap={2} bg={'gray.50'} p={4} borderRadius={'md'}>
        <Flex direction="column" mb={4}>
          
          <Flex direction="column" mb="10px">
            <Flex alignItems={'center'} gap={2}>
              <Text fontSize="15px" fontWeight={'bold'}>Carousel cards</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="14px" p="5px">
                    <Text>
                      Choose media and enter text for up to ten carousel cards.
                    </Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon boxSize={3} pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Text fontSize="13px" flex={1}>
              {mediaFiles.length} of {maxCards} cards added
            </Text>
            {mediaFiles?.length < 2 ? (
              <Flex align={'center'} gap={'5px'} px={'10px'} py={'5px'} my={'5px'} borderRadius={'sm'} bg={'gray.100'}>
                <InfoIcon boxSize={3} color={'gray.800'} />
                <Text fontSize="13px">Add at least 2 cards.</Text>
              </Flex>
            ) : null}
          </Flex>

          {/* Single Select component for each adAccount */}
          <Flex direction="column" flexShrink={0}>
            <Select
              placeholder={"Add cards"}
              width={'150px'}

              name="mediaOption"
              borderRadius={'md'}
              value={mediaType || ""}
              onChange={(e) => {
                handleMediaTypeChange(e.target.value);
              }}
              size="sm"
              mb={2}
            >
              <option value="image">{"Add image cards"}</option>
              <option value="video">{"Add video cards"}</option>
            </Select>
          </Flex>


          {/* Media Previews */}
          {mediaFiles?.length > 0 && (
            <Box mt={4}>
              <Flex wrap="wrap" gap={2} direction="column">
                {mediaFiles?.map((file, index) => {
                  const fileId = file?.id;       
                  const fileState = mediaFilesState[fileId] || {};

                  // Checking if URL is empty
                  const isUrlRequired = !fileState.link || fileState.link.trim() === '';

                  return (
                    <Box key={index} p={1} borderWidth={1} borderRadius="md" borderColor="gray.200" position={'relative'}>
                      <Flex alignItems="center" gap={2} justify="space-between">
                        <Flex alignItems="center" gap={2}>
                          {file?.creativeType === 'image' ? (
                            <img
                              src={file?.url}
                              alt={file?.name}
                              style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }}
                            />
                          ) : (
                            <video
                              src={file?.source}
                              // controls
                              style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }}
                            />
                          )}
                          <Flex direction="column">
                            <Text fontSize="10px" noOfLines={1}>
                              {fileState.name || file?.name || 'No name available'}
                            </Text>
                            <Flex gap={1}>
                              {fileState.description ? (
                                <Text fontSize="10px" noOfLines={1}>{truncateName(fileState.description)}</Text>
                              ) : (
                                <Text fontSize="10px">Enter a description</Text>
                              )}
                              {!fileState.link ? (
                                <>
                                  <Text fontSize="10px">and</Text>
                                  <Text fontSize="10px">URL&nbsp;
                                    <Tooltip

                                      borderRadius={5}
                                      label={
                                        <Box p="5px" >
                                          <Text fontSize="12px">
                                            Your carousel card {index + 1} doesn't have a destination. Please enter a URL for each card in your carousel.
                                          </Text>
                                        </Box>
                                      }
                                      fontSize="md"
                                    >
                                      <InfoOutlineIcon color={'red.500'} pointerEvents="all" />
                                    </Tooltip>
                                  </Text>
                                </>
                              ) : null}
                            </Flex>
                            <Text fontSize="10px" fontWeight={'bold'} noOfLines={1}>{truncateName(file?.adsAccount?.adsAccountName)}</Text>
                          </Flex>
                        </Flex>
                        <Flex alignItems="center" gap={'5px'}>
                          <Tooltip label="Edit copy" fontSize="xs">
                            <Box>
                              <Icon
                                _hover={{ color: 'gray.500' }}
                                as={openAccordionId === fileId ? FiChevronUp : MdEdit}
                                cursor="pointer"
                                fontSize="14px"
                                onClick={() => toggleAccordion(fileId)}
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip label="Delete" fontSize="xs">
                            <Box>
                              <Icon
                                color="red.500"
                                _hover={{ color: 'gray.400' }}
                                as={MdDelete}
                                cursor="pointer"
                                boxSize={4}
                                onClick={() => handleDeleteFile(index)}
                              />
                            </Box>
                          </Tooltip>
                        </Flex>
                      </Flex>
                      <Collapse in={openAccordionId === fileId}>
                        <Box p={4}>
                          {/* Headline Section */}
                          <Box direction="column" mb={4}>
                            <Flex alignItems="center" mb={2} gap={2}>
                              <Text fontSize="14px" fontWeight="bold">Headline</Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="14px" p="5px">
                                    <Text>
                                      Add up to five brief headlines to let people know what your ad is about. Each
                                      headline can have a maximum of 255 characters. Headlines won't appear in all
                                      placements.
                                    </Text>
                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <Input
                              placeholder="Tell people what your ad is about..."
                              size="sm"
                              borderRadius="md"
                              value={fileState.name || ''}
                              onChange={(e) => handleInputChange(fileId, 'name', e.target.value)}
                            />
                          </Box>

                          {/* Description Section */}
                          <Box direction="column" mb={4}>
                            <Flex alignItems="center" mb={2} gap={2}>
                              <Text fontSize="14px" fontWeight="bold">Description</Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="14px" p="5px">
                                    <Text>
                                      Add up to five link descriptions to emphasize why people should visit your
                                      website. This won't appear in all placements.
                                    </Text>
                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <Textarea
                              placeholder="Include additional details"
                              size="sm"
                              borderRadius="md"
                              value={fileState.description || ''}
                              onChange={(e) => handleInputChange(fileId, 'description', e.target.value)}
                              rows={2}
                            />
                          </Box>

                          {/* Conditional Input based on Destination Type */}
                          {selectedAdsetId?.destination_type === 'PHONE_CALL' ? (
                            <Box>
                                <Flex alignItems="center" mt={'10px'} mb={2}>
                                  <Text fontWeight="bold" fontSize={'14px'}>Call now</Text>
                                  <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                  <Tooltip
                                      borderRadius={5}
                                      label={
                                          <Box fontSize="sm" p="5px">
                                          <Text>You can use a different phone number for each carousel card. This can be helpful if you have different phone numbers or extensions.</Text>
                                          </Box>
                                      }
                                      fontSize="md"
                                      >
                                      <InfoOutlineIcon pointerEvents="all" />
                                  </Tooltip>
                              </Flex>
                              <PhoneInput
                                country="in"
                                value={fileState.link || ''}
                                onChange={(value) => handleInputChange(fileId, 'link', value)}
                                inputProps={{
                                  name: 'phoneNumber',
                                  required: true,
                                }}
                                enableSearch={true}
                                searchPlaceholder="Search"
                              />
                              {isUrlRequired && (
                                <Text fontSize="12px" color="red.500" mt={2}>
                                  Your carousel card {index + 1} doesn't have a destination. Please enter a phone number for each card in your carousel.
                                </Text>
                              )}
                            </Box>
                          ) : (
                            // URL Link Section
                            <Box direction="column" mb={4}>
                              <Flex alignItems="center" mb="5px">
                                <Text fontSize="14px" fontWeight="bold">Website URL</Text>
                                <Text display="inline-flex" fontSize="18px" color="red.400" ml={2} mr={2}>*</Text>
                                <Tooltip
                                  borderRadius={5}
                                  label={
                                    <Box fontSize="14px" p="5px">
                                      <Text>
                                        Enter the URL for the web page that you want people to visit.
                                      </Text>
                                    </Box>
                                  }
                                  fontSize="md"
                                >
                                  <InfoOutlineIcon pointerEvents="all" />
                                </Tooltip>
                              </Flex>
                              <Input
                                placeholder="http://www.example.com/page"
                                size="sm"
                                borderRadius="md"
                                value={fileState.link || ''}
                                onChange={(e) => handleInputChange(fileId, 'link', e.target.value)}
                                isInvalid={isUrlRequired}
                              />
                              {isUrlRequired && (
                                <Text fontSize="12px" color="red.500" mt={2}>
                                  Your carousel card {index + 1} doesn't have a destination. Please enter a website URL for each card in your carousel.
                                </Text>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Collapse>

                    </Box>
                  );
                })}
              </Flex>
            </Box>
          )}
        </Flex>

        {/* Primary Text Section */}
        <Box direction="column" mb={4}>
          <Flex alignItems="center" mb={2}>
            <Text fontWeight="bold" fontSize="14px">Primary text</Text>
            {/* <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text> */}
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>
                    The primary text for your ad appears in most placements, though the position
                    varies. You can use @ to tag Facebook profiles or Pages. We recommend 125
                    characters or less.
                  </Text>
                </Box>
              }
              fontSize="md"
            >
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Textarea
            size="sm"
            placeholder="Tell people what your ad is about..."

            name="message"
            value={singleMediaFormik.values.message}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            borderRadius="md"
          />
          {singleMediaFormik.touched.message && singleMediaFormik.errors.message ? (
            <Text color="red.500" fontSize="12px">{singleMediaFormik.errors.message}</Text>
          ) : null}
        </Box>

        {/* Call to action Section */}
        {selectedAdsetId?.destination_type != 'PHONE_CALL' ? (
          <Box direction="column">
            <Flex alignItems="center" mb={2} gap={2}>
              <Text fontWeight="bold" fontSize="14px">Call to action</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="sm" p="5px">
                    <Text>Show a button or link on your ad that represents the action you want people to take.</Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Select
              name="type"

              borderRadius="md"
              value={singleMediaFormik.values.type}
              onChange={singleMediaFormik.handleChange}
              onBlur={singleMediaFormik.handleBlur}
              size="sm"
            >
              {call_to_action_options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            {singleMediaFormik.touched.type && singleMediaFormik.errors.type ? (
              <Text color="red.500">{singleMediaFormik.errors.type}</Text>
            ) : null}
          </Box>
        ) : (
          <Box direction="column" mb={'10px'}>
            <Flex alignItems="center" mb={2} gap={2}>
              <Text fontWeight="bold" fontSize="14px">Call to action</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="sm" p="5px">
                    <Text>This is the only available option.</Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Select
              name="type"
              borderRadius="md"
              value={singleMediaFormik.values.type}
              onChange={singleMediaFormik.handleChange}
              onBlur={singleMediaFormik.handleBlur}
              size="sm"
              isDisabled
            >
              <option value="CALL_NOW">Call Now</option>
            </Select>
        </Box>
        )}

        {singleMediaFormik.touched.mediaType && singleMediaFormik.errors.mediaFiles ? (
          <Text color="red.500" fontSize="12px">{singleMediaFormik.errors.mediaFiles}</Text>
        ) : null}

        {selectedAdsetId?.destination_type == 'WEBSITE' ? (
        <Box direction="column">
          <Flex alignItems="center" mt={'10px'} mb={2}>
            <Text fontWeight="bold" fontSize={'14px'}>Website URL</Text>
            <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>Enter the URL for the webpage you want people to visit</Text>
                </Box>
              }
              fontSize="md"
            >
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Input
            type="url"
            name="link"

            borderRadius={'md'}
            placeholder="Enter URL"
            value={singleMediaFormik.values.link}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            size={'sm'}
          />
          {singleMediaFormik.touched.link && singleMediaFormik.errors.link ? (
            <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.link}</Text>
          ) : null}
        </Box>
        ) : null}

        {selectedAdsetId?.destination_type == 'PHONE_CALL' ? (
          <Box >
            <Flex alignItems="center" mt={'10px'} mb={2}>
              <Text fontWeight="bold" fontSize={'14px'}>Call now</Text>
              <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
              <Tooltip
                  borderRadius={5}
                  label={
                    <Box fontSize="sm" p="5px">
                      <Text>You can use a different phone number for each carousel card. This can be helpful if you have different phone numbers or extensions.</Text>
                    </Box>
                  }
                  fontSize="md"
                >
                <InfoOutlineIcon pointerEvents="all" />
              </Tooltip>
            </Flex>
            <PhoneInput
              country={'in'}
              value={singleMediaFormik.values.phoneNumber}
              onChange={(value) => {
                singleMediaFormik.setFieldValue('phoneNumber', value);
              }}
              name='phoneNumber'
              inputProps={{
                name: 'phoneNumber',
                required: true,
                //autoFocus: true,
              }}
              enableSearch={true}
              searchPlaceholder="Search"
            />
            {singleMediaFormik.touched.phoneNumber && singleMediaFormik.errors.phoneNumber ? (
              <Text color="red.500" fontSize={'12px'} mt={'5px'}>{singleMediaFormik.errors.phoneNumber}</Text>
            ) : null}
          </Box>
        ) : null}
      </Flex>

      <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4}>
          <Flex direction="column" gap={1}>
              <Text fontWeight="bold">CPA</Text>
              <Input
                  name="cpa"
                  placeholder="Enter CPA"
                  bg="#fff"
                  size='sm'
                  borderRadius="md"
                  value={singleMediaFormik.values.cpa}
                  onBlur={singleMediaFormik.handleBlur}
                  onChange={singleMediaFormik.handleChange}
              />
              {singleMediaFormik.touched.cpa && singleMediaFormik.errors.cpa && (
                  <Text fontSize={"12px"} color="red.500">{singleMediaFormik.errors.cpa}</Text>
              )}
          </Flex>
      </Flex>


       {/* Save Button */}
       <Flex>
          <Button
            colorScheme="orange"
            onClick={() => {
              singleMediaFormik.handleSubmit();
              onSave();
            }}
            isLoading={isAdsCreating}
            size="sm"
            isDisabled={mediaFiles?.length < 2 || isAnyUrlMissing }
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default CreateCarouselAdForm;

