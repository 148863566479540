import { api } from "../api";
import { setHasAdAccountAssigned, setAdAccountsLoading, setHasAdAccountChecked } from "./bmAccountSlice";

export const BmAccountsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFbLogin: builder.query({
      query: () => ({
        url: `/facebook`,
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),
    getFbActiveOrDeactive: builder.query({
      query: ({ businessId }) => {
        const url = businessId ? `/connect-bm/me?businessId=${businessId}` : '/connect-bm/me';
        return { url };
      },
      transformResponse: (response, meta, arg) => response.data,
    }),
    getUserName: builder.query({
      query: ({ businessId }) => ({
        url: `/connect-bm/${businessId}/user`,
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),
    logoutRelation: builder.mutation({
      query: () => ({
        url: `/connect-bm/logout`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BmAccount'],
    }),
    createClient: builder.mutation({
      query: ({ payload }) => ({
        url: "/facebook/create-client",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ['BmAccount'],
    }),
    getBusinessAccounts: builder.query({
      query: () => ({
        url: `/connect-bm/me/businesses`,
      }),
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    creatBmRelation: builder.mutation({
      query: ({ existing_client_business_id, name }) => ({
        url: `/connect-bm/managed_businesses?existing_client_business_id=${existing_client_business_id}&name=${name}`,
        method: "POST",
      }),
      invalidatesTags: ['BmAccount'],
    }),
    getOwnedPages: builder.query({
      query: ({ search, businessId, system_token = false }) => {
        let url = `/connect-bm/${businessId}/owned_pages`;

        if (search) {
          url += `?search=${search}`;
        }

        if (system_token) {
          url += search ? `&system_token=true` : `?system_token=true`;
        }

        return {
          url,
        };
      },
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    getClientPages: builder.query({
      query: ({ search, businessId, system_token = false }) => {
        let url = `/connect-bm/${businessId}/client_pages`;

        if (search) {
          url += `?search=${search}`;
        }

        if (system_token) {
          url += search ? `&system_token=true` : `?system_token=true`;
        }

        return {
          url,
        };
      },
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    getPages: builder.query({
      query: ({ search }) => {
        let url = `/connect-bm/me/accounts`;

        if (search) {
          url += `?search=${search}`;
        }

        return {
          url,
        };
      },
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    assignPages: builder.mutation({
      query: ({ pageId, businessId }) => ({
        url: `/connect-bm/${pageId}/assign_pages/?businessId=${businessId}`,
        method: "POST",
      }),
      invalidatesTags: ['BmAccount'],
    }),
    getOwnedAdAccounts: builder.query({
      query: ({ search, businessId, system_token = false }) => {
        const searchQuery = search ? `?search=${search}` : '';
        let url = `/connect-bm/${businessId}/owned_ad_accounts${searchQuery}`;

        if (system_token) {
          url += searchQuery ? `&system_token=true` : `?system_token=true`;
        }

        return {
          url,
        };
      },
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    getClientAdAccounts: builder.query({
      query: ({ search, businessId, system_token = false }) => {
        const searchQuery = search ? `?search=${search}` : '';
        let url = `/connect-bm/${businessId}/client_ad_accounts${searchQuery}`;

        if (system_token) {
          url += searchQuery ? `&system_token=true` : `?system_token=true`;
        }

        return {
          url,
        };
      },
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    getBmAdAccounts: builder.query({
      query: ({ search, after, pageSize }) => {
        const searchQuery = search ? `?search=${search}` : '';
        const afterQuery = after ? (searchQuery ? `&after=${after}` : `?after=${after}`) : '';
        const pageSizeQuery = pageSize ? (searchQuery || afterQuery ? `&pageSize=${pageSize}` : `?pageSize=${pageSize}`) : '';

        let url = `/connect-bm/me/adaccounts${searchQuery}${afterQuery}${pageSizeQuery}`;

        return {
          url,
        };
      },
      transformResponse: (response, meta, arg) => response.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        dispatch(setAdAccountsLoading(true));
        try {
          const { data: { data: bmAdAccountList } } = await queryFulfilled;
          let hasAdAccountAssigned = bmAdAccountList?.length > 0;
          dispatch(setHasAdAccountAssigned(hasAdAccountAssigned));
        } catch (error) {
          // console.error('Error in onQueryStarted:', error);
        } finally {
          dispatch(setAdAccountsLoading(false));
          dispatch(setHasAdAccountChecked(true));
        }
      }
    }),
    getBmAdAccountsDetails: builder.query({
      query: ({ adsAccounts }) => ({
        url: `/connect-bm/get/adaccount-details?adsAccounts=${encodeURIComponent(JSON.stringify(adsAccounts))}`,
      }),
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['BmAccount'],
    }),
    assignAdAccounts: builder.mutation({
      query: ({ accountId, payload }) => ({
        url: `/connect-bm/${accountId}/assign_adaccounts/`,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ['BmAccount'],
    }),
    getOwnedPixels: builder.query({
      query: ({ search, businessId, system_token = false }) => {
        const searchQuery = search ? `?search=${search}` : '';
        let url = `/connect-bm/${businessId}/owned_pixels${searchQuery}`;

        if (system_token) {
          url += searchQuery ? `&system_token=true` : `?system_token=true`;
        }

        return {
          url,
        };
      },
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    getClientPixels: builder.query({
      query: ({ search, businessId, system_token = false }) => {
        const searchQuery = search ? `?search=${search}` : '';
        let url = `/connect-bm/${businessId}/client_pixels${searchQuery}`;

        if (system_token) {
          url += searchQuery ? `&system_token=true` : `?system_token=true`;
        }

        return {
          url,
        };
      },
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    getPixelsByAdaccount: builder.query({
      query: ({ adsAccountId }) => ({
        url: `/connect-bm/${adsAccountId}/adpixels`,
      }),
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    assignPixels: builder.mutation({
      query: ({ pixelId, payload }) => ({
        url: `/connect-bm/${pixelId}/assign_pixels`,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ['BmAccount'],
    }),
    getPixelsBySharedAccounts: builder.query({
      query: ({ pixelId, businessId }) => ({
        url: `/connect-bm/${pixelId}/shared_accounts?businessId=${businessId}`,
      }),
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    getOwnedInstagramAccounts: builder.query({
      query: ({ search, businessId, system_token = false }) => {
        const searchQuery = search ? `?search=${search}` : '';
        let url = `/connect-bm/${businessId}/owned_instagram_accounts${searchQuery}`;

        if (system_token) {
          url += searchQuery ? `&system_token=true` : `?system_token=true`;
        }

        return {
          url,
        };
      },
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    getOriginalInstagramAccounts: builder.query({
      query: ({ search, businessId, system_token = false }) => {
        const searchQuery = search ? `?search=${search}` : '';
        let url = `/connect-bm/${businessId}/owned_instagram_accounts${searchQuery}`;

        if (system_token) {
          url += searchQuery ? `&system_token=true` : `?system_token=true`;
        }

        return {
          url,
        };
      },
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    getInstagramByAdaccount: builder.query({
      query: ({ adsAccountId }) => ({
        url: `/connect-bm/${adsAccountId}/instagram_accounts_by_adAccount`,
      }),
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    assignInstagramAccounts: builder.mutation({
      query: ({ instaId, payload }) => ({
        url: `/connect-bm/${instaId}/assign_instagrams`,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ['BmAccount'],
    }),
    getInstagramByAuthorizedAdaccounts: builder.query({
      query: ({ instaId, businessId }) => ({
        url: `/connect-bm/${instaId}/authorized_adaccounts?businessId=${businessId}`,
      }),
      transformResponse: (response, meta, arg) => response.data.data,
    }),
    getInstagramByPageID: builder.query({
      query: ({ pageId }) => ({
        url: `/connect-bm/${pageId}/page_backed_instagram_accounts`,
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),
    getInstagramPageDetails: builder.query({
      query: ({ instaId }) => ({
        url: `/connect-bm/instagram/details/${instaId}`,
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const {
  useGetFbLoginQuery, useGetFbActiveOrDeactiveQuery, useGetUserNameQuery, useGetPagesQuery, useLogoutRelationMutation, useCreateClientMutation, useGetBusinessAccountsQuery, useCreatBmRelationMutation,
  useGetOwnedPagesQuery, useGetClientPagesQuery, useAssignPagesMutation, useGetOwnedAdAccountsQuery, useGetBmAdAccountsDetailsQuery,
  useGetClientAdAccountsQuery, useGetBmAdAccountsQuery, useAssignAdAccountsMutation, useGetOwnedPixelsQuery, useGetClientPixelsQuery,
  useGetPixelsByAdaccountQuery, useAssignPixelsMutation, useGetPixelsBySharedAccountsQuery, useAssignInstagramAccountsMutation, useGetOriginalInstagramAccountsQuery,
  useGetOwnedInstagramAccountsQuery, useGetInstagramByAdaccountQuery, useGetInstagramByAuthorizedAdaccountsQuery,
  useGetInstagramByPageIDQuery, useGetInstagramPageDetailsQuery
} = BmAccountsApi;
