import { Box, Button, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

function Footer({ step, handleCloseForm, handleBack, handleNext, forms, campaignData, canPublish, onPublishCampaign, adsetsData }) {
    const userInfo = useSelector((state) => state.auth?.userInfo);


    return (
        <Flex width="100%" alignItems="center" justifyContent="space-between" borderTop="1px solid" borderColor="gray.200" px={'20px'} py={2}>
            <Box>
                <Button onClick={handleCloseForm} variant="outline" backgroundColor="transparent" size={'sm'}>
                    Close
                </Button>
            </Box>
            <Flex gap={'10px'}>
                {step > 0 && (
                    <Button onClick={handleBack} variant="outline" backgroundColor="transparent" size={'sm'}>
                        Back
                    </Button>
                )}
                {step < forms?.length - 1 ? (
                    <Button onClick={handleNext} colorScheme="orange" size={'sm'} isDisabled={campaignData?.adsets?.length == 0 || adsetsData?.data.length == 0}>
                        Next
                    </Button>
                ) : (
                    <Button colorScheme="orange" size={'sm'} onClick={onPublishCampaign} isDisabled={!canPublish || userInfo?.role !== 'client'}>
                        Publish
                    </Button>
                )}
            </Flex>
        </Flex>
    );
}

export default Footer;
