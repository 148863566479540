import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Badge, Box, Button, Flex, Spinner, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useColorMode, useColorModeValue, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiOutlineUserAdd } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { useAssignAdAccountsMutation, useGetClientAdAccountsQuery, useGetOwnedAdAccountsQuery } from 'store/BmAccounts/bmAccountApi';
import { selectSelectedAdAccounts, selectSelectedBmAccounts, setRefetched } from 'store/BmAccounts/bmAccountSlice';
import SearchInput from './SearchInput';

function AdAccounts() {
  const toast = useToast();
  const dispatch = useDispatch();
  const bmAccount = useSelector(selectSelectedBmAccounts);
  const AdAccount = useSelector(selectSelectedAdAccounts);
  const [searchValue, setSearchValue] = useState('');

  const [assignedAdAccounts, setAssignedAdAccounts] = useState([]);
  const [loadingAssigned, setLoadingAssigned] = useState(true);
  const [loadingStates, setLoadingStates] = useState({});  

  const boxColor = useColorModeValue('white', 'transparent');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.600');

  //owned ad accounts
  const { data: normalOwnedAdAccounts = [], isFetching: isOwnedFetching, isLoading: isOwnedLoading } = useGetOwnedAdAccountsQuery(
    { search: searchValue, businessId: bmAccount?.businessId},
    { skip: !bmAccount?.businessId}
  );

  // client ad accounts
  const { data: normalClientAdAccounts = [], isFetching: isClientFetching, isLoading: isClientLoading } = useGetClientAdAccountsQuery(
    { search: searchValue, businessId: bmAccount?.businessId},
    { skip: !bmAccount?.businessId}
  );

  // Combine normal and client ad accounts, avoiding duplicates
  const combinedAdAccounts = [
    ...normalOwnedAdAccounts,
    ...normalClientAdAccounts.filter(account => !normalOwnedAdAccounts.some(ownedAdAccount => ownedAdAccount?.account_id === account?.account_id))  // Avoid duplicates
  ];
  
  // useEffect(() => {
  //   if (isOwnedLoading || isClientLoading) {
  //     setAssignedAdAccounts([]); 
  //   }
  // }, [isOwnedLoading, isClientLoading]);
  

  // Update assigned ad accounts based on system token data
  useEffect(() => {
    if (AdAccount.length) {
      const newAssignedAdAccounts = [
        ...AdAccount.map(account => account?.account_id),
      ];
      if (newAssignedAdAccounts.length !== assignedAdAccounts.length) {
        setAssignedAdAccounts(newAssignedAdAccounts);
      }       
    }
    setLoadingAssigned(false);
  }, [AdAccount]);
  
  const [assignAdAccounts] = useAssignAdAccountsMutation();

  const handleAssignAdAccounts = (account) => {

    setLoadingStates(prevState => ({
      ...prevState,
      [account?.account_id]: true,
    }));

    const payload = {
      adsAccountName : account?.name,
      businessName : bmAccount?.businessName,
      businessId : bmAccount?.businessId
    }

    assignAdAccounts({ accountId:account?.account_id, payload })
      .unwrap()
      .then((response) => {
        toast({
          position: "top-right",
          title: "Assigned ad account successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        setAssignedAdAccounts(prev => [...prev, account?.account_id]);
        dispatch(setRefetched(true))

        // Set the account as not loading
        setLoadingStates(prevState => ({
          ...prevState,
          [account?.account_id]: false,
        }));
      })
      .catch((error) => {
        toast({
          position: "top-right",
          title: "Error in assigning ad account",
          status: "error",
          duration: 4000,
          isClosable: true,
        });

        // Set the account as not loading
        setLoadingStates(prevState => ({
          ...prevState,
          [account?.account_id]: false,
        }));
      });
  };

  return (
    <Flex direction={'column'}>
      <Box display={'flex'} p={4}>
        <Flex alignItems={'center'} gap={2} flex={0.4}>
          <Text fontSize={'lg'} fontWeight={'bold'}>Ad accounts</Text>
          <Tooltip
            borderRadius={5}
            placement={'right-start'}
            label={
              <Box fontSize="12px" p="5px">
                <Text>These are the ad accounts connected to this business portfolio.</Text>
              </Box>
            }
            fontSize="md"
          >
            <InfoOutlineIcon pointerEvents="all" />
          </Tooltip>
        </Flex>
        <Flex flex={0.6}>
            <SearchInput 
                data={combinedAdAccounts}
                isFetching={isOwnedFetching || isClientFetching}
                searchEnabled={true}
                onSearchInputChange={setSearchValue}
                searchTerm={searchValue}
            />
        </Flex>
      </Box>

      <Flex justifyContent={'center'}>
        {isOwnedLoading || isClientLoading ? (
            <Flex justifyContent="center" alignItems="center" h={'50vh'} width={'100%'}>
                <Spinner size="md" />
            </Flex>
        ) : combinedAdAccounts?.length === 0 ? (
            <Flex justifyContent="center" alignItems="center" h={'50vh'} width={'100%'}>
              <Text fontSize={'14px'}>No Ad accounts available</Text>
            </Flex>
        ) : (
        <Box h={'62vh'} overflowY="auto" width={'100%'}>
          <Table variant="unstyled">
            <Thead position={'sticky'} top={'-1px'} zIndex={1} bg={boxColor}>
              <Tr
                  borderTop='1px solid'
                  borderColor={'gray.100'}
                  boxShadow="md" 
              >
                  <Th>Account ID</Th>
                  <Th>Name</Th>
                  <Th>Action</Th>
              </Tr>
              </Thead>
              <Tbody>
              {combinedAdAccounts?.map((account) => (
                  <Tr
                    key={account.id}
                    borderTop={'1px solid'}
                    borderColor={'gray.100'}
                    _hover={{
                        bg: hoverBgColor 
                    }}
                  >
                  <Td fontSize={'14px'}>{account?.account_id}</Td>
                  <Td fontSize={'14px'}>{account?.name}</Td>
                  <Td fontSize={'14px'}>
                      {loadingAssigned ? (
                        null
                      ) : assignedAdAccounts.includes(account?.account_id) ? (
                        <Badge
                        m={1}
                        fontSize={"12px"}
                        colorScheme={'green'}
                        >
                        Assigned
                        </Badge>
                      ) : (
                        <Button 
                          bg={'none'} 
                          border={'1px'} 
                          borderColor={'gray.100'} 
                          borderRadius={'md'} 
                          size="sm" 
                          onClick={() => handleAssignAdAccounts(account)} 
                          isLoading={loadingStates[account?.account_id]}
                          loadingText=""
                        >
                          <AiOutlineUserAdd />
                          &nbsp;Assign
                        </Button>
                      )}
                  </Td>
                  </Tr>
              ))}
              </Tbody>
          </Table>
        </Box>
        )}
      </Flex>
    </Flex>
  );
}

export default AdAccounts;