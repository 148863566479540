import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Spinner } from '@chakra-ui/react';
import CreateSalesForm from './CreateSalesForm';
import CreateLeadsForm from './CreateLeadsForm';
import { useGetAdsetFormQuery } from 'store/campaigns/adsetFormApi';
import { useGetcampaignByIdQuery } from 'store/campaigns/campaignsApi';

function CreateAdsetForm({ isOpen, onClose, adsAccountData, onSuccess }) {

  const queryParams = new URLSearchParams(window.location.search);
  const campaignType = queryParams.get('objective');
  const campaignId = queryParams.get('campaignIds');

  const { data: campaignData, isLoading:isCampaignLoading } = useGetcampaignByIdQuery(
    {campaignId, adsAccountId:adsAccountData?.account_id},
    { skip: !campaignId }
);

  const { data: dataSales, error: errorSales, isLoading: isLoadingSales } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_SALES' }, { skip: campaignType !== 'OUTCOME_SALES' });
  const { data: dataLeads, error: errorLeads, isLoading: isLoadingLeads } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_LEADS' }, { skip: campaignType !== 'OUTCOME_LEADS' });    

  const renderForm = () => {

    if (isLoadingSales || isLoadingLeads || isCampaignLoading) {
        return (
            <Spinner size={'md'} />
        );
    }

    if (campaignType === 'OUTCOME_SALES' && dataSales?.data && campaignData) {
        return (
            <CreateSalesForm
                data={dataSales?.data}
                campaignData={campaignData}
                campaign_id={campaignId}
                adsAccountData={adsAccountData}
                onClose={onClose}
                onSaveSuccess={onSuccess}
            />
        );
    } else if (campaignType === 'OUTCOME_LEADS' && dataLeads?.data && campaignData) {
        return (
            <CreateLeadsForm
                data={dataLeads?.data}
                campaignData={campaignData}
                campaign_id={campaignId}
                adsAccountData={adsAccountData}
                onClose={onClose}
                onSaveSuccess={onSuccess}
            />
        );
    }

    return null;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Adset</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {renderForm()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CreateAdsetForm;
