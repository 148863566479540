import {
    Box,
    Flex,
    FormControl,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Switch,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import AdSetUpdateForm from 'components/genericForms/AdsetUpdateForm';
import { useEffect, useMemo, useState } from 'react';
import { FiDelete, FiEdit2 } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { useGetAdsetsListQuery,useGetAdsetInsightsQuery, useUpdateAdsetStatusMutation,useDeleteAdsetMutation,useGetAdsetByIDQuery} from 'store/campaigns/adsetFormApi';
import adsetMapping from '../../utils/adsetMapping.json'
import moment from 'moment';
import { selectSelectedAdsetId, selectSelectedCampaignId, setSelectedAdsetId, selectAdsetsForInsights,setAdsetsForInsights,setAfter,setBefore,selectAfter,selectBefore,selectCurrentPage,setCurrentPage} from 'store/campaigns/campaignsSlice';
import { useSelector,useDispatch } from 'react-redux';
import AppTableWithPageMeta from 'components/AppTableWithPageMeta';
import { useNavigate, useLocation } from 'react-router-dom';
import currencySymbolMap from 'currency-symbol-map';
import DeleteConfirmationModal from 'components/genericForms/DeleteConfirmationModal';
import { AddIcon } from '@chakra-ui/icons';
import { Button } from 'react-scroll';
import CreateAdsetForm from './Forms/AdsetForms/CreateAdsetForm';


function AdSetsTable({ adsAccountData, startDate, endDate, datePreset, setSelectedTab, onRefresh, onRefreshComplete }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();
    const selectedCampaignId = useSelector(selectSelectedCampaignId);
    const isCampaignSelected = Boolean(selectedCampaignId);
    const selectedAdsetId = useSelector(selectSelectedAdsetId);
    const AdsetsForInsights = useSelector(selectAdsetsForInsights);
    const userInfo = useSelector((state) => state.auth?.userInfo);
    const after = useSelector(selectAfter);
    const before = useSelector(selectBefore);
    const currentPage = useSelector(selectCurrentPage);
    const isSearchDisabled = Boolean(selectedAdsetId);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCreateFormVisible, setIsCreateFormVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedAdset, setselectedAdset] = useState(null);
    const [switchStates, setSwitchStates] = useState({});
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [adsetsListWithInsights, setAdsetsListWithInsights] = useState([]);
    const [isLoadingAccountChange, setIsLoadingAccountChange] = useState(false);
    const [isLoadingAdsetsWithInsights, setIsLoadingAdsetsWithInsights] = useState(true); 
    const currencySymbol = currencySymbolMap(adsAccountData?.currency);

    const { data: adsetsList, isFetching, isLoading:isAdsetListLoading, error: adsetsListError, refetch:refetchAdsetList } = useGetAdsetsListQuery({
        pageSize:'20',
        ...(searchValue ? {} : { after, before }),
        searchValue,
        adsAccountId:adsAccountData?.account_id,
        campaign_ids: selectedCampaignId ? [selectedCampaignId?.campaign_id ? selectedCampaignId?.campaign_id : selectedCampaignId] : [], 
        adset_id: selectedAdsetId?.adset_id ? selectedAdsetId?.adset_id : selectedAdsetId 
    }, { skip: !adsAccountData?.account_id });

    const { data: adsetsInsights, refetch:refetchAdsetInsights, isLoading:isAdsetInsightsLoading, error: AdsetsInsightsError } = useGetAdsetInsightsQuery({
        searchValue,
        adsAccountId: adsAccountData?.account_id,
        ...(datePreset ? {} : { start_date: startDate, end_date: endDate }), // Conditionally add startDate and endDate
        date_preset: datePreset,
        adsets: AdsetsForInsights?.length > 0 ? AdsetsForInsights : [],
    }, { skip: !adsAccountData?.account_id || AdsetsForInsights?.length === 0 });

    const [updateAdsetStatus, { isLoading: isAdsetStatusUpdating }] = useUpdateAdsetStatusMutation({});
    const [deleteAdset] = useDeleteAdsetMutation();
    // console.log({adsetsList});
    // console.log({adsetsInsights})

    const handleSaveSuccess = () => {
        refetchAdsetList();
    };

    useEffect(() => {
        if (adsAccountData?.account_id) {
            setIsLoadingAccountChange(true);
            refetchAdsetList();
        }
    }, [adsAccountData?.account_id]); 

    useEffect(() => {
        if (onRefresh) {
            refetchAdsetList();  
            onRefreshComplete();
        }
    }, [onRefresh,refetchAdsetList]);

    useEffect(() => {
        if (adsetsList?.data) {
            const initialSwitchStates = {};
            adsetsList.data.forEach(adset => {
                initialSwitchStates[adset.adset_id] = adset.status;
            });
            setSwitchStates(initialSwitchStates);

            const formattedAdsets = adsetsList?.data.reduce((acc, adset) => {

                const promotedObject = adset?.promoted_object;
                const customEventType = promotedObject?.custom_event_type;

                // Only add campaigns to the list if custom_event_type is present
                if (adset?.adset_id && customEventType) {
                    acc.push({
                        id: adset?.adset_id,
                        promoted_object: { custom_event_type: customEventType }
                    });
                }else{
                    acc.push({
                        id: adset?.adset_id,
                    });
                }
                return acc;
            }, []);
               
            dispatch(setAdsetsForInsights(formattedAdsets));
        }
    }, [adsetsList]);

    useEffect(() => {
        if (adsetsList?.data || adsetsInsights) {
   
            const adsetsWithInsights = adsetsList?.data.map(adset => {
                const insightData = adsetsInsights?.find(insight => insight?.adset_id === adset?.adset_id);
             
                return {
                    ...adset,
                    insight: insightData?.insights || {},
                };
            });
            setAdsetsListWithInsights(adsetsWithInsights);

            setTimeout(() => {
                setIsLoadingAdsetsWithInsights(false);
            }, 100);
        }
    }, [adsetsList, adsetsInsights]);

    useEffect(() => {
        if (adsetsList?.data || adsetsInsights) {
            setIsLoadingAccountChange(false);
        }
    }, [adsetsList, adsetsInsights]); 

    const handleSwitchChange = (adsetId, currentState) => {
        const newStatus = currentState === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';

        // update the switch state
        setSwitchStates(prev => ({ ...prev, [adsetId]: newStatus }));
        updateAdsetStatus({
            adsetId,
            payload: { 
                status: newStatus,
                adsAccount: {
                    id: adsAccountData?.id,
                    adsAccountId: adsAccountData?.account_id,
                    adsAccountName: adsAccountData?.name,
                    businessName: adsAccountData?.business?.name,
                    businessId: adsAccountData?.business?.id
                },
             }
        }).unwrap()
            .then(() => {
                refetchAdsetList();
                toast({
                    position: "top-right",
                    title: `Adset ${newStatus === 'ACTIVE' ? 'activated' : 'paused'}`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    position: "top-right",
                    title: `Error updating Adset status`,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                console.error('Error updating Adset status:', error);

                // Revert switch state if API call fails
                setSwitchStates(prev => ({ ...prev, [adsetId]: currentState }));
            });
    };

    const handlePageChange = ({ after, before, currentPage }) => {
        if (after) {
            dispatch(setAfter(after));
            dispatch(setBefore(''));
        } else if (before) {
            dispatch(setBefore(before));
            dispatch(setAfter(''));
        }

        dispatch(setCurrentPage(currentPage));
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        params.set('page_id', currentPage);
    
        navigate({ search: params.toString() }, { replace: true });

    }, [currentPage]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const storedPage = params.get('page_id');
        
        if (storedPage && !isNaN(storedPage)) {
            const parsedPage = parseInt(storedPage, 10);
            if (parsedPage >= 0 && parsedPage !== currentPage) {
                dispatch(setCurrentPage(parsedPage));
            }
        } else if (currentPage !== 0) {
            dispatch(setCurrentPage(0));
        }
    }, [location.search]);
     

    const handleDeleteAdset = (adsetData) => {

        deleteAdset({ adsetId: adsetData?.adset_id, adsAccountId: adsAccountData?.account_id, })
          .unwrap()
          .then(response => {
            refetchAdsetList();

            toast({
              position: "top-right",
              title: "Ad set deleted",
              status: "success",
              duration: 4000,
              isClosable: true,
            });
          })
          .catch(error => {
            toast({
              position: "top-right",
              title: "Error deleting ad set",
              status: "error",
              description: error?.data?.message,
              duration: 4000,
              isClosable: true,
            });
          });
    };

    const columns = useMemo(
        () => [
            {
                Header: "Off/On",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
    
                    const isActive = switchStates[original.adset_id] ?? (original.status === 'ACTIVE');
                    const tooltipText = isActive ? 'Ad set is ON' : 'Ad set is OFF';

                    return (
                        <Flex gap={'10px'}  alignItems={'center'} justifyContent={'center'}>              
                            <Tooltip label={tooltipText} fontSize='xs' >
                                <FormControl width={'fit-content'}>
                                    <Switch
                                        colorScheme='orange'
                                        size={'sm'}
                                        isChecked={original.status === 'ACTIVE'}
                                        onChange={() => handleSwitchChange(original.adset_id, original.status)}
                                        isDisabled={userInfo?.role !== 'client'}
                                    />
                                </FormControl>
                            </Tooltip>
                        </Flex>
                    )
                },
            },
            {
                Header: "Name",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isSelected = selectedAdsetId?.adset_id ? selectedAdsetId?.adset_id : selectedAdsetId  === original?.adset_id;
                  
                    return (
                    <Flex gap={'10px'} alignItems={'center'}> 
                        <Tooltip label='Edit' fontSize='xs' >
                            <Flex>
                                <Icon
                                    _hover={{ color: "gray.500" }}
                                    as={FiEdit2}
                                    cursor={userInfo?.role === 'client' ? "pointer" : "not-allowed"} 
                                    color={userInfo?.role === 'client' ? "inherit" : "gray.300"}
                                    onClick={() => {
                                        if (userInfo?.role === 'client') {
                                            setselectedAdset(original);
                                            onOpen();
                                        }
                                    }}
                                />
                            </Flex>
                        </Tooltip>
                        <Flex gap={'10px'} alignItems={'center'}>
                                <Link
                                    to={'#'} 
                                    onClick={() => {
                                        setSelectedTab(2);
                                        dispatch(setSelectedAdsetId(original)); 
                                    }}                      
                                >
                                    <Text
                                        _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        fontWeight={isSelected ? 'bold' : 'normal'}
                                        color={isSelected ? 'orange.500' : 'inherit'}
                                        fontSize={'14px'}
                                    >
                                        {original.name.length > 30 ? `${original.name.slice(0, 30)}...` : original.name}
                                    </Text>
                                </Link>
                        </Flex>
                    </Flex>
                )}
            },  
            {
                Header: "Delivery",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                

                    const getStatusMessage = (status) => {
                        switch (status) {
                            case 'WITH_ISSUES':
                                return 'Delivery error';
                            case 'CAMPAIGN_PAUSED':
                                return 'Campaign Off';
                            case 'ADSET_PAUSED':
                                return 'Adset Off';
                            case 'PAUSED':
                                return 'Off';
                            default:
                                return status || 'Unknown';
                        }
                    };
                    
                    const errorMessage = original?.issues_info?.[0]?.error_message || '';

                   // Function to determine circle color and border based on status
                    const getCircleStyles = (status) => {
                        switch (status) {
                            case 'WITH_ISSUES':
                                return { bg: 'rgb(161,11,24)', border: 'none' };  // Red for delivery error
                            case 'CAMPAIGN_PAUSED':
                            case 'ADSET_PAUSED':
                                return { bg: 'rgb(216,221,225)', border: 'none' };  // Gray for Campaign/Adset Off
                            case 'ACTIVE':
                                return { bg: 'rgb(255,255,255)', border: '2px solid green' };  // Gray background with green border for ACTIVE
                            default:
                                return { bg: 'rgb(255,255,255)', border: 'none' };  // Default gray with no border
                        }
                    };

                    const circleStyles = getCircleStyles(original?.effective_status);

                    // Determine tooltip message
                    const getTooltipMessage = (status) => {
                        switch (status) {
                            case 'WITH_ISSUES':
                                return errorMessage;  
                            case 'PAUSED':
                                return 'This ad set is turned off.';  
                            case 'ACTIVE':
                                return 'This campaign is on and running normally.'; 
                            case 'ADSET_PAUSED':
                                return 'This ad is on, but its ad set is turned off.'; 
                            case 'CAMPAIGN_PAUSED':
                                return 'This ad set is on, but its campaign is turned off.'; 
                            default:
                                return '';  // No tooltip for other statuses
                        }
                    };

                    const tooltipMessage = getTooltipMessage(original?.effective_status);

                    return (
                        <Flex>
                            {/* Only show Tooltip when status is WITH_ISSUES */}
                            {original?.effective_status === 'WITH_ISSUES' ? (
                                <Flex alignItems={'center'}>
                                 <Box
                                    bg={circleStyles.bg}
                                    border={circleStyles.border}
                                    borderRadius="50%" 
                                    boxSize="0.8rem"   
                                    display="inline-block"
                                    mr={2}             
                                />
                                <Tooltip label={tooltipMessage} aria-label="Error message" hasArrow p={2} fontSize={'12px'}>
                                    <Text fontSize={'14px'}>{getStatusMessage(original?.effective_status)}</Text>
                                </Tooltip>
                                </Flex>
                            ) : (
                                <Flex alignItems={'center'}>
                                    {original?.effective_status === 'PAUSED' ? (null) : (
                                       <Box
                                        bg={circleStyles.bg}
                                        border={circleStyles.border}
                                        borderRadius="50%" 
                                        boxSize="0.8rem"   
                                        display="inline-block"
                                        mr={2}             
                                    />
                                    )}
                                    <Tooltip label={tooltipMessage} aria-label="Error message" hasArrow p={2} fontSize={'12px'}>
                                        <Text fontSize={'14px'}>{getStatusMessage(original?.effective_status)}</Text>
                                    </Tooltip>
                                </Flex>
                            )}
                        </Flex>
                    )}
            },   
            {
                Header: "Budget",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const { daily_budget, lifetime_budget } = original;
                    
                    const displayLifetimeBudget = Number(lifetime_budget) > 0 ? (Number(lifetime_budget) / 100).toFixed(2) : null;
                    const displayDailyBudget = Number(daily_budget) > 0 ? (Number(daily_budget) / 100).toFixed(2) : null;

                    // Determine which budget to display
                    const isLifetimeBudget = displayLifetimeBudget !== null;
                    const displayBudget = isLifetimeBudget 
                                            ? `${currencySymbol ? currencySymbol : ''}${displayLifetimeBudget}` 
                                            : (displayDailyBudget !== null 
                                                ? `${currencySymbol ? currencySymbol : ''}${displayDailyBudget}` 
                                                : "No Budget");
                    const budgetType = isLifetimeBudget ? "Lifetime" : (displayDailyBudget !== null ? "Daily" : "");

                    return (
                        <Flex direction={'column'} mb={-4}>
                            <Text fontSize={'14px'}>{displayBudget}</Text>
                            {budgetType && <Text fontSize={'10px'} color={'gray.500'}>{budgetType}</Text>}
                        </Flex>
                    );
                }
            },        
            {
                Header: "Spend",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex>
                        <Text fontSize={'14px'}>{original.insight?.spend ? `${currencySymbol}${Number(original.insight.spend).toFixed(2)}`: '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Results",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex direction={'column'} mb={-4}>
                        <Text fontSize={'14px'}>{original.insight?.results ? original.insight?.results : '-'}</Text>
                        <Text fontSize={'10px'} color={'gray.500'}>{original.insight?.eventName ? (original.insight?.eventName) : null}</Text>
                    </Flex>
                )
            },
            {
                Header: "CPC",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>{original.insight?.cpc ? `${currencySymbol}${Number(original.insight?.cpc).toFixed(2)}` : '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "CTR",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>
                            {original.insight?.ctr ? `${Number(original.insight?.ctr).toFixed(2)}%` : '-'}
                        </Text>
                    </Flex>
                )
            },
            {
                Header: "CPM",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>{original.insight?.cpm ? Number(original.insight?.cpm).toFixed(2) : '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Clicks",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>{original.insight?.clicks ? original.insight?.clicks : '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Purchase Value",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>
                            {original.insight?.purchaseValue && original.insight.purchaseValue !== '-' ?
                                 `${currencySymbol}${parseFloat(original.insight.purchaseValue).toFixed(2)}` :
                                original.insight?.purchaseValue || '-'}
                        </Text>
                    </Flex>
                )
            },
            {
                Header: "Roas",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const roas = original.insight?.roas;
            
                    // Check if roas is a valid number or a non-numeric value like '-'
                    const formattedROAS = (roas && !isNaN(parseFloat(roas))) 
                        ? parseFloat(roas).toFixed(2) 
                        : '-';  // Default to '-' if roas is invalid or non-numeric
            
                    return (
                        <Flex>
                            <Text fontSize={'14px'}>
                                {formattedROAS}
                            </Text>
                        </Flex>
                    );
                }
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text fontSize={'14px'}>{original.status}</Text>
                ),
            },
            {
                Header: "Created Date",
                accessor: "createdDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text fontSize={'14px'}>{moment(original.created_time).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
            {
                Header: "Updated Date",
                accessor: "updatedDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text fontSize={'14px'}>{moment(original.updated_time).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
            {
                Header: "Actions",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Flex gap={'10px'} alignItems={'center'}>
                    <Tooltip label='Delete' fontSize='xs' >
                      <Flex>
                        <Icon
                            _hover={{ color: 'gray.400' }}
                            as={FiDelete}
                            cursor={userInfo?.role === 'client' ? "pointer" : "not-allowed"} 
                            color={userInfo?.role === 'client' ? "red.500" : "gray.300"} 
                            onClick={() => {
                                if (userInfo?.role === 'client') {
                                    setselectedAdset(original);
                                    setIsConfirmationModalOpen(true);
                                }
                            }}
                        />
                      </Flex>
                    </Tooltip>
                  </Flex>
                )
            },
        ],
        [selectedAdsetId,adsAccountData]
    );

    const confirmDelete = () => {
        if (selectedAdset) {
          handleDeleteAdset(selectedAdset);
          setIsConfirmationModalOpen(false);
          setselectedAdset(null);
        }
    };

    const handleOnSuccess = () => {
        refetchAdsetList();
    }

    return (
        <>  
           <Flex mb={2} alignItems="center">
            <Tooltip
                p={2}
                label="To create an Ad set, please select an Campaign from the Campaigns tab."
                isDisabled={isCampaignSelected}
                placement="right"
                borderRadius={'sm'}
                fontSize={'12px'}
            >
                <Box
                as="button"
                display="inline-flex"
                alignItems="center"
                bg={!isCampaignSelected ? "#F8C9C3" : "orange.300"} 
                color={"white"}
                borderRadius="md"
                fontWeight={'bold'}
                px={3}
                py={1.5}
                fontSize={'14px'}
                onClick={() => {
                    if (isCampaignSelected) {
                    setIsCreateFormVisible(true);
                    }
                }}
                _hover={{ cursor: isCampaignSelected ? "pointer" : "not-allowed" }} 
                _disabled={{ bg: "gray.300", cursor: "not-allowed" }} 
                >
                <AddIcon boxSize={3} />
                &nbsp;Create
                </Box>
            </Tooltip>
            </Flex>

            {isCreateFormVisible && (
                <CreateAdsetForm
                    adsAccountData={adsAccountData}
                    isOpen={isCreateFormVisible}
                    onClose={() => setIsCreateFormVisible(false)} 
                    onSuccess={handleOnSuccess}
                />
            )}

            <DeleteConfirmationModal
                isOpen={isConfirmationModalOpen}
                onClose={() => setIsConfirmationModalOpen(false)}
                onConfirm={confirmDelete}
                message="Are you sure you want to delete this Ad set? This action can't be undone."
           />

            {/* EDIT ADSET MODAL */}
            <Modal
                size={"5xl"}
                isCentered={true}
                isOpen={isOpen}
                scrollBehavior={"inside"}
                onClose={() => {onClose(); setselectedAdset(null)}}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Ad set</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <AdSetUpdateForm selectedAdset={selectedAdset} adsAccountData={adsAccountData} onClose={onClose} onSaveSuccess={handleSaveSuccess} />
                    </ModalBody>
                </ModalContent>
            </Modal>
   
            {adsetsListError ? (
                <Flex justifyContent={'center'} alignItems={'center'} height={'50vh'}>
                    <Text color="red.500">
                        {/* {"Sorry, Adsets not available at this moment. Try again later."} */}
                        {adsetsListError?.data?.message}
                    </Text>
                </Flex>
            ) : (isAdsetListLoading || isAdsetInsightsLoading || isLoadingAdsetsWithInsights) ? (
                    <Spinner mt="20px" />
                ) : (
                    <AppTableWithPageMeta
                        columns={columns}
                        data={adsetsListWithInsights || []}
                        searchEnabled={!isSearchDisabled}
                        isFetching={isFetching}
                        initialState={{ pageIndex: currentPage }}
                        pageMeta={adsetsList?.meta || {}}
                        onPageChange={handlePageChange}
                        onSearchInputChange={setSearchValue}
                        searchTerm={searchValue}
                    />
            )}
        </>
    );

}

export default AdSetsTable;
