import {
    Box,
    Button,
    Flex,
    Input,
    Select,
    Spinner,
    Text,
    Textarea,
    useToast,
} from '@chakra-ui/react';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';
import InstagramAccountUpdateSelector from 'components/adspresso/InstagramAccountUpdateSelector';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useGetAdCreativeQuery, useUpdateAdMutation } from 'store/campaigns/adsApi';
import * as Yup from 'yup';


const SingleImageAdUpdateForm = ({ adData, adsAccountData, call_to_action_options, onClose, onSuccess }) => {

    const toast = useToast();
    const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const { data: adCreative, refetch, isLoading } = useGetAdCreativeQuery(
        {
            creativeId: adData?.id,
            adsAccountId: adsAccountData?.account_id
        },
        { skip: !adData?.id }
    );

    const [updateAd] = useUpdateAdMutation();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Ad Name is required'),
        cpa: Yup.number()
            .typeError('must be a number')
            .positive('must be a positive number'),
        creative: Yup.object().shape({
            name: Yup.string().required('Headline is required'),
            object_story_spec: Yup.object().shape({
                link_data: Yup.object().shape({
                    // message: Yup.string().required('Primary Text is required'),
                    // name : Yup.string().required('Headline is required'),
                    // description: Yup.string().required('Description is required'),
                    // link: Yup.string().url('Invalid URL').required('Link URL is required'),
                    image_hash: Yup.string().required('Image Hash is required'),
                    call_to_action: Yup.object().shape({
                        type: Yup.string().required('Call to action type is required'),
                        value: Yup.object().shape({
                            link: Yup.string().when('type', {
                                is: 'CALL_NOW',
                                then: Yup.string().matches(/tel:\+?[0-9]{10,15}/, 'Phone number must be at least 10 digits')
                            }),
                        }),
                    }),
                }),
                page_id: Yup.string().required('Facebook Page ID is required')
            })
        }),
        image_url: Yup.string().url('Invalid URL'),
    });

    const formik = useFormik({
        initialValues: {
            name: adData?.name || '',
            hash: adData?.hash || '',
            cpa: adData?.cpa || '',
            creative: {
                name: adCreative?.name || '',
                object_story_spec: {
                    link_data: {
                        call_to_action: {
                            type: adCreative?.object_story_spec?.link_data?.call_to_action?.type || "SHOP_NOW",
                            value: adCreative?.object_story_spec?.link_data?.call_to_action?.value || {},
                        },
                        message: adCreative?.object_story_spec?.link_data?.message || '',
                        name: adCreative?.object_story_spec?.link_data?.name || '',
                        description: adCreative?.object_story_spec?.link_data?.description || '',
                        link: adCreative?.object_story_spec?.link_data?.link || '',
                        image_hash: adCreative?.object_story_spec?.link_data?.image_hash || '',
                        video_id: adCreative?.object_story_spec?.link_data?.video_id || ''
                    },
                    page_id: adCreative?.object_story_spec?.page_id || '',
                    instagram_actor_id: adCreative?.object_story_spec?.instagram_actor_id || '',
                },
            },
            image_url: adCreative?.image_url || '',
            picture: adCreative?.picture || '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting }) => {


            const isCreativeChanged = () => {
                const original = adCreative || {};
                const updated = values.creative || {};

                // Compare specific fields for the creative
                return (
                    original.name !== updated.name ||
                    original.object_story_spec?.link_data?.message !== updated.object_story_spec?.link_data?.message ||
                    original.object_story_spec?.link_data?.name !== updated.object_story_spec?.link_data?.name ||
                    original.object_story_spec?.link_data?.description !== updated.object_story_spec?.link_data?.description ||
                    original.object_story_spec?.link_data?.link !== updated.object_story_spec?.link_data?.link ||
                    original.object_story_spec?.link_data?.call_to_action?.type !== updated.object_story_spec?.link_data?.call_to_action?.type ||
                    original.object_story_spec?.link_data?.call_to_action?.value?.link !== updated.object_story_spec?.link_data?.call_to_action?.value?.link ||
                    original.object_story_spec?.link_data?.image_hash !== updated.object_story_spec?.link_data?.image_hash ||
                    original.object_story_spec?.page_id !== updated.object_story_spec?.page_id ||
                    original.object_story_spec?.instagram_actor_id !== updated.object_story_spec?.instagram_actor_id ||
                    original.image_url !== values.image_url
                );
            };

            const formatPhoneNumber = (phoneNumber) => {

                if (!phoneNumber.startsWith('tel:+')) {
                    return `tel:+${phoneNumber}`;
                }
                return phoneNumber;
            };

            const hasCreativeChanged = isCreativeChanged();

            const payload = {
                ...(hasCreativeChanged && {
                    creative: {
                        name: values.creative.name,
                        hash: values.hash,
                        image_url: values?.image_url,
                        object_story_spec: {
                            link_data: {
                                ...(values.creative.object_story_spec.link_data.message?.trim() && {
                                    message: values.creative.object_story_spec.link_data.message
                                }),
                                name: values.creative.object_story_spec.link_data.name,
                                description: values.creative.object_story_spec.link_data.description,
                                ...(values.creative.object_story_spec.link_data.link && {
                                    link: values.creative.object_story_spec.link_data.link,
                                }),
                                call_to_action: {
                                    type: values.creative.object_story_spec.link_data.call_to_action.type,
                                    ...(values.creative.object_story_spec.link_data.call_to_action.type === 'CALL_NOW' && {
                                        value: {
                                            link: formatPhoneNumber(values.creative.object_story_spec.link_data.call_to_action.value.link),
                                        }
                                    }),
                                },
                                image_hash: values.creative.object_story_spec.link_data.image_hash,
                            },
                            page_id: values.creative.object_story_spec.page_id,
                            instagram_actor_id: values.creative.object_story_spec.instagram_actor_id,
                        },
                        degrees_of_freedom_spec: {
                            creative_features_spec: {
                                standard_enhancements: {
                                    enroll_status: "OPT_OUT",
                                },
                            },
                        },
                    },
                }),
                ...(values.name !== adData.name ? { name: values.name } : {}), // Include name only if it has changed
                ...(values.cpa && values.cpa !== '' && values.cpa !== adData.cpa ? { cpa: values.cpa } : {}),
                adsAccount: {
                    id: adsAccountData?.id,
                    adsAccountId: adsAccountData?.account_id,
                    adsAccountName: adsAccountData?.name,
                    businessName: adsAccountData?.business?.name,
                    businessId: adsAccountData?.business?.id
                },
            };

            // console.log({ payload });


            updateAd({ creativeId: adData.id, payload })
                .unwrap()
                .then(response => {
                    onSuccess();
                    toast({
                        position: "top-right",
                        title: "Ad updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating ad",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    onClose();
                });
        },
    });

    useEffect(() => {
        if (formik.values.image_url) {
            setLoading(false);
        }
    }, [formik.values.image_url]);

    const handleOpenDriveModal = () => {
        setIsDriveModalOpen(true);
    };

    const handleMediaSelect = (file) => {
        if (file && file.length > 0) {
            const selectedFile = file[0];

            formik.setFieldValue('hash', selectedFile?.hash);
            formik.setFieldValue('image_url', selectedFile?.url);
            formik.setFieldValue('creative.object_story_spec.link_data.image_hash', selectedFile?.creativeId);
        }
        setIsDriveModalOpen(false);
    };

    if (isLoading) {
        return <Flex justify="center" align="center" height="60vh">
            <Spinner size="md" />
        </Flex>
    }

    //console.log(adCreative);
    // console.log(formik.values);


    return (
        <form onSubmit={formik.handleSubmit}>
            <Box direction={'column'} gap={4} width="100%">
                <Flex direction={'column'} gap={'20px'} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Ad Name</Text>
                        <Input
                            name="name"
                            placeholder="Enter your ad name here..."
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.name && formik.errors.name && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1} mb={2}>
                        <Text fontWeight="bold">Facebook Page</Text>
                        <FaceBookPageSelector
                            adsAccount={adsAccountData}
                            selectedPageId={formik.values.creative.object_story_spec.page_id}
                            onSelectedPage={(pageId) => {
                                formik.setFieldValue('creative.object_story_spec.page_id', pageId);
                            }}
                        />
                        {formik.errors.creative?.object_story_spec?.page_id && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.page_id}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Instagram accounts</Text>
                        <InstagramAccountUpdateSelector
                            pageID={formik.values.creative?.object_story_spec?.page_id}
                            adsAccount={adsAccountData}
                            selectedInstPageId={formik.values.creative?.object_story_spec?.instagram_actor_id}
                            onSelectedPage={(pageId) => {
                                formik.setFieldValue('creative.object_story_spec.instagram_actor_id', pageId);
                            }}
                        />
                        {formik.errors.creative?.object_story_spec?.instagram_actor_id && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.instagram_actor_id}</Text>
                        )}
                    </Flex>
                </Flex>

                <Flex direction={'column'} gap={'20px'} borderRadius="md" bg="gray.50" p={4} mb={4}>

                    <Flex gap={2} alignItems={'center'} justifyContent={'flex-start'}>
                        <Flex direction={'column'} gap={2}>
                            <Text fontWeight="bold">Ad creative</Text>
                            {false ? (
                                <Spinner size="sm" color="gray.500" />
                            ) : (
                                formik.values.image_url && (
                                    <img
                                        src={formik.values.image_url}
                                        alt={formik.values.name}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'cover',
                                            border: '0.5px solid grey',
                                            borderRadius: '5px',
                                        }}
                                    />
                                )
                            )}
                        </Flex>
                        <Flex mt={6}>
                            <Button
                                bg={'gray.50'}
                                size="xs"
                                borderWidth={1}
                                onClick={handleOpenDriveModal}>
                                Change Image
                            </Button>
                        </Flex>
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Primary Text</Text>
                        <Textarea
                            name="creative.object_story_spec.link_data.message"
                            placeholder="Message"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.object_story_spec.link_data.message}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.object_story_spec?.link_data?.message && formik.errors.creative?.object_story_spec?.link_data?.message && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.message}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Headline</Text>
                        <Input
                            name="creative.name"
                            placeholder="Enter a headline"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.name}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.name && formik.errors.creative?.name && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.name}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Description</Text>
                        <Textarea
                            name="creative.object_story_spec.link_data.description"
                            placeholder="Include additional details"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.object_story_spec.link_data.description}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.object_story_spec?.link_data?.description && formik.errors.creative?.object_story_spec?.link_data?.description && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.description}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Call to Action</Text>

                        {formik.values.creative.object_story_spec.link_data.call_to_action.type === 'CALL_NOW' ? (
                            <Select
                                name="creative.object_story_spec.link_data.call_to_action.type"
                                bg="#fff"
                                size="sm"
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                                isDisabled
                            >
                                <option value="CALL_NOW">Call Now</option>
                            </Select>
                        ) : (
                            <Select
                                name="creative.object_story_spec.link_data.call_to_action.type"
                                bg="#fff"
                                size="sm"
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                            >
                                {call_to_action_options?.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Select>
                        )}

                        {formik.touched.creative?.object_story_spec?.link_data?.call_to_action?.type &&
                            formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.type && (
                                <Text fontSize="12px" color="red.500">
                                    {formik.errors.creative.object_story_spec.link_data.call_to_action.type}
                                </Text>
                            )}
                    </Flex>

                    {formik.values.creative.object_story_spec.link_data.call_to_action.type === 'CALL_NOW' && (
                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Phone Number</Text>
                            <PhoneInput
                                name="creative.object_story_spec.link_data.call_to_action.value.link"
                                value={formik.values.creative.object_story_spec.link_data.call_to_action.value.link}
                                onChange={(value) => formik.setFieldValue('creative.object_story_spec.link_data.call_to_action.value.link', value)}
                                country={'in'}
                            />
                            {formik.touched.creative?.object_story_spec?.link_data?.call_to_action?.value?.link && formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.value?.link && (
                                <Text fontSize="12px" color="red.500">{formik.errors.creative.object_story_spec.link_data.call_to_action.value.link}</Text>
                            )}
                        </Flex>
                    )}

                    {formik.values.creative.object_story_spec.link_data.link ? (
                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Link URL</Text>
                            <Input
                                name="creative.object_story_spec.link_data.link"
                                placeholder="Enter a URL"
                                bg="#fff"
                                size='sm'
                                borderRadius="md"
                                value={formik.values.creative.object_story_spec.link_data.link}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.creative?.object_story_spec?.link_data?.link && formik.errors.creative?.object_story_spec?.link_data?.link && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.link}</Text>
                            )}
                        </Flex>
                    ) : (null)}

                </Flex>

                <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">CPA</Text>
                        <Input
                            name="cpa"
                            placeholder="Enter CPA"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.cpa}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.cpa && formik.errors.cpa && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.cpa}</Text>
                        )}
                    </Flex>
                </Flex>

                <Flex mb={4}>
                    <Button
                        size="sm"
                        colorScheme="orange"
                        isLoading={formik.isSubmitting}
                        type="submit"
                        isDisabled={!formik.isValid}
                    >
                        Save
                    </Button>
                </Flex>
            </Box>


            {/* Drive Modal */}
            <DriveModal
                isDriveModalOpen={isDriveModalOpen}
                selectedAdAccounts={[{
                    id: adsAccountData?.id,
                    adsAccountId: adsAccountData?.account_id,
                    adsAccountName: adsAccountData?.name,
                    businessName: adsAccountData?.business?.name,
                    businessId: adsAccountData?.business?.id
                }]}
                onClose={() => setIsDriveModalOpen(false)}
                onSubmit={handleMediaSelect}
                uploadPurpose="update"
                mediaType={'image'}
            />
        </form>
    );
};

export default SingleImageAdUpdateForm;
