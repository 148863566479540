import { api } from "../api";

export const adsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createAd: builder.mutation({
          query: ({ payload }) => ({
              url: `/ads`,
              method: 'POST',
              body: payload,
        }),
        invalidatesTags: ['ads'],
        }),
        getAdsList: builder.query({
            query: ({ pageSize, adsAccountId, searchValue, adset_ids, campaign_ids, ad_ids, after, before}) => {

              let url = `/ads/${adsAccountId}?`;
              
              if (campaign_ids && campaign_ids.length > 0) {
                url += `campaign_ids=${JSON.stringify(campaign_ids)}&`;
              }
              if (adset_ids && adset_ids.length > 0) {
                url += `adset_ids=${JSON.stringify(adset_ids)}&`;
              }
              if (ad_ids && ad_ids.length) {
                url += `ad_ids=${JSON.stringify(ad_ids)}&`;
              }
              if (pageSize) {
                url += `pageSize=${pageSize}&`;
              }
              if (after) {
                url += `after=${after}&`;
              }
              if (before) {
                url += `before=${before}&`;
              }
              if (searchValue) {
                url += `search=${searchValue}&`;
              }
          
              // Remove the trailing "&" if it exists
              url = url.endsWith('&') ? url.slice(0, -1) : url;
          
              return { url };
            },
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads'],
        }), 
        getAdsInsights: builder.query({
          query: ({ searchValue, adsAccountId, ads, start_date, date_preset, end_date }) => {
              let url = `/ads/${adsAccountId}/insights?`;
      
              if (ads && ads.length > 0) {
                  url += `ads=${encodeURIComponent(JSON.stringify(ads))}&`;
              }
              if (searchValue) {
                  url += `search=${searchValue}&`;
              }
              if (start_date) {
                  url += `start_date=${start_date}&`;
              }
              if (end_date) {
                  url += `end_date=${end_date}&`;
              }
              if (date_preset) {
                  url += `date_preset=${date_preset}&`;
              }
              // Remove the trailing "&" if it exists
              url = url.endsWith('&') ? url.slice(0, -1) : url;
      
              return { url };
            },
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads'],
        }),     
        getAds: builder.query({
          query: ({ businessId, adsAccountId, adsIds }) => ({
              url: `/ads/get/?businessId=${businessId}&adsAccountId=${adsAccountId}&adsIds=${encodeURIComponent(JSON.stringify(adsIds))}`, 
          }),
          transformResponse: (response, meta, arg) => response.data,
          providesTags: ['ads'],
        }),                  

        getAdCreative: builder.query({
            query: ({ creativeId, adsAccountId}) => ({
                url: `/ads/adcreatives/${creativeId}?adsAccountId=${adsAccountId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads']
        }),
        getAdComparison: builder.query({
            query: ({ hashes }) => ({
                url: `/ads/comparison/?hashes=${encodeURIComponent(JSON.stringify(hashes))}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads'],
        }),
        updateAd: builder.mutation({
            query: ({ creativeId,payload }) => ({
                url: `/ads/${creativeId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['ads'],
        }),
        updateAdStatus: builder.mutation({
            query: ({ adId, payload }) => ({
                url: `/ads/update/status/${adId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        deleteAd: builder.mutation({
          query: ({ adId, adsAccountId })=> ({
            url: `/ads/${adId}?adsAccountId=${adsAccountId}`,
            method: 'DELETE',
          }),
          invalidatesTags: ['ads']
      }),
    }),
});

export const { useCreateAdMutation, useGetAdsListQuery, useGetAdsInsightsQuery, useGetAdsQuery, useGetAdCreativeQuery, useGetAdComparisonQuery, useUpdateAdMutation, useUpdateAdStatusMutation, useDeleteAdMutation } = adsApi;
