import { Box, Flex, Text, Input, Divider } from '@chakra-ui/react'
import React from 'react'

function BuildUrlParams() {
  return (
    <Flex display={'coulmn'}>
        <Flex display={'coulmn'}>
            <Text fontWeight="bold" fontSize={'14px'} mb={2}>Website URL</Text>
            <Input
                type="url"
                name="link"
                bg={'#fff'}
                borderRadius={'md'}
                placeholder="Enter the website URL that you want to promote."
                value={''}
                onChange={''}
                onBlur={''}
                size={'sm'}
            />
        </Flex>

        <Flex my={4}>
            <Divider />
        </Flex>

        <Flex my={2}>
            <Text fontSize={'12px'}>{ `Fill in the fields in the form below to add parameters to your website URL. To automatically get information from your campaign, ad set or ad, click on each field and select a dynamic parameter, such as ID={{ad.id}}.`}</Text>
        </Flex>

        <Flex direction={'column'} gap={2} py={2}>
            <Flex>
                <Flex flex={0.2}>
                    <Text fontWeight={'bold'}>Campaign source</Text>
                </Flex>
                <Flex direction={'column'} flex={0.8} gap={2}>
                    <Input
                            type="url"
                            name="link"
                            bg={'#fff'}
                            borderRadius={'md'}
                            placeholder="Select a dynamic parameter or enter a value."
                            value={''}
                            onChange={''}
                            onBlur={''}
                            size={'sm'}
                        />
                    <Text fontSize={'12px'} color={'gray'}>To identify the source of traffic, e.g. Facebook, Instagram, a search engine or another source.</Text>
                </Flex>
            </Flex>

            <Flex>
                <Flex flex={0.2}>
                    <Text fontWeight={'bold'}>Campaign medium</Text>
                </Flex>
                <Flex direction={'column'} flex={0.8} gap={2}>
                    <Input
                            type="url"
                            name="link"
                            bg={'#fff'}
                            borderRadius={'md'}
                            placeholder="Select a dynamic parameter or enter a value."
                            value={''}
                            onChange={''}
                            onBlur={''}
                            size={'sm'}
                        />
                    <Text fontSize={'12px'} color={'gray'}>To identify the advertising medium. For example, "banner", "email", "Facebook_Feed" or "Instagram_Story".</Text>
                </Flex>
            </Flex>

            <Flex>
                <Flex flex={0.2}>
                    <Text fontWeight={'bold'}>Campaign name</Text>
                </Flex>
                <Flex direction={'column'} flex={0.8} gap={2}>
                    <Input
                            type="url"
                            name="link"
                            bg={'#fff'}
                            borderRadius={'md'}
                            placeholder="Select a dynamic parameter or enter a value."
                            value={''}
                            onChange={''}
                            onBlur={''}
                            size={'sm'}
                        />
                    <Text fontSize={'12px'} color={'gray'}>To identify a specific promotion or strategic campaign. For example, "summer_sale".</Text>
                </Flex>
            </Flex>

            <Flex>
                <Flex flex={0.2}>
                    <Text fontWeight={'bold'}>Campaign content</Text>
                </Flex>
                <Flex direction={'column'} flex={0.8} gap={2}>
                    <Input
                            type="url"
                            name="link"
                            bg={'#fff'}
                            borderRadius={'md'}
                            placeholder="Select a dynamic parameter or enter a value."
                            value={''}
                            onChange={''}
                            onBlur={''}
                            size={'sm'}
                        />
                    <Text fontSize={'12px'} color={'gray'}>To differentiate ads or links that point to the same URL. For example, "white_logo", "black_logo".</Text>
                </Flex>
            </Flex>
        </Flex>

        <Flex my={4}>
            <Divider />
        </Flex>

        <Flex display={'coulmn'}>
            <Text fontWeight="bold" fontSize={'14px'} mb={2}>URL preview</Text>
            <Input
                type="url"
                name="link"
                bg={'#fff'}
                borderRadius={'md'}
                placeholder=""
                value={''}
                onChange={''}
                onBlur={''}
                size={'sm'}
            />
        </Flex>
        
    </Flex>
  )
}

export default BuildUrlParams
