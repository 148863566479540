import { InfoIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, CheckboxGroup, Collapse, Divider, Flex, Icon, Input, Select, Text, Textarea, Tooltip, useToast } from '@chakra-ui/react';
import DriveModal from 'components/drive/DriveModal';
import DeleteConfirmationModal from 'components/genericForms/DeleteConfirmationModal';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { FiChevronUp } from "react-icons/fi";
import { MdDelete, MdEdit } from "react-icons/md";
import PhoneInput from 'react-phone-input-2';
import { useCreateAdspressoCreativeMutation, useRemoveAdMutation, useUpdateAdspressoAdCreativeByIdMutation } from 'store/adspresso/adspressoAdApi';
import { object, string } from "yup";

function CarouselMediaCreative({ adsAccounts, adFormik, adsetsListData, campaignId, call_to_action_options, adData }) {
  const toast = useToast();
  const [isDriveOpen, setIsDriveOpen] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [uploadPurpose, setuploadPurpose] = useState(null);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
  const [selectedAdSets, setSelectedAdSets] = useState({});
  const [mediaFiles, setMediaFiles] = useState([]);
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [fileBeingEdited, setFileBeingEdited] = useState(null);
  const [mediaFilesState, setMediaFilesState] = useState({});
  const [maxCards] = useState(10);
  const [hasSameDestinationType, sethasSameDestinationType] = useState(true);
  const [activeDestinationType, setactiveDestinationType] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);

  const [createAdspressoAds, { isLoading: isAdsCreating, error: createError }] = useCreateAdspressoCreativeMutation();
  const [updateAdspressocreativeAds, { isLoading: isAdspressoAdsetUpdating }] = useUpdateAdspressoAdCreativeByIdMutation({});
  const [removeAd, removeAdResponse] = useRemoveAdMutation();

  const toggleAccordion = (id) => {
    setOpenAccordionId(openAccordionId === id ? null : id);
  };


  const handleInputChange = (fileId, field, value) => {

    setMediaFilesState(prevState => ({
      ...prevState,
      [fileId]: {
        ...prevState[fileId],
        [field]: value,
      },
    }));
  };

  const handleMediaTypeChange = (adAccounts, type) => {
    setSelectedAdAccounts(adAccounts);
    setMediaType(type);
    setIsDriveOpen(true);
  }

  const handleAdAccountChange = (selectedValues) => {
    const selectedAccounts = adsAccounts.filter(account => selectedValues.includes(account.adsAccountId));
    setSelectedAdAccounts(selectedAccounts);
  };

  const handleAdSetChange = (selectedValues) => {
    setSelectedAdSets(prev => {
      const updated = {};
      selectedAdAccounts.forEach(account => {
        updated[account.adsAccountId] = selectedValues;
      });
      return updated;
    });
  };

  useEffect(() => {
    if (mediaFiles.length > 0) {
      setOpenAccordionId(mediaFiles[0]._id);
    }
  }, [mediaFiles]);

  const handleFileSelect = (files) => {

    if (files.length > 0) {
      if (fileBeingEdited) {
        // Handle update scenario
        setMediaFiles(files);
        const selectedFile = files[0];
      
        const payload = {
          [selectedFile?.creativeType === 'video' ? 'video_id' : 'image_hash']: selectedFile?.creativeId,
          ...(selectedFile?.creativeType === 'image' ? { url: selectedFile?.url } : { thumbnail: selectedFile?.picture }),
          hash:selectedFile?.hash,
        };

        updateAdspressocreativeAds({ adcreativeId: fileBeingEdited?._id, payload }).unwrap()
          .then(response => {
            console.log('Ad creative updated successfully:', response);
          })
          .catch(error => {
            toast({
              position: "top-right",
              title: "Error updating image",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
          });
      } else {
        // Handle creation scenario
        setMediaFiles(prevFiles => {
          // Add new files only if the total number of files is less than maxCards
          const newFiles = [...prevFiles, ...files].slice(0, maxCards);
          return newFiles;
        });
      }
      setFileBeingEdited(null);
    }
  };

  const truncateName = (name, maxLength = 14) => {
    if (name?.length > maxLength) {
      return name?.substring(0, maxLength) + '...';
    }
    return name;
  };

  let schema = object().shape({
    destinationType: string(),
    type: string()
      .max(300, 'select atleast one type')
      .required('Type is required'),
    // message: string().when('applyForAll', {
    //   is: true,
    //   then: () => string().required('Required'),
    //   otherwise: () => string().notRequired(),
    // }),
    // message: string().required('Text required'),
    link: string()
      .url('Please add a valid URL')
    .when('destinationType', {
      is: 'WEBSITE',
      then: () => string().required('Website URL is required'),
      otherwise: () => string().notRequired(),
    }),
    phoneNumber: string().
      when('destinationType', {
        is: 'PHONE_CALL',
        then: () => string().required('Phone number is required'),
        otherwise: () => string().notRequired(),
      })
      .min(10, 'Phone number is too short')
      .max(15, 'Phone number is too long'),
  })

  const singleMediaFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      destinationType: activeDestinationType,
      message: '',
      name: '',
      description: '',
      link: '',
      type: 'SHOP_NOW',
      mediaFiles: [],
      phoneNumber: ''
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {

      const { name, message, description, type, link, phoneNumber } = values;

      const adcreatives = mediaFiles?.map(file => {

        let videoId = file?.creativeType == 'video' ? { video_id: file?.creativeId } : null;
        let videoURL = file?.creativeType == 'video' ? { url: file?.source } : null;
        let imageHash = file?.creativeType == 'image' ? { image_hash: file?.creativeId } : null;
        let imageURL = file?.creativeType == 'image' ? { url: file?.url } : null;
        let videoThumbnail = file?.creativeType == 'video' ? { thumbnail: file?.picture } : null;

        return (
          {
            adsAccountId: file?.adsAccount?.adsAccountId,
            message: mediaFilesState[file.id]?.message || '',
            name: mediaFilesState[file.id]?.name || '',
            description: mediaFilesState[file.id]?.description || '',
            link: activeDestinationType == 'PHONE_CALL' ? `tel:+${mediaFilesState[file.id]?.link}`: mediaFilesState[file.id]?.link || '',
            type: activeDestinationType == 'PHONE_CALL' ? 'CALL_NOW' : '',
            hash:file?.hash,
            ...videoId,
            ...videoURL,
            ...imageHash,
            ...imageURL,
            ...videoThumbnail,
          }
        )
      });


      const adsAccounts = selectedAdAccounts.map(account => ({
        id: account.id,
        adsAccountId: account.adsAccountId,
        adsAccountName: account.adsAccountName,
        businessName:account?.businessName,
        businessId:account?.businessId,
      }));

      const adsets = adsAccounts.flatMap(account => selectedAdSets[account.adsAccountId] || []);
      const uniqueAdsets = adsets.filter((item, index) => adsets.indexOf(item) === index);

      const payload = {
        adType: adFormik.values.format,
        campaign: campaignId,
        adsets: uniqueAdsets,
        ...(message?.trim() && { message }),
        name: name,
        description: description,
        type: phoneNumber?.length ? 'CALL_NOW' : type,
        link: phoneNumber?.length ? `tel:+${phoneNumber}` : link,
        adcreatives: adcreatives,
        adsAccounts: adsAccounts,
      };

      //  console.log({ payload });

      try {
        if (uploadPurpose === 'create') {
          createAdspressoAds(payload).unwrap()
            .then(response => {
              toast({
                position: "top-right",
                title: "Ad creative added",
                status: "success",
                duration: 4000,
                isClosable: true,
              });
              //console.log('Ads creative created successfully:', response);
            })
            .catch(error => {
              const errorMessage = error?.message || "An unexpected error occurred";

              toast({
                position: "top-right",
                title: "Error creating Ad creative",
                description: errorMessage,
                status: "error",
                duration: 4000,
                isClosable: true,
              });
            });
        }
      } catch {
        // handle exception if necessary
      } finally {
        setuploadPurpose(null);
        setMediaType(null);
        setMediaFiles([]); // Clear the media files
        setSelectedAdAccounts([]); // Reset selected ad accounts
        setSelectedAdSets({}); // Clear selected ad sets
        resetForm();
        setactiveDestinationType('');
      }

    },
  });

  const handleDeleteFile = (indexToDelete) => {
    setMediaFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
  };

  useEffect(() => {
    singleMediaFormik.setFieldValue('mediaFiles', mediaFiles)
  }, [mediaFiles])

  const handleDeleteAd = (adId) => {
    removeAd(adId)
      .unwrap()
      .then(response => {
        toast({
          position: "top-right",
          title: "Ad deleted",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          position: "top-right",
          title: "Error deleting Ad",
          status: "error",
          description: error?.data?.message,
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const confirmDelete = () => {
    if (selectedAd) {
        handleDeleteAd(selectedAd);
        setModalOpen(false);
        setSelectedAd(null);
    }
  };  

  function checkSameDestinationType(selectedIds, adsets) {
    const filteredAdsets = adsets.filter(adset => selectedIds.includes(adset._id));

    if (filteredAdsets.length === 0) {
      return true;
    }

    // Get the destination_type of the first filtered adset
    const firstDestinationType = filteredAdsets[0].destination_type;

    // Loop through the filtered adsets and check if all have the same destination_type
    for (let i = 1; i < filteredAdsets.length; i++) {
      if (filteredAdsets[i].destination_type !== firstDestinationType) {
        return false;
      }
    }

    setactiveDestinationType(firstDestinationType);


    return true;
  }

  useEffect(() => {
    // console.log(checkSameDestinationType(Object.values(selectedAdSets).flat(), adsetsListData?.data))
    if (Object.values(selectedAdSets)?.flat()?.length > 1) {
      const checkresult = checkSameDestinationType(Object.values(selectedAdSets).flat(), adsetsListData?.data);
      sethasSameDestinationType(checkresult)
    } else if (Object.values(selectedAdSets)?.flat()?.length == 1) {
      //console.log(String(Object.values(selectedAdSets)?.flat()?.[0]));
      let activeSingleAdset = adsetsListData?.data?.filter((adset) => adset._id == String(Object.values(selectedAdSets)?.flat()?.[0]));
      // console.log(activeSingleAdset);
      setactiveDestinationType(activeSingleAdset?.[0]?.destination_type)
      sethasSameDestinationType(true)
    } else {
      setactiveDestinationType('')
    }

  }, [selectedAdSets])


  const isAnyUrlMissing = mediaFiles.some(file => {
    const fileId = file?.id;
    const fileState = mediaFilesState[fileId] || {};
    return !fileState.link || fileState.link.trim() === '';
  });


  return (
    <>
      <DriveModal
        isDriveModalOpen={isDriveOpen}
        mediaType={mediaType}
        onClose={() => {
          setIsDriveOpen(false);
          setMediaType(null);
          setFileBeingEdited(null);
        }}
        onSubmit={handleFileSelect}
        mediaFormat={adFormik?.values?.format}
        selectedAdAccounts={selectedAdAccounts}
        uploadPurpose={uploadPurpose}
        adFormik={adFormik}
      />

      <DeleteConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onConfirm={confirmDelete}
          message="Do you wish to proceed with deleting this Ad?"
      />

      <Flex flex="3" direction="column" gap={2}>
        <Flex direction="column" mb={4}>
          {/* Ad Account Select */}
          <Flex direction={'column'} gap={'10px'} mb={'10px'}>
            <Text fontSize={'14px'} fontWeight={'bold'}>Select Ad accounts</Text>
            <CheckboxGroup
              colorScheme="orange"
              value={selectedAdAccounts.map(account => account.adsAccountId)}
              onChange={handleAdAccountChange}
            >
                {adsAccounts?.map(account => (
                  <Box key={account.adsAccountId} px={'5px'} py={'2px'}>
                    <Checkbox value={account.adsAccountId}>
                    <Text fontSize={'14px'} noOfLines={2} maxWidth={'510px'}>
                        {/* {account.adsAccountName.length > 20
                          ? `${account.adsAccountName.substring(0, 20)}...`
                          : account.adsAccountName} */}
                            {account.adsAccountName}
                      </Text>
                    </Checkbox>
                  </Box>
                ))}

            </CheckboxGroup>
          </Flex>

          {/* Ad Set Select */}
          <Flex direction="column" gap={'5px'} mb={'10px'}>
            <Text fontSize={'14px'} fontWeight={'bold'}>Select Ad sets</Text>
            <CheckboxGroup
              colorScheme="orange"
              value={Array.from(new Set(Object.values(selectedAdSets).flat()))}
              onChange={handleAdSetChange}
              isDisabled={!selectedAdAccounts.length > 0}
            >
                {adsetsListData?.data?.map((adSet, index) => (
                  <Box key={`${adSet._id}-${index}`} px={'5px'} py={'2px'}>
                    <Checkbox value={adSet._id} display={'flex'} flex={1}>
                      <Text fontSize={'14px'} noOfLines={2} maxWidth={'510px'}>{adSet?.name}</Text>
                    </Checkbox>
                  </Box>
                ))}
            </CheckboxGroup>

            {!hasSameDestinationType ? (
              <Flex>
                <Text color="red.500" fontSize={'12px'}>Conversion goals don't match &nbsp;
                </Text>
                <Tooltip
                  borderRadius={5}
                  label={
                    <Box fontSize="14px" p="5px">
                      <Text>
                        The selected Ad sets have different conversion goals. Please select Ad sets with the same goal (either Website or Calls). You can create a separate set of Ads for the other conversion goal thereafter.
                      </Text>
                    </Box>
                  }
                  fontSize="md"
                >
                  <InfoOutlineIcon pointerEvents="all" color={'red.500'} boxSize={4} />
                </Tooltip>
              </Flex>
            ) : null}
          </Flex>

          <Flex direction="column" mb="10px">
            <Flex alignItems={'center'} gap={2}>
              <Text fontSize="15px" fontWeight={'bold'}>Carousel cards</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="14px" p="5px">
                    <Text>
                      Choose media and enter text for up to ten carousel cards.
                    </Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon boxSize={3} pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Text fontSize="13px" flex={1}>
              {mediaFiles.length} of {maxCards} cards added
            </Text>
            {mediaFiles?.length < 2 ? (
              <Flex align={'center'} gap={'5px'} px={'10px'} py={'5px'} my={'5px'} borderRadius={'sm'} bg={'gray.100'}>
                <InfoIcon boxSize={3} color={'gray.800'} />
                <Text fontSize="13px">Add at least 2 cards.</Text>
              </Flex>
            ) : null}
          </Flex>

          {/* Single Select component for each adAccount */}
          <Flex direction="column" flexShrink={0}>
            <Select
              placeholder={"Add cards"}
              width={'150px'}
              name="mediaOption"
              borderRadius={'md'}
              value={mediaType || ""}
              onChange={(e) => {
                setuploadPurpose('create');
                handleMediaTypeChange(selectedAdAccounts, e.target.value);
              }}
              size="sm"
              isDisabled={Object.keys(selectedAdSets)?.length === 0 || !selectedAdAccounts?.length > 0 || !hasSameDestinationType}
            >
              <option value="image">{"Add image cards"}</option>
              <option value="video">{"Add video cards"}</option>
            </Select>
          </Flex>

          {/* Media Previews */}
          {uploadPurpose === 'create' && mediaFiles?.length > 0 && (
            <Box mt={4}>
              <Flex wrap="wrap" gap={2} direction="column">
                {mediaFiles?.map((file, index) => {
                  const fileId = file?.id;       
                  const fileState = mediaFilesState[fileId] || {};

                  // Checking if URL is empty
                  const isUrlRequired = !fileState.link || fileState.link.trim() === '';

                  return (
                    <Box key={index} p={1} borderWidth={1} borderRadius="md" borderColor="gray.200" position={'relative'}>
                      <Flex alignItems="center" gap={2} justify="space-between">
                        <Flex alignItems="center" gap={2}>
                          {file?.creativeType === 'image' ? (
                            <img
                              src={file?.url}
                              alt={file?.name}
                              style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }}
                            />
                          ) : (
                            <video
                              src={file?.source}
                              // controls
                              style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }}
                            />
                          )}
                          <Flex direction="column">
                            <Text fontSize="10px" noOfLines={1}>
                              {fileState.name || file?.name || 'No name available'}
                            </Text>
                            <Flex gap={1}>
                              {fileState.description ? (
                                <Text fontSize="10px" noOfLines={1}>{truncateName(fileState.description)}</Text>
                              ) : (
                                <Text fontSize="10px">Enter a description</Text>
                              )}
                              {!fileState.link ? (
                                <>
                                  <Text fontSize="10px">and</Text>
                                  <Text fontSize="10px">URL&nbsp;
                                    <Tooltip

                                      borderRadius={5}
                                      label={
                                        <Box p="5px" >
                                          <Text fontSize="12px">
                                            Your carousel card {index + 1} doesn't have a destination. Please enter a URL for each card in your carousel.
                                          </Text>
                                        </Box>
                                      }
                                      fontSize="md"
                                    >
                                      <InfoOutlineIcon color={'red.500'} pointerEvents="all" />
                                    </Tooltip>
                                  </Text>
                                </>
                              ) : null}
                            </Flex>
                            <Text fontSize="10px" fontWeight={'bold'} noOfLines={1}>{truncateName(file?.adsAccount?.adsAccountName)}</Text>
                          </Flex>
                        </Flex>
                        <Flex alignItems="center" gap={'5px'}>
                          <Tooltip label="Edit copy" fontSize="xs">
                            <Box>
                              <Icon
                                _hover={{ color: 'gray.500' }}
                                as={openAccordionId === fileId ? FiChevronUp : MdEdit}
                                cursor="pointer"
                                fontSize="14px"
                                onClick={() => toggleAccordion(fileId)}
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip label="Delete" fontSize="xs">
                            <Box>
                              <Icon
                                color="red.500"
                                _hover={{ color: 'gray.400' }}
                                as={MdDelete}
                                cursor="pointer"
                                boxSize={4}
                                onClick={() => handleDeleteFile(index)}
                              />
                            </Box>
                          </Tooltip>
                        </Flex>
                      </Flex>
                      <Collapse in={openAccordionId === fileId}>
                        <Box p={4}>
                          {/* Headline Section */}
                          <Box direction="column" mb={4}>
                            <Flex alignItems="center" mb={2} gap={2}>
                              <Text fontSize="14px" fontWeight="bold">Headline</Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="14px" p="5px">
                                    <Text>
                                      Add up to five brief headlines to let people know what your ad is about. Each
                                      headline can have a maximum of 255 characters. Headlines won't appear in all
                                      placements.
                                    </Text>
                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <Input
                              placeholder="Tell people what your ad is about..."
                              size="sm"
                              borderRadius="md"
                              value={fileState.name || ''}
                              onChange={(e) => handleInputChange(fileId, 'name', e.target.value)}
                            />
                          </Box>

                          {/* Description Section */}
                          <Box direction="column" mb={4}>
                            <Flex alignItems="center" mb={2} gap={2}>
                              <Text fontSize="14px" fontWeight="bold">Description</Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="14px" p="5px">
                                    <Text>
                                      Add up to five link descriptions to emphasize why people should visit your
                                      website. This won't appear in all placements.
                                    </Text>
                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <Textarea
                              placeholder="Include additional details"
                              size="sm"
                              borderRadius="md"
                              value={fileState.description || ''}
                              onChange={(e) => handleInputChange(fileId, 'description', e.target.value)}
                              rows={2}
                            />
                          </Box>

                          {/* Conditional Input based on Destination Type */}
                          {activeDestinationType === 'PHONE_CALL' ? (
                            <Box>
                                <Flex alignItems="center" mt={'10px'} mb={2}>
                                  <Text fontWeight="bold" fontSize={'14px'}>Call now</Text>
                                  <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                  <Tooltip
                                      borderRadius={5}
                                      label={
                                          <Box fontSize="sm" p="5px">
                                          <Text>You can use a different phone number for each carousel card. This can be helpful if you have different phone numbers or extensions.</Text>
                                          </Box>
                                      }
                                      fontSize="md"
                                      >
                                      <InfoOutlineIcon pointerEvents="all" />
                                  </Tooltip>
                              </Flex>
                              <PhoneInput
                                country="in"
                                value={fileState.link || ''}
                                onChange={(value) => handleInputChange(fileId, 'link', value)}
                                inputProps={{
                                  name: 'phoneNumber',
                                  required: true,
                                }}
                                enableSearch={true}
                                searchPlaceholder="Search"
                              />
                              {isUrlRequired && (
                                <Text fontSize="12px" color="red.500" mt={2}>
                                  Your carousel card {index + 1} doesn't have a destination. Please enter a phone number for each card in your carousel.
                                </Text>
                              )}
                            </Box>
                          ) : (
                            // URL Link Section
                            <Box direction="column" mb={4}>
                              <Flex alignItems="center" mb="5px">
                                <Text fontSize="14px" fontWeight="bold">Website URL</Text>
                                <Text display="inline-flex" fontSize="18px" color="red.400" ml={2} mr={2}>*</Text>
                                <Tooltip
                                  borderRadius={5}
                                  label={
                                    <Box fontSize="14px" p="5px">
                                      <Text>
                                        Enter the URL for the web page that you want people to visit.
                                      </Text>
                                    </Box>
                                  }
                                  fontSize="md"
                                >
                                  <InfoOutlineIcon pointerEvents="all" />
                                </Tooltip>
                              </Flex>
                              <Input
                                placeholder="http://www.example.com/page"
                                size="sm"
                                borderRadius="md"
                                value={fileState.link || ''}
                                onChange={(e) => handleInputChange(fileId, 'link', e.target.value)}
                                isInvalid={isUrlRequired}
                              />
                              {isUrlRequired && (
                                <Text fontSize="12px" color="red.500" mt={2}>
                                  Your carousel card {index + 1} doesn't have a destination. Please enter a website URL for each card in your carousel.
                                </Text>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Collapse>

                    </Box>
                  );
                })}
              </Flex>
            </Box>
          )}
        </Flex>

        <Divider />

        {/* Primary Text Section */}
        <Box direction="column" mb={4}>
          <Flex alignItems="center" mb={2}>
            <Text fontWeight="bold" fontSize="14px" mr={2}>Primary text</Text>
            {/* <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text> */}
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>
                    The primary text for your ad appears in most placements, though the position
                    varies. You can use @ to tag Facebook profiles or Pages. We recommend 125
                    characters or less.
                  </Text>
                </Box>
              }
              fontSize="md"
            >
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Textarea
            size="sm"
            placeholder="Tell people what your ad is about..."

            name="message"
            value={singleMediaFormik.values.message}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            borderRadius="md"
          />
          {singleMediaFormik.touched.message && singleMediaFormik.errors.message ? (
            <Text color="red.500" fontSize="12px">{singleMediaFormik.errors.message}</Text>
          ) : null}
        </Box>

        {/* Call to action Section */}
        {activeDestinationType != 'PHONE_CALL' ? (
          <Box direction="column">
            <Flex alignItems="center" mb={2} gap={2}>
              <Text fontWeight="bold" fontSize="14px">Call to action</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="sm" p="5px">
                    <Text>Show a button or link on your ad that represents the action you want people to take.</Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Select
              name="type"

              borderRadius="md"
              value={singleMediaFormik.values.type}
              onChange={singleMediaFormik.handleChange}
              onBlur={singleMediaFormik.handleBlur}
              size="sm"
            >
              {call_to_action_options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            {singleMediaFormik.touched.type && singleMediaFormik.errors.type ? (
              <Text color="red.500">{singleMediaFormik.errors.type}</Text>
            ) : null}
          </Box>
        ) : (
          <Box direction="column" mb={'10px'}>
            <Flex alignItems="center" mb={2} gap={2}>
              <Text fontWeight="bold" fontSize="14px">Call to action</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="sm" p="5px">
                    <Text>This is the only available option.</Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Select
              name="type"
              borderRadius="md"
              value={singleMediaFormik.values.type}
              onChange={singleMediaFormik.handleChange}
              onBlur={singleMediaFormik.handleBlur}
              size="sm"
              isDisabled
            >
              <option value="CALL_NOW">Call Now</option>
            </Select>
        </Box>
        )}

        {singleMediaFormik.touched.mediaType && singleMediaFormik.errors.mediaFiles ? (
          <Text color="red.500" fontSize="12px">{singleMediaFormik.errors.mediaFiles}</Text>
        ) : null}

        {activeDestinationType == 'WEBSITE' ? (
        <Box direction="column">
          <Flex alignItems="center" mt={'10px'} mb={2}>
            <Text fontWeight="bold" fontSize={'14px'}>Website URL</Text>
            <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>Enter the URL for the webpage you want people to visit</Text>
                </Box>
              }
              fontSize="md"
            >
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Input
            type="url"
            name="link"

            borderRadius={'md'}
            placeholder="Enter URL"
            value={singleMediaFormik.values.link}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            size={'sm'}
          />
          {singleMediaFormik.touched.link && singleMediaFormik.errors.link ? (
            <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.link}</Text>
          ) : null}
        </Box>
        ) : null}

        {activeDestinationType == 'PHONE_CALL' ? (
          <Box >
            <Flex alignItems="center" mt={'10px'} mb={2}>
              <Text fontWeight="bold" fontSize={'14px'}>Call now</Text>
              <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
              <Tooltip
                  borderRadius={5}
                  label={
                    <Box fontSize="sm" p="5px">
                      <Text>You can use a different phone number for each carousel card. This can be helpful if you have different phone numbers or extensions.</Text>
                    </Box>
                  }
                  fontSize="md"
                >
                <InfoOutlineIcon pointerEvents="all" />
              </Tooltip>
            </Flex>
            <PhoneInput
              country={'in'}
              value={singleMediaFormik.values.phoneNumber}
              onChange={(value) => {
                singleMediaFormik.setFieldValue('phoneNumber', value);
              }}
              name='phoneNumber'
              inputProps={{
                name: 'phoneNumber',
                required: true,
                //autoFocus: true,
              }}
              enableSearch={true}
              searchPlaceholder="Search"
            />
            {singleMediaFormik.touched.phoneNumber && singleMediaFormik.errors.phoneNumber ? (
              <Text color="red.500" fontSize={'12px'} mt={'5px'}>{singleMediaFormik.errors.phoneNumber}</Text>
            ) : null}
          </Box>
        ) : null}


        {/* Save Button */}
        <Flex justifyContent="flex-start" mt="10px">
          <Button
            colorScheme="orange"
            onClick={() => {
              singleMediaFormik.handleSubmit();
            }}
            isLoading={isAdsCreating || isAdspressoAdsetUpdating}
            size="sm"
            isDisabled={mediaFiles?.length < 2 || isAnyUrlMissing || !adData?.name || !hasSameDestinationType}
          >
            Save
          </Button>
        </Flex>

        {/* {uploadedCreativesList?.length > 0 && (
          <>
            <Divider my="10px" />
            <Flex direction="column" gap={2}>
              {uploadedCreativesList}
            </Flex>
          </>
        )} */}
      </Flex>
    </>
  );
};

export default CarouselMediaCreative;

