import {
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Spinner,
  Text,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { COUNTRIES } from "components/formFields/countries";
import { Formik } from "formik";
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useSignUpUserMutation } from "store/auth/authApi";
import { signUpschema } from "utils/schema";
import { useSelector } from "react-redux";

function SignUp() {
  const toast = useToast()
  const navigate = useNavigate()
  const [signUpUser, { data: signUpResponse, error, isLoading }] = useSignUpUserMutation();
  const userInfo = useSelector((state) => state.auth?.userInfo);

  const textColor = useColorModeValue("gray.400", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const [showPassword, setShowPassword] = useState(false);

  const [value, setValue] = useState()
  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso
  }));

  const location = useLocation();
  const from = location.state?.from?.pathname || "/";


  useEffect(() => {
    if (userInfo) navigate(from);
  }, [userInfo]);


  useEffect(() => {
    //console.log(error);

    if (error) {
      const { message } = error;
      //console.log(message);
      toast({
        position: 'top-right',
        title: 'Error',
        description: message,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }, [error, signUpResponse])


  return (
    <Flex
      direction='column'
      alignItems='center'
      justifyContent='center'
      overflow='hidden'
      h={'100vh'}
      w={'100vw'}
      bg={useColorModeValue('white', '#231f20')}
    >
      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        <Flex
          direction='column'
          w='445px'
          background='transparent'
          borderRadius='5px'
          p='40px'
          mx={{ base: "100px" }}
          bg={bgColor}
          boxShadow='0 20px 30px 20px rgb(0 0 0 / 5%)'>

          <Formik
            validationSchema={signUpschema}
            initialValues={{ firstName: "", lastName: "", email: '', password: '', phoneNumber: '' }}
            onSubmit={async (values) => {
              // alert(JSON.stringify(values));
              if (values) {
                await signUpUser(values)
                  .unwrap()
                  .then((payload) => {
                    const { status } = payload;
                    if (status == 'success') {
                      toast({
                        position: "top-right",
                        title: "Account created",
                        description: 'Please check your mail',
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                      });
                      setTimeout(() => {
                        navigate('/signin')
                      }, 800);
                    }
                  })
                  .catch((error) => {
                    // //console.log(error);
                  })
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              setFieldValue
            }) => (
              <>
                <FormControl>

                  <Input
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.firstName && errors.firstName ? true : false}
                    fontSize='sm'
                    ms='4px'
                    borderRadius='5px'
                    type='text'
                    placeholder='First name'
                    mb='24px'
                    size='lg'
                  />

                  <Input
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.lastName && errors.lastName ? true : false}
                    fontSize='sm'
                    ms='4px'
                    borderRadius='5px'
                    type='text'
                    placeholder='Last name'
                    mb='24px'
                    size='lg'
                  />

                  <Input
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && errors.email ? true : false}
                    fontSize='sm'
                    ms='4px'
                    borderRadius='5px'
                    type='email'
                    placeholder='Email ID'
                    mb='24px'
                    size='lg'
                  />

                  {/* <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="RU"
                    value={value}
                    onChange={setValue} /> */}

                  {/* <FormControl mb="32px">
                    <PhoneNumberInput
                      value={value}
                      options={countryOptions}
                      placeholder="Enter phone number"
                      onChange={value => {
                        setValue(value)
                        setFieldValue('phoneNumber', value)
                      }}
                    />
                  </FormControl> */}
                  <Input
                    id="phoneNumber"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.phoneNumber && errors.phoneNumber ? true : false}
                    fontSize='sm'
                    ms='4px'
                    borderRadius='5px'
                    type='text'
                    placeholder='Phone Number'
                    mb='24px'
                    size='lg'
                  />

                  <FormControl id="password_" isRequired mb={'20px'}>
                    <InputGroup>
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        borderRadius='5px'
                        fontSize='sm'
                        size='lg'
                        placeholder='Password'
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        isInvalid={touched.password && errors.password ? true : false}
                        isDisabled={isLoading}
                      />
                      <InputRightElement h={'full'}>
                        <Button
                          variant={'ghost'}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }>
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <Button
                    type='submit'
                    fontSize='14px'
                    color='white'
                    fontWeight='bold'
                    w='100%'
                    h='45'
                    mb='24px'
                    colorScheme="brand"
                    _hover={{
                      bg: 'brand.300'
                    }}
                    bg={'brand.200'}
                    onClick={handleSubmit}
                  >
                    {isLoading ? <Spinner /> : 'SIGN UP'}
                  </Button>
                </FormControl>
                <Flex
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                  maxW='100%'
                  mt='0px'>
                  <Text color={textColor} fontWeight='medium'>
                    Already have an account?
                    <Link color={'brand.100'}
                      as={RouterLink}
                      ms='5px'
                      href='#'
                      fontWeight='bold' to={'/signIn'}>
                      Sign In
                    </Link>
                  </Text>
                </Flex>
              </>
            )}
          </Formik>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUp;
