import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Input, InputGroup, InputLeftElement, InputRightElement, Spinner } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

const SearchInput = ({ searchTerm, data = [], searchEnabled, isFetching, onSearchInputChange }) => {

  const inputRef = useRef();
  const [searchValue, setsearchValue] = useState(searchTerm);
  const [showError, setShowError] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue?.length > 2) {
        onSearchInputChange(searchValue);
        setHasSearched(true);
      } else if (searchValue?.length === 0) {
        onSearchInputChange(searchValue);
        setHasSearched(false);
      }
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  useEffect(() => {
    if (data?.length === 0) {
      setShowError(true);
      onSearchInputChange('');
    }
  }, [data]);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  }, [showError]);

  return (
    <>
      {hasSearched ? (
        <>
          {searchEnabled && (
            <Box width={'100%'} bg={'none'}>
              <InputGroup  size={'sm'} borderRadius={'sm'}>
                <InputLeftElement pointerEvents='none'>
                  <SearchIcon color='gray.300' />
                </InputLeftElement>
                <Input
                  ref={inputRef}
                  type="text"
                  value={searchValue || ""}
                  onChange={e => {
                    setsearchValue(inputRef.current.value);
                  }}
                  placeholder="Search..."
                 
                />
                {searchValue && (
                  <InputRightElement
                    cursor={'pointer'}
                    children={isFetching ? <Spinner size={'xs'}/> : <CloseIcon fontSize={12} _hover={{ color: 'gray.600' }} color='gray.300' />}
                    onClick={() => { setsearchValue(''); }}
                  />
                )}
              </InputGroup>
              {showError == true && (
                <Flex flexDirection={'column'} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                  <Text mt={2} fontSize={'12px'} color='red.500'>
                    No results found. Try a different search term.
                  </Text>
                </Flex>
              )}
            </Box>
          )}
        </>
      ) : (
        <>
              {searchEnabled && (
                <Box width={'100%'} bg={'none'}>
                  <InputGroup  size={'sm'} borderRadius={'sm'}>
                    <InputLeftElement pointerEvents='none'>
                      <SearchIcon color='gray.300' />
                    </InputLeftElement>
                    <Input
                      ref={inputRef}
                      type="text"
                      value={searchValue || ""}
                      onChange={e => {
                        setsearchValue(inputRef.current.value);
                      }}
                      placeholder="Search..."
                    />
                    {searchValue && (
                      <InputRightElement
                        cursor={'pointer'}
                        children={isFetching ? <Spinner  size={'xs'}/> : <CloseIcon fontSize={12} _hover={{ color: 'gray.600' }} color='gray.300' />}
                        onClick={() => { setsearchValue(''); }}
                      />
                    )}
                  </InputGroup>
                </Box>
              )}
            </>
          )}
        </>
  );
};

export default SearchInput;
