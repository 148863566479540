import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Divider,
    Flex,
    Input,
    Radio,
    RadioGroup,
    Stack,
    Text,
    Tooltip,
    useToast,
    Image,
    useColorModeValue,
    Icon, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalHeader
} from '@chakra-ui/react';
import CarouselMediaCreative from 'components/adspresso/CarouselMediaCreative';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';
import InstagramAccountSelector from 'components/adspresso/InstagramAccountSelector';
import SingleMediaCreative from 'components/adspresso/SingleMediaCreative';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useCreateAdspressoAdHeaderMutation, useGetAdspressoAdDataByCampaignIdQuery, useUpdateAdspressoAdDataByAdIdMutation, useGetAdspressoCreativeByCampaignIdQuery, useUpdateAdspressoAdCreativeByIdMutation, useRemoveCreativeMutation, useRemoveAdMutation } from 'store/adspresso/adspressoAdApi';
import { useGetAdspressoAdsetsByCampainIdQuery } from 'store/adspresso/adspressoAdsetapi';
import { adFormSchema } from '../../utils/schema';
import { useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DriveModal from "components/drive/DriveModal";
import DeleteConfirmationModal from "components/genericForms/DeleteConfirmationModal";
import SingleMediaUpdateForm from "components/adspresso/creativeUpdateForms/SingleMediaUpdateForm";
import CarouselMediaUpdateForm from "components/adspresso/creativeUpdateForms/CarouselMediaUpdateForm";
import { useSelector } from "react-redux";

const call_to_action_options = [
    { value: 'SHOP_NOW', label: 'Shop Now' },
    { value: 'LEARN_MORE', label: 'Learn More' },
    { value: 'APPLY_NOW', label: 'Apply Now' },
    // { value: 'DOWNLOAD', label: 'Download' },
    // { value: 'CONTACT_US', label: 'Contact Us' },
    // { value: 'BOOK_NOW', label: 'Book Now' },
];

function AdForm({ campaignData, onRefresh, onRefreshComplete }) {

    const toast = useToast();

    const backgroundColor = useColorModeValue('gray.50', 'gray.800');

    const [creativesError, setcreativesError] = useState(false);
    const [isDriveOpen, setIsDriveOpen] = useState(false);
    const [mediaType, setMediaType] = useState(null);
    const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
    const [selectedAd, setSelectedAd] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [fileBeingEdited, setFileBeingEdited] = useState(null);
    const { adsAccounts } = campaignData || {};
    const userInfo = useSelector((state) => state.auth?.userInfo);

    const [issetModalOpen, setIsModalOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);

    const { id } = useParams();

    const [createAdspressoAddata, { isLoading: isAddataCreating }] = useCreateAdspressoAdHeaderMutation();

    const { data: adsetsListData, error: adsetsListError, isLoading: isLoadingadsetsList } = useGetAdspressoAdsetsByCampainIdQuery(id, {
        skip: !id,
    });

    const { data: adData, refetch: refetchAdspressoAdData } = useGetAdspressoAdDataByCampaignIdQuery(id, { skip: !id });
    const [updateAdspressoAdDataByAdId, { }] = useUpdateAdspressoAdDataByAdIdMutation({});
    const { data: AdspressoCreatives, refetch: refetchAdspressoCreative } = useGetAdspressoCreativeByCampaignIdQuery(id);
    const [updateAdspressocreativeAds, { isLoading: isAdspressoAdsetUpdating }] = useUpdateAdspressoAdCreativeByIdMutation({});
    const [removeCreative] = useRemoveCreativeMutation();
    const [removeAd, removeAdResponse] = useRemoveAdMutation();

    const openModal = (mediaUrl) => {
        setSelectedMedia(mediaUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedMedia(null);
    };

    useEffect(() => {
        if (onRefresh) {
            refetchAdspressoCreative();
            onRefreshComplete();
        }
    }, [onRefresh, refetchAdspressoCreative]);




    // Formik setup
    const adFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: adData?.name || '',
            accounts: adData?.adsAccounts || [],
            source: adData?.source || 'MYDRIVE',
            format: AdspressoCreatives?.data[0]?.adType || 'SINGLE',
        },
        validationSchema: adFormSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            //console.log('Ad Form Values:', values);

            const addataPayload = {
                campaign: campaignData._id,
                name: values.name,
                source: values.source || "MYDRIVE",
                adsAccounts: values.accounts,
            };

            if (adData?.name?.length) {
                //console.log('updating');

                updateAdspressoAdDataByAdId({ adId: adData?._id, payload: addataPayload }).unwrap()
                    .then(response => {
                        refetchAdspressoAdData();
                        toast({
                            position: "top-right",
                            title: "Ad data saved",
                            status: "success",
                            duration: 4000,
                            isClosable: true,
                        });
                        //console.log('Ad data created successfully:', response);
                    })
                    .catch(error => {
                        toast({
                            position: "top-right",
                            title: "Error updating ad data",
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                        });
                        console.error('Failed to update ad data:', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                        resetForm();
                    });
            } else {
                createAdspressoAddata(addataPayload).unwrap()
                    .then(response => {
                        refetchAdspressoAdData();
                        toast({
                            position: "top-right",
                            title: "Ad data saved",
                            status: "success",
                            duration: 4000,
                            isClosable: true,
                        });
                        //console.log('Ad data created successfully:', response);
                    })
                    .catch(error => {
                        toast({
                            position: "top-right",
                            title: "Error creating ad data",
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                        });
                        console.error('Failed to create ad data:', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                        resetForm();
                    });
            }
        },
    });


    useEffect(() => {
        if (creativesError) {
            const timer = setTimeout(() => setcreativesError(false), 5000);
            return () => clearTimeout(timer);
        }
    }, [creativesError]);


    const handleFacebookPageSelection = (pageId) => {
        // Check if pageId is valid (not null or undefined)
        if (pageId) {
            // If a pageId is selected, update all adsAccountId with that pageId
            const updatedAccounts = adsAccounts?.map(account => ({
                adsAccountId: account.adsAccountId,
                page_id: pageId
            }));
    
            // Set the updated accounts array in Formik
            adFormik.setFieldValue('accounts', updatedAccounts);
        } else {
            // Optionally, handle the case when pageId is invalid 
            const updatedAccounts = adsAccounts?.map(account => ({
                // adsAccountId: account.adsAccountId,
                page_id: ''  
            }));
    
            // Set the updated accounts array with null page_id in Formik
            adFormik.setFieldValue('accounts', updatedAccounts);
        }
    };




    const handleInstagramPageSelection = (pageId, adsAccountId) => {

        const updatedAccounts = adFormik.values.accounts.map(obj =>
            obj.adsAccountId === adsAccountId ? { ...obj, instagram_actor_id: pageId } : obj
        );

        if (!adFormik.values.accounts.find(obj => obj.adsAccountId === adsAccountId)) {
            updatedAccounts.push({ adsAccountId, instagram_actor_id: pageId });
        }
        adFormik.setFieldValue('accounts', updatedAccounts);
    };

    const renderCreativeComponent = () => {

        if (adFormik.values.format === 'CAROUSEL') {
            return (
                <CarouselMediaCreative
                    adData={adData}
                    campaignId={id}
                    adFormik={adFormik}
                    adsAccounts={adsAccounts}
                    adsetsListData={adsetsListData}
                    call_to_action_options={call_to_action_options}
                />
            );
        } else if (adFormik.values.format === 'SINGLE') {
            return (
                <SingleMediaCreative
                    adData={adData}
                    campaignId={id}
                    adFormik={adFormik}
                    adsAccounts={adsAccounts}
                    adsetsListData={adsetsListData}
                    call_to_action_options={call_to_action_options}
                />
            );
        }
    };

    const handleChangeMedia = (file, adAccount) => {
        const mediaType = file?.image_hash ? 'image' : 'video';
        setMediaType(mediaType);
        setIsDriveOpen(true);
        setFileBeingEdited(file);
        setSelectedAdAccounts([adAccount]);
    };

    const handleFileSelect = (files) => {

        if (files.length > 0) {
            // Handle update scenario
            const selectedFile = files[0];

            const payload = {
                [selectedFile?.creativeType === 'video' ? 'video_id' : 'image_hash']: selectedFile?.creativeId,
                ...(selectedFile?.creativeType === 'image' ? { url: selectedFile?.url } : { thumbnail: selectedFile?.picture, url: selectedFile?.source }),
                hash: selectedFile?.hash,
            };

            updateAdspressocreativeAds({ adcreativeId: fileBeingEdited?._id, payload }).unwrap()
                .then(response => {
                    //console.log('Ad creative updated successfully:', response);
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating image",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                });
        }
        setFileBeingEdited(null);
    };


    const handleDeleteCreative = (creativeId) => {

        removeCreative(creativeId)
            .unwrap()
            .then(response => {
                toast({
                    position: "top-right",
                    title: "Ad creative deleted",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch(error => {
                toast({
                    position: "top-right",
                    title: "Error deleting Ad creative",
                    status: "error",
                    description: error?.data?.message,
                    duration: 4000,
                    isClosable: true,
                });
                //console.error('Failed to delete Ad creative:', error);
            });
    };

    const handleDeleteAd = (adId) => {
        removeAd(adId)
            .unwrap()
            .then(response => {
                toast({
                    position: "top-right",
                    title: "Ad deleted",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch(error => {
                toast({
                    position: "top-right",
                    title: "Error deleting Ad",
                    status: "error",
                    description: error?.data?.message,
                    duration: 4000,
                    isClosable: true,
                });
            });
    };

    const uploadedCreativesList = AdspressoCreatives?.data
        ?.filter(creative => creative?.adcreatives?.length > 0)
        ?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
        ?.map((creative, index) => {
            if (adFormik?.values?.format === creative?.adType) {
                return (
                    <Box key={creative?._id + index} p={'10px'} borderWidth={1} borderRadius="md" borderColor="gray.200">
                        <Flex justifyContent={'space-between'}>
                            <Text fontSize={'14px'} fontWeight={'bold'}>Ad Account: {creative?.adsAccount?.adsAccountName}</Text>
                            <Tooltip label="Delete" fontSize="xs">
                                <Box>
                                    <Icon
                                        color={userInfo?.role !== 'client' ? 'gray.300' : 'red.500'}
                                        _hover={{ color: 'gray.400' }}
                                        as={MdDelete}
                                        cursor={userInfo?.role !== 'client' ? 'not-allowed' : 'pointer'}
                                        boxSize={5}
                                        onClick={() => {
                                            if (userInfo?.role === 'client') {
                                                setSelectedAd(creative?._id);
                                                setModalOpen(true);
                                            }
                                        }}
                                        pointerEvents={userInfo?.role !== 'client' ? 'none' : 'auto'}
                                    />
                                </Box>
                            </Tooltip>
                        </Flex>
                        <Flex align={'flex-start'} direction={'column'}>
                            <Text fontSize={'14px'}>Adsets:</Text>
                            <Flex direction={'column'} ml={'5px'}>
                                {creative?.adsets?.map((adset, adsetIndex) => (
                                    <Text key={adsetIndex + adset._id} fontSize={'12px'}>{adset.name}</Text>
                                ))}
                            </Flex>
                        </Flex>

                        {/* Small Image Preview */}
                        <Flex wrap="wrap" gap={2} direction="column" mt={'10px'}>
                            {creative?.adcreatives?.map((file, fileIndex) => {
                                return (
                                    <Box key={file?.url + fileIndex}>
                                        {/* Based on adType */}
                                        {creative.adType === 'CAROUSEL' ? (
                                            <CarouselMediaUpdateForm
                                                adData={adData}
                                                adAccount={creative?.adsAccount}
                                                creative={file}
                                                call_to_action_options={call_to_action_options}
                                                conversionType={creative?.type}
                                                onDelete={handleDeleteCreative}
                                                onChangeImage={handleChangeMedia}
                                                onClick={() => openModal(file)}
                                            />
                                        ) : (
                                            <SingleMediaUpdateForm
                                                adData={adData}
                                                adAccount={creative?.adsAccount}
                                                call_to_action_options={call_to_action_options}
                                                creative={file}
                                                conversionType={creative?.type}
                                                onDelete={handleDeleteCreative}
                                                onChangeImage={handleChangeMedia}
                                                onClick={() => openModal(file)}
                                            />
                                        )}
                                    </Box>
                                );
                            })}
                        </Flex>
                    </Box>
                );
            }
            return null;
        });

    const confirmDelete = () => {
        if (selectedAd) {
            handleDeleteAd(selectedAd);
            setModalOpen(false);
            setSelectedAd(null);
        }
    };

    const creativesList = uploadedCreativesList?.filter(item => item !== null) || [];


    return (

        <>
            {/* Modal for Image/Video */}
            <Modal isOpen={issetModalOpen} onClose={closeModal} size="sm">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader />
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedMedia ? (
                            selectedMedia?.video_id ? (
                                <video
                                    src={selectedMedia?.url}
                                    controls
                                    style={{ width: '100%' }}
                                />
                            ) : (
                                <Image
                                    src={selectedMedia?.url}
                                    alt="image"
                                    mx={'auto'} maxW={'100%'} maxH={'50vh'} borderRadius={2}
                                />
                            )
                        ) : (
                            <Box>No media selected</Box>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>

            <DriveModal
                isDriveModalOpen={isDriveOpen}
                mediaType={mediaType}
                onClose={() => {
                    setIsDriveOpen(false);
                    setMediaType(null);
                }}
                mediaFormat={adFormik?.values?.format}
                onSubmit={handleFileSelect}
                selectedAdAccounts={selectedAdAccounts}
                uploadPurpose={'update'}
            />

            <DeleteConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={confirmDelete}
                message="Do you wish to proceed with deleting this Ad?"
            />

            <Flex p={'20px'} height="100%" gap={2}>
                {/* Scrollable Content */}
                <Box
                    flex="0.6"
                    overflowY="auto"
                    display="flex"
                    flexDirection="column"
                >
                    <form onSubmit={adFormik.handleSubmit}>
                        {/* Ad Name Section */}
                        <Flex direction="column" mb={4} bg={backgroundColor} p={4} borderRadius="md" gap={4}>
                            <Flex direction={'column'} gap={2}>
                                <Flex>
                                    <Text fontWeight="bold">Ad name</Text>
                                    <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                </Flex>
                                <Input
                                    placeholder="Enter your ad name here..."
                                    name="name"
                                    value={adFormik.values.name}
                                    onChange={adFormik.handleChange}
                                    onBlur={adFormik.handleBlur}
                                    size={'sm'}
                                    borderRadius={'md'}
                                    isDisabled={userInfo?.role !== 'client'}
                                />
                                {adFormik.touched.name && adFormik.errors.name ? (
                                    <Text color="red.500" fontSize={'12px'}>{adFormik.errors.name}</Text>
                                ) : null}
                            </Flex>

                            {/* Identity Section */}
                            <Flex direction="column" gap={2}>
                                <Text fontWeight="bold">Identity</Text>
                                <Flex alignItems={'center'}>
                                    <Text fontWeight="bold">Facebook pages</Text>
                                    <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Your Facebook Pages or Instagram accounts represents your business in ads. </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>

                                {
                                    adsAccounts?.[0] && (
                                        <FaceBookPageSelector
                                            adsAccount={adsAccounts[0]}
                                            selectedPageId={adData?.adsAccounts?.filter((account_) => account_?.adsAccountId == adsAccounts[0]?.adsAccountId)?.[0]?.page_id}
                                            onSelectedPage={(pageId) => handleFacebookPageSelection(pageId)}
                                        />
                                    )
                                }

                                {(adFormik.touched.accounts && adFormik?.errors.accounts) ? (
                                    <Text color="red.500" fontSize={'12px'}>{adFormik.errors.accounts}</Text>
                                ) : null}
                                <Divider />
                                <Text fontWeight="bold">Instagram accounts</Text>
                                {adsAccounts?.map((account, index) => {
                                    return (
                                        <InstagramAccountSelector
                                            adsAccount={account}
                                            selectedInstPageId={adData?.adsAccounts?.filter((account_) => account_?.adsAccountId == account?.adsAccountId)?.[0]?.instagram_actor_id}
                                            onSelectedPage={(page) => handleInstagramPageSelection(page, account.adsAccountId)}
                                            pageID={adFormik.values?.accounts?.length > 0 && adFormik.values?.accounts?.[0].page_id}
                                            key={`insta_accounts_${account?.adsAccountId}_${index}`}
                                            isNewAdCreating={!adData?.name}
                                        />
                                    )
                                })
                                }
                            </Flex>

                            <Flex justify="flex-start" >
                                <Button
                                    colorScheme="orange"
                                    isLoading={adFormik.isSubmitting}
                                    type="submit"
                                    size={'sm'}
                                    isDisabled={!adFormik.values.name || userInfo?.role !== 'client'}
                                >Save</Button>
                            </Flex>
                        </Flex>

                        {/* Ad Setup Section */}
                        <Flex direction="column" mb={4} bg={backgroundColor} p={4} borderRadius="md" gap={2}>

                            {/* Creative Source Section */}
                            <Flex direction="column" borderRadius="md" gap={2}>
                                <Flex align={'center'}>
                                    <Text fontWeight="bold">Creative source &nbsp;</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Choose how you'd like to provide the media for your ad. </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>

                            </Flex>

                            {/* Format Section */}
                            <Flex direction="column" mb={4} borderRadius="md" gap={2}>
                                <Flex align={'center'}>
                                    <Text fontWeight="bold">Format &nbsp;</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Choose how you'd like to structure your ad. </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>

                                <RadioGroup
                                    onChange={(value) => {
                                        adFormik.setFieldValue("format", value);
                                        adFormik.setFieldValue('mediaOption', '')
                                    }}
                                    value={adFormik.values.format}
                                >
                                    <Stack spacing={5} direction='column'>
                                        {/* Single image or video option */}
                                        <Flex direction="column">
                                            <Flex align="center">
                                                <Radio colorScheme='orange' value='SINGLE' />
                                                <Text fontWeight="bold" ml={2}>Single image or video</Text>
                                            </Flex>
                                            <Text fontSize={'sm'} ml={6}>One image or video, or a slideshow with multiple images</Text>
                                        </Flex>

                                        {/* Carousal format option */}
                                        <Flex direction="column">
                                            <Flex align="center">
                                                <Radio colorScheme='orange' value='CAROUSEL' />
                                                <Text fontWeight="bold" ml={2}>Carousel</Text>
                                            </Flex>
                                            <Text fontSize={'sm'} ml={6}>Two or more scrollable images or videos</Text>
                                        </Flex>
                                    </Stack>
                                </RadioGroup>
                            </Flex>


                            {/* Ad creative Section */}
                            <Flex direction="column" >
                                <Box mb={'10px'} >
                                    <Flex alignItems={'center'}>
                                        <Text fontWeight="bold" >Ad creative &nbsp;</Text>
                                        <Tooltip
                                            borderRadius={5}
                                            label={
                                                <Box fontSize="sm" p="5px">
                                                    <Text>Add a variety of creative elements and group the ones that can be shown together. We'll combine the right elements to show each person the version of your ad that they're most likely to engage with. </Text>
                                                </Box>
                                            }
                                            fontSize="md"
                                        >
                                            <InfoOutlineIcon pointerEvents="all" />
                                        </Tooltip>
                                    </Flex>
                                </Box>

                                {/* Media selection */}
                                <Flex direction="column">
                                    <Flex alignItems="center" justifyContent={'space-between'}>
                                        <Flex direction='row'>
                                            <Text fontWeight="bold">Media</Text>
                                            <Text display="inline-flex" fontSize="18px" color="red.400">*</Text>
                                        </Flex>
                                    </Flex>

                                    <Flex alignItems="center">
                                        {renderCreativeComponent()}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </form>
                </Box>
                <Box flex="0.4" overflowY="auto" bg={creativesList?.length > 0 ? backgroundColor : undefined} borderRadius="md" p={4}>
                    {creativesList?.length > 0 ? (
                        <>
                            <Flex direction="column" gap={2} >
                                {creativesList}
                            </Flex>
                        </>
                    ) : null}
                </Box>
            </Flex>
        </>
    );
}

export default AdForm;

