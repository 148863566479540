import { api } from "../api";

export const adsetFormApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createAdset: builder.mutation({
            query: ({ payload, adsAccountId }) => ({
                url: `/adsets/${adsAccountId}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['AdsetForm'],
        }),
        getAdsetForm: builder.query({
            query: ({ campaign_type }) => ({
                url: `/adsets/form`,
                params: { campaign_type },
            }),
            providesTags: ['AdsetForm'],
        }),
        getAdsetsList: builder.query({
            query: ({ pageSize, searchValue, adsAccountId, campaign_ids, adset_id, after, before }) => {
                let url = `/adsets/${adsAccountId}?`;

                if (campaign_ids && campaign_ids.length > 0) {
                    url += `campaign_ids=${JSON.stringify(campaign_ids)}&`;
                }
                if (pageSize) {
                    url += `pageSize=${pageSize}&`;
                }
                if (adset_id) {
                    url += `adset_id=${adset_id}&`;
                }
                if (searchValue) {
                    url += `search=${searchValue}&`;
                }
                if (after) {
                    url += `after=${after}&`;
                }
                if (before) {
                    url += `before=${before}&`;
                }
                // Remove the trailing "&" if it exists
                url = url.endsWith('&') ? url.slice(0, -1) : url;

                return { url };
            },
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdsetForm'],
        }),
        getAdsetInsights: builder.query({
            query: ({ searchValue, adsAccountId, adsets, start_date, date_preset, end_date }) => {
                let url = `/adsets/${adsAccountId}/insights?`;

                if (adsets && adsets.length > 0) {
                    url += `adsets=${encodeURIComponent(JSON.stringify(adsets))}&`;
                }
                if (searchValue) {
                    url += `search=${searchValue}&`;
                }
                if (start_date) {
                    url += `start_date=${start_date}&`;
                }
                if (end_date) {
                    url += `end_date=${end_date}&`;
                }
                if (date_preset) {
                    url += `date_preset=${date_preset}&`;
                }
                // Remove the trailing "&" if it exists
                url = url.endsWith('&') ? url.slice(0, -1) : url;

                return { url };
            },
            transformResponse: (response, meta, arg) => response.data,
            invalidatesTags: ['AdsetForm'],
        }),
        getAdsetByID: builder.query({
            query: ({ adsetId, adsAccountId }) => ({
                url: `/adsets/get/${adsetId}?adsAccountId=${adsAccountId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdsetForm'],
        }),
        getAdsets: builder.query({
            query: ({ adsAccountId, adsetIds }) => ({
                url: `/adsets/get/?adsAccountId=${adsAccountId}&adsetIds=${encodeURIComponent(JSON.stringify(adsetIds))}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdsetForm'],
        }),
        updateAdset: builder.mutation({
            query: ({ adsetId, payload }) => ({
                url: `/adsets/${adsetId}`,
                method: 'PUT',
                body: payload,
            }),
            transformResponse: (response, meta, arg) => response.data,
            invalidatesTags: ['AdsetForm'],
        }),
        updateAdsetStatus: builder.mutation({
            query: ({ adsetId, payload }) => ({
                url: `/adsets/update/status/${adsetId}`,
                method: 'PUT',
                body: payload,
            }),
            transformResponse: (response, meta, arg) => response.data,
            invalidatesTags: ['AdsetForm'],
        }),
        deleteAdset: builder.mutation({
            query: ({ adsetId, adsAccountId }) => ({
                url: `/adsets/${adsetId}?adsAccountId=${adsAccountId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AdsetForm'],
        }),
        getAdsetPlacements: builder.query({
            query: ({ optimization_goal, objective, buying_type, billing_event, account_id, destination_type, effective_device_platforms }) => ({
                url: `/adsets/ad_campaign_placement`,
                params: {
                    optimization_goal,
                    objective,
                    buying_type,
                    billing_event,
                    account_id,
                    destination_type,
                    effective_device_platforms,
                },
            }),
        }),
        // getAudienceEstimateList: builder.query({
        //     query: ({ adsAccountId, targeting_spec }) => ({
        //         url: `/adsets/reachestimate/${adsAccountId}?targeting_spec=${targeting_spec}`,
        //     }),
        //     transformResponse: (response, meta, arg) => response.data,
        //     providesTags: ['AdsetForm'],
        // }),
        getAudienceEstimateList: builder.mutation({
            query: ({ payload, adsAccountId }) => ({
                url: `/adsets/deliveryestimate/${adsAccountId}`,
                method: 'POST',
                body: payload,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdsetForm'],
        }),
        getConversionEvents: builder.query({
            query: ({ pixelId, businessId }) => ({
                url: `/adsets/stats/${pixelId}?businessId=${businessId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdsetForm'],
        }),
    }),
});

export const {
    useCreateAdsetMutation,
    useGetAdsetFormQuery,
    useGetAdsetsListQuery,
    useGetAdsetsQuery,
    useGetAdsetInsightsQuery,
    useGetAdsetByIDQuery,
    useUpdateAdsetMutation,
    useGetAdsetPlacementsQuery,
    useUpdateAdsetStatusMutation,
    useDeleteAdsetMutation,
    useGetAudienceEstimateListMutation,
    useGetConversionEventsQuery,
} = adsetFormApi;
