import {
    AvatarBadge,
    Box,
    Button,
    Flex,
    FormControl,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Switch,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import AppTableWithPageMeta from 'components/AppTableWithPageMeta';
import DeleteConfirmationModal from 'components/genericForms/DeleteConfirmationModal';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { FiEdit2, FiDelete } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGetcampaignsListQuery, useGetcampaignsInsightsQuery, useUpdateCampaignMutation, useUpdateCampaignStatusMutation, useDeleteCampaignMutation } from 'store/campaigns/campaignsApi';
import { setSelectedCampaignId, selectSelectedCampaignId, setCampaignsForInsights, selectCampaignsForInsights, selectAfter, selectBefore, setAfter, setBefore, selectCurrentPage, setCurrentPage } from 'store/campaigns/campaignsSlice';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import currencySymbolMap from 'currency-symbol-map';
import { AddIcon } from '@chakra-ui/icons';
import CreateCampaignForm from './Forms/CreateCampaignForm';

function CampaignsTable({ adsAccountData, startDate, endDate, datePreset, setSelectedTab, onRefresh, onRefreshComplete }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const selectedCampaignId = useSelector(selectSelectedCampaignId);
    const campaignsForInsights = useSelector(selectCampaignsForInsights);
    const userInfo = useSelector((state) => state.auth?.userInfo);
    const after = useSelector(selectAfter);
    const before = useSelector(selectBefore);
    const currentPage = useSelector(selectCurrentPage);
    const isSearchDisabled = Boolean(selectedCampaignId);
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCreateFormVisible, setIsCreateFormVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [campaignsListWithInsights, setCampaignsListWithInsights] = useState([]);
    const [switchStates, setSwitchStates] = useState({});
    const [isLoadingAccountChange, setIsLoadingAccountChange] = useState(false);
    const [isLoadingCampaignsListWithInsights, setIsLoadingCampaignsListWithInsights] = useState(true);
    const currencySymbol = currencySymbolMap(adsAccountData?.currency);

    const { data: campaignsList, isFetching, refetch: refetchCampaignsList, isLoading: isCampaignListLoading, error: campaignsListError } = useGetcampaignsListQuery({
        pageSize: '20',
        ...(searchValue ? {} : { after, before }),
        searchValue,
        adsAccountId: adsAccountData?.account_id,
        campaign_ids: selectedCampaignId ? [selectedCampaignId?.campaign_id ? selectedCampaignId?.campaign_id : selectedCampaignId] : [],
    }, { skip: !adsAccountData?.account_id });

    const { data: campaignsInsights, refetch: refetchCampaignsInsights, isLoading: isCampaignInsightsLoading, error: campaignsInsightsError } = useGetcampaignsInsightsQuery(
        {
            searchValue,
            adsAccountId: adsAccountData?.account_id,
            date_preset: datePreset,
            campaigns: campaignsForInsights?.length > 0 ? campaignsForInsights : [],
            ...(datePreset ? {} : { start_date: startDate, end_date: endDate }), // Conditionally add startDate and endDate
        },
        { skip: !adsAccountData?.account_id || campaignsForInsights?.length === 0 }
    );

    const [updateCampaign, { isLoading: isCampaignUpdating }] = useUpdateCampaignMutation({});
    const [updateCampaignStatus] = useUpdateCampaignStatusMutation({});
    const [deleteCampaign] = useDeleteCampaignMutation();


    useEffect(() => {
        if (adsAccountData?.account_id) {
            setIsLoadingAccountChange(true);
            refetchCampaignsList();
        }
    }, [adsAccountData?.account_id]);

    useEffect(() => {
        if (onRefresh) {
            refetchCampaignsList();
            onRefreshComplete();
        }
    }, [onRefresh, refetchCampaignsList]);

    useEffect(() => {
        if (campaignsList?.data) {
            const initialSwitchStates = {};
            campaignsList?.data.forEach(campaign => {
                initialSwitchStates[campaign.campaign_id] = campaign.status;
            });
            setSwitchStates(initialSwitchStates);

            const formattedCampaigns = campaignsList?.data.reduce((acc, campaign) => {
                const promotedObject = campaign?.adsets?.[0]?.promoted_object;
                const customEventType = promotedObject?.custom_event_type;

                // Only add campaigns to the list if custom_event_type is present
                if (campaign?.campaign_id && customEventType ) {
                    acc.push({
                        campaign_id: campaign?.campaign_id,
                        promoted_object: { custom_event_type: customEventType }
                    });
                }else{
                    acc.push({
                        campaign_id: campaign?.campaign_id,
                    });
                }
                return acc;
            }, []);

            dispatch(setCampaignsForInsights(formattedCampaigns));
        }
    }, [campaignsList]);

    useEffect(() => {
        if (campaignsList?.data || campaignsInsights) {

            const campaignsWithInsights = campaignsList?.data.map(campaign => {
                const insightData = campaignsInsights?.find(insight => insight?.campaign_id === campaign?.campaign_id);

                return {
                    ...campaign,
                    insight: insightData?.insights || {},
                };
            });

            setCampaignsListWithInsights(campaignsWithInsights);

            setTimeout(() => {
                setIsLoadingCampaignsListWithInsights(false);
            }, 100);
        }
    }, [campaignsList, campaignsInsights]);

    useEffect(() => {
        if (campaignsList?.data || campaignsInsights) {
            setIsLoadingAccountChange(false);
        }
    }, [campaignsList, campaignsInsights])

    useEffect(() => {
        if (selectedCampaign) {
            formik.setValues({
                name: selectedCampaign?.name,
                // objective: selectedCampaign.objective  
            });
        }
    }, [selectedCampaign]);

    const formik = useFormik({
        initialValues: {
            name: '',
            // objective: ''  
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Campaign name is required'),
            // objective: Yup.string().required('Objective is required')  
        }),
        onSubmit: (values) => {
            const { name } = values;
            const payload = {
                name: name,
                // objective: objective,  
                adsAccount: {
                    id: adsAccountData?.id,
                    adsAccountId: adsAccountData?.account_id,
                    adsAccountName: adsAccountData?.name,
                    businessName: adsAccountData?.business?.name,
                    businessId: adsAccountData?.business?.id
                },
            }
            updateCampaign({
                campaignId: selectedCampaign.campaign_id,
                payload
            }).unwrap()
                .then(() => {
                    refetchCampaignsList();
                    // refetchCampaignsInsights();
                    toast({
                        position: "top-right",
                        title: "Campaign updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                    onClose();
                })
                .catch((error) => {
                    toast({
                        position: "top-right",
                        title: "Error updating campaign",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                    console.error('Error updating campaign:', error);
                });
        }
    });

    const handleSwitchChange = (campaignId, currentState) => {
        const newStatus = currentState === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';

        // update the switch state
        setSwitchStates(prev => ({ ...prev, [campaignId]: newStatus }));
        updateCampaignStatus({
            campaignId,
            payload: {
                status: newStatus,
                adsAccount: {
                    id: adsAccountData?.id,
                    adsAccountId: adsAccountData?.account_id,
                    adsAccountName: adsAccountData?.name,
                    businessName: adsAccountData?.business?.name,
                    businessId: adsAccountData?.business?.id
                },
            }
        }).unwrap()
            .then(() => {
                refetchCampaignsList();
                // refetchCampaignsInsights();
                toast({
                    position: "top-right",
                    title: `Campaign ${newStatus === 'ACTIVE' ? 'activated' : 'paused'}`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    position: "top-right",
                    title: `Error updating campaign status`,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                console.error('Error updating campaign status:', error);

                // Revert switch state if API call fails
                setSwitchStates(prev => ({ ...prev, [campaignId]: currentState }));
            });
    };

    const handleDeleteCampaign = (campaignData) => {

        deleteCampaign({ campaignId: campaignData?.campaign_id, adsAccountId: adsAccountData?.account_id, })
            .unwrap()
            .then(response => {
                refetchCampaignsList();
                // refetchCampaignsInsights();
                toast({
                    position: "top-right",
                    title: "Campaign deleted",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch(error => {
                toast({
                    position: "top-right",
                    title: "Error deleting campaign",
                    status: "error",
                    description: error?.data?.message,
                    duration: 4000,
                    isClosable: true,
                });
            });
    };

    const handlePageChange = ({ after, before, currentPage }) => {
        if (after) {
            dispatch(setAfter(after));
            dispatch(setBefore(''));
        } else if (before) {
            dispatch(setBefore(before));
            dispatch(setAfter(''));
        }

        dispatch(setCurrentPage(currentPage));
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        params.set('page_id', currentPage);

        navigate({ search: params.toString() }, { replace: true });

    }, [currentPage]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const storedPage = params.get('page_id');

        if (storedPage && !isNaN(storedPage)) {
            const parsedPage = parseInt(storedPage, 10);
            if (parsedPage >= 0 && parsedPage !== currentPage) {
                dispatch(setCurrentPage(parsedPage));
            }
        } else if (currentPage !== 0) {
            dispatch(setCurrentPage(0));
        }
    }, [location.search]);


    const columns = useMemo(
        () => [
            {
                Header: "Off/On",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    const isActive = switchStates[original.campaign_id] ?? (original.status === 'ACTIVE');
                    const tooltipText = isActive ? 'Campaign is ON' : 'Campaign is OFF';

                    return (
                        <Flex alignItems={'center'} justifyContent={'center'}>
                            <Tooltip label={tooltipText} fontSize='xs' >
                                <FormControl width={'fit-content'}>
                                    <Switch
                                        colorScheme='orange'
                                        size={'sm'}
                                        isChecked={original.status === 'ACTIVE'}
                                        onChange={() => handleSwitchChange(original.campaign_id, original.status)}
                                        isDisabled={userInfo?.role !== 'client'}
                                    />
                                </FormControl>
                            </Tooltip>
                        </Flex>
                    )
                },
            },
            {
                Header: "Name",
                accessor: "name",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isSelected = selectedCampaignId?.campaign_id ? selectedCampaignId?.campaign_id : selectedCampaignId === original?.campaign_id;

                    return (
                        <Flex gap={'10px'} alignItems={'center'}>
                            <Tooltip label='Edit' fontSize='xs' >
                                <Flex>
                                    <Icon
                                        _hover={{ color: "gray.500" }}
                                        as={FiEdit2}
                                        cursor={userInfo?.role === 'client' ? "pointer" : "not-allowed"}
                                        color={userInfo?.role === 'client' ? "inherit" : "gray.300"}
                                        onClick={() => {
                                            if (userInfo?.role === 'client') {
                                                setSelectedCampaign(original);
                                                onOpen();
                                            }
                                        }}
                                    />
                                </Flex>
                            </Tooltip>
                            <Flex alignItems={'flex-start'}>
                                <Link
                                    to={'#'}
                                    onClick={() => {
                                        setSelectedTab(1);
                                        dispatch(setSelectedCampaignId(original));
                                    }}
                                >
                                    <Text
                                        _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        fontWeight={isSelected ? 'bold' : 'normal'}
                                        color={isSelected ? 'orange.500' : 'inherit'}
                                        fontSize={'14px'}
                                    >
                                        {original.name.length > 30 ? `${original.name.slice(0, 30)}...` : original.name}
                                    </Text>
                                </Link>
                            </Flex>
                        </Flex>

                    )
                },
            },
            {
                Header: "Delivery",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                

                    const getStatusMessage = (status) => {
                        switch (status) {
                            case 'WITH_ISSUES':
                                return 'Delivery error';
                            case 'CAMPAIGN_PAUSED':
                                return 'Campaign Off';
                            case 'ADSET_PAUSED':
                                return 'Adset Off';
                            case 'PAUSED':
                                return 'Off';
                            default:
                                return status || 'Unknown';
                        }
                    };
                    
                    const errorMessage = original?.issues_info?.[0]?.error_message || '';

                   // Function to determine circle color and border based on status
                    const getCircleStyles = (status) => {
                        switch (status) {
                            case 'WITH_ISSUES':
                                return { bg: 'rgb(161,11,24)', border: 'none' };  // Red for delivery error
                            case 'CAMPAIGN_PAUSED':
                            case 'ADSET_PAUSED':
                                return { bg: 'rgb(216,221,225)', border: 'none' };  // Gray for Campaign/Adset Off
                            case 'ACTIVE':
                                return { bg: 'rgb(255,255,255)', border: '2px solid green' };  // Gray background with green border for ACTIVE
                            default:
                                return { bg: 'rgb(255,255,255)', border: 'none' };  // Default gray with no border
                        }
                    };

                    const circleStyles = getCircleStyles(original?.effective_status);

                    // Determine tooltip message
                    const getTooltipMessage = (status) => {
                        switch (status) {
                            case 'WITH_ISSUES':
                                return errorMessage;  
                            case 'PAUSED':
                                return 'This campaign is turned off';  
                            case 'ACTIVE':
                                return 'This campaign is on and running normally.'; 
                            case 'ADSET_PAUSED':
                                return 'This ad is on, but its ad set is turned off.'; 
                            default:
                                return '';  // No tooltip for other statuses
                        }
                    };

                    const tooltipMessage = getTooltipMessage(original?.effective_status);

                    return (
                        <Flex>
                            {/* Only show Tooltip when status is WITH_ISSUES */}
                            {original?.effective_status === 'WITH_ISSUES' ? (
                                <Flex alignItems={'center'}>
                                 <Box
                                    bg={circleStyles.bg}
                                    border={circleStyles.border}
                                    borderRadius="50%" 
                                    boxSize="0.8rem"   
                                    display="inline-block"
                                    mr={2}             
                                />
                                <Tooltip label={tooltipMessage} aria-label="Error message" hasArrow p={2} fontSize={'12px'}>
                                    <Text fontSize={'14px'}>{getStatusMessage(original?.effective_status)}</Text>
                                </Tooltip>
                                </Flex>
                            ) : (
                                <Flex alignItems={'center'}>
                                    {original?.effective_status === 'PAUSED' ? (null) : (
                                       <Box
                                        bg={circleStyles.bg}
                                        border={circleStyles.border}
                                        borderRadius="50%" 
                                        boxSize="0.8rem"   
                                        display="inline-block"
                                        mr={2}             
                                    />
                                    )}
                                    <Tooltip label={tooltipMessage} aria-label="Error message" hasArrow p={2} fontSize={'12px'}>
                                        <Text fontSize={'14px'}>{getStatusMessage(original?.effective_status)}</Text>
                                    </Tooltip>
                                </Flex>
                            )}
                        </Flex>
                    )}
            }, 
            {
                Header: "Spend",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>
                            {original?.insight?.spend ? `${currencySymbol}${Number(original.insight.spend).toFixed(2)}` : '-'}
                        </Text>
                    </Flex>
                )
            },
            {
                Header: "Results",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex direction={'column'} mb={-4}>
                        <Text fontSize={'14px'}>{original.insight?.results ? (original.insight?.results) : '-'}</Text>
                        <Text fontSize={'12px'} color={'gray.500'}>{original.insight?.eventName ? (original.insight?.eventName) : null}</Text>
                    </Flex>
                )
            },
            {
                Header: "CPC",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>{original.insight?.cpc ? `${currencySymbol}${Number(original.insight?.cpc).toFixed(2)}` : '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "CTR",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex>
                        <Text fontSize={'14px'}>
                            {original.insight?.ctr ? `${Number(original.insight?.ctr).toFixed(2)}%` : '-'}
                        </Text>
                    </Flex>
                )
            },
            {
                Header: "CPM",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>{original.insight?.cpm ? Number(original.insight?.cpm).toFixed(2) : '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Clicks",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>{original.insight?.clicks ? original.insight?.clicks : '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Purchase Value",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>
                            {original.insight?.purchaseValue && original.insight.purchaseValue !== '-' ?
                                `${currencySymbol}${parseFloat(original.insight.purchaseValue).toFixed(2)}` :
                                original.insight?.purchaseValue || '-'}
                        </Text>
                    </Flex>
                )
            },
            {
                Header: "Roas",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const roas = original.insight?.roas;
            
                    // Check if roas is a valid number or a non-numeric value like '-'
                    const formattedROAS = (roas && !isNaN(parseFloat(roas))) 
                        ? parseFloat(roas).toFixed(2) 
                        : '-';  // Default to '-' if roas is invalid or non-numeric
            
                    return (
                        <Flex>
                            <Text fontSize={'14px'}>
                                {formattedROAS}
                            </Text>
                        </Flex>
                    );
                }
            },
            {
                Header: "Objective",
                accessor: "objective",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    let objective = '';

                    if (original.objective === 'OUTCOME_SALES') {
                        objective = 'Sales';
                    } else if (original.objective === 'OUTCOME_LEADS') {
                        objective = 'Leads';
                    }

                    return <Text fontSize={'14px'}>{objective}</Text>;
                }
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    // console.log(original);

                    let statusCopy = '';
                    if (original.status == 'ARCHIVED') {
                        statusCopy = 'PUBLISHED';
                    } else {
                        statusCopy = original.status;
                    }
                    return (
                        <Text fontSize={'14px'}>{statusCopy}</Text>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "createdDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text fontSize={'14px'}>{moment(original.created_time).format("DD-MM-YYYY hh:mm A")}</Text>

                ),
            },
            {
                Header: "Updated Date",
                accessor: "updatedDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text fontSize={'14px'}>{moment(original.updated_time).format("DD-MM-YYYY hh:mm A")}</Text>
                ),
            },
            {
                Header: "Actions",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'} alignItems={'center'}>
                        <Tooltip label='Delete' fontSize='xs' >
                            <Flex>
                                <Icon
                                    cursor={userInfo?.role === 'client' ? "pointer" : "not-allowed"}
                                    color={userInfo?.role === 'client' ? "red.500" : "gray.300"}
                                    as={FiDelete}
                                    onClick={() => {
                                        if (userInfo?.role === 'client') {
                                            setSelectedCampaign(original);
                                            setIsConfirmationModalOpen(true);
                                        }
                                    }}
                                />
                            </Flex>
                        </Tooltip>
                    </Flex>
                )
            },
        ],
        [selectedCampaignId, adsAccountData]
    );

    const confirmDelete = () => {
        if (selectedCampaign) {
            handleDeleteCampaign(selectedCampaign);
            setIsConfirmationModalOpen(false);
            setSelectedCampaign(null);
        }
    };

    const handleOnSuccess = () => {
        refetchCampaignsList();
    }

    return (
        <>
            <Flex mb={2}>
                <Box
                    as="button"
                    display="inline-flex"
                    alignItems="center"
                    bg="orange.300"
                    color="white"
                    fontWeight={'bold'}
                    borderRadius="md"
                    px={3}
                    py={1.5}
                    fontSize={'14px'}
                    onClick={() => setIsCreateFormVisible(true)}
                >
                    <AddIcon boxSize={3} />
                    &nbsp;Create
                </Box>
            </Flex>

            {isCreateFormVisible && (
                <CreateCampaignForm
                    adsAccountData={adsAccountData}
                    isOpen={isCreateFormVisible}
                    onClose={() => setIsCreateFormVisible(false)}
                    onSuccess={handleOnSuccess}
                />
            )}

            <DeleteConfirmationModal
                isOpen={isConfirmationModalOpen}
                onClose={() => setIsConfirmationModalOpen(false)}
                onConfirm={confirmDelete}
                message="Are you sure you want to delete this Campaign? This action can't be undone."
            />

            {/* EDIT CAMPAIGN MODAL */}
            <Modal
                size={"md"}
                isCentered={true}
                isOpen={isOpen}
                scrollBehavior={"inside"}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Campaign</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={formik.handleSubmit}>
                            <Box width="100%" maxWidth="600px" bg="gray.50" p={4} borderRadius="md">
                                <Flex direction="column" >
                                    <Box mb={4}>
                                        <Flex align="center">
                                            <Text mb={2}>
                                                Campaign Name
                                            </Text>
                                        </Flex>
                                        <Input
                                            placeholder="Enter campaign name"
                                            bg="#fff"
                                            name="name"
                                            size={'sm'}
                                            borderRadius="md"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <Text fontSize={'12px'} color="red.500" mt={1}>{formik.errors.name}</Text>
                                        )}
                                    </Box>
                                </Flex>

                                {/* Submit Button in a separate Flex container */}

                            </Box>
                            <Flex mt={4}>
                                <Button
                                    colorScheme="orange"
                                    isLoading={isCampaignUpdating}
                                    isDisabled={!formik.isValid}
                                    type="submit"
                                    size={'sm'}
                                    mb={'10px'}
                                >
                                    Save
                                </Button>
                            </Flex>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {campaignsListError || campaignsInsightsError ? (
                <Flex justifyContent={'center'} alignItems={'center'} height={'50vh'}>
                    <Text color="red.500">
                        {/* {"Sorry, Campaigns not available at this moment. Try again later."} */}
                        {campaignsListError?.data?.message}
                    </Text>
                </Flex>
            ) : (isCampaignListLoading || isCampaignInsightsLoading) ? (
                    <Spinner mt="20px" />
            ) : (
                <AppTableWithPageMeta
                    columns={columns}
                    data={campaignsListWithInsights || []}
                    searchEnabled={!isSearchDisabled}
                    isFetching={isFetching}
                    initialState={{ pageIndex: currentPage }}
                    pageMeta={campaignsList?.meta || {}}
                    onPageChange={handlePageChange}
                    onSearchInputChange={setSearchValue}
                    searchTerm={searchValue}
                />
            )}
        </>
    );
}

export default CampaignsTable;
