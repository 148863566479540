const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    selectedBmAccounts: [],
    selectedAdAccounts: [],
    fbData: null, 
    isRefetched: false,
    isFbDataRefetched:false,
    hasAdAccountAssigned: false,
    adAccountLoading: false,
    hasAdAccountChecked: false,
};

const bmSlice = createSlice({
    name: "bmAccounts",
    initialState,
    reducers: {
        setSelectedBmAccounts: (state, action) => {
            state.selectedBmAccounts = action.payload;
        },
        setSelectedAdAccounts: (state, action) => {
            state.selectedAdAccounts = action.payload;
        },
        setFbData: (state, action) => {
            state.fbData = action.payload; 
        },
        setRefetched: (state, { payload }) => {
            state.isRefetched = payload
        },
        setRefetchFbData: (state, { payload }) => {
            state.isFbDataRefetched = payload
        },
        setHasAdAccountAssigned: (state, { payload }) => {
            state.hasAdAccountAssigned = payload
        },
        setAdAccountsLoading: (state, { payload }) => {
            state.adAccountLoading = payload;
        },
        setHasAdAccountChecked(state, action) {
            state.hasAdAccountChecked = action.payload;
        },
    }
});

export const { setSelectedBmAccounts, setSelectedAdAccounts, setFbData, setRefetched, setRefetchFbData,  setHasAdAccountAssigned, setAdAccountsLoading, setHasAdAccountChecked } = bmSlice.actions;
export const selectSelectedBmAccounts = (state) => state.bmAccounts.selectedBmAccounts;
export const selectSelectedAdAccounts = (state) => state.bmAccounts.selectedAdAccounts;
export const selectFbData = (state) => state.bmAccounts.fbData; 
export const selectRefetched = (state) => state.bmAccounts.isRefetched; 
export const selectFbDataRefetched = (state) => state.bmAccounts.isFbDataRefetched; 
export const selectAdAccountAssigned = (state) => state.bmAccounts.hasAdAccountAssigned; 
export const selectAdAccountLoading = (state) => state.bmAccounts.adAccountLoading; 
export const selecthasAdAccountChecked = (state) => state.bmAccounts.hasAdAccountChecked; 
export default bmSlice.reducer;
