import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Badge, Box, Button, Flex, Spinner, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useToast, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiOutlineUserAdd } from "react-icons/ai";
import { useSelector } from 'react-redux';
import { useAssignPagesMutation, useGetClientPagesQuery, useGetOwnedPagesQuery } from 'store/BmAccounts/bmAccountApi';
import { selectSelectedBmAccounts } from 'store/BmAccounts/bmAccountSlice';
import SearchInput from './SearchInput';

function Pages() {
  const toast = useToast();
  const bmAccount = useSelector(selectSelectedBmAccounts);
  const [searchValue, setSearchValue] = useState('');

  const [assignedPages, setAssignedPages] = useState([]);
  const [loadingAssigned, setLoadingAssigned] = useState(true); 
  const [loadingStates, setLoadingStates] = useState({});  

  const boxColor = useColorModeValue('white', 'transparent');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.600');

  const { data: normalOwnedPages = [], isFetching: isOwnedFetching, isLoading: isOwnedLoading } = useGetOwnedPagesQuery(
    { search: searchValue, businessId: bmAccount?.businessId },
    { skip: !bmAccount?.businessId }
  );

  const { data: normalClientPages = [], isFetching: isClientFetching, isLoading: isClientLoading } = useGetClientPagesQuery(
    { search: searchValue, businessId: bmAccount?.businessId },
    { skip: !bmAccount?.businessId }
  );

  const { data: systemTokenOwnedPages = [], isFetching: isSystemTokenOwnedFetching } = useGetOwnedPagesQuery(
    { search: searchValue, businessId: bmAccount?.businessId, system_token: true },
    { skip: !bmAccount?.businessId },
  );

  const { data: systemTokenClientPages = [], isFetching: isSystemTokenClientFetching } = useGetClientPagesQuery(
    { search: searchValue, businessId: bmAccount?.businessId, system_token: true },
    { skip: !bmAccount?.businessId },
  );

  const combinedPages = [
    ...normalOwnedPages,
    ...normalClientPages.filter(page => !normalOwnedPages.some(ownedPage => ownedPage.id === page.id))  // Avoid duplicates
  ];

  // Merge and check if a page is assigned
  useEffect(() => {
    if (systemTokenOwnedPages.length || systemTokenClientPages.length) {
      const newAssignedPages = [
        ...systemTokenOwnedPages.map(page => page.id),
        ...systemTokenClientPages.map(page => page.id),
      ];
      if (newAssignedPages.length !== assignedPages.length) {
        setAssignedPages(newAssignedPages);
      }     
    }
      setLoadingAssigned(false);
  }, [systemTokenOwnedPages, systemTokenClientPages]);

  
  const [assignPages] = useAssignPagesMutation();

  const handleAssignPages = (pageId) => {

    setLoadingStates(prevState => ({
      ...prevState,
      [pageId]: true,
    }));

    assignPages({ pageId, businessId: bmAccount?.businessId})
      .unwrap()
      .then((response) => {
        toast({
          position: "top-right",
          title: "Assigned page successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        setAssignedPages(prev => [...prev, pageId]); 
         // Set the account as not loading
         setLoadingStates(prevState => ({
          ...prevState,
          [pageId]: false,
        }));
      })
      .catch((error) => {
        toast({
          position: "top-right",
          title: "Error in assigning page",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
         // Set the account as not loading
         setLoadingStates(prevState => ({
          ...prevState,
          [pageId]: false,
        }));
      });
  }
  
  if (!bmAccount?.businessId) {
    return <Flex justifyContent="center" alignItems="center" h={'50vh'} width={'100%'}>
             <Text fontSize={'14px'}>No Data available</Text>
           </Flex>
  }
  
  return (
    <Flex direction={'column'}>
      <Box display={'flex'} p={4}>
        <Flex alignItems={'center'} gap={2} flex={0.4}>
          <Text fontSize={'lg'} fontWeight={'bold'}>Pages</Text>
          <Tooltip
            borderRadius={5}
            placement={'right-start'}
            label={
              <Box fontSize="12px" p="5px">
                <Text>These are the Pages connected to this business portfolio.</Text>
              </Box>
            }
            fontSize="md"
          >
            <InfoOutlineIcon pointerEvents="all" />
          </Tooltip>
        </Flex>
        <Flex flex={0.6}>
            <SearchInput 
                data={combinedPages}
                isFetching={isOwnedFetching || isClientFetching}
                searchEnabled={true}
                onSearchInputChange={setSearchValue}
                searchTerm={searchValue}
            />
        </Flex>
      </Box>

      <Flex justifyContent={'center'}>
        {isOwnedLoading || isClientLoading || isSystemTokenOwnedFetching || isSystemTokenClientFetching ? (
            <Flex justifyContent="center" alignItems="center" h={'50vh'} width={'100%'}>
                <Spinner size="md" />
            </Flex>
        ) : combinedPages?.length === 0 ? (         
            <Flex justifyContent="center" alignItems="center" h={'50vh'} width={'100%'}>
              <Text fontSize={'14px'}>No pages available</Text>
            </Flex>
        ) : (
        <Box h={'62vh'} overflowY="auto" width={'100%'}>
          <Table variant="unstyled">
              <Thead position={'sticky'} top={'-1px'} zIndex={1} bg={boxColor}>
              <Tr
                  borderTop='1px solid'
                  borderColor={'gray.100'}
                  boxShadow="md" 
              >
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th>Action</Th>
              </Tr>
              </Thead>
              <Tbody>
              {combinedPages?.map((page) => (
                  <Tr
                    key={page.id}
                    borderTop={'1px solid'}
                    borderColor={'gray.100'}
                    _hover={{
                        bg: hoverBgColor, 
                    }}
                  >
                  <Td fontSize={'14px'}>{page?.id}</Td>
                  <Td fontSize={'14px'}>{page?.name}</Td>
                  <Td fontSize={'14px'}>
                      {loadingAssigned ? (
                        null
                      ) : assignedPages.includes(page?.id) ? (
                        <Badge
                          m={1}
                          fontSize={"12px"}
                          colorScheme={'green'}
                        >
                        Assigned
                      </Badge>
                      ) : (
                        <Button 
                          bg={'none'} 
                          border={'1px'} 
                          borderColor={'gray.100'} 
                          borderRadius={'md'} 
                          size="sm" 
                          onClick={() => handleAssignPages(page?.id)} 
                          isLoading={loadingStates[page?.id]}
                          loadingText=""
                        >
                          <AiOutlineUserAdd />
                          &nbsp;Assign
                        </Button>
                      )}
                  </Td>
                  </Tr>
              ))}
              </Tbody>
          </Table>
        </Box>
        )}
      </Flex>
    </Flex>
  )
}

export default Pages