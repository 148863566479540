import moment from "moment";
import { api } from "../api";

export const campaignsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createCampaign: builder.mutation({
            query: ({ payload, adsAccountId }) => ({
                url: `/campaigns/${adsAccountId}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Campaigns'],
        }),
        getcampaignsList: builder.query({
            query: ({ pageSize, searchValue, adsAccountId, campaign_ids, after, before }) => {
                let url = `/campaigns/${adsAccountId}?`;
        
                if (campaign_ids && campaign_ids.length > 0) {
                    url += `campaign_ids=${encodeURIComponent(JSON.stringify(campaign_ids))}&`;
                }
                if (pageSize) {
                    url += `pageSize=${pageSize}&`;
                }
                if (searchValue) {
                    url += `search=${searchValue}&`;
                }
                if (after) {
                    url += `after=${after}&`;
                }
                if (before) {
                    url += `before=${before}&`;
                }
                // Remove the trailing "&" if it exists
                url = url.endsWith('&') ? url.slice(0, -1) : url;
        
                return { url };
            },
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['Campaigns'],
        }),   
        getcampaignsInsights: builder.query({
            query: ({ searchValue, adsAccountId, campaigns, start_date, date_preset, end_date }) => {
                let url = `/campaigns/${adsAccountId}/insights?`;
        
                if (searchValue) {
                    url += `search=${searchValue}&`;
                }
                if (start_date) {
                    url += `start_date=${start_date}&`;
                }
                if (end_date) {
                    url += `end_date=${end_date}&`;
                }
                if (date_preset) {
                    url += `date_preset=${date_preset}&`;
                }
                if (campaigns && campaigns.length > 0) {
                    url += `campaigns=${encodeURIComponent(JSON.stringify(campaigns))}&`;
                }
                // Remove the trailing "&" if it exists
                url = url.endsWith('&') ? url.slice(0, -1) : url;
        
                return { url };
            },
            transformResponse: (response, meta, arg) => response.data,
            invalidatesTags: ['Campaigns'],
        }),           
        getcampaignForm: builder.query({
            query: () => ({
                url: `/campaigns/form`,
            }),
        }),
        getcampaignById: builder.query({
            query: ({ campaignId, adsAccountId }) => ({
                url: `/campaigns/get/${campaignId}?adsAccountId=${adsAccountId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['Campaigns']
        }),
        updateCampaign: builder.mutation({
            query: ({ campaignId, payload }) => ({
                url: `/campaigns/${campaignId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        updateCampaignStatus: builder.mutation({
            query: ({ campaignId, payload }) => ({
                url: `/campaigns/update/status/${campaignId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        getCampaignInsights: builder.query({
            query: ({ campaignId, startDate, endDate }) => {

                let dateQuery = (moment(startDate)?.isValid() && moment(endDate)?.isValid()) ? `start_date=${startDate}&end_date=${endDate}` : ``;

                return ({
                    url: `/campaigns/insights/${campaignId}?${dateQuery}&limit=1`,
                })
            },
            transformResponse: (response, meta, arg) => response.data,
        }),
        deleteCampaign: builder.mutation({
            query: ({ campaignId, adsAccountId })=> ({
              url: `/campaigns/${campaignId}?adsAccountId=${adsAccountId}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['Campaigns']
        }),
    }),
});

export const { useCreateCampaignMutation, useGetcampaignsListQuery,useGetcampaignsInsightsQuery, useGetcampaignFormQuery, useUpdateCampaignMutation, useGetCampaignInsightsQuery, useUpdateCampaignStatusMutation, useGetcampaignByIdQuery, useDeleteCampaignMutation } = campaignsApi;


