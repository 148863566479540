import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    Spinner,
    Text,
    useToast,
    Select,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdatePublishAdMutation } from 'store/publishTracking/trackingApi';
import DriveModal from 'components/drive/DriveModal';
import FaceBookPageSelector from '../FaceBookPageSelector';

const SingleCreativeAdupdate = ({ trackingData, onClose, onSaveSuccess, call_to_action_options }) => {
    const { adsAccount, form, _id } = trackingData;
    const toast = useToast();
    const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
    const [updatePublishAd] = useUpdatePublishAdMutation();
    
    //console.log(trackingData)

    const initialValues = {

        name: form.name || "",
        cpa: form.cpa || "",
        identity: {
            page_id: form?.identity?.page_id || "",
        },
        adcreative: {
            _id: form.adcreative?._id || "",
            message: form.adcreative?.message || "",
            name: form.adcreative?.name || "",
            description: form.adcreative?.description || "",
            type: form.adcreative?.type || "",
            hash: form.adcreative?.hash || "",
            link: form.adcreative?.link || "",
            video_id: form.adcreative?.video_id || "",
            thumbnail: form.adcreative?.thumbnail || "",
            image_hash: form.adcreative?.image_hash || "",
            url: form.adcreative?.url || "",
        },
    };

    // Validation Schema
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Ad Name is required'),
        identity: Yup.object().shape({
            page_id: Yup.string().required('Page ID is required'),
        }),
        adcreative: Yup.object().shape({
            // message: Yup.string().required('Message is required'),
            name: Yup.string(),
            description: Yup.string(),
            type: Yup.string().required('Type is required'),
            link: Yup.string().url('Invalid URL').required('Link URL is required'),
        }),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            const payload = {
                name: values.name,
                cpa: values.cpa,
                identity: {
                    page_id: values.identity.page_id,
                },
                adcreative: {
                    _id: values.adcreative._id,
                    ...(values.adcreative.message?.trim() && { message: values.adcreative.message }),
                    name: values.adcreative.name,
                    description: values.adcreative.description,
                    type: values.adcreative.type,
                    link: values.adcreative.link,
                    hash: values.adcreative.hash,
                    ...(formik.values.adcreative.video_id && {
                        video_id: values.adcreative.video_id,
                        thumbnail: values.adcreative.thumbnail,
                    }),
                    ...(formik.values.adcreative.image_hash && {
                        image_hash: values.adcreative.image_hash,
                        url: values.adcreative.url,
                    }),
                },
            };
            // console.log("payload",payload)
            updatePublishAd({ trackingId: _id, payload })
                .unwrap()
                .then(response => {
                    onSaveSuccess();
                    toast({
                        position: "top-right",
                        title: "Ad updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating ad",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    onClose();
                });
        },
    });

    const handleOpenDriveModal = () => {
        setIsDriveModalOpen(true);
    };

    const handleMediaSelect = (file) => {
        if (file && file.length > 0) {
            const selectedFile = file[0];

            if (formik.values?.adcreative?.image_hash) {
                formik.setFieldValue('adcreative.image_hash', selectedFile?.creativeId);
                formik.setFieldValue('adcreative.url', selectedFile?.url);
                formik.setFieldValue('adcreative.hash', selectedFile?.hash);
            } else {
                formik.setFieldValue('adcreative.video_id', selectedFile?.creativeId);
                formik.setFieldValue('adcreative.thumbnail', selectedFile?.picture);
                formik.setFieldValue('adcreative.hash', selectedFile?.hash);
            }
        }
        setIsDriveModalOpen(false);
    };

    if (!trackingData || !formik.values) {
        return <Spinner mt={'20px'} />;
    }   

    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex direction={'column'}>
                <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Ad Name</Text>
                        <Input
                            name="name"
                            placeholder="Enter ad name"
                            size='sm'
                            borderRadius="md"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Facebook Page ID</Text>
                        <FaceBookPageSelector
                            adsAccount={adsAccount}
                            selectedPageId={formik?.values?.identity?.page_id}
                            onSelectedPage={(pageId) => {
                                formik.setFieldValue('identity.page_id', pageId);
                            }}
                        />
                        {formik.errors.identity?.page_id && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.identity?.page_id}</Text>
                        )}
                    </Flex>
                </Flex>
                <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Primary text</Text>
                        <Input
                            name="adcreative.message"
                            placeholder="Enter message"
                            size='sm'
                            borderRadius="md"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.adcreative.message}
                        />
                        {formik.touched.adcreative?.message && formik.errors.adcreative?.message && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative?.message}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Headline</Text>
                        <Input
                            name="adcreative.name"
                            placeholder="Enter name"
                            size='sm'
                            borderRadius="md"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.adcreative.name}
                        />
                        {formik.touched.adcreative?.name && formik.errors.adcreative?.name && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative?.name}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Description</Text>
                        <Input
                            name="adcreative.description"
                            placeholder="Enter description"
                            size='sm'
                            borderRadius="md"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.adcreative.description}
                        />
                        {formik.touched.adcreative?.description && formik.errors.adcreative?.description && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative?.description}</Text>
                        )}
                    </Flex>


                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Call to action</Text>

                            {formik.values.adcreative?.type  === 'CALL_NOW' ? (
                                    <Select
                                        name="adcreative.type"
                                        size="sm"
                                        borderRadius="md"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.adcreative.type}
                                        isDisabled
                                        >
                                        <option value="CALL_NOW">Call Now</option>
                                    </Select>
                                ) : (
                                    <Select
                                        name="adcreative.type"
                                        size="sm"
                                        borderRadius="md"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.adcreative.type}
                                    >
                                        {call_to_action_options?.map((option) => (
                                            <option key={option.value} value={option.value}>
                                            {option.label}
                                            </option>
                                        ))}
                                    </Select>
                                )}

                            {formik.touched.adcreative?.type && formik.errors.adcreative?.type && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative?.type}</Text>
                            )}  
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Website URL</Text>
                        <Input
                            name="adcreative.link"
                            placeholder="Enter link URL"
                            size='sm'
                            borderRadius="md"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.adcreative.link}
                        />
                        {formik.touched.adcreative?.link && formik.errors.adcreative?.link && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative?.link}</Text>
                        )}
                    </Flex>
                    <Box>
                        <Text fontWeight="bold">Creative</Text>
                        <Flex direction="row" gap={2} alignItems={'center'}>
                            <img
                                src={formik.values.adcreative.image_hash ? formik.values.adcreative.url : formik.values.adcreative.thumbnail }
                                alt={formik.values.adcreative.name}
                                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                            />
                            <Button
                                bg={'gray.50'}
                                size="xs"
                                borderWidth={1}
                                onClick={handleOpenDriveModal}>
                                {formik.values?.adcreative?.image_hash ? "Change Image" : "Change Video"}
                            </Button>
                        </Flex>
                    </Box>
                </Flex>
                        
                <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">CPA</Text>
                        <Input
                            name="cpa"
                            placeholder="Enter CPA"
                            size='sm'
                            borderRadius="md"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.cpa}
                        />
                </Flex>
            </Flex>

                <Flex mb={2}>
                    <Button
                        size="sm"
                        colorScheme="orange"
                        isLoading={formik.isSubmitting}
                        type="submit"
                        isDisabled={!formik.isValid}
                    >
                        Save
                    </Button>
                </Flex>
            </Flex>

            {/* Drive Modal */}
            <DriveModal
                isDriveModalOpen={isDriveModalOpen}
                selectedAdAccounts={[{
                    id: adsAccount._id,
                    adsAccountId: adsAccount.adsAccountId,
                    adsAccountName: adsAccount.adsAccountName,
                }]}
                onClose={() => setIsDriveModalOpen(false)}
                onSubmit={handleMediaSelect}
                uploadPurpose="update"
                mediaType={formik.values?.adcreative?.image_hash ? 'image' : 'video'}
            />
        </form>
    );
};

export default SingleCreativeAdupdate;
